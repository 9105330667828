import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash-es';

interface openToastData {
  title: string;
  message: string;
  type: "success" | "error" | "info" | "warning";
}

@Injectable({
  providedIn: 'root'
})

export class ErrorHandlerService {


  private options: GlobalConfig;
  private lastInserted: number[] = [];

  constructor(
    private toastr: ToastrService
  ) {
    this.options = this.toastr.toastrConfig;
    this.options.tapToDismiss = false;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.resetTimeoutOnDuplicate = true;
    this.options.timeOut = 3000;
    this.options.extendedTimeOut = 200;
    this.options.maxOpened = 3;
    this.options.easeTime = 200;
  }

  public openToast(data: openToastData) {
    const { message, title, type } = data
    const opt = cloneDeep(this.options);
    const inserted = this.toastr.show(
      message,
      title,
      opt,
      this.options.iconClasses[type],
    );
    if (inserted) {
      this.lastInserted.push(inserted.toastId);
    }
    return inserted;
  }
}
