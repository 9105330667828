import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { Set } from 'src/app/interfaces/set';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  loggedInUserId = '';
  member = null;
  memberGroups = [];
  subscription: Subscription[] = [];
  currSetGroupId = '';
  setMap: any = {}
  organizationalStructure = []


  constructor(
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService,
    private setService: SetsService,
    private store: Store<AppState>,
  ) { }

  async ngOnInit(): Promise<void> {

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
        }
      })
    )

    this.member = await this.memberService.getMember(this.loggedInUserId).toPromise();
    this.organizationalStructureService.loadOrganizationalStructure();
    this.setService.loadSets();
    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
        this.organizationalStructure = groups
        this.memberGroups = [];
        groups.forEach((group: Group) => {
          if (group.members.some(memberId => memberId === this.member.id)) {
            this.memberGroups.push(group)
            if (!this.currSetGroupId) this.currSetGroupId = group.id
          }
        });

      })
    )

    this.subscription.push(
      this.setService.set$.subscribe(sets => {
        this.setMap = {}
        sets.forEach((set: Set) => {
          // if (set.group.type === 'organization') this.setMap.global.push(set);
          if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set);
          else this.setMap[set.group.id] = [set]
        });
      })
    )
  }


  getCardImg(groupId) {
    return this.organizationalStructure.find(group => group.id === groupId)?.logoUrl
  }

}
