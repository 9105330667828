<div dirControl class="main-card card">
    <div class="card-body">
        <div class="position-relative mb-3">
            <button (click)="selectAll()" class="btn btn-primary mb-3">{{'select all'|translatePipe}}</button>
            <div class="col-12 list">

                <div class="row">
                    <div *ngFor="let member of members" class="custom-checkbox custom-control form-check col-6 col-sm-4 col-md-3 col-lg-2">
                        <input type="checkbox" [id]="member.id" class="form-check-input" [checked]="member.isCheck"
                            (change)="member.isCheck = !member.isCheck">
                        <label [for]="member.id" class="form-label form-check-label" [class.uncheck]="!member.isCheck">
                            <img class="col-12 mb-3" [src]="member.profileImg" alt="">
                            <p class="col-12 identifier">{{member.identifier}}</p>
                        </label>
                    </div>
                </div>
            </div>

        </div>

        <form *ngIf="getNumOfSelected()" class="options mb-3">
            <label for="action">{{'selected'|translatePipe}}: {{getNumOfSelected()}} {{'users'|translatePipe}}:</label>
            <div>
                <select id="action" [(ngModel)]="currAction" class="form-control form-select"
                    name="searchOptionUserName">
                    <option *ngFor="let option of options" [ngValue]="option">
                        {{option|translatePipe}}</option>
                </select>
            </div>
            <div>
                <button class="btn btn-primary">{{'go'|translatePipe}}</button>
            </div>
        </form>
        <div class="col-12">
            <button (click)="goBack()" class="btn btn-danger">{{'back'|translatePipe}}</button>
        </div>
    </div>

</div>