import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateTime'
})
export class FormatDateTimePipe implements PipeTransform {


  transform(value: number): string {
    const date = new Date(value)
    let minutes = date.getMinutes()
    const minutesString = minutes > 9 ? '' + minutes : '0' + minutes;
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${minutesString}`
  }

}
