<app-input-label name="roleSearch" [(value)]="roleSearch" [label]="'search role'|translatePipe"></app-input-label>

<div class="table-headers">
    <div class="name">
        {{'name'|translatePipe}}
    </div>
    <div class="members">
        {{'members'|translatePipe}}
    </div>
    <div class="name">
        {{'options'|translatePipe}}
    </div>
</div>
<div cdkDropList class="role-list" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let role of roles">
        <div class="role-item" *ngIf="role.name.toLowerCase().includes(roleSearch.toLowerCase())"  cdkDrag>
            <div cdkDragHandle class="name" [ngStyle]="{'color':role.color}">
                {{role.name|titleCase}}
            </div>
            <div class="members">
                <button (click)="manageRoleMembers(role)" class="btn btn-primary">
                    {{role.members.length}} {{'members'|translatePipe}}
                </button>
            </div>
            <div class="options">
                <a [routerLink]="'/role-view/'+role.id" class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i
                        class="pe-7s-edit btn-icon-wrapper">
                    </i></a>
                <button (click)="deleteRole(role)" class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                        class="pe-7s-close btn-icon-wrapper"> </i></button>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #manageRoleMembersPop let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Add {{currRole.name}}</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-dropdown-input [(text)]="memberSearch" [dropDownOpions]="notRoleMembers"
            (chooseOption)="openToggleRoleMemberModal($event,false)">
        </app-dropdown-input>
        <div class="role-members">
            <div *ngFor="let member of currRole.members" class="role-member space-between">
                <span>{{member.identifier}}</span>
                <button (click)="openToggleRoleMemberModal(member,true)"
                    class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only"><i
                        class="pe-7s-close btn-icon-wrapper"> </i></button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

<ng-template #sureModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{isSureDelete?'Remove':'Give'}} {{currRole.name}} role
        </h5>

        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            isSureDelete?
            'Are you sure you want to remove '+currRole.name+ ' role from':
            'Are you sure to give '+ currRole.name +' role to'
            }} {{currMember.identifier}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" [class.btn-danger]="isSureDelete" [class.btn-primary]="!isSureDelete"
            (click)="toggleRoleMember(close)">
            {{isSureDelete?'Remove':'Give'}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

<a routerLink="/role-view" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw" aria-hidden="true"
        title="add"></i></a>