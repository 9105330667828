<ng-container [ngSwitch]="set.property.type">
    <app-input-label *ngSwitchCase="'number'" [required]="set.advancedOptions.isRequired" [name]="set.title"
        [label]="set.title" type="number" [(value)]="value" [(isValid)]="isValid"
        [min]="set.functionality.min.isCheck? set.functionality.min.number: undefined"
        [max]="set.functionality.max.isCheck? set.functionality.max.number: undefined">
    </app-input-label>

    <app-date-picker *ngSwitchCase="'timestamp'" [required]="set.advancedOptions.isRequired" [label]="set.title"
        [(timestamp)]="value">
    </app-date-picker>

    <app-time-picker *ngSwitchCase="'time'" [required]="set.advancedOptions.isRequired" [(time)]="value"
        [label]="set.title" [name]="set.title">
    </app-time-picker>

    <app-image-picker *ngSwitchCase="'url'" [required]="set.advancedOptions.isRequired" [(value)]="value"
        [label]="set.title" [name]="set.title">
    </app-image-picker>


    <app-dropdown-label *ngSwitchCase="'select'" [label]="set.title" [(value)]="value" [name]="set.title"
        [options]="set.options"></app-dropdown-label>

    <app-rate-picker *ngSwitchCase="'rate'" [label]="set.title" [(value)]="value"></app-rate-picker>

    <app-dropdown-multy-select *ngSwitchCase="'tags'" [label]="set.title" [(values)]="value">
    </app-dropdown-multy-select>


    <app-check-box-label *ngSwitchCase="'check'" [label]="set.title" [(isCheck)]="value" [name]="set.id"
        [color]="(set.functionality?.color?.isCheck)?set.functionality.color.value:null"></app-check-box-label>

    <app-textarea-label *ngSwitchCase="'long-text'" [required]="set.advancedOptions.isRequired" [label]="set.title"
        [name]="set.title" [(value)]="value"
        [minChar]="set.functionality?.minChar?.isCheck? set.functionality.minChar.number: undefined"
        [maxChar]="set.functionality?.maxChar?.isCheck? set.functionality.maxChar.number: undefined">
    </app-textarea-label>

    <app-input-label *ngSwitchDefault [required]="set.advancedOptions.isRequired" [label]="set.title" [name]="set.title"
        type="text" [(isValid)]="isValid" [(value)]="value"
        [minChar]="set.functionality?.minChar?.isCheck? set.functionality.minChar.number: undefined"
        [maxChar]="set.functionality?.maxChar?.isCheck? set.functionality.maxChar.number: undefined">
    </app-input-label>
</ng-container>