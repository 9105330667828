import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-button-box-tree',
  templateUrl: './button-box-tree.component.html',
  styleUrls: ['./button-box-tree.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class ButtonBoxTreeComponent implements OnInit, OnChanges {

  @Input() group !: Group  ;
  @Input() numOfSpace !: number;
  @Output() onChooseGroup = new EventEmitter<string>();


  children: Group[] = [];
  arrayOfSpace: any[] = [];
  slideSituation = 'out'



  constructor(private organizationalStructureService: OrganizationalStructureService) { }

  ngOnInit(): void {
    this.arrayOfSpace = [...Array(this.numOfSpace).keys()]
    this.updateChildren()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChildren();
  }

  toggleSlideSituation(): void {
    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in';
  }

  async updateChildren() {
    const newChildren: Group[] = [];
    this.group?.children.forEach(async (childId) => {
      const child = await (await this.organizationalStructureService.getGroupById(childId).toPromise());
      newChildren.push(child);
    });
    this.children = newChildren;
  }

}
