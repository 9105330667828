<div dirControl class="div">
    <app-organization-header [organization]="organization"></app-organization-header>
    <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper">
        <mat-step [stepControl]="confirmationFormGroup">
            <ng-template matStepLabel>
                <!-- Confirmation -->
            </ng-template>
            <div class="term-of-service">

                <ng-container *ngIf="isInvition; else notInvition">
                    <h3 class="card-title text-center">
                        {{'you are invited to'|translatePipe}} {{organization?.name}} {{'club'|translatePipe}} <span
                            *ngIf="invitedBy">{{'by'|translatePipe}}
                            {{invitedBy.identifier}}</span>
                    </h3>

                </ng-container>
                <ng-template #notInvition>
                    <h3 class="card-title text-center">{{'welcome to'|translatePipe}} {{organization?.name}}
                        {{'club'|translatePipe}}</h3>
                </ng-template>

                <p>
                    {{'lorem'|translatePipe}}
                </p>
                <p>
                    {{'lorem'|translatePipe}}

                </p>
                <p>
                    {{'lorem'|translatePipe}}

                </p>
                <p>
                    {{'lorem'|translatePipe}}

                </p>
                <p>
                    {{'lorem'|translatePipe}}

                </p>
            </div>
            <app-check-box-label name="isAcceptTermOfService" [(isCheck)]="isAcceptTermOfService"
                [label]="'i have read and accept Flexyc terms and condition'|translatePipe"></app-check-box-label>
            <button [disabled]="!isAcceptTermOfService" class="btn btn-primary mb-2 mr-2" mat-button
                matStepperNext>{{'next'|translatePipe}}</button>
        </mat-step>
        <mat-step [stepControl]="identificationFormGroup">
            <ng-template matStepLabel>
                <!-- Identification -->
            </ng-template>
            <app-input-label (isValidChange)="isValidChange($event)" [isValid]="isEmailValid"
                [label]="'email'|translatePipe" name="email" [required]="true" [email]="true" type="text"
                [(value)]="email">
            </app-input-label>
            <div class="btns-container">
                <button class="btn btn-secondary" mat-button matStepperPrevious>{{'back'|translatePipe}}</button>
                <button (click)="sendCode()" [disabled]="!isEmailValid" class="btn btn-primary" mat-button
                    matStepperNext>{{'next'|translatePipe}}</button>
            </div>
        </mat-step>
        <mat-step [stepControl]="authenticationFormGroup" *ngIf="isHaveAuth">
            <ng-template matStepLabel>
                <!-- Authentication -->
            </ng-template>
            <p>{{'what a code did you receive'|translatePipe}}?</p>
            <app-verify-code (valueChange)="verifyCodeChange($event)" [value]="verifyCode"></app-verify-code>
            <ul class="verify-btns">
                <li>{{'code has been send to'|translatePipe}} {{email}}. <button matStepperPrevious
                        style="border: none; background-color: transparent; padding: 0;"
                        (click)="chageIdentification()">{{'change it'|translatePipe}}</button> </li>
                <li>{{'what code'|translatePipe}}? <button
                        style="border: none; background-color: transparent; padding: 0;" (click)="sendCode()">{{'i did
                        not received anything'|translatePipe}}...</button> </li>
            </ul>
            <div class="btns-container">
                <button class="btn btn-secondary" mat-button matStepperPrevious>{{'back'|translatePipe}}</button>
                <button [disabled]="verifyCode!==backendVerifyCode" class="btn btn-primary" mat-button
                    matStepperNext>{{'next'|translatePipe}}</button>
            </div>

        </mat-step>
        <mat-step [stepControl]="passwordFormGroup">
            <ng-template matStepLabel>
                <!-- Password -->
            </ng-template>
            <app-register-password [(repeatPassword)]="password" (isPasswordValidChange)="isPasswordValidChange($event)"
                [isPasswordValid]="isPasswordValid">
            </app-register-password>
            <div class="btns-container">
                <button class="btn btn-secondary" mat-button matStepperPrevious>Back</button>
                <button (click)="sendCode()" [disabled]="!isPasswordValid" class="btn btn-primary" mat-button
                    matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step [stepControl]="dataFormGroup">
            <ng-template matStepLabel>
                <!-- Fill Set Data -->
            </ng-template>
            <app-input-label #userNameInput label="User name" name="username" [required]="true" type="text"
                [asyncValidators]="['usernameTaken']" [(value)]="userName">
            </app-input-label>
            <ng-container *ngFor="let set of setList">
                <app-set-input [(value)]="userSetMap[set.id].data" [(isValid)]="userSetMap[set.id].isValid" [set]="set">
                </app-set-input>
            </ng-container>
            <div class="btns-container">
                <button class="btn btn-secondary" mat-button matStepperPrevious>{{"back"|translatePipe}}</button>
                <button (click)="sendCode()" [disabled]="!(isUserNameValid && setDataValid)" class="btn btn-primary"
                    mat-button matStepperNext>{{'next'|translatePipe}}</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>
                <!-- Completed -->
            </ng-template>
            <h3 class="card-title text-center">Thank you for joining {{organization?.name}} club</h3>
            <p>
                {{'lorem'|translatePipe}}
            </p>
            <p>
                {{'lorem'|translatePipe}}
            </p>
            <div class="text-center">
                <button class="btn btn-success">{{organization?.name || '' | titleCase}} {{'home page'|translatePipe}}</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>