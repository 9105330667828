import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-event-mini-view-server',
  templateUrl: './add-event-mini-view-server.component.html',
  styleUrls: ['./add-event-mini-view-server.component.scss'],

})
export class AddEventMiniViewServerComponent implements OnInit {
  @Input() accountTypeAndId;

  constructor(
    private router: Router,
  ) { }



  ngOnInit(): void {
  }

  onAddEvent() {
    this.router.navigate(['account-event-view-server','account', this.accountTypeAndId]);
  }

}
