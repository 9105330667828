import * as AuthActions from '../actions/auth.actions';

type Action = AuthActions.login | AuthActions.logout

export const initialState = {
  email: 'oasdko@asddas.com',
  id: 'u101',
  token: '11111',
  profileImg: "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg",
};

export interface State {
  email: string,
  id: string,
  token: string
}

export function authReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case AuthActions.ActionTypes.login:
      return {
        ...state,
        token: action.token
      };
    case AuthActions.ActionTypes.logout:
      return {
        ...state,
        email: '',
        id: '',
        token: ''
      };
    default:
      return state;
  }
}