import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariablesService } from './global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class AccountEventServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'AccountEvent'


  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
  ) { }

  public getEmpty() {
    return {
      "id": "",
      "accountId": 0,
      "accountType":0,
      "eventImage": "https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/hpk415zehgopqjbm8tc8.jpg",
      "proccess": "",
      "type": "",
      "format": "",
      "title": "",
      "participants": [],
      "date": Date.now(),
      "location": "",
      "description": "",
      "attachFiles": [],
      "isVisibleToAccount": false,
      "formatData": null
    }
  }

  getAccountEvent(accountType, accountId:Number,limit? : Number){
    console.log(accountType,accountId,limit);
    
    return this.http.post<any[]>(this.BASE_URL+"/getEventByAccount", {type:accountType,id:accountId,limit:limit});
  }

  add(event){
    const participants = event.participants.map(member=>member.id);
    return this.http.post<any>(this.BASE_URL, {...event,participants});
  }

  edit(event){
    const participants = event.participants.map(member=>member.id);
    return this.http.put<any>(this.BASE_URL+'/'+event.id, {...event,participants});
  }

  getById(id:number){
    return this.http.get<any>(this.BASE_URL+"/event/"+id);
  }

  delete(events){
    return this.http.post<void>(this.BASE_URL+"/deleteAccountEvents",events.map(event=>event.id))
  }
}
