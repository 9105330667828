import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  maxSize = 5
  pageSizeOptions = [5, 10, 15, 25, 50, 75, 100];
  private _page;
  private _pageSize;
  pageEvent: PageEvent
  @Input() collectionSize: number;
  @Output() pageChange = new EventEmitter<number>();
  @Input() set page(val: number) {
    console.log(val);
    this._page = val;
    this.pageChange.emit(val);
  }
  get page() {
    return this._page;
  }

  @Output() pageSizeChange = new EventEmitter<number>();
  @Input() set pageSize(val: number) {
    this._pageSize = val;
    this.pageSizeChange.emit(val);
  }
  get pageSize() {
    return this._pageSize;
  }

  onPageSizeChange(ev: PageEvent) {
    if (ev) this.pageSize = ev.pageSize
  }



  constructor() { }

  ngOnInit(): void {
  }

}
