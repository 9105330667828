import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Card } from 'src/app/interfaces/card';
import { Group } from 'src/app/interfaces/group';
import { CardService } from 'src/app/services/card.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-card-management',
  templateUrl: './card-management.component.html',
  styleUrls: ['./card-management.component.scss']
})
export class CardManagementComponent implements OnInit {

  subscription: Subscription[] = [];
  cards: Card[] = [];
  filterCards: Card[] = [];
  organizationalStructure: Group[] = [];
  cardGroups: any[] = [];
  organizationCards: Card[] = [];
  private _search = '';
  private _currGroup: any;

  get currGroup() {
    return this._currGroup;
  }

  set currGroup(val) {
    this._currGroup = val;
    this.onFilterCards();
  }

  get search() {
    return this._search
  }

  set search(val) {
    this._search = val;
    this.onFilterCards();
  }

  constructor(
    private cardService: CardService,
    private organizationalStructureService: OrganizationalStructureService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.cardService.cards$.subscribe(cards => {
        this.cards = cards;
        this.onFilterCards()
        this.cardGroups = cards.reduce((acc, card) => {
          if (card.group.type === 'organization' || acc.find(accOne => accOne.id === card.group.id)) return acc
          return [...acc, card.group]
        }, [])
        this.organizationCards = cards.filter((card) => card.group.type === 'organization');
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.organizationalStructure = organizationalStructure;
        const organization = organizationalStructure.find(child => child.type === "organization");
        this.currGroup = {
          id: organization?.id,
          name: organization?.name,
          type: 'organization'
        }
      })
    )
    this.cardService.loadCards();
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  onFilterCards() {
    this.filterCards = this.cards.filter(card => {
      return (card.title.toLowerCase().includes(this.search.toLowerCase()) ||
        card.group.name.toLowerCase().includes(this.search.toLowerCase()) ||
        card.group.type.toLowerCase().includes(this.search.toLowerCase())) &&
        (this.currGroup?.type === 'organization' || card.group.id === this.currGroup?.id)
    })
  }

  getCardImg(groupId) {
    return this.organizationalStructure.find(group => group.id === groupId)?.logoUrl
  }

  setCurrGroup(group: any) {
    if (!group) this.currGroup = { type: 'organization', id: '', name: '' }
    else this.currGroup = group
  }

  onEditCard(cardId) {
    this.router.navigate(['card-view', cardId]);
  }

  onRemoveCard(cardId){
    this.cardService.removeCard(cardId);
  }
}
