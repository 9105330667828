import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-items-table',
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.scss']
})
export class ItemsTableComponent implements OnInit {

  @Input() items: any[];
  @Input() displayedColumns: string[];
  dataSource = new MatTableDataSource
  private _page = 1;
  pageSize = 3;

  get page() {
    return this._page
  }

  set page(value) {
    this._page = value;
    this.dataSource = new MatTableDataSource(this.itemsToShow());
  }



  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.itemsToShow())
  }

  
  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.itemsToShow());
  }

  getClass() {
    return `checkbox-container ${this.items.find(card => card.isCheck) ? 'checked' : ''}`
  }

  itemsToShow(){
    const start = (this.page - 1) * this.pageSize;
    return this.items?.slice(start, start + this.pageSize);
  }

}
