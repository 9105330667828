import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RecycleBinItem } from '../interfaces/recycle-bin-item';

@Injectable({
  providedIn: 'root'
})
export class RecycleBinListService {

  private BASE_URL = 'https://api.flexyc.com/recycleBinList';
  private _recycleBinList$ = new BehaviorSubject<RecycleBinItem[]>([])
  public recycleBinList$ = this._recycleBinList$.asObservable();

  constructor(private http: HttpClient) { }

  public loadRecycleBinList() {
    this.http.get<RecycleBinItem[]>(this.BASE_URL)
      .subscribe(recycleBinList => {
        this._recycleBinList$.next(recycleBinList);
      })
  }
}
