import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent implements OnInit {

  @ViewChild('description') description;


  private _memberIdentidier = '';
  subscription: Subscription[] = [];
  account: any = this.portalAccountService.getEmptyWithOutType();
  accountEvents = [];
  isEdit = false
  members = [];
  filteredMembers = [];
  emailSetId = '';
  phoneSetId = '';
  type = ''
  currProccess = 'onboarding';
  baseAccount = null

  proccessOptions = ['onboarding', 'development']

  get memberIdentidier() {
    return this._memberIdentidier
  }

  set memberIdentidier(value) {
    this._memberIdentidier = value;
    this.filterMembers()
  }

  get directManagerShowData() {
    return this.account.typeData.directManagers.map(directManager => directManager.identifier).join(', ')
  }
  get seconderyContactShowData() {
    return this.account.typeData.seconderyContact.map(contact => contact.identifier).join(', ')
  }

  constructor(
    private route: ActivatedRoute,
    private portalAccountService: PortalAccountsService,
    private portalEventService: PortalEventService,
    private memberService: MembersService,
    private setService: SetsService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const type = params.get('type');
        const id = params.get('id');
        if (type) {
          console.log(type);
          this.type = type
          if (type === 'employee') this.account = this.portalAccountService.getEmptyEmployee();
          else if (type === 'costumer') this.account = this.portalAccountService.getEmptyCostumer();
          else if (type === 'candidate') this.account = this.portalAccountService.getEmptyCandidate();
          else if (type === 'patient') this.account = this.portalAccountService.getEmptypatient();
        } else if (id) {
          this.account = await this.portalAccountService.getById(id).toPromise();
          
          this.baseAccount = JSON.parse(JSON.stringify(this.account));
          this.portalEventService.loadPortalEvents().toPromise();
          this.memberIdentidier = this.account.contactInfo.member.identifier
          this.type = this.account.type
          
        }

      })
    )

    this.members = await this.memberService.getMembersPromise();
    this.filterMembers();
    const sets = await this.setService.getSets().toPromise();
    sets.forEach(set => {
      if (set.property.type === 'phone') this.phoneSetId = set.id
      else if (set.property.type === 'email') this.emailSetId = set.id
    })
  }

  filterMembers() {
    this.filteredMembers = this.members.filter(member => member.identifier.toLowerCase().includes(this.memberIdentidier.toLowerCase()))
  }

  toggleIsEdit() {
    if (this.isEdit) {
      this.account = JSON.parse(JSON.stringify(this.baseAccount));
      this.isEdit = false;
    } else {
      this.isEdit = true
    }
  }

  chooseMember(member) {
    this.memberIdentidier = member.identifier
    this.account.contactInfo.member = {
      id: member.id,
      identifier: member.identifier
    }
    if (this.emailSetId) {
      const email = member.setMap[this.emailSetId]
      if (email) this.account.contactInfo.email = email
    }
    if (this.phoneSetId) {
      const phone = member.setMap[this.phoneSetId]
      if (phone) this.account.contactInfo.phone = phone
    }

  }

  async saveAccount() {
    if (this.account.id) this.account = await this.portalAccountService.update(this.account).toPromise()
    else this.account = await this.portalAccountService.add(this.account).toPromise()
    this.router.navigate(['account-view', 'account', this.account.id]);
    this.baseAccount = JSON.parse(JSON.stringify(this.account));
    this.isEdit = false
  }



  // setAccountEventMap() {
  //   const accountEventMap = {
  //     recent: [],
  //     onboarding: [],
  //     development: [],
  //     termination: [],
  //   }

  //   this.accountEvents.forEach(event => {
  //     this.proccessOptions = ['onboarding', 'development']
  //     if (!event.proccess) accountEventMap.recent.push(event)
  //     else {
  //       accountEventMap[event.proccess].push(event);
  //       if (event.proccess === 'termination') this.proccessOptions = ['onboarding', 'development', 'termination']
  //     }
  //   })

  //   this.accountEventMap = accountEventMap;

  // }

  async updateDirectManagers(groupId) {
    const members = await this.memberService.getMembersPromise()
    this.account.typeData.directManagers = members.filter(member => member.moderatorOf.some(moderatorOfListGroupId => moderatorOfListGroupId === groupId))
  }

  onGroupChange(group) {
    console.log(this.account.typeData);

    if (group?.id && this.account.typeData?.object.id !== group.id) {
      this.account.typeData.object = group;
      this.updateDirectManagers(group.id);
    }
  }

  openDescription() {
    this.modalService.open(this.description).result.then((result) => {
    }, (reason) => {
    });
  }


}
