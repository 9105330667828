import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalPostService } from 'src/app/services/portal-post.service';

@Component({
  selector: 'app-employee-portal',
  templateUrl: './employee-portal.component.html',
  styleUrls: ['./employee-portal.component.scss']
})
export class EmployeePortalComponent implements OnInit {

  subscription: Subscription[] = []
  posts = []

  constructor(
    private portalPostService: PortalPostService
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.portalPostService.portalPosts$.subscribe(posts => {
        this.posts = posts;
      })
    )
    this.portalPostService.loadPortalPosts();
  }

}
