import * as fromOrganization from './reducers/organization.reducer'
// import * as fromStructure from './reducers/structure.reducer'
import * as fromAuth from './reducers/auth.reducer'
import { ActionReducerMap } from '@ngrx/store'

export interface AppState {
    organization: fromOrganization.State,
    // structure: fromStructure.State
    auth: fromAuth.State
}

export const appReducer: ActionReducerMap<AppState> = {
    organization: fromOrganization.organizationReducer,
    // structure: fromStructure.structureReducer,
    auth: fromAuth.authReducer
};