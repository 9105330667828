<div class="main-card card ">
    <div *ngIf="card.card.limitAmountOfTickets?.isCheck && card.used>=card.card.limitAmountOfTickets?.limit" class="cover"></div>
    <div class="card-body">
        <div class="img-container">
            <img class="card-img" [src]="cardImg" alt="">
        </div>
        <h3 class="title text-center">{{card.card.title | titleCase}}</h3>
        <div *ngIf="card.card.limitAmountOfTickets?.isCheck">
            <app-card-usage-preview-use-in-limit [limit]="card.card.limitAmountOfTickets.limit" [used]="card.used">
            </app-card-usage-preview-use-in-limit>
        </div>
        <div *ngIf="card.card.startDate?.isCheck && card.card.startDate.timestamp>now">
            <p>{{'CardTemplatePreview.startAt'|translatePipe}}: {{card.card.startDate.timestamp| formatDate}}</p>
        </div>
        <div *ngIf="card.card.experationDate?.isCheck">
            <p>{{'CardTemplatePreview.experationAt'|translatePipe}}: {{card.card.experationDate.timestamp| formatDate}}</p>
        </div>
        <div *ngIf="card.card.limitAmountOfTickets?.isCheck && isShowUseButton" class="btn-container text-center">
            <button (click)="useCard.emit(card.id)" class="btn btn-success">{{'use'|translatePipe}}</button>
        </div>
    </div>
</div>