import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-group-chat-settings',
  templateUrl: './group-chat-settings.component.html',
  styleUrls: ['./group-chat-settings.component.scss']
})
export class GroupChatSettingsComponent implements OnInit {

  constructor() { }

  @Input() group
  @Output() toggleIsAllowChat = new EventEmitter<string>();



  ngOnInit(): void {
  }

}
