import { Component, Input, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-tooltip-member',
  templateUrl: './tooltip-member.component.html',
  styleUrls: ['./tooltip-member.component.scss']
})
export class TooltipMemberComponent implements OnInit {

  constructor(
    private organizationalStructureService: OrganizationalStructureService
  ) { }

  @Input() member: Member
  moderatorOf: string[] = []

  ngOnInit(): void {
    this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
      this.moderatorOf = [];
      groups.forEach(group => {
        if (this.member.moderatorOf.some(id => id === group.id)) this.moderatorOf.push(group.name)
      })
    })
    this.organizationalStructureService.loadOrganizationalStructure();
  }

}
