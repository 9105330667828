<span [ngSwitch]="type">
    <span *ngSwitchCase="'timestamp'">
        {{
        value?
        (value | formatDate):
        'dont set'
        }}
    </span>
    <span *ngSwitchCase="'time'">
        {{
        value?
        (value | formatTime):
        'dont set'
        }}
    </span>

    <span *ngSwitchCase="'check'">
        {{
        value?
        'true':
        'false'
        }}
    </span>
    <span *ngSwitchCase="'rate'">
        <span *ngFor="let item of [1,2,3,4,5]" >
            {{value>=item?'★':'☆'}}
        </span>
    </span>
    <span *ngSwitchCase="'tags'">
        <span *ngFor="let valueItem of value;let idx=index">{{valueItem}}{{idx<(value.length-1)?', ':''}}</span>
    </span>

    <span *ngSwitchDefault>
        {{value}}
    </span>
</span>