<div class="example-container mat-elevation-z8 mb-3">
    <!-- <mat-table *ngIf="false" #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="member">
            <mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
            <mat-cell *matCellDef="let card">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="card.isCheck=!card.isCheck" [checked]="card.isCheck" class="form-check-input"
                            type="checkbox">
                    </div>
                </div>
                {{card.member.identifier}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let card"> {{card.card.title}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="structure">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Structure</mat-header-cell>
            <mat-cell *matCellDef="let card"> {{card?.card?.group?.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="limits">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Limits</mat-header-cell>
            <mat-cell *matCellDef="let card">
                {{getLimits(card.card)}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Options</mat-header-cell>
            <mat-cell *matCellDef="let card">
                <div class="options-container">

                    <a [routerLink]="'/card-give/edit/'+card.id" class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning"><i class="fa fa-fw"
                            aria-hidden="true" title="edit"></i></a>
                    <button (click)="removeCardOfMember(card.member.id,card.id)" class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                    <a [routerLink]="'/card-usage/'+card.member.id"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-info"><i class="pe-7s-user btn-icon-wrapper">
                        </i></a>
                    <button (click)="usedCard(card.member.id,card.id)" *ngIf="card.card.limitAmountOfTickets?.isCheck" class="mb-2 me-2 btn-icon b tn-icon-only btn btn-success">use</button>

                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table> -->

    <div dirControl class="mb-4">
        <app-flexyc-table #table [dataForPage]="cardsToShow" [dataSource]="cards"
            [dataItemKeys]="displayedColumns">
            <ng-container options>
                <div class="col-container">
                    <div class="col">
                        <div class="col-header cell">
                            {{'limits'|translatePipe}}
                        </div>
                        <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                            *ngFor="let dataItem of cardsToShow;let rowIdx = index"
                            [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                            {{getLimits(dataItem.card)}}
                        </div>
                    </div>
                </div>
                <div class="col-container">
                    <div class="col">
                        <div class="col-header cell">
                            {{'options'|translatePipe}}
                        </div>
                        <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                            *ngFor="let dataItem of cardsToShow;let rowIdx = index"
                            [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                            <a [routerLink]="'/card-give/edit/'+dataItem.id" class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning"><i class="fa fa-fw"
                                aria-hidden="true" title="edit"></i></a>
                        <button (click)="removeCardOfMember(dataItem.member.id,card.id)" class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button>
                        <a [routerLink]="'/card-usage/'+dataItem.member.id"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-info"><i class="pe-7s-user btn-icon-wrapper">
                            </i></a>
                        <button (click)="usedCard(dataItem.member.id,dataItem.id)" *ngIf="dataItem.card.limitAmountOfTickets?.isCheck" class="mb-2 me-2 btn-icon b tn-icon-only btn btn-success">use</button>
    
                        </div>
                    </div>
                </div>
    
            </ng-container>
    
        </app-flexyc-table>
        <app-paginator [collectionSize]="cards.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
    </div>

    <!-- <div *ngIf="cards?.length>pageSize" class="pagination-container">
        <div class="">
            <ngb-pagination [collectionSize]="cards.length" [pageSize]="pageSize" [(page)]="page"
                aria-label="Default pagination"></ngb-pagination>
        </div>
    </div> -->
</div>

