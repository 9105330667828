import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import slideVertical from 'src/app/animations/slide-vertical.animation';
import { Card } from 'src/app/interfaces/card';
import { Member } from 'src/app/interfaces/member';
import { CardService } from 'src/app/services/card.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-card-view-give',
  templateUrl: './card-view-give.component.html',
  styleUrls: ['./card-view-give.component.scss'],
  animations: [
    slideVertical
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CardViewGiveComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public cardService: CardService,
    public memberService: MembersService,
    public organizationalStructureService: OrganizationalStructureService,
    private location: Location
  ) { }

  expriedAfterTimeOptions = ['hours', 'days', 'weeks', 'month', 'years']
  subscription: Subscription[] = [];
  groupOptions = [];
  title = ''
  editedId = '';
  card: Card = this.cardService.emptyCard
  slideSituation = 'out'
  isHaveAdvancedOptions = false;
  member: Member = this.memberService.emptyMember;
  used = 0;

  async ngOnInit(): Promise<void> {

    this.memberService.loadMembers();
    this.organizationalStructureService.loadOrganizationalStructure()
    this.subscription.push(
      this.memberService.members$.subscribe(_ => { })
    )

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const templateId = params.get('id');
        if (templateId) {
          this.subscription.push(
            this.cardService.getCard(templateId).subscribe(card => {
              this.card = card;
              this.updateIsHaveAdvancedOptions()
            }))

        } else {
          const cardId = params.get('cardId');
          this.editedId = cardId;
          await this.updateCardById(cardId);
        }
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.groupOptions = organizationalStructure.map(child => ({
          id: child.id,
          type: child.type,
          name: child.name
        }))
        this.sortGroupOptions();
        if (!this.card.id) this.card.group = this.groupOptions.find(option => option.type === 'organization')
        else {
          const currOption = this.groupOptions.find(option => {
           return option.id === this.card.group.id
          })
          console.log(currOption);

          this.card.group = currOption
        }
      })
    )
  }

  sortGroupOptions() {
    this.groupOptions.sort((optionA, optionB) => {
      if (optionA.type > optionB.type) return 1
      if (optionA.type < optionB.type) return -1
      else return 0
    })
  }

  toggleSlideSituation() {
    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in'
  }

  onAddCardToUser() {
    if (this.editedId) {
      let { experationDate, expiredAfter, limitAmountOfTickets, startDate } = this.card
      console.log(experationDate, expiredAfter, limitAmountOfTickets, startDate);
      if (!experationDate) experationDate = this.cardService.emptyCard.experationDate
      if (!expiredAfter) expiredAfter = this.cardService.emptyCard.expiredAfter
      if (!limitAmountOfTickets) limitAmountOfTickets = this.cardService.emptyCard.limitAmountOfTickets
      if (!startDate) startDate = this.cardService.emptyCard.startDate

      const advanceOptions = { experationDate, expiredAfter, limitAmountOfTickets, startDate }

      this.memberService.updateCardOfMember(this.member.id, this.editedId, advanceOptions, this.used)
      this.location.back()

    } else {
      this.memberService.addCardToMember(this.member.id, this.card);
    }
  }

  getMemberToSearch() {
    return this.memberService.getMembers().filter(member => member.identifier.toLowerCase().includes(this.member.identifier));
  }

  onChooseMember(member) {
    this.member = member;
  }

  async updateCardById(cardId) {
    const member = await this.memberService.findMemberByCardId(cardId);
    this.member = member
    const card = member.cards.find(card => card.id === cardId);
    this.card = card.card;
    this.used = card.used

    if (this.groupOptions) {
      const currOption = this.groupOptions.find(option => option.id === this.card.group.id)
      this.card.group = currOption
    }
    this.updateIsHaveAdvancedOptions()
  }

  updateIsHaveAdvancedOptions() {
    if (!this.card.limitAmountOfTickets?.isCheck) delete this.card.limitAmountOfTickets;
    if (!this.card.startDate?.isCheck) delete this.card.startDate;
    if (!this.card.experationDate?.isCheck) delete this.card.experationDate;
    if (!this.card.expiredAfter?.isCheck) delete this.card.expiredAfter;
    if (this.card.limitAmountOfTickets ||
      this.card.startDate ||
      this.card.experationDate ||
      this.card.expiredAfter) this.isHaveAdvancedOptions = true
  }

}
