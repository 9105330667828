import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountServerService } from 'src/app/services/account-server.service';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-manage-customer-server',
  templateUrl: './manage-customer-server.component.html',
  styleUrls: ['./manage-customer-server.component.scss']
})
export class ManageCustomerServerComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  costumers = []
  search = ''

  constructor(
    private accountService: AccountServerService,
    private globalVariableService: GlobalVariablesService
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.accounts$.subscribe(accounts => {
        this.updateCostumers(accounts);
      })
    )
    this.accountService.loadAccounts("Customer");

  }

  updateCostumers(costumers) {
    this.costumers = costumers.map(costumer =>{
            
    return  { ...costumer,type:"Costumer", isCheck: false };
    })
  }

  filterCostumers() {
    return this.costumers.filter(costumer => (
      costumer.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      costumer.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  async deleteCustomer(customer) {
    await this.accountService.delete(this.globalVariableService.AccountTypes["Customer"],[customer]).toPromise();
    this.accountService.loadAccounts('Customer');
  }

  getNumOfSelected() {
    return this.costumers.reduce((acc, employee) => {
      if (employee.isCheck) acc++
      return acc
    }, 0)
  }

  unselect() {
    this.updateCostumers(this.costumers)
  }

  deleteSelected() {
    const selected = this.costumers.filter(costumer => costumer.isCheck)
    // selected.forEach(costumer => {
    //   this.deleteCostumer(costumer)
    // })

  }


}
