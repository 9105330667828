import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MemberServerService } from 'src/app/services/member-server.service';

@Component({
  selector: 'app-members-multy-select-server',
  templateUrl: './members-multy-select-server.component.html',
  styleUrls: ['./members-multy-select-server.component.scss']
})
export class MembersMultySelectServerComponent implements OnInit {

  @Input() label: string
  @Input() name: string
  isFocus = false;
  members = [];
  filterMembers = [];
  subscription: Subscription[] = []
  input = null

  private _text = ''
  private _values = []
  @Output() valuesChange = new EventEmitter<any[]>();
  @Input() set values(values: any[]) {
    this._values = values;
    this.valuesChange.emit(values);
  }

  set text(value) {
    this._text = value
    this.setFilterMembers()
  }

  get text() {
    return this._text
  }

  get values() {
    return this._values;
  }

  constructor(
    private memberService: MemberServerService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members
      })
    )
    this.memberService.loadMembers();
    this.setFilterMembers()
  }

  ngAfterViewInit(): void {
    this.input = this.elementRef.nativeElement.querySelector('.member-multy-select-input')
    this.input.addEventListener('keydown', e => {
      if (e.keyCode === 8 && e.target.value === '') {
        this.values = this.values.filter((value, idx) => idx !== this.values.length - 1)
        this.setFilterMembers();
      }

    })
  }


  setFilterMembers() {
    this.filterMembers = this.members.filter(member =>
      member.identifier.toLowerCase().includes(this.text.toLocaleLowerCase()) &&
      !this.values.some(value => value.id === member.id)
    )
  }

  onChooseMember(member) {
    this.values = [...this.values, member]
    this.text = '';
    this.setFilterMembers()
  }

  removeValue(value) {
    this.values = this.values.filter(valuesItem => valuesItem.id !== value.id);
    this.setFilterMembers()
  }

}
