<div [ngClass]="{rtl:translateService.locale==='he'}"
  [class]="'sidebar-b2c app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme"
  (mouseover)="sidebarHoverMouseIn()" (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: calc(100vh - 60px); overflow-y: auto;">
        <div class="sidebar-container">
          <div class="v-sidebar-menu vsm-default">
            <div class="vsm-list mt-4">
              <a routerLink="/profile" routerLinkActive="active-item"
                class="vsm-link user ">
                <i>
                  <img [src]="loggedInUser.profileImg" alt="">
                </i>
                <span class="vsm-title">{{loggedInUser.identifier}}</span>
              </a>
              <div class="divider"></div>
              <div class="vsm-item">
                <a routerLink="/dashboard/os101" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-home"></i>
                  <span class="vsm-title">{{'home page'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/chat" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-chat"></i>
                  <span class="vsm-title">{{'chat'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/calendar" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-date"></i>
                  <span class="vsm-title">{{'calendar'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/sets" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-menu"></i>
                  <span class="vsm-title">{{'sets'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/cards" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-id"></i>
                  <span class="vsm-title">{{'cards'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/cards" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-check"></i>
                  <span class="vsm-title">{{'tasks'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/groups" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-network"></i>
                  <span class="vsm-title">{{'groups'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/organization-media" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-film"></i>
                  <span class="vsm-title">{{'media'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/organization-ecommerce" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-cart"></i>
                  <span class="vsm-title">{{'ecommerce'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/organization-landing-page" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-plane"></i>
                  <span class="vsm-title">{{'landing page'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/user-control-pannel" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-config"></i>
                  <span class="vsm-title">{{'user Control Panel'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/employee-directory" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-user"></i>
                  <span class="vsm-title">{{'employee directory'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/employee-portal" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-display2"></i>
                  <span class="vsm-title">{{'employee portal'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/customer-directory" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-note2"></i>
                  <span class="vsm-title">{{'customer directory'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/customer-portal" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-display1"></i>
                  <span class="vsm-title">{{'customer portal'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/candidate-directory" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-add-user"></i>
                  <span class="vsm-title">{{'candidate directory'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/candidate-portal" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-news-paper"></i>
                  <span class="vsm-title">{{'candidate Portal'|translatePipe}}</span>
                </a>
              </div>

            </div>
          </div>
          <div class="v-sidebar-menu vsm-default">
            <div class="vsm-list mt-4">
              <div class="vsm-item">
                <a routerLink="/dashboard/xgDPr3O" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-info"></i>
                  <span class="vsm-title">{{'about'|translatePipe}}</span>
                </a>
              </div>
              <div class="vsm-item">
                <a routerLink="/dashboard/xgDPr3O" routerLinkActive="active-item" class="vsm-link">
                  <i class="vsm-icon pe-7s-global"></i>
                  <span class="vsm-title">{{'language'|translatePipe}}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="version-and-url">
            <p>{{'version'|translatePipe}} 0.1</p>
            <p>www.flexyc.com</p>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>