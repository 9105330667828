import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-recycle-bin-table',
  templateUrl: './recycle-bin-table.component.html',
  styleUrls: ['./recycle-bin-table.component.scss']
})
export class RecycleBinTableComponent implements OnInit {

  @Input() recycleBinList: any[];

  dataSource = new MatTableDataSource
  displayedColumns = ['title', 'type','timeLeft', 'options'];
  private _page = 1;
  pageSize = 25;

  get page() {
    return this._page
  }

  set page(value) {
    this._page = value;
    this.dataSource = new MatTableDataSource(this.recycleBinListToShow());
  }

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.recycleBinListToShow())
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.recycleBinListToShow())
  }

  recycleBinListToShow() {
    const start = (this.page - 1) * this.pageSize;
    return this.recycleBinList?.slice(start, start + this.pageSize);
  }

  getClass() {
    return `checkbox-container ${this.recycleBinList.find(card => card.isCheck) ? 'checked' : ''}`
  }

}
