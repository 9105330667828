import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit {
  @ViewChild('emailInput') emailRef!: any;
  isCopied: boolean;
  name = '';
  email = '';
  group = this.organizationalStructureService.emptyGroup
  isHaveName = true;
  linkLimit = 0;

  get link() {
    return `https://flexyc/${this.group.name}/id?=${this.group.id}&limit?=${this.linkLimit}`
  }

  constructor(public organizationalStructureService: OrganizationalStructureService) { }

  ngOnInit(): void {
  }

  isSendDisable() {
    if (!this.emailRef) return true;
    if (!this.isHaveName) return !!this.emailRef?.invalid
    return (!!this.emailRef?.invalid) || !this.name;
  }

  onCopyFailure() {
    alert('copy fail!');
  }

}
