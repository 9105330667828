<!-- <div class="control-btns">
    <button (click)="onChooseAction('add')">
        <mat-icon>add</mat-icon>
    </button>
    <button (click)="onChooseAction('edit')">
        <mat-icon>edit</mat-icon>
    </button>
    <button (click)="onChooseAction('delete')">
        <mat-icon>delete</mat-icon>
    </button>
    <button (click)="onChooseAction('members')">
        <mat-icon>people_alt</mat-icon>
    </button>
</div> -->

<div class="control-btns">
    <button (click)="onChooseAction('add')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="add"></i>
    </button>
    <button (click)="onChooseAction('edit')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="edit"></i>
    </button>
    <button *ngIf="group.type!=='organization'" (click)="onChooseAction('delete')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="delete"></i>
    </button>
    <button *ngIf="group.type!=='department'"  (click)="onChooseAction('members')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="members"></i>
    </button>
</div>
