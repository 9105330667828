<div dirControl class="row">
    <div class="col-12 mb-3">
        <div class="main-card card">
            <div class="card-body categories">
                <h3 class="card-title">{{'categories'|translatePipe}}</h3>
                <div class="categories-container">
                    <p class="category-option" (click)="currCategory='all'" [class.curr]="currCategory==='all'">{{'all'|translatePipe}}</p>
                    <p class="category-option" (click)="currCategory='premade'" [class.curr]="currCategory==='premade'">
                        {{'premade'|translatePipe}}</p>
                    <p class="category-option" (click)="currCategory='generic'" [class.curr]="currCategory==='generic'">
                        {{'generic'|translatePipe}}</p>
                    <p class="category-option" (click)="currCategory='auto'" [class.curr]="currCategory==='auto'">{{'auto'|translatePipe}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="main-card card mb-3">
            <div class="card-body">
                <form class="">
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            placeholder="search">
                    </div>
                </form>
            </div>
        </div>
        <div class="overflow-hidden mb-3">

            <div class="g-0 row">
                <ng-container *ngIf="currCategory==='all' || currCategory==='premade'">
                    <ng-container *ngFor="let key of premadeKeys">
                        <div (click)="onPropertyClick(key)" *ngIf="key.toLowerCase().includes(search.toLowerCase())"
                            class="col-12 col-sm-6 property-view">
                            <!-- <div class="main-card card mb-3">
                                <div class="card-body row">
                                    <div class="col-6 text-center icon-container">
                                        <div [ngSwitch]="propertyMap.premade[key].type">
                                            <i *ngSwitchCase="'short-text'" class="fa fa-fw" aria-hidden="true"
                                                title="short-text"></i>
                                            <i *ngSwitchCase="'long-text'" class="fa fa-fw" aria-hidden="true"
                                                title="long-text"></i>
                                            <i *ngSwitchCase="'timestamp'" class="fa fa-fw" aria-hidden="true"
                                                title="date"></i>
                                            <i *ngSwitchCase="'url'" class="fa fa-fw" aria-hidden="true"
                                                title="image"></i>
                                            <i *ngSwitchCase="'email'" class="fa fa-fw" aria-hidden="true"
                                                title="email"></i>
                                            <i *ngSwitchCase="'number'" class="fa fa-fw" aria-hidden="true"
                                                title="number"></i>
                                            <i *ngSwitchCase="'time'" class="fa fa-fw" aria-hidden="true"
                                                title="time"></i>
                                            <i *ngSwitchCase="'phone'" class="fa fa-fw" aria-hidden="true"
                                                title="phone"></i>
                                            <i *ngSwitchCase="'check'" class="fa fa-fw" aria-hidden="true"
                                                title="check"></i>
                                            <i *ngSwitchDefault class="fa fa-fw" aria-hidden="true" title="edit"></i>
                                        </div>
                                        <p class="property-name">{{key | titleCaseToSpaces | titleCase}}</p>

                                    </div>
                                    <div class="col-8">
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa, animi. Eaque
                                            et
                                            laboriosam possimus, veniam cum architecto obcaecati perferendis quia unde
                                            praesentium
                                            similique, voluptatum neque. Optio veritatis soluta eos nam.</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="widget-chart widget-chart-hover">
                                <div class="icon-wrapper">
                                    <div [ngClass]="'text-'+propertyMap.premade[key].classColor" class="widget-numbers">
                                        {{key | translatePipe | titleCaseToSpaces | titleCase}}</div>
                                    <div [ngClass]="'bg-'+propertyMap.premade[key].classColor" class="icon-wrapper-bg ">
                                    </div>
                                    <i [ngClass]="'text-'+propertyMap.premade[key].classColor" class="fa fa-fw">
                                        {{getTypeIcon(propertyMap.premade[key].type)}}
                                    </i>
                                    <div class="widget-numbers">{{'premadeSet'|translatePipe}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currCategory==='all' || currCategory==='generic'">
                    <ng-container *ngFor="let key of genericKeys">
                        <div (click)="onPropertyClick(key)" *ngIf="key.toLowerCase().includes(search.toLowerCase())"
                            class="col-12 col-sm-6 property-view">
                            <!-- <div class="main-card card mb-3">
                                <div class="card-body row">
                                    <div class="col-6 text-center icon-container">
                                        <div [ngSwitch]="propertyMap.generic[key].type">
                                            <i *ngSwitchCase="'short-text'" class="fa fa-fw" aria-hidden="true"
                                                title="short-text"></i>
                                            <i *ngSwitchCase="'long-text'" class="fa fa-fw" aria-hidden="true"
                                                title="long-text"></i>
                                            <i *ngSwitchCase="'timestamp'" class="fa fa-fw" aria-hidden="true"
                                                title="date"></i>
                                            <i *ngSwitchCase="'url'" class="fa fa-fw" aria-hidden="true"
                                                title="image"></i>
                                            <i *ngSwitchCase="'email'" class="fa fa-fw" aria-hidden="true"
                                                title="email"></i>
                                            <i *ngSwitchCase="'number'" class="fa fa-fw" aria-hidden="true"
                                                title="number"></i>
                                            <i *ngSwitchCase="'time'" class="fa fa-fw" aria-hidden="true"
                                                title="time"></i>
                                            <i *ngSwitchCase="'check'" class="fa fa-fw" aria-hidden="true"
                                                title="check"></i>
                                            <i *ngSwitchCase="'phone'" class="fa fa-fw" aria-hidden="true"
                                                title="phone"></i>
                                            <i *ngSwitchDefault class="fa fa-fw" aria-hidden="true" title="edit"></i>
                                        </div>
                                        <p class="property-name">{{key | titleCaseToSpaces | titleCase}}</p>
                                        <p>Generic set</p>

                                    </div>
                                    <div class="col-8">
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa, animi. Eaque
                                            et
                                            laboriosam possimus, veniam cum architecto obcaecati perferendis quia unde
                                            praesentium
                                            similique, voluptatum neque. Optio veritatis soluta eos nam.</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="widget-chart widget-chart-hover">
                                <div class="icon-wrapper">
                                    <div [ngClass]="'text-'+propertyMap.generic[key].classColor" class="widget-numbers">
                                        {{key | translatePipe | titleCaseToSpaces | titleCase}}</div>
                                    <div [ngClass]="'bg-'+propertyMap.generic[key].classColor" class="icon-wrapper-bg">
                                    </div>
                                    <i [ngClass]="'text-'+propertyMap.generic[key].classColor" class=" fa fa-fw">
                                        {{getTypeIcon(propertyMap.generic[key].type)}}
                                    </i>
                                    <div class="widget-numbers">{{'genericSet'|translatePipe}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="currCategory==='all' || currCategory==='auto'">
                    <ng-container *ngFor="let key of autoKeys">
                        <div (click)="onPropertyClick(key)" *ngIf="key.toLowerCase().includes(search.toLowerCase())"
                            class="col-12 col-sm-6 property-view">
                            <!-- <div class="main-card card mb-3">
                                <div class="card-body row">
                                    <div class="col-6 text-center icon-container">
                                        <div [ngSwitch]="propertyMap.auto[key].type">
                                            <i *ngSwitchCase="'short-text'" class="fa fa-fw" aria-hidden="true"
                                                title="short-text"></i>
                                            <i *ngSwitchCase="'long-text'" class="fa fa-fw" aria-hidden="true"
                                                title="long-text"></i>
                                            <i *ngSwitchCase="'timestamp'" class="fa fa-fw" aria-hidden="true"
                                                title="date"></i>
                                            <i *ngSwitchCase="'url'" class="fa fa-fw" aria-hidden="true"
                                                title="image"></i>
                                            <i *ngSwitchCase="'email'" class="fa fa-fw" aria-hidden="true"
                                                title="email"></i>
                                            <i *ngSwitchCase="'phone'" class="fa fa-fw" aria-hidden="true"
                                                title="phone"></i>
                                            <i *ngSwitchCase="'time'" class="fa fa-fw" aria-hidden="true"
                                                title="time"></i>
                                            <i *ngSwitchCase="'check'" class="fa fa-fw" aria-hidden="true"
                                                title="check"></i>
                                            <i *ngSwitchCase="'number'" class="fa fa-fw" aria-hidden="true"
                                                title="number"></i>
                                            <i *ngSwitchDefault class="fa fa-fw" aria-hidden="true" title="edit"></i>
                                        </div>
                                        <p class="property-name">{{key | titleCaseToSpaces | titleCase}}</p>
                                        <p>Auto set</p>
                                    </div>
                                    <div class="col-8">
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa, animi. Eaque
                                            et
                                            laboriosam possimus, veniam cum architecto obcaecati perferendis quia unde
                                            praesentium
                                            similique, voluptatum neque. Optio veritatis soluta eos nam.</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="widget-chart widget-chart-hover">
                                <div class="icon-wrapper">
                                    <div [ngClass]="'text-'+propertyMap.auto[key].classColor" class="widget-numbers">
                                        {{key | translatePipe | titleCaseToSpaces | titleCase}}</div>
                                    <div [ngClass]="'bg-'+propertyMap.auto[key].classColor" class="icon-wrapper-bg ">
                                    </div>
                                    <i [ngClass]="'text-'+propertyMap.auto[key].classColor" class="fa fa-fw">
                                        {{getTypeIcon(propertyMap.auto[key].type)}}
                                    </i>
                                    <div class="widget-numbers">{{'autoSet'|translatePipe}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>





            </div>
        </div>
    </div>
</div>