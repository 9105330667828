import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/interfaces/event';
import { Member } from 'src/app/interfaces/member';
import { EventsService } from 'src/app/services/events.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {

  constructor(
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  async ngOnInit(): Promise<void> {

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          this.event = await this.eventService.getEvent(id).toPromise()
        }
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members.map(member => ({
          identifier: member.identifier,
          id: member.id,
        }));
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.groupOptions = organizationalStructure.map(group => (
          { id: group.id, type: group.type, name: group.name })).sort((groupA, groupB) => {
            if (groupA.name > groupB.name) return -1
            if (groupA.name < groupB.name) return 1
            return 0
          })
        if (!this.event.id) this.event.info.group = this.groupOptions.find(group => group.type === 'organization')
        else this.event.info.group = this.groupOptions.find(option => option.id === this.event.info.group.id);
      })
    )
    this.memberService.loadMembers()
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  subscription: Subscription[] = [];
  searchMember = ''
  members: any[] = [];
  private _searchOptions: any[] = [];
  newGuest = '';
  groupOptions: any[] = [];
  unitsOptions = ['hours', 'minutes']
  locationsOptions = ['above', 'below']
  event: Event = this.eventService.emptyEvent;
  isInputFocus = false;


  get searchOptions() {
    this.getMemberToSearch()
    return this._searchOptions
  }

  getMemberToSearch() {
    this._searchOptions.splice(0, this._searchOptions.length)
    if (this.searchMember) {
      let members = this.members.filter(member => !this.event.invites.members.some(listMember => listMember.id === member.id) && member.identifier.toLowerCase().includes(this.searchMember))
      members.forEach(member => {
        this._searchOptions.push(member)
      })
    }
  }

  addMemberToMemberList(member) {
    member.status = 'pending'
    this.event.invites.members = [...this.event.invites.members, member];
    this.searchMember = '';
  }

  removeMemberFromMemberList(memberId) {
    this.event.invites.members = this.event.invites.members.filter(member => memberId !== member.id);
  }

  addGuest(ev) {
    ev.preventDefault();
    this.event.invites.guests = [...this.event.invites.guests, { status: 'pending', email: this.newGuest }];
    this.newGuest = ''
  }

  removeGuest(guest) {
    this.event.invites.guests = this.event.invites.guests.filter(listGuest => listGuest !== guest)
  }

  saveEvent() {
    if (this.event.id) this.eventService.editEvent(this.event).toPromise();
    else this.eventService.addEvent(this.event).toPromise();
    this.location.back()
  }

}
