<div class="container">
    <img [src]="imgUrl" alt="profile image">
    <ng-container *ngIf="member.type==='member'; else elseTemplate">
        <p class="text-center">{{member.identifier}}</p>
    </ng-container>
    <ng-template #elseTemplate>
        <p class="text-center">{{member.email}}</p>
    </ng-template>
    <button (click)="removeMember.emit(member)">
        <i class="fa fa-fw" aria-hidden="true" title="minus"></i>
    </button>
</div>