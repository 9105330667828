import { Action } from '@ngrx/store';

export enum ActionTypes {
    login = 'login',
    logout = 'logout'
}



export class login implements Action {
    readonly type = ActionTypes.login;
    constructor(
        public token: string
    ) { }
}



export class logout implements Action {
    readonly type = ActionTypes.logout;
}
