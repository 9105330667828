import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-local-administrators',
  templateUrl: './local-administrators.component.html',
  styleUrls: ['./local-administrators.component.scss']
})
export class LocalAdministratorsComponent implements OnInit {

  @ViewChild('addMember', { static: true }) addMember: any;
  @ViewChild('sureModal', { static: true }) sureModal: TemplateRef<any>;

  group = this.organizationalStructureService.emptyGroup
  currGroup: Group
  choosenMember: Member
  membersMap = {
    administrators: [],
    moderatorsMap: {},
    allmembers: []
  };
  moderators = []
  notModerators = []
  isSureDelete = false;

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private memberService: MembersService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
      this.group = groups.find(group => group.type === 'organization')
      this.membersMap.moderatorsMap
    })

    this.memberService.members$.subscribe(members => {
      this.membersMap = {
        administrators: [],
        moderatorsMap: {},
        allmembers: []
      };
      members.forEach(member => {
        this.membersMap.allmembers.push(member);
        member.moderatorOf.forEach(groupId => {
          if (this.membersMap.moderatorsMap[groupId]) this.membersMap.moderatorsMap[groupId].push(member);
          else this.membersMap.moderatorsMap[groupId] = [member]
        })
        if (member.isAdministrator) this.membersMap.administrators.push(member);
        if (member.moderatorOf.length) { }
      })
    })
    this.memberService.loadMembers();
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  async onChooseGroup(groupId) {
    const currGroup = await this.organizationalStructureService.getGroupById(groupId).toPromise();
    let groupMemberModerators = []
    if (currGroup.type === 'organization') this.moderators = this.membersMap.administrators
    else this.moderators = this.membersMap.moderatorsMap[groupId] || []
    this.notModerators = this.membersMap.allmembers.filter(member => !this.moderators.some(moderator => moderator.id === member.id))
    this.currGroup = currGroup

  }

  open() {
    this.modal.open(this.addMember, { size: 'lg' });
  }

  openSureModal(member: Member, isSureDelete: boolean, closeFn?: CallableFunction) {
    this.choosenMember = member
    this.isSureDelete = isSureDelete
    this.modal.open(this.sureModal, { size: 'sm' });
    if (closeFn) closeFn()
  }

  async onToggleModerator(closeFn) {
    closeFn()
    if (this.isSureDelete) {
      this.moderators = this.moderators.filter(moderator => moderator.id !== this.choosenMember.id)
      this.notModerators = [...this.notModerators, this.choosenMember]
    } else {
      this.notModerators = this.notModerators.filter(notModerator => notModerator.id !== this.choosenMember.id)
      this.moderators = [...this.moderators, this.choosenMember]
    }
    if (this.currGroup.type === 'organization') {
      await this.memberService.toggleMemberIsAdministrator(this.choosenMember.id).toPromise();
    } else {
      await this.memberService.toggleMemberIsModerator(this.choosenMember.id, this.currGroup.id).toPromise();
    }
  }

}




