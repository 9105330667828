import { Component, Input, OnInit } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { PortalPostService } from 'src/app/services/portal-post.service';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss']
})
export class PostViewComponent implements OnInit {

  constructor(
    private portalPostService: PortalPostService
  ) { }

  faCalendarAlt = faCalendarAlt;


  @Input() post;
  @Input() isEditable: boolean;

  postClone;
  isEdit = false;


  ngOnInit(): void {
  }

  onDelete() {
    this.portalPostService.delete(this.post.id).toPromise()
  }

  toggleIsEdit() {
    if (this.isEdit) {
      this.post = { ...this.postClone }
      this.postClone = null;
      this.isEdit = false
    } else {
      this.postClone = { ...this.post },
        this.isEdit = true;
    }
  }

  onUpdate(){
    this.portalPostService.edit(this.post).toPromise();
  }

}
