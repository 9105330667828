<div class="passowrd-input-container flexyc-input">
    <span class="eye-btn">
        <i class="pe-7s-look btn-icon-wrapper" (click)="isShowPassword= !isShowPassword"></i>
    </span>
    <app-input-label [syncValidators]="['password']" [label]="'password'|translatePipe" name="password"
        [required]="true" [type]="isShowPassword? 'text':'password'" [(value)]="password">
    </app-input-label>
</div>
<div class="mb-4">
    <ngb-progressbar height="30px" [type]="passwordProgressBarData.type" [value]="passwordProgressBarData.value">
        {{passwordProgressBarData.text|translatePipe}}
    </ngb-progressbar>
</div>

<div class="passowrd-input-container flexyc-input">
    <span class="eye-btn">
        <i class="pe-7s-look btn-icon-wrapper" (click)="isShowRepeatPassword= !isShowRepeatPassword"></i>
    </span>
    <app-input-label #repeatPasswordInput [invalid]="repeatPassword!==password"
        [label]="'repeat password'|translatePipe" name="reapet-password" [required]="true"
        [type]="isShowRepeatPassword? 'text':'password'" [(value)]="repeatPassword">
    </app-input-label>
</div>