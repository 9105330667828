<div dirControl class="social-network">
    <h3 class="card-title text-center">{{'socialNetwork'|translatePipe}}</h3>

    <div class="main-card card mb-4">
        <div class="card-body">
            <h3 class="card-title">{{'online'|translatePipe}} {{'members'|translatePipe}} ({{onlineLength}} {{'online'|translatePipe}})</h3>
            <ngx-slick-carousel dir="ltr" class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let members of membersMap.online">
                    <div class="row">
                        <div (click)="onChooseMember(member.id)" class="col-3" *ngFor="let member of members">
                            <app-member-view [member]="member"></app-member-view>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>

    <div class="main-card card mb-4">
        <div class="card-body">
            <h3 class="card-title">{{'offline'|translatePipe}} {{'members'|translatePipe}} ({{offlineLength}} {{'offline'|translatePipe}})</h3>
            <ngx-slick-carousel dir="ltr" class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let members of membersMap.offline">
                    <div class="row">
                        <div class="col-3" *ngFor="let member of members">
                            <app-member-view [member]="member"></app-member-view>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>