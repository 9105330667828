<div [class.editable]="isEditable" class="main-card card mb-2 post post-view">
    <div *ngIf="isEditable" class="control-btns">
        <button class="edit" (click)="toggleIsEdit()">
            <ng-container *ngIf="isEdit; else closeEdit">
                <i class="pe-7s-close"></i>
            </ng-container>
            <ng-template #closeEdit>
                <i class="fa fa-fw" aria-hidden="true" title="edit"></i>

            </ng-template>

        </button>
        <button class="delete" *ngIf="!isEdit" (click)="onDelete()">
            <i class="fa fa-fw" aria-hidden="true" title="delete"></i>
        </button>
    </div>
    <small class="date opacity-6">
        <fa-icon [icon]="faCalendarAlt" class="me-1"></fa-icon>
        {{
        post.editedAt?
        'Edited at '+ (post.editedAt | formatDateTime):
        'Create at '+ (post.createAt | formatDateTime)
        }}
    </small>
    <div class="card-body">
        <ng-container *ngIf="isEdit; else elseTemplate">
            <div class="row mt-4">
                <div class="col-sm-8 col-md-9 col-xl-10">
                    <app-input-label [(value)]="post.title" label="title" name="new-post-title"></app-input-label>
                    <app-textarea-label [(value)]="post.content" label="content" name="new-post-content">
                    </app-textarea-label>
                </div>
                <div class="col-sm-4 col-md-3 col-xl-2">
                    <app-image-picker [(value)]="post.image" label="image"></app-image-picker>
                </div>
            </div>
            <button (click)="onUpdate()" class="btn btn-success">save</button>
        </ng-container>
        <ng-template #elseTemplate>
            <h3 *ngIf="post.title" class="card-title">{{post.title}}</h3>
            <div class="row">
                <div class="col-8 col-md-10">
                    <p *ngIf="post.title">{{post.content}}</p>
                </div>
                <div class="col-4 col-md-2">
                    <img *ngIf="post.image" fullScreenImg [src]="post.image" alt="post-image">
                </div>
            </div>
        </ng-template>
    </div>
</div>