import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-flexyc-table',
  templateUrl: './flexyc-table.component.html',
  styleUrls: ['./flexyc-table.component.scss']
})
export class FlexycTableComponent implements OnInit {
  @ContentChild(TemplateRef) templateVariable: TemplateRef<any>;

  private _selectAll = false;

  get selectAll() {
    return this._selectAll;
  }

  hoveredIdx=-1;

  set selectAll(isSelect) {
    this._selectAll = isSelect;
    if (isSelect) this.dataForPage.forEach(dataItem => dataItem.isCheck = true)
    else this.dataForPage.forEach(dataItem => dataItem.isCheck = false)
  }

  constructor() { }
  @Input() dataSource: any[];
  @Input() dataForPage: any[];
  @Input() dataItemKeys: string[];




  ngOnInit(): void {
  }



  getClass() {
    return `checkbox-container ${this.dataSource?.find(DataSourceItem => DataSourceItem?.isCheck) ? 'checked' : ''}`
  }

}
