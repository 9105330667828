<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="tasksToShow" [dataSource]="tasks" [dataItemKeys]="['title']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'status'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        <app-task-status [status]="dataItem.status" (updateStatus)="updateTaskStatus($event,dataItem)">
                        </app-task-status>
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'priority'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of tasksToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        <div class="priority-container">
                            <span *ngFor="let item of [1,2,3,4,5]">
                                {{dataItem.priority>=item?'★':'☆'}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="tasks.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>