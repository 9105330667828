import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Structure } from 'src/app/interfaces/structure';

@Component({
  selector: 'li[tree-server]',
  templateUrl: './tree-server.component.html',
  styleUrls: ['./tree-server.component.scss']
})
export class TreeServerComponent implements OnInit {
  @Input() group !: Structure;
  @Input() action !: string;
  @Input() isShowButtons = false;
  @Output() onSelectChild = new EventEmitter<any>()
  @Output() onSetCurrAction = new EventEmitter<string>()
  @Output() onHoveredButtonClick = new EventEmitter<{ action: string, structure: Structure }>()


  constructor(
  ) { }

  ngOnInit(): void {
  }

  // clickedHoveredButton(action: string, groupId) {
  //   this.onSetCurrAction.emit(action);
  //   this.onSelectChild.emit(groupId);
  //   this.onSetCurrAction.emit('');
  // }

  clickedHoveredButton(action: string, structure: Structure) {
    this.onHoveredButtonClick.emit({ action, structure })
  }
}
