import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Structure } from 'src/app/interfaces/structure';
import { OrganizationServerService } from 'src/app/services/organization-server.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-group-picker-input-server',
  templateUrl: './group-picker-input-server.component.html',
  styleUrls: ['./group-picker-input-server.component.scss']
})
export class GroupPickerInputServerComponent implements OnInit {

  // @Input() groups?: any[]
  structure: Structure = null;
  structureArr: any[] = [];
  @Input() label?: string = 'Group'
  @Input() disabled?: boolean = false
  subscription: Subscription[] = []

  @Input() set group(val: any) {
    this._group = val;
    this.groupChange.emit(val);
  }
  @Output() groupChange = new EventEmitter<any>();
  get group() {
    return this._group;
  }
  private _group;

  constructor(
    private organizationService: OrganizationServerService,
    private utilService: UtilService,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.organizationService.structure$.subscribe(structure => {
        if (structure) {
          this.structure = structure;
          this.structureArr = this.utilService.structureToArr(structure);
        }
        if (this.group?.id) {
          if (this.structureArr.length) this.group = this.structureArr.find(group => group.id === this.group.id);
        } else this.group = this.structureArr.find(group => group.type === "organization");
      })
    )
    this.organizationService.loadStructure();
    if (this.group?.id && this.structureArr.length) this.group = this.structureArr.find(group => group.id === this.group.id);
  }

  onSelectChild(structure) {
    this.group = this.structureArr.find(structureItem=>(structureItem.id===structure.id && structureItem.type===structure.type));
  }

}
