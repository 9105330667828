<div dirControl class="row mb-3">
    <div class="col-12 col-sm-6 mb-3">
        <h3 class="card-title">{{'total'|translatePipe}} {{log.length}} {{'log'|translatePipe}}</h3>
        <button class="btn btn-success">Export</button>
    </div>
    <div class="col-12 col-sm-6">
        <div class=" card">
            <div class="card-body">
                <form class="mb-3">
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-log-resaults-table [log]="filterLog()"></app-log-resaults-table>