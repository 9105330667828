import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private BASE_URL = 'https://api.flexyc.com/roles';
  private _role$ = new BehaviorSubject<any[]>([])
  public role$ = this._role$.asObservable();


  constructor(
    private http: HttpClient,

  ) { }

  public getEmptyRole(): any {
    return {
      "description": "",
      "name": "New role",
      "color": "#000000",
      "isShowInAdministrators": false,
      "premmissions": [],
      members:[]
    }
  }

  public loadRoles() {
    this.http.get<any[]>(this.BASE_URL)
      .subscribe(rules => {
        this._role$.next(rules);
      })
  }

  public getRole(id: string) {
    return this.http.get<any>(`${this.BASE_URL}/${id}`)
  }

  public getRoles() {
    return this.http.get<any[]>(this.BASE_URL);
  }



  async add(role: any) {
    const roles = await this.http.get<any[]>(this.BASE_URL).toPromise();
    role.idx = roles.length
    return this.http.post<any>(this.BASE_URL, role).pipe(
      tap(_ => {
        this.loadRoles();
      })
    ).toPromise();
  }



  public edit(role: any) {
    return this.http.put<any>(`${this.BASE_URL}/${role.id}`, role).pipe(
      tap(_ => {
        this.loadRoles();
      })
    )
  }

  public updateroles(roles: any) {
    const updateRolesPromises = roles.map(role => this.edit(role).toPromise());
    return Promise.all(updateRolesPromises)
  }

  public delete(id: string) {
    return this.http.delete<any>(`${this.BASE_URL}/${id}`).pipe(
      tap(_ => {
        this.loadRoles();
      })
    )
  }

  // public addSet(set: Set) {
  //   delete set.id
  //   return this.http.post<Set>(this.BASE_URL, set).pipe(
  //     map(set => {
  //       this.loadSets();
  //       return set
  //     })
  //   )
  // }

  // public getSet(id: string) {
  //   return this.http.get<Set>(`${this.BASE_URL}/${id}`)
  // }

  // public getSets() {
  //   return this.http.get<Set[]>(this.BASE_URL)
  // }

  // public editSet(set: Set) {
  //   return this.http.put<Set>(`${this.BASE_URL}/${set.id}`, set).pipe(
  //     map(set => {
  //       this.loadSets();
  //       return set
  //     })
  //   )
  // }

  // public deleteSet(id: string) {
  //   return this.http.delete<Set>(`${this.BASE_URL}/${id}`).pipe(
  //     map(_ => {
  //       this.loadSets();
  //     })
  //   )
  // }


}
