<div class="logo-container" [class.circle]="isCircle">
    <label for="">{{label | titleCase}}</label>
    <div class="logo">
        <ng-container *ngIf="isLoader; else imageTemplate">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #imageTemplate>
            <ng-container *ngIf="value; else emptyValue">
                <img class="image" [src]="domSanitizer.bypassSecurityTrustResourceUrl(getImg())" alt="logo">
            </ng-container>
            <ng-template #emptyValue>
                <div class="empty-image-container" [class.disabled]="disabled">
                    <img class="empty-image" [src]="domSanitizer.bypassSecurityTrustResourceUrl(getImg())" alt="logo">
                    <p><span>{{'ImagePicker.choose'|translatePipe}}</span> {{'ImagePicker.drag'|translatePipe}}</p>
                </div>
            </ng-template>


        </ng-template>
        <input [disabled]="disabled" type="file" name="img-upload" id="img-upload" (change)="uploadImg($event)" />
    </div>
    <button [disabled]="disabled" class="btn btn-danger remove-button mb-4 col-12" (click)="value=null">{{'remove'|translatePipe}}</button>
</div>