import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Member } from 'src/app/interfaces/member';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-members-resaults',
  templateUrl: './members-resaults.component.html',
  styleUrls: ['./members-resaults.component.scss']
})
export class MembersResaultsComponent implements OnInit {


  constructor(
    private globalVariableService: GlobalVariablesService,
    private router: Router
  ) { }

  members = [];
  currAction='do'
  options=['do','dont']

  ngOnInit(): void {
    if (!this.globalVariableService.memberSearchResults) {
      
      this.router.navigate(['search-members']);
    }
    else {
      this.members = this.globalVariableService.memberSearchResults.map(member => ({ ...member, isCheck: false }))
    }
  }

  getNumOfSelected() {
    return this.members.reduce((acc, member) => {
      if (member.isCheck) acc++
      return acc
    }, 0)
  }

  selectAll() {
    this.members = this.members.map(member => ({ ...member, isCheck: true }))
  }

  goBack(){
    this.router.navigate(['search-members']);
  }

}
