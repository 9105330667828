<div dirControl class="group-invite">
    <div class="mini-data-and-menu">
        <app-group-mini-data [group]="group"></app-group-mini-data>
        <app-mini-menu [buttons]="menuButtons"></app-mini-menu>
    </div>

    <div class="main-card card mb-4">
        <div class="card-body">
            <!-- <h3 class="card-title"></h3> -->
            <app-input-label [(isValid)]="isEmailValid" [email]="true" [required]="true" [label]="'email'|translatePipe" name="email"
                type="email" [(value)]="email"></app-input-label>
            <div class="text-center">
                <button class="btn btn-success">{{'send'|translatePipe}}</button>
            </div>
        </div>
    </div>

    <!-- <div class="main-card card mb-4">
        <div class="card-body">
            <div class="input-group mb-3">
                <label>link</label>
                <input type="text" class="form-control" [value]="link"
                    placeholder="content will be copied after click the copy button">
                <button class="btn btn-primary" type="button" ngxClipboard [cbContent]="link"
                    (cbOnSuccess)="isCopied = true" (cbOnError)="onCopyFailure()"
                    [class.btn-success]="isCopied">Copy</button>
            </div>

            <app-check-box-label label="Include your name in this invitation" [(isCheck)]="isIncludeNameInInvitation">
            </app-check-box-label>
        </div>
    </div> -->
</div>