import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../interfaces/validation-result';
import { MemberServerService } from '../services/member-server.service';

@Injectable({
    providedIn: 'root'
})
export class AppAsyncValidators {
    constructor(
        private memberService: MemberServerService
    ) { }


    public usernameTaken = async (control: AbstractControl): Promise<ValidationResult> => {
        const isValid = await this.memberService.isIdentifierValid(control.value).toPromise();
        if (!isValid) return { "usernameTaken": true };
        return null;
    }

    validator = {
        usernameTaken: this.usernameTaken
    }
}