import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'li[tree]',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() group !: Group;
  @Input() action !: string;
  @Input() isShowButtons = false;
  @Output() onSelectChild = new EventEmitter<string>()
  @Output() onSetCurrAction = new EventEmitter<string>()
  children: Group[] = [];


  constructor(private organizationalStructureService: OrganizationalStructureService) { }

  ngOnInit(): void {
    this.updateChildren()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateChildren();
  }

  async updateChildren() {
    const newChildren: Group[] = this.group?.children.map(value => this.organizationalStructureService.emptyGroup);
    let promises = this.group?.children.map(async (childId, idx) => {
      const child: Group = await this.organizationalStructureService.getGroupById(childId).toPromise()
      return child
    });
    if (promises) {
      Promise.all(promises).then(resolve => {
        this.children = resolve;
      })
    }
  }

  clickedHoveredButton(action: string, groupId) {
    this.onSetCurrAction.emit(action);
    this.onSelectChild.emit(groupId);
    this.onSetCurrAction.emit('');
  }
}
