import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-trigger-managment-table',
  templateUrl: './trigger-managment-table.component.html',
  styleUrls: ['./trigger-managment-table.component.scss']
})
export class TriggerManagmentTableComponent implements OnInit, OnChanges {

  @Output() removeTrigger = new EventEmitter<any>();
  @Input() triggers: any[];
  triggersToShow=[];
  page = 1;
  pageSize = 25;

  constructor() { }

 

  ngOnInit(): void {
    this.setTriggersToShow()
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.setTriggersToShow()
  }

  getClass() {
    return `checkbox-container ${this.triggers.find(trigger => trigger.isCheck) ? 'checked' : ''}`
  }

  setTriggersToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.triggersToShow = this.triggers?.slice(start, start + this.pageSize);
  }

  // getTriggerTimerString(trigger){
  //   let string = trigger.timer
  //   if(trigger.timer==='once a day'){
  //     string+=` at ${this._getTimeString(trigger.timeInDay.time)}`          
  //   }else if(trigger.timer==='once a week'){
  //     string+=` at ${trigger.timeInWeek.day} in ${this._getTimeString(trigger.timeInWeek.time)}`          
  //   }else if(trigger.timer==='once a month'){
  //     string+=` at ${trigger.timeInMonth.day}th of the month in ${this._getTimeString(trigger.timeInMonth.time)}`          
  //   }
  //   return string;
  // }

  getTimeString(time){
    return `${time.hour}:${time.minute<10?'0'+time.minute:time.minute}`
  }

}
