import * as OrganizationActions from '../actions/organization.actions';

type Action = OrganizationActions.setOrganization

export const initialState = {
  id: 'os101',
  title: 'Icon fitness'
};

export interface State {
  id: string,
  title: string
}

export function organizationReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case OrganizationActions.ActionTypes.setOrganization:
      return {
        ...state,
        id: action.id,
        title: action.title
      };
    default:
      return state;
  }
}