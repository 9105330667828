<div class="row mb-3">
    <div class="col-6">
        <div class="main-card card  total-users-card">
            <div class="card-body">
                <h3 class="card-title">Total {{recycleBinList.length}} archived items</h3>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class=" card">
            <div class="card-body">
                <form>
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            placeholder="search">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-recycle-bin-table [recycleBinList]="filterRecycleBinList()" ></app-recycle-bin-table>