import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card } from 'src/app/interfaces/card';

@Component({
  selector: 'app-card-template-preview',
  templateUrl: './card-template-preview.component.html',
  styleUrls: ['./card-template-preview.component.scss']
})
export class CardTemplatePreviewComponent implements OnInit {
  @Input() card: Card
  @Input() imgUrl: string
  @Output() onEditCard = new EventEmitter<string>();
  @Output() onRemoveCard = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

}
