import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-group-picker-input',
  templateUrl: './group-picker-input.component.html',
  styleUrls: ['./group-picker-input.component.scss']
})
export class GroupPickerInputComponent implements OnInit {

  @Input() groups?: any[]
  @Input() label?: string = 'Group'
  @Input() disabled?: boolean = false
  subscription: Subscription[] = []

  @Input() set currGroup(val: any) {
    this._currGroup = val;
    this.currGroupChange.emit(val);
  }
  @Output() currGroupChange = new EventEmitter<any>();
  get currGroup() {
    return this._currGroup;
  }
  private _currGroup;

  constructor(
    private organizationalStructureService: OrganizationalStructureService
  ) { }

  ngOnInit(): void {
    if (!this.groups) {
      this.subscription.push(
        this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
          this.groups = organizationalStructure.map(group => {
            const { id, name, type } = group
            return { id, name, type }
          })
          if (this.currGroup?.id) {
            if (this.groups.length) {
              this.currGroup = this.groups.find(group => group?.id === this.currGroup?.id);
            }
          }

          else this.currGroup = this.groups.find(group => group?.type === 'organization');
        })
      )
      this.organizationalStructureService.loadOrganizationalStructure();
    }
    if (this.currGroup?.id && this.groups.length) {
      this.currGroup = this.groups.find(group => group?.id == this.currGroup?.id);
    }
  }

  onSelectChild(childId) {
    this.currGroup = this.groups.find(group => group.id === childId)
  }

}
