<app-organization-header [organization]="organization"></app-organization-header>
<div class="overflow-hidden mb-3">
    <div dirControl class="g-0 row">
        <div *ngFor="let dashbordItem of dashboardData" class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <div [routerLink]="dashbordItem.routerLink" class="widget-chart widget-chart-hover">
                <div class="icon-wrapper">
                    <div [ngClass]="'widget-numbers text-'+dashbordItem.color">{{dashbordItem.title | translatePipe}}</div>
                    <div [ngClass]="'icon-wrapper-bg bg-'+dashbordItem.color"></div>
                    <i [ngClass]="dashbordItem.icon+' pb-3 text-'+dashbordItem.color"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <app-button-box-tree-container (onChooseGroup)="onChooseGroup($event)" [group]="organization">
</app-button-box-tree-container> -->