<div class="row">
    <div class="col-11 row">
        <div class="col-4">
            <span>{{group.name}}</span>
        </div>
        <div class="col-4">
            <span>{{group.members.length}} {{'users'|translatePipe}}</span>
        </div>
        <div class="col-4">
            <span>{{group.chatSettings.lastActivity | formatDate}}</span>
        </div>
    </div>
    <div class="col-1">
        <bSwitch [switch-animate]="false" switch-size="mini" name="side-select" (ngModelChange)="toggleIsAllowChat.emit(group.id)"
            [ngModel]="group.chatSettings.isAllowChat">
        </bSwitch>
    </div>
</div>
<div class="divider"></div>