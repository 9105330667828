<div class="control-btns">
    <button [class.curr-control]="action === 'add'" (click)="onSetCurrAction.emit('add')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="add"></i>
    </button>
    <button [class.curr-control]="action === 'edit'" (click)="onSetCurrAction.emit('edit')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="edit"></i>
    </button>
    <button [class.curr-control]="action === 'delete'" (click)="onSetCurrAction.emit('delete')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="delete"></i>
    </button>
    <button [class.curr-control]="action === 'members'"  (click)="onSetCurrAction.emit('members')"
        class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
        <i class="fa fa-fw" aria-hidden="true" title="members"></i>
    </button>
</div>