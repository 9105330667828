import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-register-password',
  templateUrl: './register-password.component.html',
  styleUrls: ['./register-password.component.scss']
})
export class RegisterPasswordComponent implements OnInit {

  constructor() { }

  @ViewChild('repeatPasswordInput') repeatPasswordInput!: any;


  isShowPassword = false;
  password = '';
  isShowRepeatPassword = false;
  // repeatPassword = '';
  isVeryStrongPassword = false;
  private _isPasswordValidChange
  @Output() isPasswordValidChange = new EventEmitter<boolean>();
  @Output() repeatPasswordChange = new EventEmitter<string>();
  @Input() labelPassword? = 'password'
  @Input() labelRepeatPassword? = 'repeat password'
  @Input() set isPasswordValid(val: boolean) {
    this._isPasswordValidChange = val;
    this.isPasswordValidChange.emit(val);
  }

  _repeatPassword = ''
  get repeatPassword() {
    return this._repeatPassword
  }

  @Input() set repeatPassword(value) {
    this._repeatPassword = value || '';
    this.repeatPasswordChange.emit(value);
    this.isPasswordValid = value === this.password && this.isVeryStrongPassword
    if (!this.isPasswordValid) {
      this.repeatPasswordInput?.formControler?.setErrors({ 'incorrect': true })
    }
  }

  get isPasswordValid() {
    return this._isPasswordValidChange;
  }

  get passwordProgressBarData() {
    if (!this.password.length) return {
      text: 'empty',
      type: 'secondary',
      value: 5
    }

    const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    const lowerLetters = 'abcdefghijklmnopqrstuvwxyz'.split('')
    const digits = '1234567890'.split('')

    const passwordData = this.password.split('').reduce((acc, char) => {
      if (!acc.isCapital && capitalLetters.some(capitalLetter => capitalLetter === char)) acc.isCapital = true;
      else if (!acc.isLower && lowerLetters.some(lowerLetter => lowerLetter === char)) acc.isLower = true
      else if (!acc.isDigit && digits.some(digit => digit === char)) acc.isDigit = true
      return acc
    }, { isCapital: false, isLower: false, isDigit: false })

    let value = 5;
    if (passwordData.isCapital) value += 25
    if (passwordData.isLower) value += 25
    if (passwordData.isDigit) value += 25
    if (this.password.length >= 8) value += 20

    let type = 'secondary'
    let text = 'empty'
    if (value >= 30 && value < 55) {
      type = 'danger'
      text = 'weak'
    }
    if (value >= 55 && value < 80) {
      type = 'danger'
      text = 'medium'
    }
    if (value >= 80 && value < 100) {
      type = 'warning'
      text = 'strong'

    }
    if (value === 100) {
      type = 'success'
      text = 'very strong'

    }

    this.isVeryStrongPassword = value === 100;
    this.isPasswordValid = this.isVeryStrongPassword && this.password === this.repeatPassword
    if (!this.isPasswordValid) {
      this.repeatPasswordInput?.formControler?.setErrors({ 'incorrect': true })
    }

    return {
      text: text,
      type: type,
      value: value
    }
  }

  ngOnInit(): void {
  }

}
