<div dirControl class="row event-managment">
    <div class="col-12 mb-3">
        <div class="main-card card">
            <div class="card-body">
                <div class="event-groups">
                    <h3 class="card-title">{{'groups'|translatePipe}}</h3>
                    <div class="groups">
                        <p class="group-option" [class.curr]="currGroup.id===group.id" *ngFor="let group of groups"
                            (click)="currGroup = group">
                            {{group.name==='all'?('all'|translatePipe):(group.name| titleCase)}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-3">
        <div class="main-card card">
            <div class="card-body">
                <p class="groups" [class.curr]="currGroup.id===group.id" *ngFor="let group of groups"
                    (click)="currGroup = group">
                    {{group.name | titleCase}}
                </p>
            </div>
        </div>
    </div> -->
    <div class="col-12">
        <div class="card main-card mb-3">
            <div class="card-body">
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="btn-group">
                            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                                (viewDateChange)="closeOpenMonthViewDay()">
                                {{'previous'|translatePipe}}
                            </div>
                            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                                {{'today'|translatePipe}}
                            </div>
                            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                                (viewDateChange)="closeOpenMonthViewDay()">
                                {{'next'|translatePipe}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                    </div>
                    <div class="col-md-4">
                        <div class="btn-group">
                            <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                                [class.active]="view === CalendarView.Month">
                                {{'month'|translatePipe}}
                            </div>
                            <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                                [class.active]="view === CalendarView.Week">
                                {{'week'|translatePipe}}
                            </div>
                            <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                                [class.active]="view === CalendarView.Day">
                                {{'day'|translatePipe}}
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                        [events]="filterEvents" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                        (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
                    </mwl-calendar-day-view>
                </div>
            </div>
        </div>
        <ng-template #modalContent let-close="close">
            <div class="modal-header">
                <ng-container *ngIf="modalData?.action!=='delete'; else deleteModal">
                    <h5 class="modal-title">{{modalData?.event.title | titleCase}} event</h5>
                </ng-container>
                <ng-template #deleteModal>
                    <h5 class="modal-title">Delete {{modalData?.event.title}} event?</h5>
                </ng-template>

                <button type="button" class="btn-close" (click)="close()">
                </button>
            </div>
            <div class="modal-body">

                <ng-container *ngIf="modalData?.action!=='delete'; else elseTemplate">
                    <div class="data-container" [ngClass]="modalData?.event.image.location">
                        <div *ngIf="modalData?.event.image.url" class="img-container">
                            <img [src]="modalData?.event.image.url" alt="event image">
                        </div>
                        <div class="data">
                            <p>start: {{modalData?.event.start.getTime() | formatDateTime}}</p>
                            <p>end: {{modalData?.event.end.getTime() | formatDateTime}}</p>
                            <div class="divider"></div>
                            <p>description: {{modalData?.event.description}}</p>
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplate>
                    <div class="">
                        <p>Are you Sure you want delete {{modalData?.event.title}} event?</p>
                    </div>
                </ng-template>



            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="modalData?.action==='delete'" class="btn btn-danger"
                    (click)="deleteEvent(modalData?.event.id,close)">
                    Delete
                </button>

                <button type="button" class="btn btn-secondary" (click)="close()">
                    Close
                </button>
            </div>
        </ng-template>
    </div>
</div>

<a routerLink="/event-view" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw" aria-hidden="true"
        title="add"></i></a>