import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalPostService } from 'src/app/services/portal-post.service';

@Component({
  selector: 'app-portal-posts',
  templateUrl: './portal-posts.component.html',
  styleUrls: ['./portal-posts.component.scss']
})
export class PortalPostsComponent implements OnInit {

  postsTypes = ['employees', 'supplies', 'customers'];
  @Input() currType = 'employees'
  subscription: Subscription[] = []
  posts = []
  postsMap = {
    employees: [],
    supplies: [],
    costumers: []
  }

  newPost = {
    title: '',
    image: '',
    content: ''
  }

  constructor(
    private portalPostService: PortalPostService
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.portalPostService.portalPosts$.subscribe(posts => {
        this.posts = posts;
        this.setPostsMap();
      })
    )
    this.portalPostService.loadPortalPosts();
  }

  setPostsMap() {
    const postsMap = {
      employees: [],
      supplies: [],
      costumers: []
    }

    this.posts.forEach(post => {
      console.log(post.type);
      if(postsMap[post.type]) postsMap[post.type].push(post);
      else postsMap[post.type]= [post]
    })

    this.postsMap = postsMap
  }

  onAddPost() {
    const newPost = {
      type: this.currType,
      ...this.newPost
    }
    this.newPost = {
      title: '',
      image: '',
      content: ''
    }
    this.portalPostService.add(newPost).toPromise();
  }

}
