import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-group-invite',
  templateUrl: './group-invite.component.html',
  styleUrls: ['./group-invite.component.scss']
})
export class GroupInviteComponent implements OnInit {

  subscription: Subscription[] = [];
  menuButtons: any[] = [
    // {
    //   text: 'invite friends',
    //   cb: () => { }
    // },
    // {
    //   text: 'share',
    //   cb: () => { }
    // },
    // {
    //   text: 'report',
    //   cb: () => { }
    // },
    // {
    //   text: 'leave'
    //   ,
    //   cb: () => { }
    // }
  ]

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,

  ) { }

  group: Group = this.organizationalStructureService.emptyGroup;
  email = ''
  isEmailValid = false;
  isIncludeNameInInvitation = false;
  isCopied: boolean;

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          this.group = await this.organizationalStructureService.getGroupById(id).toPromise()
        }
      })
    )
  }

  get link() {
    if (this.isIncludeNameInInvitation) return `http://localhost:4200/register/${this.group.id}?isInvited=true&invitedBy=userId`
    return `http://localhost:4200/register/${this.group.id}?isInvited=true`
  }

  onCopyFailure() {
    // alert('copy fail!');
  }

  toggleIsIncludeNameInInvitation() {
    console.log('hap');
    this.isIncludeNameInInvitation = !this.isIncludeNameInInvitation
  }


}
