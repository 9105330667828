import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-organization-dashboard',
  templateUrl: './organization-dashboard.component.html',
  styleUrls: ['./organization-dashboard.component.scss']
})
export class OrganizationDashboardComponent implements OnInit {

  subscription: Subscription[] = [];
  organization: Group

  dashboardData = [
    {
      icon: 'pe-7s-user',
      title: 'my profile',
      color: 'red',
      routerLink:'/profile'
    },
    {
      icon: 'pe-7s-user',
      title: 'employee directory',
      color: 'blue',
      routerLink:'/employee-directory'
    },
    {
      icon: 'pe-7s-add-user',
      title: 'candidate directory',
      color: 'green',
      routerLink:'/candidate-directory'
    },
    {
      icon: 'pe-7s-note2',
      title: 'customer directory',
      color: 'blue2',
      routerLink:'/customer-directory'
    },
    {
      icon: 'pe-7s-date',
      title: 'calendar',
      color: 'indigo',
      routerLink:'/calendar'
    },
    {
      icon: 'pe-7s-id',
      title: 'cards',
      color: 'purple2',
      routerLink:'/cards'
    },
    {
      icon: 'pe-7s-menu',
      title: 'sets',
      color: 'purple',
      routerLink:'/sets'
    },
    {
      icon: 'pe-7s-cart',
      title: 'ecommerce',
      color: 'blue2',
      routerLink:'/organization-ecommerce'
    },
    {
      icon: 'pe-7s-display2',
      title: 'employee portal',
      color: 'teal',
      routerLink:'/employee-portal'
    },
    {
      icon: 'pe-7s-display1',
      title: 'customer portal',
      color: 'green',
      routerLink:'/customer-portal'
    },
    {
      icon: 'pe-7s-news-paper',
      title: 'candidate Portal',
      color: 'green2',
      routerLink:'/candidate-portal'
    },
    {
      icon: 'pe-7s-comment',
      title: 'socialNetwork',
      color: 'blue',
      routerLink:'/club-social-network'
    },
    {
      icon: 'pe-7s-film',
      title: 'media',
      color: 'green2',
      routerLink:'/organization-media'
    },
    {
      icon: 'pe-7s-chat',
      title: 'chat',
      color: 'red',
      routerLink:'/chat'
    },
    {
      icon: 'pe-7s-network',
      title: 'groups',
      color: 'pink',
      routerLink:'/groups'
    },
    {
      icon: 'pe-7s-config',
      title: 'user Control Panel',
      color: 'grey2',
      routerLink:'/user-control-pannel'
    },
  ]


  constructor(
    public organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const organizationId = params.get('id');
        if (organizationId) {
          this.organization = await this.organizationalStructureService.getGroupById(organizationId).toPromise()
        }
      })
    )
  }

  onChooseGroup(groupId) {
    this.router.navigate(['group-view', groupId]);
  }

}
