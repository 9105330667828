<div dirControl class="row">
    <!-- <div class="col-3">
        <div class="main-card card">
            <div class="card-body">
                <div *ngIf="cardGroups.length" class="card-groups">
                    <h3 class="card-title">Group cards</h3>
                    <p (click)="setCurrGroup(null)" [class.curr]="currGroup?.type==='organization'">
                        All groups
                    </p>
                    <p *ngFor="let group of cardGroups" [class.curr]=" currGroup?.id===group.id"
                        (click)="setCurrGroup(group)">
                        {{group.name}}
                    </p>
                    <div *ngIf=" organizationCards.length" class="divider">
                    </div>
                </div>
                <div *ngIf="organizationCards.length" class="organization-cards">
                    <h3 class="card-title">{{organizationCards[0].group.name}} cards</h3>
                    <p *ngFor="let card of organizationCards">{{card.title}}</p>
                </div>
            </div>
        </div>
    </div> -->
    <div *ngIf="cardGroups.length" class="col-12 mb-3">
        <div class="main-card card">
            <div class="card-body">
                <div class="card-groups">
                    <h3 class="card-title">{{'groupCards'|translatePipe}}</h3>
                    <div class="groups">
                        <p (click)="setCurrGroup(null)" class="group-option" [class.curr]="currGroup?.type==='organization'">
                            {{'allGroups'|translatePipe}}
                        </p>
                        <p *ngFor="let group of cardGroups" class="group-option" [class.curr]=" currGroup?.id===group.id"
                            (click)="setCurrGroup(group)">
                            {{group.name}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="main-card card mb-3">
            <div class="card-body">
                <form>
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <div *ngFor="let card of filterCards" class="col-12 col-sm-6 col-lg-4 mb-3">
                <app-card-template-preview (onEditCard)="onEditCard($event)" [card]="card"
                    [imgUrl]="getCardImg(card.group.id)" (onRemoveCard)="onRemoveCard($event)">
                </app-card-template-preview>
            </div>
        </div>


    </div>
</div>
<a routerLink="/card-view" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw" aria-hidden="true"
        title="add"></i></a>