import { animate, state, style, transition, trigger } from "@angular/animations";

export default trigger('slide-vertical', [
    state('in', style({
        height: '0px',
        overflow: 'hidden'
    })),
    state('out', style({
        overflow: 'default',
    })),
    transition('in <=> out', animate(100)),
])