import { Directive, Input, Output, EventEmitter, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FullScreenImgService } from '../services/full-screen-img.service';

@Directive({
  selector: '[fullScreenImg]'
})
export class FullScreenImgDirective {

  // @Output() clickOutside = new EventEmitter<void>();


  constructor(
    private elementRef: ElementRef,
    private fullScreenImgSevice: FullScreenImgService,
    private rendrer: Renderer2
  ) { 
    rendrer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer')
  }

  // @Input() src: any;


  @HostListener('click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (clickedInside) {
      this.fullScreenImgSevice.onImageClick(this.elementRef.nativeElement.src)
      // this.clickOutside.emit();
    }
  }

}
