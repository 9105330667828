import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import slideVertical from 'src/app/animations/slide-vertical.animation';

@Component({
  selector: 'app-description-and-legend',
  templateUrl: './description-and-legend.component.html',
  styleUrls: ['./description-and-legend.component.scss'],
  animations: [
    slideVertical
  ]
})
export class DescriptionAndLegendComponent implements OnInit {
  @Output() toggleView = new EventEmitter<null>()

  legends = [
    {
      color: '#d92550',
      description: 'organization'
    },
    {
      color: '#f7b924',
      description: 'department'
    },
    {
      color: '#3ac47d',
      description: 'groupSubGroup'
    },
  ]
  slideSituation = 'out';


  constructor() { }

  ngOnInit(): void {
  }

  toggleSlideSituation() {
    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in'
  }

}
