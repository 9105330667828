import { NgModule } from "@angular/core";
import { TranslatePipe } from "projects/otech-i18n/src/lib/translate.pipe";
import { OtechI18nModule } from '../../projects/otech-i18n/src/lib/otech-i18n.module'
@NgModule({
    imports: [
        OtechI18nModule.forRoot({
            'en': {
                organization: 'Organization',
                orgnaizationalStructure: 'Organizational Structure',
                systemSettings: 'System Settings',
                members: 'Members',
                manageMembers: 'Manage Members',
                searchMember: 'Search Member',
                memberView: 'Member View',
                inviteMember: 'Invite Member',
                roleManagement: 'Role Managment',
                globalAdminstrators: 'Global Adminstrators',
                globalAdministrators: 'Global Administrators',
                localAdminstrators: 'Local Administrators',
                setManagement: 'Set Management',
                propertyList: 'Property List',
                cardManagement: 'Card Management',
                cardUsage: 'Card Usage',
                calendarManagement: 'Calender Management',
                chatManagement: 'Calender Management',
                stockManagement: 'Stock Management',
                itemsManagement: 'Items Management',
                socialNetwork: 'Social Network',
                digitalWallet: 'Digital Wallet',
                media: 'Media',
                notificiations: 'Notifications',
                portal: 'Portal',
                customerService: 'Customer Service',
                globalSearch: 'Global Search',
                advenvedSearch: 'Advanced Search',
                logs: 'Logs',
                archive: 'Archive',
                backups: 'Backups',
                admins: 'Admins',
                sets: 'Sets',
                cards: 'Cards',
                events: 'Events',
                chat: 'Chat',
                ecommerce: 'eCommerce',
                tools: 'Tools',
                legend: 'Legend',
                department: 'Department',
                group: 'Group',
                groups: 'Groups',
                subGroup: 'Sub-Group',
                groupSubGroup: 'Group / Sub-Group',
                hide: 'Hide',
                show: 'Show',
                lorem: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro deserunt voluptas ipsam odit, ducimus, molestias quos nam fugit voluptatem velit eaque, ex error? Odio dicta rem reiciendis quis repellat aliquam.',
                changeView: 'Change view',
                generalInformation: 'General information',
                privacy: 'Privacy',
                options: 'Options',
                contact: 'Contact',
                phone: 'Phone',
                email: 'Email',
                whatsapp: 'WhatsApp',
                saveSettings: 'Save Settings',
                memberSince: 'Member since?',
                identifier: 'Identifier',
                lastSeen: 'Last seen',
                search: 'Search',
                actions: 'Actions',
                go: 'Go',
                remove: 'Remove',
                suspension: 'Suspenision',
                "send messege": 'Send messege',
                searchCondition: 'Search condition',
                contain: 'Contain',
                same: 'Same',
                end: 'End',
                start: 'Start',
                "user name": 'User name',
                "member since": "Member since",
                to: "To",
                clear: "Clear",
                "save filter": 'Save filter',
                "saved filter": 'Saved filter',
                load: 'Load',
                rename: 'Rename',
                delete: 'Delete',
                "member details": 'Member details',
                "temp password": "Temp password",
                generate: "Generate",
                "add to": "Add to",
                "profile image": "Profile image",
                "global sets": 'Global sets',
                "local sets": 'Local sets',
                "save member": 'Save member',
                name: 'Name',
                send: 'Send',
                "global administrators": 'Global administrators',
                "local administrators": 'Local administrators',
                administrators: 'Administrators',
                moderators: 'Moderators',
                moderator: 'Moderator',
                all: 'All',
                premade: 'Premade',
                generic: 'Generic',
                auto: 'Auto',
                categories: 'Categories',
                firstName: 'First name',
                lastName: 'Last name',
                profilePicture: 'Profile picture',
                birthDate: 'Birth date',
                number: 'Number',
                text: 'Text',
                longText: 'Long Text',
                date: 'Date',
                time: 'Time',
                check: 'Check',
                registered: 'Registered',
                age: 'Age',
                fullName: 'Full name',
                autoSet: 'Auto set',
                genericSet: 'Generic set',
                premadeSet: 'Premade set',
                "set view": "Set view",
                title: 'Title',
                default: 'Default',
                min: 'Minimum',
                max: 'Maximum',
                symbol: 'Symbol',
                right: 'Right',
                left: 'Left',
                symbolSide: 'Symbol side',
                thousandCommas: 'Use thousand commas',
                minCharacters: 'Minimum characters',
                maxCharacters: 'Maximum characters',
                showDay: 'Show day of the week',
                formatDate: 'Format date',
                formatTime: 'Format time',
                color: 'Color',
                advanceOptions: 'Advance option',
                save: 'Save',
                groupCards: 'Group cards',
                allGroups: 'All groups',
                properties: 'Properties',
                hours: 'Hours',
                days: 'Days',
                minutes: 'Minutes',
                weeks: 'Weeks',
                months: 'Months',
                years: 'Years',
                give: 'Give',
                edit: 'Edit',
                cardView: 'Card view',
                units: 'Units',
                member: 'Member',
                use: 'Use',
                outOf: 'out of',
                used: 'used',
                previous: 'Previous',
                next: 'Next',
                today: 'Today',
                month: 'Month',
                week: 'Week',
                day: 'Day',
                filters: 'Filters',
                user: 'User',
                structure: 'Structure',
                limits: 'Limits',
                accepted: 'Accepted',
                "not sure": 'Not sure',
                declined: 'declined',
                pending: 'Pending',
                guests: 'Guests',
                users: 'Users',
                description: 'Description',
                "club type": "Club type",
                'organization & users': 'Organization & Users',
                'communnity & members': 'Communnity & Members',
                'business & employees': 'Business & Employees',
                worldwide: 'Worldwide',
                israel: 'Israel',
                country: 'Country',
                'brand color': 'Brand color',
                logo: 'logo',
                'select all': 'Select all',
                back: 'Back',
                selected: 'Selected',
                'event view': 'Event view',
                info: 'Info',
                'image location': 'Image location',
                above: 'Above',
                below: 'Below',
                'event image': 'Event image',
                length: 'Length',
                invitations: 'Invitations',
                'chat settings': 'Chat settings',
                'guest email': 'Guest email',
                'search objects': 'Search objects',
                'object name': 'Object name',
                'users in chat': 'Users in chat',
                'last activity': 'Last activity',
                'ecommerce managment': 'eCommerce managment',
                'ecommerce status': 'eCommerce status',
                'digital wallet': 'Digital wallet',
                'digital wallet status': 'Digital wallet status',
                'media managment': 'Media managment',
                'media status': 'Media status',
                'objects': 'Objects',
                from: 'From',
                object: 'Object',
                value: 'Value',
                seen: 'Seen',
                security: 'Security',
                connections: 'Connections',
                "created by me": "Created by me",
                export: 'Export',
                'appliedBy': 'Applied by',
                'appliedOn': 'Applied on',
                'logType': 'Log type',
                'archived items': 'archived items',
                employees: 'Employees',
                customers: 'Customers',
                notification: 'Notification',
                settings: 'Settings',
                recruitment: 'Recruitment',
                crmPortal: 'CRM portal',
                hrPortal: 'HR portal',
                'total members': 'Total members',
                'total employees': 'Total employees',
                'total customers': 'Total customers',
                type: 'Type',
                'module management': 'Module management',
                MatIconArrowClose: 'arrow_right',
                'in organization': 'In organization',
                'in department': 'In department',
                'in group': 'In group',
                'sub groups': 'Sub groups',
                'member name': 'Member name',
                'manage members': 'Manage members',
                'search members': 'Search members',
                'members of group': 'Members of group',
                'members of department': 'Members of department',
                yes: 'Yes',
                no: 'No',
                'short-text': 'Short text',
                'long-text': 'Long text',
                'url': 'Url',
                'timestamp': 'Date',
                'possible characters': 'Possible characters',
                'default time': 'Default time',
                'show less': 'Show less',
                'show more': 'Show more',
                'landing page': 'Landing page',
                'landing page status': 'Landing page status',
                'customer service managment': 'Customer service managment',
                'customer service status': 'Customer service status',
                'support user name': 'Support user name',
                'messege': 'Messege',
                candidates: 'Candidates',
                patients: 'Patients',
                ats: 'ATS',
                'employees posts': 'Employees posts',
                'employee view': 'Employee view',
                'manage employees': 'Manage employees',
                'customers posts': 'Customers posts',
                'customer view': 'Customer view',
                'manage customers': 'Manage customers',
                'candidates posts': 'Candidates posts',
                'candidate view': 'Candidate view',
                'manage candidates': 'Manage candidates',
                'patients posts': 'Patients posts',
                'patient view': 'Patient view',
                'manage patients': 'Manage patients',
                content: 'Content',
                'add post to': 'Add post to',
                'image': 'image',
                'member identifier': 'Member identifier',
                'full name': 'Full name',
                'add event': 'Add event',
                'edit description': 'Edit description',
                'contact info': 'Contact info',
                'connect to member': 'Connect to member',
                'gender': 'Gender',
                'male': 'Male',
                'female': 'Female',
                'other': 'Other',
                'address': 'Address',
                'candidate info': 'Candidate info',
                'hirring date': 'Hirring date',
                'devision': 'Devision',
                'candidate status': 'Candidate status',
                'employee info': 'Employee info',
                'id number': 'ID number',
                'start date': 'Start date',
                'direct managers': 'Direct managers',
                'employee status': 'Employee status',
                'customer info': 'Customer info',
                'sector': 'Sector',
                'secondery contact': 'Secondery contact',
                'company status': 'Company status',
                'patient info': 'patient info',
                'diagnosis': 'Diagnosis',
                'patient status': 'Patient status',
                'home address': 'Home address',
                active: 'Active',
                inactive: 'Inactive',
                'recent events': 'Recent events',
                'see more': 'See more',
                'choose type': 'Choose type',
                'event name': 'Event name',
                'event participants': 'Event participants',
                'event date': 'Event date',
                'event location': 'Event location',
                'event description': 'Event description',
                'attach files': 'Attach files',
                'save and continue': 'Save and continue',
                'account': 'Account',
                'call to': 'call to',
                'employee': 'Employee',
                'customer': 'Customer',
                'candidate': 'Candidate',
                'patient': 'Patient',
                'choose a files': 'Choose a files',
                'or drag them here': 'or drag them here',
                'log search': 'Log search',
                'local moderators': 'Local moderators',
                'welcome back to': 'Welcome back to',
                'password': 'Password',
                'sign in': 'Sign in',
                'sign up here': 'Sign up here',
                'new user': 'New user',
                'forgot your password': 'Forgot your password',
                'recover your account': 'Recover your account',
                'what a code did you receive': 'What a code did you receive',
                'code has been send to': 'Code has been send to',
                'change user': 'Change user',
                'what code': 'What code',
                'i did not received anything': 'I did not received anything',
                submit: 'submit',
                'you are invited to': 'You are invited to',
                'club': 'Club',
                'by': 'by',
                'welcome to': 'welcome to',
                'i have read and accept Flexyc terms and condition': 'i have read and accept Flexyc terms and condition',
                'change it': 'Change it',
                'repeat password': 'Repeat password',
                empty: 'Empty',
                weak: 'Weak',
                medium: 'Medium',
                strong: 'Strong',
                'very strong': 'very strong',
                'home page': 'Home page',
                calendar: 'Calendar',
                'user Control Panel': 'User Control Pannel',
                'employee directory': 'Employee directory',
                'employee portal': 'Employee portal',
                'customer directory': 'Customer directory',
                'customer portal': 'customer portal',
                'candidate directory': 'Candidate directory',
                'candidate Portal': 'Candidate Portal',
                'about': 'About',
                'language': 'Language',
                version: 'Version',
                'my profile': 'My profile',
                'view all': 'View all',
                online: 'online',
                'groups and sub groups': 'Groups and sub groups',
                'view all groups': 'View all groups',
                offline: 'Offline',
                'invite friends': 'Invite friends',
                'share': 'Share',
                'report': 'Report',
                'leave': 'Leave',
                yesterday: 'Yesterday',
                typing: 'Typing',
                deadline: 'Deadline',
                status: 'Status',
                priority: 'Priority',
                "task managment": "Task managment",
                "trigger managment": "Trigger managment",
                "automations managment": "Automations managment",
                files: "Files",
                taskView: 'Task view',
                filter: 'Filter',

                triggers: "Triggers",
                timer: "Timer",
                task: "Task",
                "add members": 'Add members',

                done: 'Done',
                stuck: 'Stuck',
                canceled: 'Canceled',
                "work on it": 'Work on it',
                review: 'Review',
                "no need": 'No need',
                "are you sure": 'Are you sure',
                "choose status": "Choose status",
                "triggerView": "Trigger view",

                "once a week": "Once a week",
                "once a day": "Once a day",
                "once a month": "Once a month",
                "time in week": "Time in week",
                "time in day": "Time in day",
                "time in month": "Time in month",
                sunday: "Sunday",
                monday: "Monday",
                tuesday: "Tuesday",
                wednesday: "Wednesday",
                thursday: "Thursday",
                friday: "Friday",
                saturday: "Saturday",
                "num of days":"Num of days",
                at:"at",
                "home-page":"Home page",
                navigation:"Navigation",
                baseModules:"מודלים בסיסיים",
                extraModules:"מודלים נוספים",

                PortalEventView: {
                    visible: 'This event is visible only to managers.',
                    createEvent: 'Create event in the schedule',
                    email: 'Send a email',
                    whatsapp: 'Send a whatsapp',

                },
                ChatSettings: {
                    allowDirect: "Allow direct messages",
                    allowGroup: "Allow object group chats",
                },
                ImagePicker: {
                    choose: 'Choose an image',
                    drag: 'or drag it here'
                },
                EventAttendance: {
                    "invitedM&G": 'invited members and guests',
                },
                CardViewAdvancedOptions: {
                    limit: 'Limit by amount of tickets',
                    startDate: 'Start date',
                    experationDate: 'Experation date',
                    expiredAfter: 'Start date',
                },
                CardTemplatePreview: {
                    limited: 'Tickets is limited to',
                    startAt: 'Start at',
                    experationAt: 'Experation at',
                    experationAfter: 'Experation after',
                },
                setAdvanceOptions: {
                    adminEditOnly: 'This set can be modified by administrators',
                    registrationSet: 'This set appears in registration form',
                    required: 'This set is required',
                    searchable: 'This set shows up in member list table',
                    adminVisibleOnly: 'This set is visible only to admins',
                },
                InviteMember: {
                    email: "invite via email",
                },
                SystemSettings: {
                    publicClub: 'Public club: Allow new users to sign up in independently',
                    privateClub: 'Private club: Users can only be added by an admin',
                    frozenMembers: 'Enable freeze status',
                    leaveRequests: 'Require leave request system',
                    addNewContact: 'Add new contact option:'
                },
                ManageMembers: {
                    statistics: 'View members statistics...',
                    advanced: 'Advanced search'
                },
                Trigger:{
                    at:"at ",
                    "th of the month in":"th of the month in ",
                    in:"in ",
                }
            },
            'he': {
                organization: 'אירגון',
                orgnaizationalStructure: 'מבנה ארגוני',
                systemSettings: 'הגדרות מערכת',
                members: 'משתמשים',
                manageMembers: 'ניהול משתמשים',
                searchMember: 'חיפוש משתמש',
                memberView: 'תצוגת משתמש',
                inviteMember: 'הזמנת משתמש',
                roleManagement: 'ניהול תפקידים',
                globalAdminstrators: 'מנהלים גלובליים',
                globalAdministrators: 'מנהלים גלובליים',
                localAdminstrators: 'מנהלים מקומיים',
                setManagement: 'ניהול מידע',
                propertyList: 'רשימת תכונות',
                cardManagement: 'ניהול כרטיס',
                cardUsage: 'שימוש כרטיס',
                calendarManagement: 'ניהול יומן',
                chatManagement: "ניהול צ'ט",
                stockManagement: 'ניהול מלאי',
                itemsManagement: 'ניהול פריטים',
                socialNetwork: 'רשת חברתית',
                digitalWallet: 'ארנק דיגיטלי',
                media: 'מדיה',
                notificiations: 'התראות',
                portal: 'פורטל',
                customerService: 'שירות לקוחות',
                globalSearch: 'חיפוש גלובלי',
                advenvedSearch: 'חיפוש מורחב',
                logs: 'יומן פעילות',
                archive: 'ארכיון',
                backups: 'גיבויים',
                admins: 'מנהלים',
                sets: 'שדות',
                cards: 'כרטיסים',
                events: 'אירועים',
                chat: "צ'ט",
                ecommerce: "חנות אונליין",
                tools: "כלים",
                legend: 'מקרא',
                department: 'מחלקה',
                group: 'קבוצה',
                groups: 'קבוצות',
                subGroup: 'תת-קבוצה',
                groupSubGroup: 'קבוצה / תת קבוצה',
                hide: 'הסתר',
                show: 'הצג',
                lorem: "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך. קונדימנטום קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.",
                changeView: 'שנה תצוגה',
                generalInformation: 'מידע כללי',
                privacy: 'פרטיות',
                options: 'אופציות',
                contact: 'דרכי התקשרות',
                phone: 'טלפון',
                email: 'דואר אלקטרוני',
                whatsapp: 'WhatsApp',
                saveSettings: 'שמור הגדרות',
                identifier: 'מזהה',
                memberSince: 'משתמש מאז?',
                lastSeen: 'נראה לאחרונה',
                total: 'סך הכל',
                search: 'חפש',
                actions: 'פעולות',
                go: 'בצע',
                remove: 'הסרה',
                suspension: 'השעיה',
                "send messege": 'שלח הודעה',
                searchCondition: 'תנאי חיפוש',
                contain: 'כולל',
                same: 'זהה',
                end: 'נגמר ב',
                start: 'מתחיל ב',
                "user name": 'שם משתמש',
                "member since": "משתמש מאז",
                to: "עד",
                clear: "נקה",
                "save filter": 'שמור חיפוש',
                "saved filter": "חיפושים שמורים",
                load: 'טען',
                rename: 'שנה שם',
                delete: 'מחק',
                "member details": 'פרטי משתמש',
                "temp password": 'סיסמה זמנית',
                generate: "צור",
                "add to": "הוסף ל",
                "profile image": "תמונת פרופיל",
                "global sets": 'שדות גלובליים',
                "local sets": 'שדות מקומיים',
                "save member": 'שמור משתמש',
                name: 'שם',
                send: 'שלח',
                "global administrators": 'מנהלים גלובליים',
                "local administrators": 'מנהלים מקומיים',
                administrators: 'מנהלים גלובליים',
                moderators: 'מנהלים מקומיים',
                moderator: 'מנהל מקומי',
                all: 'הכל',
                premade: 'מוכנים מראש',
                generic: 'גנרים',
                auto: 'אוטומטיים',
                categories: 'קטגוריות',
                firstName: 'שם פרטי',
                lastName: 'שם משפחה',
                profilePicture: 'תמונת פרופיל',
                birthDate: 'תאריך לידה',
                number: 'מספר',
                text: 'טקסט',
                longText: 'פיסקה',
                date: 'תאריך',
                time: 'שעה',
                check: 'תיבת סימון',
                registered: 'תאריך רישום',
                age: 'גיל',
                fullName: 'שם מלא',
                autoSet: 'שדה אוטומטי',
                genericSet: 'שדה גנרי',
                premadeSet: 'שדה מוכן מראש',
                "set view": "תצוגת שדה",
                title: 'כותרת',
                default: 'ברירת מחדל',
                min: 'מינימום',
                max: 'מקסימום',
                symbol: 'סימן',
                right: 'ימין',
                left: 'שמאל',
                symbolSide: 'צד',
                minCharacters: 'מינימום תווים',
                maxCharacters: 'מקסימום תווים',
                thousandCommas: 'השתמש בפסיק להפרדת אלפים',
                showDay: 'הראה יום בשבוע',
                formatDate: 'תבנית לתאריך',
                formatTime: 'תבנית לשעה',
                color: 'צבע',
                advanceOptions: 'אופציות מתקדמות',
                save: 'שמור',
                groupCards: 'קבוצות',
                allGroups: 'כל הקבוצות',
                properties: 'פרטים',
                hours: 'שעות',
                days: 'ימים',
                minutes: 'דקות',
                weeks: 'שבועות',
                months: 'חודשים',
                years: 'שנים',
                give: 'תן',
                edit: 'ערוך',
                cardView: 'תצוגת כרטיס',
                units: 'יחידות',
                member: 'משתמש',
                use: 'נצל',
                outOf: 'מתוך',
                used: 'נוצלו',
                previous: 'הקודם',
                next: 'הבא',
                today: 'היום',
                month: 'חודש',
                week: 'שבוע',
                day: 'יום',
                filters: 'סינונים',
                user: 'משתמש',
                structure: 'מבנה',
                limits: 'גבולות',
                accepted: 'אישרו',
                "not sure": 'לא בטוחים',
                declined: 'דחו',
                pending: 'ממתין',
                guests: 'אורחים',
                users: 'משתמשים',
                description: 'תיאור',
                "club type": "סוג מועדון",
                'organization & users': 'אירגון ומשתמשים',
                'communnity & members': 'קהילה ומשתמשים',
                'business & employees': 'עסק ועובדים',
                worldwide: 'עולמי',
                israel: 'ישראל',
                country: 'מדינה',
                'brand color': 'צבע מותג',
                logo: 'סמל',
                'select all': 'בחר הכל',
                back: 'חזור',
                selected: 'נבחרו',
                'event view': 'תצוגת אירוע',
                info: 'מידע',
                'image location': 'מיקום תמונה',
                above: 'מעל',
                below: 'מתחת',
                'event image': 'תמונת אירוע',
                length: 'אורך',
                invitations: 'הזמנות',
                'guest email': 'אימייל אורח',
                'chat settings': "הגדרות צ'ט",
                'search objects': 'חפש אוביקטים',
                'object name': 'שם האובייקט',
                'users in chat': "משתמשים בצ'אט",
                'last activity': 'פעילות אחרונה',
                'ecommerce managment': 'ניהול חנות אונליין',
                'ecommerce status': 'מצב חנות אונליין',
                'digital wallet': 'ארנק דיגיטלי',
                'digital wallet status': 'מצב ארנק דיגיטלי',
                'media managment': 'ניהול מדיה',
                'media status': 'מצב מדיה',
                value: 'ערך',
                objects: 'אובייקטים',
                from: 'מ',
                object: 'אובייקט',
                seen: 'נראה',
                security: 'אבטחה',
                connections: 'התחברות',
                "created by me": "נוצר על ידך",
                export: 'ייצוא',
                'appliedBy': 'נעשה על ידי',
                'appliedOn': 'נעשה על',
                'logType': 'סוג פעילות',
                'archived items': 'פריטים בארכיון',
                employees: 'עובדים',
                customers: 'לקוחות',
                notification: 'התראות',
                settings: 'הגדרות',
                recruitment: 'גיוס',
                crmPortal: 'פורטל לקוחות',
                hrPortal: 'פורטל משאבי אנוש',
                'total members': 'סך כל המשמשים',
                'total employees': 'סך כל העובדים',
                'total customers': 'סך כל הלקוחות',
                type: 'סוג',
                'module management': 'ניהול מודלים',
                MatIconArrowClose: 'arrow_left',
                'in organization': 'באירגון',
                'in department': 'במחלקה',
                'in group': 'בקבוצה',
                'sub groups': 'תת קבוצות',
                'member name': 'שם משתמש',
                'manage members': 'נהל משתמשים',
                'search members': 'חפש משתמשים',
                'members of group': 'משתמשים בקבוצה',
                'members of department': 'משתמשים במחלקה',
                yes: 'כן',
                no: 'לא',
                'short-text': 'טקסט',
                'long-text': 'פיסקה',
                'url': 'כתובת אתר',
                'timestamp': 'תאריך',
                'possible characters': 'תווים אפשריים',
                'default time': 'זמן ברירת מחדל',
                'show less': 'ראה פחות',
                'show more': 'ראה יותר',
                'landing page': 'דף נחיתה',
                'landing page status': 'מצב דף נחיתה',
                'customer service managment': 'ניהול שירות לקוחות',
                'customer service status': 'מצב שירות לקוחות',
                'support user name': 'מתמש נותן השירות',
                'messege': 'הודעה',
                candidates: 'מועמדים',
                patients: 'מטופלים',
                ats: 'מה"ב',
                'employees posts': 'הודעות עובדים',
                'employee view': 'תצוגת עובד',
                'manage employees': 'ניהול עובדים',
                'customers posts': 'הודעות לקוחות',
                'customer view': 'תצוגת לקוח',
                'manage customers': 'ניהול לקוחות',
                'candidates posts': 'הודעות מועמדים',
                'candidate view': 'תצוגת מועמד',
                'manage candidates': 'ניהול מועמדים',
                'patient view': 'תצוגת מטופל',
                'patients posts': 'הודעות מטופלים',
                'manage patients': 'ניהול מטופלים',
                content: 'תוכן',
                'add post to': 'הוסף הודעה',
                'image': 'תמונה',
                'member identifier': 'מזהה משתמש',
                'full name': 'שם מלא',
                'edit description': 'ערוך תיאור',
                'contact info': 'פרטי התקשרות',
                'connect to member': 'חבר למשתמש',
                'gender': 'מין',
                'male': 'זכר',
                'female': 'נקבה',
                'other': 'אחר',
                'address': 'כתובת',
                'candidate info': 'פרטי מועמד',
                'hirring date': 'תאריך העסקה',
                'devision': 'מחלקה',
                'candidate status': 'סטטוס מועמד',
                'employee info': 'מידע עובד',
                'id number': 'מספר מזהה',
                'start date': 'תאריך התחלה',
                'direct managers': 'מנהלים ישירים',
                'employee status': 'מצב עובד',
                'customer info': 'מידע לקוח',
                'sector': 'מחלקה',
                'secondery contact': 'איש קשר משני',
                'company status': 'מצב חברה',
                'patient info': 'מידע מטופל',
                'diagnosis': 'איבחון',
                'patient status': 'מצב מטופל',
                'home address': 'כתובת בית',
                active: 'פעיל',
                inactive: 'לא פעיל',
                'recent events': 'אירועים אחרונים',
                'see more': 'ראה עוד',
                'add event': 'הוסף אירוע',
                'choose type': 'בחר סוג',
                'event name': 'שם אירוע',
                'event participants': 'משתתפים באירוע',
                'event date': 'תאריך אירוע',
                'event location': 'מיקום אירוע',
                'event description': 'תיאור אירוע',
                'attach files': 'צרף קבצים',
                'save and continue': 'שמור והמשך',
                'account': 'משתמש',
                'call to': 'תקשר',
                'employee': 'עובד',
                'customer': 'לקוח',
                'candidate': 'מועמד',
                'patient': 'מטופל',
                'choose a files': 'בחר קבצים',
                'or drag them here': 'או גרור אותם לכאן',
                'log search': 'חיפוש ביומן',
                'local moderators': 'מנהלים מקומיים',
                'welcome back to': 'ברוך הבא ל',
                'password': 'סיסמא',
                'sign in': 'להתחבר',
                'sign up here': 'הירשם כאן',
                'new user': 'משתמש חדש',
                'forgot your password': 'שחכתה סיסמא',
                'recover your account': 'שיחזור משתמש',
                'what a code did you receive': 'איזה קוד קיבלתה',
                'code has been send to': 'הקוד נשלח ל',
                'change user': 'שנה משתמש',
                'what code': 'מה הקוד',
                'i did not received anything': 'לא קיבלתי כלום',

                'you are invited to': 'הוזמנת ל',
                'club': 'מועדון',
                'by': 'ע"י',
                'welcome to': 'ברוך הבא ל',
                'i have read and accept Flexyc terms and condition': 'אני קראתי ומאשר את תנאי השימוש',
                submit: 'submit',
                'repeat password': 'חזור על הסיסמא',
                'change it': 'שנה את זה',
                empty: 'ריק',
                weak: 'חלש',
                medium: 'בינוני',
                strong: 'חזק',
                'home page': 'דף הבית',
                'very strong': 'חזק מאוד',
                calendar: 'יומן',
                'user Control Panel': 'לוח בקרה משתמש',
                'employee directory': 'תיק עובד',
                'employee portal': 'לוח עובדים',
                'customer directory': 'תיק לקוח',
                'customer portal': 'לוח לקוח',
                'candidate directory': 'תיק מועמד',
                'candidate Portal': 'לוח מועמד',
                'about': 'אודות',
                'language': 'שפה',
                version: 'גרסה',
                'my profile': 'פרופיל',
                'view all': 'ראה כל',
                'groups and sub groups': 'קבוצות ותתי קבוצות',
                'view all groups': 'ראה כל הקבוצות',
                offline: 'מנותקים',
                online: 'מחוברים',
                'invite friends': 'הזמן חברים',
                'share': 'שתף',
                'report': 'דווח',
                'leave': 'עזוב',
                typing: 'מקליד',
                yesterday: 'אתמול',

                deadline: 'מועד אחרון',
                status: 'מצב',
                priority: 'עדיפות',
                "task managment": "ניהול משימות",
                "trigger managment": "ניהול טריגרים",
                "automations managment": "ניהול אוטומציות",
                files: "קבצים",
                taskView: 'תצוגת משימה',
                filter: 'סנן',

                triggers: "טריגרים",
                timer: "תזמון",
                task: "משימה",
                "add members": 'הוסף משתמשים',


                done: 'בוצע',
                stuck: 'תקוע',
                canceled: 'מבוטל',
                "work on it": 'עובד על זה',
                review: 'ביקורת',
                "no need": 'לא צריך',
                "are you sure": 'אתה בטוח',
                "choose status": "בחר מצב",
                "triggerView": "תצוגת טריגר",
                "once a week": "פעם בשבוע",
                "once a day": "פעם ביום",
                "once a month": "פעם בחודש",
                "time in week": "זמן בשבוע",
                "time in day": "זמן ביום",
                "time in month": "זמן בחודש",
                sunday: "ראשון",
                monday:"שני",
                tuesday:"שלישי",
                wednesday:"רביעי",
                thursday:"חמישי",
                friday:"שישי",
                saturday:"שבת",
                "num of days":"מספר הימים",
                "home-page":"דף הבית",
                navigation:"ניווט",

                baseModules:"Base modules",
                extraModules:"Extra modules",


                PortalEventView: {
                    visible: 'האירוע הזה צפי רק למנהלים',
                    createEvent: 'צור אירוע בלוח הזמנים',
                    email: 'שלח מייל',
                    whatsapp: 'שלח וואטסאפ',

                },
                ChatSettings: {
                    allowDirect: "אפשר שיחות אישיות",
                    allowGroup: "אפשר שיחות בקבצות",
                },
                ImagePicker: {
                    choose: 'בחר תמונה',
                    drag: 'או גרור אותה לכאן'
                },
                EventAttendance: {
                    "invitedM&G": 'מוזמנים משתמשים ואורחים',
                },
                CardViewAdvancedOptions: {
                    limit: 'הגבל כמות ניקובים',
                    startDate: 'תאריך התחלה',
                    experationDate: 'תוקף',
                    expiredAfter: 'פג אחרי',
                },
                CardTemplatePreview: {
                    limited: 'הכרטיסים מוגבלים ל',
                    startAt: 'מתחיל ב',
                    experationAt: 'פג ב',
                    experationAfter: 'פג אחרי',
                },
                setAdvanceOptions: {
                    adminEditOnly: 'השדה יכול להערך רק ע"י מנהלים',
                    registrationSet: 'השדה יופיע במהלך ההרשמה',
                    required: 'שדה חובה',
                    searchable: 'שדה יופיע ברשימת המשתמשים',
                    adminVisibleOnly: 'השדה נראה למנהלים בלבד',
                },
                InviteMember: {
                    email: "הזמן באמצעות דואר אלקטרוני",
                },
                SystemSettings: {
                    publicClub: 'מועדון ציבורי: מאפשר למשתמשים חדשים להירשם באופן עצמאי',
                    privateClub: 'מועדון פרטי: משמשים יכולים להתוסף רק על ידי מנהל המערכת',
                    frozenMembers: 'אפשר הקפאת משתמשים',
                    leaveRequests: 'בקשה לעזיבת מערכת',
                    addNewContact: 'הוסף דרך התקשרות חדשה:'
                },
                ManageMembers: {
                    statistics: 'צפה בסטטיסטיקה',
                    advanced: 'חיפוש מתקדם'
                },
                Trigger:{
                    at:"ב",
                    "th of the month in":" לחודש ב",
                    in:"ב ",
                }
            }
        })
    ],
    exports: [
        TranslatePipe
    ]
})
export class AppI18N {

}