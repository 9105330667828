import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Account } from '../interfaces/account';
import { GlobalVariablesService } from './global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class AccountServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'Account/'
  private _accounts$ = new BehaviorSubject<any[]>([])
  public accounts$ = this._accounts$.asObservable();

  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
  ) { }

  getEmpty(type: string) {
    let newAccount: any = {
      "fullName": "",
      "title": "",
      "profileImgae": null,
      "member": null,
      "email": "",
      "phone": "",
      "gender": "male",
      "address": "",
      "description": ""
    }
    switch (type) {
      case "employee":
        newAccount.type = "Employee";
        newAccount.typeData = {
          "idNumber": "",
          "startDate": Date.now(),
          "status": false,
          "directManagers": [],
          "group": {
            id: 0,
            title: "",
            type: ""
          }
        }
        return newAccount
      case "customer":
        newAccount.type = "Customer";
        newAccount.typeData = {
          "idNumber": "",
          "startDate": Date.now(),
          "status": false,
          "secondaryContacts": [],
          "group": {
            id: 0,
            title: "",
            type: ""
          }
        }
        return newAccount
      case "candidate":
        newAccount.type = "Candidate";
        newAccount.typeData = {
          "startDate": Date.now(),
          "status": false,
          "group": {
            id: 0,
            title: "",
            type: ""
          }
        }
        return newAccount
    }
    return newAccount
  }

  loadAccounts(type: string) {
    this.getAccounts(type).pipe(
      tap(accounts => {
        console.log(accounts);

        this._accounts$.next(accounts);
      })
    ).toPromise();
  }

  getAccounts(type: string, id?: number) {

    const numType: number = this.globalVariableService.AccountTypes[type];

    const body = {
      type: numType,
      id: !id ? 0 : id
    };
    return this.http.post<any[]>(this.BASE_URL + "getAccount", body)
  }

  add(account) {
    let typeData = {}
    const accountImage = account.profileImgae;
    delete account.profileImgae;
    switch (account.type) {
      case "Employee":
        typeData = {
          "objectId": account.typeData.group.id + '',
          "objectType": this.globalVariableService.ObjectTypes[account.typeData.group.type] + '',
          "idNumber": account.typeData.idNumber,
          "startDate": (account.typeData.startDate - account.typeData.startDate % 1000) / 1000 + '',
          "status": account.typeData.status.toString(),
          "directManagers": account.typeData.directManagers.map(member => member.id).join(',')
        }
        break;
      case "Customer":
        typeData = {
          "objectId": account.typeData.group.id + '',
          "objectType": this.globalVariableService.ObjectTypes[account.typeData.group.type] + '',
          "idNumber": account.typeData.idNumber,
          "startDate": (account.typeData.startDate - account.typeData.startDate % 1000) / 1000 + '',
          "status": account.typeData.status.toString(),
          "secondaryContacts": account.typeData.secondaryContacts.map(member => member.id).join(',')
        }
        break;
      case "Candidate":
        typeData = {
          "objectId": account.typeData.group.id + '',
          "objectType": this.globalVariableService.ObjectTypes[account.typeData.group.type] + '',
          "startDate": (account.typeData.startDate - account.typeData.startDate % 1000) / 1000 + '',
          "status": account.typeData.status.toString(),
        }
        break;
    }
    const memberID = account.member ? account.member.id : 0;
    delete account.member
    const newAccount: Account = {
      ...account,
      memberID,
      type: this.globalVariableService.AccountTypes[account.type],
      typeData
    }
    return this.http.post<any[] | any>(this.BASE_URL + (account.id?? 0), newAccount).pipe(
      tap(returnedAccount=>{
        let formData: FormData = new FormData();
        formData.append("accountImage",accountImage);
        this.http.post<any[]>(this.BASE_URL + "accountImage", {accountId:returnedAccount.id,accountType:returnedAccount.type} , this.globalVariableService.httpOptionsFormData).toPromise();
      })
    );
  }

  delete(type,accounts){
    return this.http.post<any[]>(this.BASE_URL + "deleteAccounts", {type:type, ids:accounts.map(account=>account.id)})
  }

  joinTypeAndId(type, id: number) {
    return 't' + type + 'id' + id
  }


  splitTypeAndId(typeAndId: string) {
    if (typeAndId.charAt(0) !== 't' || !typeAndId.includes('id')) return null
    const splitedTypeAndId = typeAndId.split('id');
    const type = splitedTypeAndId[0].slice(1);
    return {
      type: +type,
      id: +splitedTypeAndId[1]
    }
  }
}
