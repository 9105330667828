import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AccountEventServerService } from 'src/app/services/account-event-server.service';
import { AccountServerService } from 'src/app/services/account-server.service';

@Component({
  selector: 'app-portal-event-view-server',
  templateUrl: './portal-event-view-server.component.html',
  styleUrls: ['./portal-event-view-server.component.scss']
})
export class PortalEventViewServerComponent implements OnInit {

  @ViewChild('content') content!: ElementRef;

  // event = this.portalEventService.getEmpty();
  // // proccessOptions = ['', 'onboarding', 'development', 'termination'];
  // // formatOptions = this.portalEventService.getFormatList();
  // // typeOptions = ['salary raise']
  // subscription: Subscription[] = [];
  // account = null;

  // typeMap = {}
  // typeMapKeys = [];
  // currCategory = ''

  // constructor(
  //   // private portalEventService: PortalEventService,
  //   private route: ActivatedRoute,
  //   private accountService: AccountServerService,
  //   private accountEventService:AccountEventServerService,
  //   // private portalAccountService: PortalAccountsService,
  //   private modalService: NgbModal
  // ) { }

  ngOnInit(): void {
  //   this.subscription.push(
  //     this.route.paramMap.subscribe(async (params) => {
  //       const accountId = params.get('accountId');
  //       const eventId = params.get('eventId');
  //       if (accountId) {
  //         this.event.accountId = accountId;
  //         this.account = await this.portalAccountService.getById(accountId).toPromise();
  //         this.setFormats()

  //       }
  //       else if (eventId) {
  //         this.event = await this.portalEventService.getById(eventId).toPromise();
  //         this.account = await this.portalAccountService.getById(this.event.accountId).toPromise();
  //         this.setFormats()

  //       }

  //     })
  //   )
  }

  // async saveEvent() {
  //   if (this.event.id) await this.portalEventService.update(this.event).toPromise();
  //   else {
  //     this.event = await this.portalEventService.add(this.event).toPromise();
  //   }
  // }

  // saveAndContinue() {
  //   if (this.event.formatData) return;
  //   this.saveEvent();
  // }

  // changeFormat(format) {
  //   if (format) {
  //     this.event.format = format;
  //     this.event.formatData = this.portalEventService.getFormat(format)
  //   } else {
  //     this.event.format = ''
  //     this.event.formatData = null
  //   }
  // }

  // setFormats() {
  //   if (this.account.type === 'employee') this.typeMap = this.portalEventService.getEmployeeFormats();
  //   else if (this.account.type === 'costumer') this.typeMap = this.portalEventService.getCustomerFormats()
  //   else if (this.account.type === 'candidate') this.typeMap = this.portalEventService.getCandidateFormats()
  //   else if (this.account.type === 'patient') this.typeMap = this.portalEventService.getPatientFormats()
  //   this.typeMapKeys = Object.keys(this.typeMap);


  // }

  // open() {
  //   this.modalService.open(this.content, {
  //     size: 'lg'
  //   })
  // }

  // chooseType(type, cb?) {
  //   if (cb) cb();
  //   if (type) this.event.eventName = type
  //   this.event.eventType = type;
  //   switch (type) {
  //     case 'מעבר על תקנון למניעת הטרדות מיניות':
  //       this.changeFormat('policy for the Prevention of Sexual Harassment')
  //       break;
  //     case 'הצמדת חונך / מנטור':
  //       this.changeFormat('attaching a mentor')
  //       break;
  //     case 'סיבת העזבה':
  //       this.changeFormat('reason for leaving')
  //       break;
  //     case 'סיבת פיטורים':
  //       this.changeFormat('reason for dismissal')
  //       break;
  //     case 'שיחה אישית':
  //       this.changeFormat('personal conversation')
  //       break;
  //     case 'שינוי מקום':
  //       this.changeFormat('change of place')
  //       break;
  //     case 'רילוקיישן':
  //       this.changeFormat('relocation')
  //       break;
  //     case 'לימודים (שאינם במסגרת עבודה)':
  //     case 'כנס':
  //     case 'השתלמות':
  //     case 'הכשרה':
  //     case 'חופשת לידה':
  //       this.changeFormat('range date')
  //       break;
  //     case 'מחלה':
  //       this.changeFormat('disease')
  //       break;
  //     case 'משוב שוטף':
  //     case 'הערכת ביצועים':
  //       this.changeFormat('feedback')
  //       break;
  //     case 'קבלה וחתימה על ציוד':
  //       this.changeFormat('equipment list')
  //       break;

  //     default:
  //       this.changeFormat(null)
  //       break;
  //   }

  //   this.setImage(type)
  // }

  // getIconType(type) {
  //   switch (type) {
  //     case 'אישורים':
  //     case 'אישורי העסקה':
  //     case 'עמידה ביעדים':
  //     case 'תנאי סף לבדיקה':
  //     case 'ביטול הרשאות למערכות החברה':
  //     case 'הרשאות למערכות החברה':
  //     case 'אבחון אמינות':
  //       return ''
  //     case 'ידע מקדים':
  //       return ''
  //     case 'ביטול הרשאות למערכות החברה קליטה מנהל ישיר':
  //     case 'שיחת סיום':
  //     case 'החלפת תפקיד שיחת קליטה':
  //     case 'שיחת סיכום תפקיד':
  //     case 'שיחת קליטה מנהל ישיר':
  //     case 'חוות דעת ממליצים':
  //     case 'ראיון מנהל ישיר':
  //     case 'ראיון מנהל מחלקה':
  //     case 'שיחת נזיפה':
  //     case '':
  //     case 'שיחה אישית':
  //       return ''
  //     case 'יום ראשון בעבודה':
  //     case 'יום אחרון בעבודה':
  //       return ''
  //     case 'החזרת ציוד':
  //     case 'קבלה וחתימה על ציוד':
  //       return ''
  //     case 'הדרכה על מערכות החברה':
  //     case 'מעבר על תקנון למניעת הטרדות מיניות':
  //     case 'הערכת ביצועים':

  //       return ''
  //     case 'ראיון משאבי אנוש':
  //     case 'הצמדת חונך / מנטור':
  //     case 'לקוח חדש':
  //       return ''
  //     case 'סיבת העזבה':
  //     case 'סיבת פיטורים':
  //     case 'סיום שירות':
  //       return ''
  //     case 'מכתב התפטרות':
  //       return ''
  //     case 'שימוע':
  //     case 'סיכום שימוע':
  //       return ''
  //     case 'דיון פנים אירגוני':
  //     case 'כנס':
  //       return ''
  //     case 'קידום':
  //     case 'העלאת משכורת':
  //     case 'פתיחת מסגרת אשראי':
  //     case 'שינוי אחוז הנחה':
  //     case 'עדכון מחירונים':
  //     case 'עדכון מבצעים':
  //       return ''
  //     case 'מעבר מחלקה':
  //       return ''
  //     case 'רילוקיישן':
  //     case 'שינוי מקום':
  //     case 'שינוי כתובת לקוח':
  //     case 'שינוי כתובת אספקה':
  //       return ''
  //     case 'לומדה':
  //     case 'השתלמות':
  //     case 'הכשרה':
  //     case 'לימודים (שאינם במסגרת עבודה)':
  //       return ''
  //     case 'ציון לשבח':
  //       return ''
  //     case 'לידה':
  //       return ''
  //     case 'חופשת לידה':
  //     case 'מחלה':
  //       return ''

  //     default:
  //       return ''
  //   }
  // }

  // setImage(type) {
  //   switch (type) {
  //     case 'אישורים':
  //     case 'אישורי העסקה':
  //     case 'תנאי סף לבדיקה':
  //     case 'ביטול הרשאות למערכות החברה':
  //     case 'הרשאות למערכות החברה':
  //     case 'הגדרת יעדים':
  //     case 'עמידה ביעדים':
  //     case 'אבחון אמינות':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/xhjnyurmx3ktkxheici9.jpg'
  //       break
  //     case 'ידע מקדים':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/gyofzzdrwjl4pz9cwary.jpg'
  //       break
  //     case 'ביטול הרשאות למערכות החברה קליטה מנהל ישיר':
  //     case 'שיחת סיום':
  //     case 'החלפת תפקיד שיחת קליטה':
  //     case 'שיחת סיכום תפקיד':
  //     case 'שיחת קליטה מנהל ישיר':
  //     case 'שיחת נזיפה':
  //     case 'שיחה אישית':
  //     case 'חוות דעת ממליצים':
  //     case 'ראיון מנהל ישיר':
  //     case 'ראיון מנהל מחלקה':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/svvuehrrwm1cgq1cjiyr.jpg'
  //       break
  //     case 'יום ראשון בעבודה':
  //     case 'יום אחרון בעבודה':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/lkpz2dccu7rcothglyyl.jpg'
  //       break
  //     case 'החזרת ציוד':
  //     case 'קבלה וחתימה על ציוד':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/dysevhjlztuxdkwmwlce.jpg'
  //       break
  //     case 'הדרכה על מערכות החברה':
  //     case 'מעבר על תקנון למניעת הטרדות מיניות':
  //     case 'הערכת ביצועים':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/dysevhjlztuxdkwmwlce.jpg'
  //       break
  //     case 'הצמדת חונך / מנטור':
  //     case 'לקוח חדש':
  //     case 'ראיון משאבי אנוש':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/zakhqjije7hvqwx9pzou.jpg'
  //       break
  //     case 'סיבת העזבה':
  //     case 'סיבת פיטורים':
  //     case 'סיום שירות':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/ha2cygnbmzcwb35fhhyf.jpg'
  //       break
  //     case 'מכתב התפטרות':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050454/n8nwyax9la62wu2srl1g.jpg'
  //       break
  //     case 'שימוע':
  //     case 'סיכום שימוע':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/jvwzni8cq18fgjvigzko.jpg'
  //       break
  //     case 'דיון פנים אירגוני':
  //     case 'כנס':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/fas3ss4iomtnz8gwe2s6.jpg'
  //       break
  //     case 'קידום':
  //     case 'העלאת משכורת':
  //     case 'פתיחת מסגרת אשראי':
  //     case 'עדכון מחירונים':
  //     case 'עדכון מבצעים':
  //     case 'פתיחת מסגרת אשראי':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/q3ogqe4urz45d5wy8gkg.jpg'
  //       break
  //     case 'מעבר מחלקה':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/usaf8nvewnqcjgk3yqpi.jpg'
  //       break
  //     case 'רילוקיישן':
  //     case 'שינוי מקום':
  //     case 'שינוי כתובת לקוח':
  //     case 'שינוי כתובת אספקה':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/qbpfoqvxwhehbwkfpgrz.jpg'
  //       break
  //     case 'לומדה':
  //     case 'השתלמות':
  //     case 'הכשרה':
  //     case 'לימודים (שאינם במסגרת עבודה)':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/sklbsxlnr4rpr3xzkf7u.jpg'
  //       break
  //     case 'ציון לשבח':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/dm639rv8vizhbr5cp1wh.jpg'
  //       break
  //     case 'לידה':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/sbxg3rxdijwh602jewb5.jpg'
  //       break
  //     case 'חופשת לידה':
  //     case 'מחלה':
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/s4ewsoyf8q2qnmhnxcla.jpg'
  //       break

  //     default:
  //       this.event.eventImage = 'https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/hpk415zehgopqjbm8tc8.jpg'

  //   }
  // }

}
