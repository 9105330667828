<div dirControl class="mb-3">
    <h3 class="card-title text-center mt-4">{{'total'|translatePipe}} {{members.length}} {{'users'|translatePipe}}</h3>
    <!-- <a routerLink="">View user statistics...</a> -->
    <div class="">
        <div class=" card">
            <div class="card-body">
                <form class="mb-3">
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" (ngModelChange)="searchChange($event)" [ngModel]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
                <a [routerLink]="'/search-members'">{{'ManageMembers.advanced'|translatePipe}}</a>
            </div>
        </div>
    </div>
</div>


<!-- <app-manage-members-table [sets]="setsToList" (removeMember)="removeMember($event)"
(changeMemberStatus)="changeMemberStatus($event)" [members]="filterMembers()"></app-manage-members-table> -->
<app-manage-member-table-server (removeMember)="openSureModal($event)" (changeMemberStatus)="changeMemberStatus($event)" [members]="filterMembers$|async">
</app-manage-member-table-server>


<div *ngIf="getNumOfSelected()" class="mb-3 card col-6">
    <div class="card-body">
        <form class="options actions-controller">
            <!-- <label for="action">Selected: {{getNumOfSelected()}} users:</label> -->
            <!-- <div>
            <select id="action" [(ngModel)]="currAction" class="form-control form-select"
                name="searchOptionUserName">
                <option *ngFor="let option of options" [ngValue]="option">
                    {{option}}</option>
            </select>
        </div> -->
            <app-dropdown-label label="actions" [options]="options" [(value)]="currAction"></app-dropdown-label>
            <div>
                <button (click)="onSelectedMembersAction()" class="btn btn-primary">Go</button>
            </div>
        </form>
    </div>
</div>

<ng-template #messageInfo let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Send messege to choosen members</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-input-label [(value)]="messageTitle" label="title"></app-input-label>
        <app-textarea-label [(value)]="message" label="info"></app-textarea-label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="close()">
            Send
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

<div modal #deleteMember title="Delete organization group/department"
    info="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam molestiae aliquam voluptates esse totam aliquid 
nesciunt recusandae eveniet ut fugit ipsum mollitia officiis facere sed ipsam, praesentium nulla, numquam reprehenderit?" (accept)="removeMember($event)"></div>

<div modal #changeStatusMember title="Delete organization group/department"
    info="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam molestiae aliquam voluptates esse totam aliquid 
nesciunt recusandae eveniet ut fugit ipsum mollitia officiis facere sed ipsam, praesentium nulla, numquam reprehenderit?" (accept)="onChangeStatus($event)"></div>