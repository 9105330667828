import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Card } from '../interfaces/card';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  private BASE_URL = 'https://api.flexyc.com/cards';
  private _cards$ = new BehaviorSubject<Card[]>([])
  public cards$ = this._cards$.asObservable();
  public emptyCard: Card = {
    title: '',
    group: {
      id: '',
      name: '',
      type: ''
    },
    limitAmountOfTickets: {
      isCheck: false,
      limit: 0
    },
    startDate: {
      isCheck: false,
      timestamp: Date.now()
    },
    experationDate: {
      isCheck: false,
      timestamp: Date.now() + 1000 * 60 * 60 * 24 * 0
    },
    expiredAfter: {
      isCheck: false,
      amount: 0,
      unit: 'days'
    },
  }
  constructor(private http: HttpClient) { }

  public loadCards() {
    this.http.get<Card[]>(this.BASE_URL)
      .subscribe(cards => {
        this._cards$.next(cards);
      })
  }

  public getCard(id: string) {
    return this.http.get<Card>(`${this.BASE_URL}/${id}`)
  }

  public addCard(card: Card) {
    delete card.id;
    this.http.post<Card>(this.BASE_URL, card).subscribe(_ => {
      this.loadCards();
    })
  }

  public editCard(card: Card) {
    this.http.put<Card>(`${this.BASE_URL}/${card.id}`, card).subscribe(_ => {
      this.loadCards();
    })
  }
  
  public removeCard(cardId: string) {
    this.http.delete<Card>(`${this.BASE_URL}/${cardId}`).subscribe(_=>{
      this.loadCards();
    })
  }
}
