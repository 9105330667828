import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { StateGroup } from 'src/app/DemoPages/Material/FormControls/auto-complete/auto-complete.component';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-manage-member-table-server',
  templateUrl: './manage-member-table-server.component.html',
  styleUrls: ['./manage-member-table-server.component.scss']
})
export class ManageMemberTableServerComponent implements OnInit {



  dataSource = new MatTableDataSource
  setsColumns: string[] = []
  page = 1;
  pageSize = 25;
  // displayedColumns = ['account name', 'member identifier', 'options'];
  membersToShow=[];
  @Input() members: any[];
  @Output() removeMember = new EventEmitter<string>();
  @Output() changeMemberStatus = new EventEmitter<ChangeMemberStatusData>();

  constructor(
    public globalVariableService:GlobalVariablesService,
    // public accountService:AccountServerService
  ) { }

  ngOnInit(): void {
    this.setMembersToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setMembersToShow()
  }

  getClass() {
    return `checkbox-container ${this.members.find(member => member.isCheck) ? 'checked' : ''}`
  }

  setMembersToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.membersToShow = this.members?.slice(start, start + this.pageSize);
  }

  

}
