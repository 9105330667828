import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MultySelectService } from 'src/app/services/multy-select.service';

@Component({
  selector: 'app-dropdown-multy-select',
  templateUrl: './dropdown-multy-select.component.html',
  styleUrls: ['./dropdown-multy-select.component.scss']
})
export class DropdownMultySelectComponent implements OnInit {

  @Input() label: string
  subscription: Subscription[] = [];
  multySelect = [];
  options = [];
  isEdit = false
  isAddLabelValid = false;
  isLoader = false;
  isOpen = false;
  isDenineClose = false
  filterOptions = [];
  currMultySelect;

  private _search = ""
  private _values = []
  @Output() valuesChange = new EventEmitter<string[]>();
  @Input() set values(values: string[]) {
    this._values = values;
    this.valuesChange.emit(values);
    this.setIsAddLabelValid();
  }

  set search(value) {
    this._search = value
    this.setFilterOptions();
    this.setIsAddLabelValid();
  }

  get search() {
    return this._search
  }

  get values() {
    return this._values;
  }

  addValue(value) {
    this.values = [...this.values, value]
    this.setFilterOptions();
  }



  removeValue(value) {
    this.values = this.values.filter(valuesItem => valuesItem !== value);
    this.setFilterOptions();
  }


  constructor(
    private multySelectService: MultySelectService
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.multySelectService.multySelect$.subscribe(async multySelect => {
        this.multySelect = multySelect
        this.currMultySelect = multySelect.find(multySelectItem => multySelectItem.key === this.label)
        if (this.currMultySelect && multySelect) {
          this.options = this.currMultySelect?.values || []
        } else if (multySelect.length) (
          this.currMultySelect = await this.multySelectService.add(this.label).toPromise()
        )
        this.setFilterOptions()
      })
    )
    this.multySelectService.loadMultySelect();
  }

  setFilterOptions() {
    this.filterOptions = this.options.filter(option => (!this.values.some(value => value === option)) && (option.toLowerCase().includes(this.search.toLocaleLowerCase())))
  }

  setIsAddLabelValid() {
    this.isAddLabelValid = (!!this.search) && (!this.options.some(option => option === this.search))
  }

  optionClick(option) {
    if (this.isEdit) this.removeOption(option)
    else this.addValue(option)
  }

  async addOption() {
    this.isLoader = true;
    const options = [...this.options, this.search];
    const editedMultySelect = {
      ...this.currMultySelect,
      values: options
    }
    await this.multySelectService.updateMultySelectKey(editedMultySelect).toPromise();
    this.options = options
    this.currMultySelect = editedMultySelect
    this.addValue(this.search);
    this.search = '';
    this.isLoader = false
  }

  async removeOption(option) {
    this.isLoader = true;
    const options = this.options.filter(optionsItem => optionsItem !== option);
    const editedMultySelect = {
      ...this.currMultySelect,
      values: options
    }
    await this.multySelectService.updateMultySelectKey(editedMultySelect).toPromise();
    this.options = options
    this.currMultySelect = editedMultySelect
    this.search = '';
    this.isLoader = false
  }

  close() {
    if (this.isDenineClose) return
    this.isOpen = false;
  }

  denineClose() {
    this.isDenineClose = true;
    setTimeout(() => {
      this.isDenineClose = false
    }, 100);
  }



}
