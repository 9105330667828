<div class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let archiveItem">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="archiveItem.isCheck=!archiveItem.isCheck" [checked]="archiveItem.isCheck"
                            class="form-check-input" type="checkbox">
                    </div>
                </div>
                {{archiveItem.title}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let archiveItem"> {{archiveItem.type}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Options</mat-header-cell>
            <mat-cell *matCellDef="let archiveItem">
                <div class="options-container">
                    <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger">
                        <i class="pe-7s-trash"></i>
                    </button>
                    <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-success">
                        <i class="fa fa-fw" aria-hidden="true" title="add"></i>

                    </button>

                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <app-paginator [collectionSize]="archiveList.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>

</div>