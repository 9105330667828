import { E } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-organizational-structure',
  templateUrl: './organizational-structure.component.html',
  styleUrls: ['./organizational-structure.component.scss']

})
export class OrganizationalStructureComponent implements OnInit {
  @ViewChild('modal') modal!: any;

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  organizationalStructure: Group[] = [this.organizationalStructureService.emptyGroup]; // contain the list of the organization structure
  currAction = ''; // contain the action that will do when choose group
  isViewA = true; // used to toggle between view a and view b (view a = tree view view b = table view)
  askedDeltedChildId = ''; // saved the id that will deleted if the user accept delete on the modal
  subscriotion!: Subscription

  ngOnInit(): void {
    this.subscriotion = this.organizationalStructureService.organizationalStructure$.subscribe((organizationalStructure: Group[]) => {
      this.organizationalStructure = organizationalStructure; // this subscription will update the organization every change on the list
    })
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  onSetCurrAction(action: string): void { // set action
    if (action === this.currAction) this.currAction = '';
    else this.currAction = action
  }

  async onSelectGroupChild(id: string) {
    // when you clicked on group on the tree / choose group and action on the table this function will decide what to do
    switch (this.currAction) {
      case 'add': // add child to the choosen group and navigate to edit him
        const newChild = await (await this.organizationalStructureService.addGroup(id)).toPromise();
        this.router.navigate(['editor', newChild.id]);
        break;
      case 'edit': // navigate to edit choosen group
        const editGroup = await this.organizationalStructureService.getGroupById(id).toPromise()
        if (editGroup.type === 'organization') this.router.navigate(['system_settings']);
        else this.router.navigate(['editor', id]);
        break;
      case 'delete': // saved the id in property and open are you sure delete organization
        this.askedDeltedChildId = id
        this.open()
        break;
      case 'members': // navigate to group manege members
        const groupToMember = await this.organizationalStructureService.getGroupById(id).toPromise()
        if (groupToMember.type === 'organization') this.router.navigate(['manage-members']);
        else this.router.navigate(['editor', id, 'members']);
        break;
      default:
        break;
    }
  }



  toggleView() {
    this.isViewA = !this.isViewA
  }

  // onCloseYouSureDeleteModal() { 
  //   this.askedDeltedChildId = '';
  // }

  async onDeleteOrganizationalStructureChild(cb: Function) {
    // this function will run when the user accapted delete choosen delete on the modal
    // she get close modal function to close the modal after delete the group
    await (await this.organizationalStructureService.removeGroup(this.askedDeltedChildId)).toPromise();
    this.askedDeltedChildId = ''
    cb('Close click')
  }

  open() { // open modal run from selecting group with delete action
    this.modalService.open(this.modal.content).result.then((result) => {
    }, (reason) => {
    });
  }

}
