import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { Set } from 'src/app/interfaces/set';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class MemberProfileComponent implements OnInit {

  constructor(
    private memberService: MembersService,
    private route: ActivatedRoute,
    private setService: SetsService,
    private organizationalStructureService: OrganizationalStructureService,
    private router: Router,
  ) { }

  subscription: Subscription[] = [];
  currLocalSetGroupId = '';
  member: Member = this.memberService.emptyMember
  memberGroups: Group[] = []
  menuButtons = [
    {
      text: 'send chat',
      cb: () => { }
    },
    {
      text: 'share profile',
      cb: () => { }
    },
    {
      text: 'report',
      cb: () => { }
    }
  ]
  setMap = {
    global: []
  }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const memberId = params.get('id');
        if (memberId) this.member = await this.memberService.getMember(memberId).toPromise()
        this.setService.loadSets();
        this.organizationalStructureService.loadOrganizationalStructure();
      })
    )

    this.subscription.push(
      this.setService.set$.subscribe(sets => {
        sets.forEach((set: Set) => {
          if (set.group.type === 'organization') this.setMap.global.push(set);
          else if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set);
          else this.setMap[set.group.id] = [set]
        });
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
        groups.forEach((group: Group) => {
          if (group.members.some(memberId => memberId === this.member.id)) {
            if (!this.currLocalSetGroupId) this.currLocalSetGroupId = group.id
            this.memberGroups.push(group)
          }
        });
      })
    )
  }

  onChooseGroup(groupId){
    this.router.navigate(['group-view', groupId]);
  }

}
