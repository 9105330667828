import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'div [modal]',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnInit {
  @Input() title: string
  @Input() info: string
  @Output() accept = new EventEmitter<any>();
  @ViewChild('content') content!: ElementRef;



  constructor() { }

  ngOnInit(): void {
  }

}
