import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rate-picker',
  templateUrl: './rate-picker.component.html',
  styleUrls: ['./rate-picker.component.scss']
})
export class RatePickerComponent implements OnInit {

  private _value;
  hovered = 0;
  @Input() maxRate = 5
  @Input() label = ''
  @Output() valueChange = new EventEmitter<number>();
  @Input() set value(val: number) {
    this._value = val;
    this.valueChange.emit(val);
  }
  get value() {
    return this._value;
  }

  get maxRateArr() {
    return Array(this.maxRate).fill(' ');
  }

  constructor() { }

  onHover(){
    console.log('hover');
    
  }
  onLeave(){
    console.log('leave');
  }

  ngOnInit(): void {
  }

}
