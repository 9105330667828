<div class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>

        <ng-container *ngFor="let displayedColumn of displayedColumns; let idx = index"
            [matColumnDef]="displayedColumn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{displayedColumn | titleCase}}</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <div *ngIf="idx === 0" [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="item.isCheck=!item.isCheck" [checked]="item.isCheck" class="form-check-input"
                            type="checkbox">
                    </div>
                </div>
                <span>{{item[displayedColumn]}}</span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="items?.length>pageSize" class="pagination-container">
        <div class="">
            <ngb-pagination [collectionSize]="items.length" [pageSize]="pageSize" [(page)]="page"
                aria-label="Default pagination"></ngb-pagination>
        </div>
    </div>
</div>