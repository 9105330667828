import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RoleService } from 'src/app/services/role.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-role-managment',
  templateUrl: './role-managment.component.html',
  styleUrls: ['./role-managment.component.scss']
})
export class RoleManagmentComponent implements OnInit {
  @ViewChild('manageRoleMembersPop') elManageRoleMembersPop: any;
  @ViewChild('sureModal') elSureModal: any;

  constructor(
    private roleService: RoleService,
    private modal: NgbModal,
    private memberService: MembersService,
  ) { }


  subscription: Subscription[] = []
  roles = [];
  currRole = null;
  currMember = null;
  memberSearch = '';
  roleSearch = '';
  notRoleMembers = [];
  members = [];
  isSureDelete = false;



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.roles, event.previousIndex, event.currentIndex);
    this.roles.forEach((role, idx) => {
      role.idx = idx
    })
    this.roleService.updateroles(this.roles)

  }

  ngOnInit(): void {
    this.subscription.push(
      this.roleService.role$.subscribe(roles => {
        const newRoles = roles.sort((role1, role2) => role1.idx - role2.idx)
        if (roles) this.roles = newRoles
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        if (members) this.members = members;
      })
    )

    this.memberService.loadMembers();
    this.roleService.loadRoles();
  }

  deleteRole(role) {
    this.roleService.delete(role.id).toPromise();
  }

  manageRoleMembers(role) {
    this.currRole = role;
    this.updateNotRoleMembers()
    this.modal.open(this.elManageRoleMembersPop, { size: 'lg' });
  }

  updateNotRoleMembers(){
    this.notRoleMembers = this.members.filter(member => !this.currRole.members.some(roleMember => member.id === roleMember.id))
  }

  openToggleRoleMemberModal(member, isDelete) {
    this.currMember = member;
    this.isSureDelete = isDelete;
    this.modal.open(this.elSureModal, { size: 'lg' });
  }

  toggleRoleMember(cb?) {
    if (cb) cb()
    if (this.isSureDelete) this.currRole.members = this.currRole.members.filter(member => member.id !== this.currMember.id);
    else this.currRole.members = [...this.currRole.members, this.currMember];
    this.roleService.edit(this.currRole).toPromise();
    this.updateNotRoleMembers()
  }

}
