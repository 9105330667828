<div dirControl class="row mb-3">
    <div class="col-12">
        <div class="main-card card">
            <div class="card-body">
                <div class="search-container" ngbDropdown>
                    <form>
                        <input id="member" ngbDropdownToggle name="member" type="text" autocomplete="off"
                            class="form-control" [placeholder]="'member'|translatePipe" [(ngModel)]="member.identifier">
                    </form>
                    <div *ngIf="member.identifier && getMemberToSearch().length" ngbDropdownMenu>
                        <button *ngFor="let member of getMemberToSearch()" (click)="onChooseMember(member)"
                            class="dropdown-item">{{member.identifier}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h3 *ngIf="member.id" class="card-title text-center mt-4">{{member.identifier}} {{'cards'|translatePipe}}</h3>
</div>
<div dirControl class="row">
    <div *ngFor="let card of member.cards" class="col-12 col-sm-6 col-lg-4 mb-4">
        <app-card-usage-preview (useCard)="useCard($event)" [cardImg]="getCardImg(card.card.group.id)" [card]="card">
        </app-card-usage-preview>
    </div>
</div>