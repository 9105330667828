import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortalEventService {
  private BASE_URL = 'https://api.flexyc.com/portalEvents';
  private _portalEvents$ = new BehaviorSubject<any[]>([])
  public portalEvents$ = this._portalEvents$.asObservable();



  constructor(
    private http: HttpClient,
  ) { }

  public getEmpty() {
    return {
      "id": "",
      "accountId": "",
      "eventImage": "https://res.cloudinary.com/dif8yy3on/image/upload/v1651050455/hpk415zehgopqjbm8tc8.jpg",
      "proccess": "",
      "eventType": "",
      "format": "",
      "eventName": "",
      "eventParticipants": [],
      "eventDate": Date.now(),
      "eventLocation": "",
      "eventDescription": "",
      "attachFiles": [],
      "isVisivaleToAccount": false,
      "formatData": null
    }
  }

  private formats = [
    {
      name: "policy for the Prevention of Sexual Harassment",
      questions: [
        {
          property: {
            type: "check",
          },
          id: 'sad',
          title: "Policy for the Prevention of Sexual Harassment Signed",
          default: false,
          data: false,
          advancedOptions: {
            isRequired: false
          }
        }
      ]
    },
    {
      name: "equipment list",
      questions: [
        {
          property: {
            type: "tags",
          },
          id: 'sad',
          title: "equipment list",
          default: [],
          data: [],
          advancedOptions: {
            isRequired: false
          }
        }
      ]
    },
    {
      name: "attaching a mentor",
      questions: [
        {
          property: {
            type: "text",
          },
          title: "mentor name",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "reason for leaving",
      questions: [
        {
          property: {
            type: "select",
          },
          title: "Reason for leaving",
          default: 'חוסר תחושת שייכות',
          data: 'חוסר תחושת שייכות',
          options: [
            'חוסר תחושת שייכות',
            'חוסר סיפוק מקצועי',
            'שכר לא מתגמל',
            'שעות עבודה לא גמישות',
            'חוסר התאמה עם המנהל',
            'פער בין תיאור תפקיד לתפקיד בפועל',
            'הצעת עבודה מפתה בחברה אחרת',
            'תנאים פיזיים',
            'טיפול בבן משפחה',
            'הטרדה מינית',
            'הריון',
            'מיצוי',
            'הרעה בתנאי העסקה',
            'מעבר דירה',
            'סיום חוזה',
            'לימודים',
            'מצב בריאותי',
            'הסבה',
          ],
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "personal conversation",
      questions: [
        {
          property: {
            type: "text",
          },
          title: "Initiative of conversation",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "text",
          },
          title: "Purpose of conversation",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "long-text",
          },
          title: "Summary of conversation",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "change of place",
      questions: [
        {
          property: {
            type: "text",
          },
          title: "Address",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "text",
          },
          title: "Floor",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "text",
          },
          title: "Room",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "relocation",
      questions: [
        {
          property: {
            type: "text",
          },
          title: "Address",
          default: '',
          data: '',
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "range date",
      questions: [
        {
          property: {
            type: "timestamp",
          },
          title: "Start date",
          default: Date.now(),
          data: Date.now(),
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "timestamp",
          },
          title: "end date",
          default: Date.now(),
          data: Date.now(),
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "disease",
      questions: [
        {
          property: {
            type: "timestamp",
          },
          title: "Start date",
          default: Date.now(),
          data: Date.now(),
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "timestamp",
          },
          title: "end date",
          default: Date.now(),
          data: Date.now(),
          advancedOptions: {
            isRequired: false
          }
        },
        {
          property: {
            type: "select",
          },
          title: "Disease type",
          default: 'אישית',
          data: 'אישית',
          options: [
            'אישית',
            'בן משפחה',
            'טיפול',
            'היעדרות בהריון',
            'שמירת היריון',
            'חופשת לידה',
            'היריון ולידת בת זוג',
            'תאונת עבודה',
            'תאונת עבודהמגבלה שנקבעה ע"י רופא תעסוקתי',
          ],
          advancedOptions: {
            isRequired: false
          }
        },
      ]
    },
    {
      name: "feedback",
      questions: [
        {
          property: {
            type: "rate",
          },
          title: "quest 1",
          default: '1',
          data: '1',
          advancedOptions: {
            isRequired: false
          },
        },
        {
          property: {
            type: "rate",
          },
          title: "quest 2",
          default: '1',
          data: '1',
          advancedOptions: {
            isRequired: false
          },
        },
        {
          property: {
            type: "rate",
          },
          title: "quest 3",
          default: '1',
          data: '1',
          advancedOptions: {
            isRequired: false
          },
        },
        {
          property: {
            type: "rate",
          },
          title: "quest 4",
          default: '1',
          data: '1',
          advancedOptions: {
            isRequired: false
          },
        },
      ]
    },
  ]



  public getEmployeeFormats() {
    return {
      'תחילת עבודה': [
        'חוזה ותנאי העסקה',
        'הסכם סודיות',
        'טופס 101',
        'טפסי הצהרה',
        'אישורים',
        'אישורי העסקה',
        'ידע מקדים',
        'תנאי סף לבדיקה',
        'שיחת קליטה מנהל ישיר',
        'יום ראשון בעבודה',
        'קבלה וחתימה על ציוד',
        'הרשאות למערכות החברה',
        'הדרכה על מערכות החברה',
        'מעבר על תקנון למניעת הטרדות מיניות',
        'הצמדת חונך / מנטור',
      ],
      'סיום העסקה': [
        'סיבת העזבה',
        'מכתב התפטרות',
        'סיבת פיטורים',
        'שימוע',
        'דיון פנים אירגוני',
        'סיכום שימוע',
      ],
      'סיום עבודה': [
        'יום אחרון בעבודה',
        'שיחת סיום',
        'החזרת ציוד',
        'ביטול הרשאות למערכות החברה',
      ],
      'החלפת תפקיד': [
        'שיחת סיכום תפקיד',
        'החלפת תפקיד שיחת קליטה',
        'קידום',
        'מעבר מחלקה',
      ],
      // 'הערכות': [],
      'שינוי מקום': [
        'שינוי מקום',
        'רילוקיישן',
      ],
      'למידה': [
        'לומדה',
        'לימודים (שאינם במסגרת עבודה)',
        'כנס',
        'השתלמות',
        'הכשרה',

      ],
      'שכר': [
        'העלאת משכורת',
      ],
      'קשר עם עובד': [
        'משוב שוטף',
        'ציון לשבח',
        'הערכת ביצועים',
        'שיחת נזיפה',
        'שיחה אישית',
        'Retention risk recognition',
        'Credibility/Integrity assessment',
        'Intrapreneurs detection and assessment',

      ],
      'קבצים': [
        'טופס כללי',
      ],
      'אירועים אישיים': [
        'לידה',
        'חופשת לידה',
        'מחלה',
        'פטירת קרוב משפחה',
      ]
    }
  }

  public getPatientFormats() {
    return {
      'רפואי': [
        'אבחון ראשוני',
        'אבחון רופא מומחה',
        'הוצאת מרשם',
        'הפניה לבדיקה',
        ' ועדת מומחים'
      ],

    }
  }

  public getCustomerFormats() {
    return {
      'מכירות': [
        'קמפיין משותף',
        'עדכון מבצעים',
        'עדכון מחירונים',
        'ערבות אישית',
        'שינוי כתובת לקוח',
        'שינוי כתובת אספקה',
        'הזמנה חדשה',
        ' פתיחת מסגרת אשראי',
        'שינוי אחוז הנחה',
        'לקוח חדש',
      ],
      'יעדים': [
        'הגדרת יעדים',
        'עמידה ביעדים',
      ],
      'סגירה': [
        'סיום שירות',
      ]

    }
  }

  public getCandidateFormats() {
    return {
      'גיוס': [
        'קורות חיים',
        'חוות דעת ממליצים',
        'ראיון משאבי אנוש',
        'ראיון מנהל ישיר',
        'אבחון אמינות',
        'מרכז הערכה',
        'ראיון מנהל מחלקה',
        'Retention risk recognition',
        'Credibility/Integrity assessment',
        'Intrapreneurs detection and assessment',
      ],
    }
  }

  public getFormatList() {
    return this.formats.map(format => format.name)
  }

  public getFormat(formatName) {
    return this.formats.find(format => format.name === formatName);
  }

  public loadPortalEvents() {
    return this.http.get<any[]>(this.BASE_URL).pipe(
      tap(portalEvents => {
        this._portalEvents$.next(portalEvents);
      })
    )
  }

  getById(id) {
    return this.http.get<any>(`${this.BASE_URL}/${id}`)
  }

  add(event) {
    delete event.id;
    return this.http.post<any>(this.BASE_URL, event).pipe(
      tap(_ => {
        this.loadPortalEvents().toPromise()
      })
    )
  }


  update(event) {
    return this.http.put<any>(`${this.BASE_URL}/${event.id}`, event).pipe(
      tap(_ => {
        this.loadPortalEvents().toPromise()
      })
    )
  }

  delete(id) {
    return this.http.delete<any>(`${this.BASE_URL}/${id}`).pipe(
      tap(result => {
        this.loadPortalEvents().toPromise()
      })
    )
  }

}
