import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-description-and-legend-server',
  templateUrl: './description-and-legend-server.component.html',
  styleUrls: ['./description-and-legend-server.component.scss']
})
export class DescriptionAndLegendServerComponent implements OnInit {

  @Output() toggleView = new EventEmitter<null>()

  legends = [
    {
      color: '#d92550',
      description: 'organization'
    },
    {
      color: '#f7b924',
      description: 'department'
    },
    {
      color: '#3ac47d',
      description: 'groupSubGroup'
    },
  ]
  slideSituation = 'out';


  constructor() { }

  ngOnInit(): void {
  }

  toggleSlideSituation() {
    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in'
  }

}
