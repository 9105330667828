import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { Set } from 'src/app/interfaces/set';

@Component({
  selector: 'app-manage-members-table',
  templateUrl: './manage-members-table.component.html',
  styleUrls: ['./manage-members-table.component.scss']
})
export class ManageMembersTableComponent implements OnInit {
  constructor() { }

  dataSource = new MatTableDataSource
  // displayedColumns = ['identifier', 'email', 'memberSince', 'lastSeen', 'options'];
  setsColumns: string[] = []
  page = 1;
  pageSize = 25;

  @Input() members: any[];
  @Input() sets: Set[] = []
  @Output() removeMember = new EventEmitter<any>();
  @Output() freezeMember = new EventEmitter<any>();
  @Output() suspensionMember = new EventEmitter<any>();
  membersToShow = [];

  get allColumns() {
    return ['identifier', 'email', 'memberSince', 'lastSeen', ...this.setsColumns, 'options']
  }


  ngOnInit(): void {
    this.setsColumns = this.sets.map(set => set.id)
    this.setMembersToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setsColumns = this.sets.map(set => set.id)
    this.setMembersToShow()
  }

  getClass() {
    return `checkbox-container ${this.members.find(member => member.isCheck) ? 'checked' : ''}`
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  setMembersToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.membersToShow = this.members?.slice(start, start + this.pageSize);
  }
}
