import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortalAccountsService {
  private BASE_URL = 'https://api.flexyc.com/portalAcounts';
  private _portalAccounts$ = new BehaviorSubject<any[]>([])
  public portalAccounts$ = this._portalAccounts$.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  public getEmptyWithOutType() {
    return {
      "id": "",
      "type": "employee",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },

    }
  }

  public getEmptyCandidate() {
    return {
      "id": "",
      "type": "candidate",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "hiringDate": Date.now(),
        "candidateStatus": true,
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }
  public getEmptyEmployee() {
    return {
      "id": "",
      "type": "employee",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "idNumber": "",
        "hiringDate": Date.now(),
        "employeeStatus": true,
        "directManagers": [],
        "partOfTeams": [],
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }
  public getEmptyCostumer() {
    return {
      "id": "",
      "type": "customer",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "idNumber": "",
        "startDate": Date.now(),
        "companyStatus": true,
        "seconderyContact": [],
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }
  public getEmptypatient() {
    return {
      "id": "",
      "type": "patient",
      "events": [],
      "data": {
        "fullName": "",
        "title": "",
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"
      },
      "contactInfo": {
        "member": {
          "id": "",
          "identifier": ""
        },
        "email": "",
        "phone": "",
        "gender": "male",
        "address": ""
      },
      "typeData": {
        "idNumber": "",
        "diagnosis": "",
        "status": 'KYP',
        "object": {
          "id": '',
          "title": ''
        }
      }
    }
  }

  public loadPortalAccounts() {
    this.http.get<any[]>(this.BASE_URL).pipe(
      tap(portalAccounts => {
        this._portalAccounts$.next(portalAccounts);
      })
    ).toPromise()
  }

  getById(id) {
    return this.http.get<any>(`${this.BASE_URL}/${id}`)
  }

  add(account) {
    delete account.id;
    return this.http.post<any>(this.BASE_URL, account).pipe(
    )
  }

  update(account) {
    return this.http.put<any>(`${this.BASE_URL}/${account.id}`, account).pipe(
    )
  }

  delete(id: string) {
    return this.http.delete<any>(`${this.BASE_URL}/${id}`).pipe(
      tap(result => {
        this.loadPortalAccounts();
      })
    )
  }
}
