<div *ngIf="false" class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="identifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Identifier</mat-header-cell>
            <mat-cell [ngClass]="member.status" class="asd" *matCellDef="let member">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="member.isCheck=!member.isCheck" [checked]="member.isCheck"
                            class="form-check-input" type="checkbox">
                    </div>
                </div>
                {{member.identifier}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="memberSince">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Member Since?</mat-header-cell>
            <mat-cell [ngClass]="member.status" *matCellDef="let member"> {{formatDate(member.memberSince)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastSeen">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Last seen</mat-header-cell>
            <mat-cell [ngClass]="member.status" *matCellDef="let member"> {{formatDate(member.lastSeen)}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
            <mat-cell [ngClass]="member.status" *matCellDef="let member">
                {{member.email}}
            </mat-cell>
        </ng-container>
        <ng-container *ngFor="let set of sets">

            <ng-container [matColumnDef]="set.id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{set.title | titleCase}}</mat-header-cell>
                <mat-cell *matCellDef="let member">
                    <!-- {{member.email}} -->
                    {{member.setMap[set.id]}}
                </mat-cell>
            </ng-container>

        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Options</mat-header-cell>
            <mat-cell [ngClass]="member.status" *matCellDef="let member">
                <div class="options-container">
                    <a [routerLink]="'/member-view/'+member.id"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i class="pe-7s-user btn-icon-wrapper">
                        </i></a>
                    <button (click)="changeMemberStatus.emit({id:member.id,status:'suspension'})"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-focus"><i class="pe-7s-tools btn-icon-wrapper">
                        </i></button>
                    <button (click)="changeMemberStatus.emit({id:member.id,status:'freeze'})"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-secondary"><i
                            class="pe-7s-mail btn-icon-wrapper">
                        </i></button>
                    <button (click)="removeMember.emit(member.id)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-delete-user btn-icon-wrapper"> </i></button>

                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="allColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: allColumns;"></mat-row>
    </mat-table>
    <app-paginator [collectionSize]="members.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>

<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="membersToShow" [dataSource]="members"
        [dataItemKeys]="['identifier','email']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member since'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.memberSince | formatDate}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'lastSeen'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.lastSeen | formatDate}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/member-view/'+dataItem?.id"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i
                                class="pe-7s-user btn-icon-wrapper">
                            </i></a>
                        <a [href]="'mailTo:'+dataItem.email"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-secondary"><i
                                class="pe-7s-mail btn-icon-wrapper">
                            </i></a>
                        <!-- <button (click)="suspensionMember.emit(dataItem)"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning"><i
                                class="pe-7s-tools btn-icon-wrapper"> </i></button> -->
                        <!-- <button (click)="freezeMember.emit(member)"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning"><i class="pe-7s-lock btn-icon-wrapper">
                            </i></button> -->
                        <button (click)="removeMember.emit(dataItem)"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                                class="pe-7s-delete-user btn-icon-wrapper"> </i></button>

                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="members.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>