import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-log-resaults',
  templateUrl: './log-resaults.component.html',
  styleUrls: ['./log-resaults.component.scss']
})
export class LogResaultsComponent implements OnInit {

  subscription: Subscription[] = []
  log: any[] = [];
  search = '';

  constructor(
    private globalVariableService: GlobalVariablesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (!this.globalVariableService.globalSearchResults) this.router.navigate(['log-search']);
    else this.log = this.globalVariableService.logResaults
  }

  filterLog() {
    return this.log.filter(singleLog => (
      singleLog.appliedBy.identifier.toLowerCase().includes(this.search.toLowerCase()) ||
      singleLog.appliedOn.title.toLowerCase().includes(this.search.toLowerCase()) ||
      singleLog.logType.toLowerCase().includes(this.search.toLowerCase()) ||
      singleLog.description.toLowerCase().includes(this.search.toLowerCase())
    ))
  }


}

