import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-textarea-label',
  templateUrl: './textarea-label.component.html',
  styleUrls: ['./textarea-label.component.scss']
})
export class TextareaLabelComponent implements OnInit {

  @Input() label: string
  @Input() minRow?: number = 3;
  @Input() name: string
  @Input() set value(val: string) {
    this._value = val;
    this.valueChange.emit(val);
  }
  @Output() valueChange = new EventEmitter<string>();
  isFocus = false
  get value() {
    return this._value;
  }
  private _value;

  constructor() { }

  ngOnInit(): void {
  }

}
