import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-event-attendance',
  templateUrl: './event-attendance.component.html',
  styleUrls: ['./event-attendance.component.scss']
})
export class EventAttendanceComponent implements OnInit {

  @ViewChild('content') content!: ElementRef;

  constructor(
    private eventService: EventsService,
    private route: ActivatedRoute,
    private memberService: MembersService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          this.event = await this.eventService.getEvent(id).toPromise()
          this.event.invites.members.forEach(member => {
            this.inviteMap[member.status].listMember.push({
              type: 'member',
              id: member.id,
              identifier: member.identifier
            })
          })
          this.event.invites.guests.forEach(guest => {
            this.inviteMap[guest.status].listGuest.push({
              type: 'guest',
              email: guest.email,
            })
          })
        }
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members.map(member => ({
          identifier: member.identifier,
          id: member.id,
        }));
      })
    )

    this.memberService.loadMembers();
  }

  public LIMIT = 12;
  public LIMIT_GUEST = 50;
  private _searchOptions: any[] = [];
  get searchOptions() {
    this.getMemberToSearch()
    return this._searchOptions
  }
  searchMember = ''
  members: any[] = [];
  subscription: Subscription[] = [];
  event = this.eventService.emptyEvent;
  inviteMap = {
    accepted: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
    "not sure": {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
    declined: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
    pending: {
      isShowAll: false,
      listGuest: [],
      listMember: []
    },
  }
  inviteMapKeys = Object.keys(this.inviteMap);
  currAttendance = 'accepted'


  getMemberToSearch() {
    this._searchOptions.splice(0, this._searchOptions.length)
    if (this.searchMember) {
      let members = this.members.filter(member => !this.event.invites.members.some(listMember => listMember.id === member.id) && member.identifier.toLowerCase().includes(this.searchMember))
      members.forEach(member => {
        this._searchOptions.push(member)
      })
    }
  }

  toggleIsShowAll(key){
    this.inviteMap[key].isShowAll=!this.inviteMap[key].isShowAll
  }

  onChooseUser(member) {
    this.searchMember = member.identifier
  }

  async onSend() {
    await this.eventService.addInvitesMemberToEvent(this.searchMember, this.event.id);
    this.event = await this.eventService.getEvent(this.event.id).toPromise()
    this.searchMember = '';
  }

  async removeMember(member) {
    await this.eventService.removeInvitesMemberToEvent(member, this.event.id)
    this.event = await this.eventService.getEvent(this.event.id).toPromise()

    if (member.type === 'guest') {
      this.inviteMap.accepted.listGuest = this.inviteMap.accepted.listGuest.filter(guest => guest.email !== member.email)
      this.inviteMap.declined.listGuest = this.inviteMap.declined.listGuest.filter(guest => guest.email !== member.email)
      this.inviteMap["not sure"].listGuest = this.inviteMap["not sure"].listGuest.filter(guest => guest.email !== member.email)
      this.inviteMap.pending.listGuest = this.inviteMap.pending.listGuest.filter(guest => guest.email !== member.email)
    } else {
      this.inviteMap.accepted.listMember = this.inviteMap.accepted.listMember.filter(inviteMember => inviteMember.id !== member.id)
      this.inviteMap.declined.listMember = this.inviteMap.declined.listMember.filter(inviteMember => inviteMember.id !== member.id)
      this.inviteMap["not sure"].listMember = this.inviteMap["not sure"].listMember.filter(inviteMember => inviteMember.id !== member.id)
      this.inviteMap.pending.listMember = this.inviteMap.pending.listMember.filter(inviteMember => inviteMember.id !== member.id)
    }
  }

  isMoreThatLimit(attendance) {
    let num = attendance.listGuest.length > this.LIMIT_GUEST ? 1 : attendance.listGuest.length
    num += attendance.listMember.length
    return num > this.LIMIT
  }

  open(attendance) {
    this.currAttendance = attendance;
    this.modalService.open(this.content, {size:'sm'});
  }

}
