import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-box-label',
  templateUrl: './check-box-label.component.html',
  styleUrls: ['./check-box-label.component.scss']
})
export class CheckBoxLabelComponent implements OnInit , AfterViewInit {

  @Input() label: string
  @Input() name: string
  @Input() color?: string = '#42a5f5'
  @Input() set isCheck(val: boolean) {
    console.log(val);
    
    this._isCheck = val;
    this.isCheckChange.emit(val);
  }
  @Output() isCheckChange = new EventEmitter<boolean>();

  get isCheck() {
    return this._isCheck;
  }
  private _isCheck;

  elCheckbox = null;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.elCheckbox = this.elementRef.nativeElement.querySelector('.custome-checkbox');
    // this.elCheckbox.
    // console.log(
    //   this.elCheckbox.style

    // );
    
    
  }

}
