import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariablesService } from './global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'Department';

  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
  ) { }

  getById(id: string | number) {
    return this.http.get<any>(this.BASE_URL + '/' + id);
  }

  update(updateDepartmentData: { title: string, description: string }, departmentId: string | number) {
    return this.http.put(this.BASE_URL + '/' + departmentId, updateDepartmentData)
  }

  add(addDepartmentData: { title: string, description: string }) {
    return this.http.post(this.BASE_URL, addDepartmentData);
  }

  remove(id: string | number) {
    return this.http.delete(this.BASE_URL + '/' + id)
  }
}
