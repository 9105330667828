<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="triggersToShow" [dataSource]="triggers" [dataItemKeys]="['title']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'timer'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of triggersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        <!-- <span>{{getTriggerTimerString(dataItem)}}</span> -->
                        <span>
                            {{dataItem.timer|translatePipe}} {{'Trigger.at'|translatePipe}}{{dataItem.timer==='once a week'?(
                                (dataItem.timeInWeek.day|translatePipe)+' '+('Trigger.in'|translatePipe)+getTimeString(dataItem.timeInWeek.time)):(
                                    dataItem.timer==='once a month'? dataItem.timeInMonth.day+('Trigger.th of the month in'|translatePipe)+getTimeString(dataItem.timeInMonth.time):(
                                    getTimeString(dataItem.timeInDay.time)))
                        }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'type'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of triggersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.type |translatePipe}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of triggersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/trigger-view/'+dataItem?.id"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary">
                            <i class="fa fa-fw"></i>
                        </a>
                        <button (click)="removeTrigger.emit(dataItem)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger">
                        <i class="fa fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="triggers.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>
