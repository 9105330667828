<div class="main-card card mb-3">
    <button (click)="onRemoveCard.emit(card.id)" class="close-btn">
        <i class="pe-7s-close"></i>
    </button>
    <div class="card-body">
        <div class="img-container mb-3">
            <img class="card-img" [src]="imgUrl" alt="">
        </div>
        <h3 class="card-title text-center">{{card.title}}</h3>
        <div class="divider"></div>
        <h3 class="card-title">{{'properties'|translatePipe}}</h3>

        <div *ngIf="card.limitAmountOfTickets.isCheck" class="">
            <p>{{'CardTemplatePreview.limited'|translatePipe}} {{card.limitAmountOfTickets.limit}}</p>
        </div>

        <div *ngIf="card.startDate.isCheck" class="">
            <p>{{'CardTemplatePreview.startAt'|translatePipe}} {{card.startDate.timestamp | formatDate}}</p>
        </div>

        <div *ngIf="card.experationDate.isCheck" class="">
            <p>{{'CardTemplatePreview.experationAt'|translatePipe}} {{card.experationDate.timestamp | formatDate}}</p>
        </div>

        <div *ngIf="card.expiredAfter.isCheck" class="">
            <p>{{'CardTemplatePreview.experationAfter'|translatePipe}} {{card.expiredAfter.amount}} {{card.expiredAfter.unit|translatePipe}}</p>
        </div>
        <div class="btns-container">
            <button (click)="onEditCard.emit(card.id)" class="btn icon-btn btn-primary">
                <i class="fa fa-fw" aria-hidden="true" title="edit"></i>
                <span>{{'edit'|translatePipe}}</span>
            </button>
            <a class="btn icon-btn btn-success" [routerLink]="'/card-give/'+card.id" >
                <i class="fa fa-fw" aria-hidden="true" title="add"></i>
                <span>{{'give'|translatePipe}}</span>
            </a>
            <a [routerLink]="'card/'+card.id" class="btn icon-btn btn-info"  >
                <i class="fa fa-fw" aria-hidden="true" title="eye"></i>
                <span>{{'cards'|translatePipe}}</span>
            </a>
        </div>
    </div>
</div>