<div class="row-container">
    <div *ngIf="group?.children.length;else noChild" class="flex ">
        <div *ngFor="let num of arrayOfSpace" class="space"></div>
        <button class="toggle-btn flex align-center" (click)="toggleSlideSituation()">
            <mat-icon *ngIf="slideSituation==='out'">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="slideSituation==='in'">arrow_right</mat-icon>
        </button>
        <div class="group-container" (click)="onChooseGroup.emit(group.id)" >
            <app-group-object-view [group]="group"></app-group-object-view>
        </div>
    </div>
    <ng-template #noChild>
        <div class="flex no-child">
            <div *ngFor="let num of arrayOfSpace" class="space"></div>
            <div class="group-container" (click)="onChooseGroup.emit(group.id)" >
                <app-group-object-view [group]="group"></app-group-object-view>
            </div>
        </div>
    </ng-template>
</div>

<div [@slide-vertical]="slideSituation" *ngFor="let child of children">
    <app-button-box-tree (onChooseGroup)="onChooseGroup.emit($event)"  [group]="child" [numOfSpace]="numOfSpace+1"></app-button-box-tree>
</div>