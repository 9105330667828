import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-card-memngment-table-table',
  templateUrl: './card-memngment-table-table.component.html',
  styleUrls: ['./card-memngment-table-table.component.scss']
})
export class CardMemngmentTableTableComponent implements OnInit {
  @Input() cards: any[];


  constructor(
    private memberService: MembersService
  ) { }

  dataSource = new MatTableDataSource
  displayedColumns = ['member', 'title', 'structure'];
  private _page = 1;
  pageSize = 3;
  cardsToShow = [];

  get page() {
    return this._page
  }

  set page(value) {
    this._page = value;
    this.setCardsToShow()
  }


  ngOnInit(): void {
    this.setCardsToShow()
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.setCardsToShow()
  }

  getClass() {
    return `checkbox-container ${this.cards.find(card => card.isCheck) ? 'checked' : ''}`
  }

  setCardsToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.cardsToShow = this.cards?.slice(start, start + 3);
  }

  getLimits(card) {
    let limits: string[] = []
    if (card.limitAmountOfTickets?.isCheck) limits.push(`${card.limitAmountOfTickets.limit} tickets`);
    if (card.startDate?.isCheck) limits.push(`start at: ${this.formatDate(card.startDate.timestamp)}`);
    if (card.experationDate?.isCheck) limits.push(`expired at: ${this.formatDate(card.experationDate.timestamp)}`);
    if (card.expiredAfter?.isCheck) limits.push(`${card.expiredAfter.amount} ${card.expiredAfter.unit}`);
    return limits.join('/ ');
  }

  formatDate(timestamp) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  usedCard(memberId, cardId) {
    this.memberService.useCardOfMember(memberId, cardId);
  }

  removeCardOfMember(memberId, cardId) {
    this.memberService.removeCardOfMember(memberId, cardId);
  }
}
