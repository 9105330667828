import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationResult } from '../interfaces/validation-result';




@Injectable({
    providedIn: 'root'
})

export class AppSyncValidators {

    public startsWithNumber = (control: AbstractControl): ValidationResult => {
        if (control.value != "" && !isNaN(control.value.charAt(0))) {
            return { "startsWithNumber": true };
        }
        return null;
    }

    public password = (control: AbstractControl): ValidationResult => {
        const passwordCharArr = control.value.split('');
        const capitalLettersArr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        const lowerLettersArr = 'abcdefghijklmnopqrstuvwxyz'.split('');
        const digits = '0123456789'.split('');
        if (
            passwordCharArr.length < 8 ||
            !passwordCharArr.some(char => lowerLettersArr.some(lowerLetter => lowerLetter === char)) ||
            !passwordCharArr.some(char => capitalLettersArr.some(capitalLetter => capitalLetter === char)) ||
            !passwordCharArr.some(char => digits.some(digit => digit === char))
        ) return { "password": true };
        return null;
    }

    public youtubeUrl = (control: AbstractControl): ValidationResult => {
        var url = control.value;
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                return null;
            }
        }
        return { "youtubeUrl": true };
    }

    public url = (control: AbstractControl): ValidationResult => {
        var url = control.value;
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (!!pattern.test(url)) return null
        return { "url": true };
    }

    validator = {
        usernameTaken: this.startsWithNumber,
        youtubeUrl: this.youtubeUrl,
        password: this.password,
        url: this.url
    }
}