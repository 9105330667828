<div [ngClass]="{
'closed-sidebar' : globals.toggleSidebar,
'closed-sidebar-md' : globals.toggleSidebarMobile,
'settings-open' : globals.toggleThemeOptions,
'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
'header-menu-open' : globals.toggleHeaderMobile,
'drawer-open' : globals.toggleDrawer,
'fixed-footer' : globals.toggleFixedFooter
}" [class]="'app-container app-theme-white'">
<!-- <app-header></app-header> -->
<app-header-b2c></app-header-b2c>
<!-- <app-header-b2c></app-header-b2c> -->
<app-sidebar-b2c></app-sidebar-b2c>
<div [ngClass]="{rtl:translateService.locale==='he'}" class="app-main__outer">
  <div class="app-main__inner">
    <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
  <!-- <app-footer></app-footer> -->
  <ng-container *ngIf="isShowSupport">
    <app-support-b2c ></app-support-b2c>
  </ng-container>
  </div>
  <!-- <app-options-drawer></app-options-drawer> -->
  <!-- <app-drawer></app-drawer> -->
  <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
<ngx-loading-bar [color]="'#3f6ad8'"></ngx-loading-bar>