import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Group } from 'src/app/interfaces/group';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-global-search-resaults',
  templateUrl: './global-search-resaults.component.html',
  styleUrls: ['./global-search-resaults.component.scss']
})
export class GlobalSearchResaultsComponent implements OnInit {

  results = {};
  currKey = "";
  groups: Group[] = []
  isOptionsOpen=false;

  constructor(
    private globalVariableService: GlobalVariablesService,
    private router: Router,
    private organizationalStructureService: OrganizationalStructureService
  ) { }

  ngOnInit(): void {
    if (!this.globalVariableService.globalSearchResults) this.router.navigate(['global-search']);
    else {
      this.results = this.globalVariableService.globalSearchResults
      this.currKey = Object.keys(this.results)[0]
      this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
        this.groups = groups
      })
      this.organizationalStructureService.loadOrganizationalStructure();
    }
  }

  getCardImg(groupId) {
    return this.groups.find(group => group.id === groupId)?.logoUrl
  }


  get resultsKeys() {
    return Object.keys(this.results)
  }

  onChooseKey(key) {
    this.currKey = key;
  }

  navigate(id) {
    switch (this.currKey) {
      case 'members':
        this.router.navigate(['member-view', id]);
        break;
      case 'objects':
        this.router.navigate(['editor', id]);
        break;
      case 'cards':
        this.router.navigate(['card-give/edit', id]);
        break;

      default:
        break;
    }
  }

}
