import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullScreenImgService {

  private _img$ = new BehaviorSubject<string>('')
  public img$ = this._img$.asObservable();

  onImageClick(src) {
    this._img$.next(src);
  }

  onCloseImage(){
    this._img$.next('')
  }

  constructor() { }
}
