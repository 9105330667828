import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-log-search',
  templateUrl: './log-search.component.html',
  styleUrls: ['./log-search.component.scss']
})
export class LogSearchComponent implements OnInit {

  subscription: Subscription[] = [];
  isSavedFilterMobileOpen = false;
  textOptions = ['same', 'contain', 'end', 'start'];
  currGroup: any;
  filterName = '';
  groupsOptions: any[] = [];
  choosenFilter!: any;
  fromTimeStamp = Date.now() - 1000 * 60 * 60 * 24 * 7;
  toTimeStamp = Date.now();
  value = {
    text: '',
    option: 'contain'
  }
  addedFiltersChecker = {
    time: false,
    group: false,
  }
  searchFrom = [
    {
      name: 'security',
      isCheck: false
    },
    {
      name: 'members',
      isCheck: true
    },
    {
      name: 'sets',
      isCheck: false
    },
    {
      name: 'events',
      isCheck: false
    },
    {
      name: 'cards',
      isCheck: false
    },
    {
      name: 'connections',
      isCheck: false
    },
    {
      name: 'objects',
      isCheck: false
    },
  ]

  set savedFilter(val: any[]) {
    const stringsArray = val.map(obj => JSON.stringify(obj))
    localStorage.setItem('_saved_filter_log', stringsArray.join('/'));
  }

  get savedFilter(): any[] {
    const localStorageString = localStorage.getItem('_saved_filter_log');
    if (!localStorageString) return [];
    const stringsArray = localStorageString.split('/')
    return stringsArray?.map(string => JSON.parse(string)) ?? []
  }

  constructor(
    private modalService: NgbModal,
    public organizationalStructureService: OrganizationalStructureService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.groupsOptions = organizationalStructure.map(child => ({
          id: child.id,
          type: child.type,
          name: child.name
        }))
        this.groupsOptions.sort((optionA, optionB) => {
          if (optionA.type > optionB.type) return 1
          if (optionA.type < optionB.type) return -1
          else return 0
        })

        this.currGroup = this.groupsOptions[0]
      })
    )
    this.organizationalStructureService.loadOrganizationalStructure()
  }


  open(content) {
    this.modalService.open(content).result.then((result) => {
    }, (reason) => {
    });
  }

  onChooseFilter(filter) {
    this.choosenFilter = filter;
  }

  disableSaveFilterButton() {
    return !this.filterName || this.savedFilter.some(filter => filter.name === this.filterName)
  }

  addNewFilter(cb: any) {
    if (!this.savedFilter.find(filter => filter.name === this.filterName)) {
      const newFilter = {
        name: this.filterName,
        data: {
          value: this.value,
          searchFrom: this.searchFrom,
          addedFiltersChecker: this.addedFiltersChecker,
          currGroup: this.currGroup,
          fromTimeStamp: this.fromTimeStamp,
          toTimeStamp: this.toTimeStamp,
        }
      }
      this.savedFilter = [...this.savedFilter, newFilter]
      cb('Close click')
    }
    this.filterName = ''
  }

  onRenameFilter(cb) {
    this.savedFilter = this.savedFilter.map(filter => {
      if (filter.name !== this.choosenFilter.name) return filter;
      const newFilter = {
        ...filter,
        name: this.filterName
      }
      this.choosenFilter = newFilter;
      return newFilter
    })
    cb('Close click')
    this.filterName = '';
  }

  onDeleteFilter() {
    if (!this.choosenFilter) return;
    this.savedFilter = this.savedFilter.filter(filter => filter.name !== this.choosenFilter.name)
  }

  onLoadFilter() {
    const { value, searchFrom, currGroup, addedFiltersChecker, fromTimeStamp, toTimeStamp } = this.choosenFilter.data
    this.value = value
    this.searchFrom = searchFrom
    this.addedFiltersChecker = addedFiltersChecker
    this.fromTimeStamp = fromTimeStamp
    this.toTimeStamp = toTimeStamp
    this.currGroup = this.groupsOptions.find(group => group.id === currGroup.id)
  }

  clearSearch() {
    this.fromTimeStamp = Date.now() - 1000 * 60 * 60 * 24 * 7;
    this.toTimeStamp = Date.now();
    this.value = {
      option: 'contain',
      text: ''
    }
    this.searchFrom = this.searchFrom.map(option => ({ ...option, isCheck: false }))
    Object.keys(this.addedFiltersChecker).forEach(key => {
      this.addedFiltersChecker[key] = false;
    })

    this.currGroup = ''
  }

  onSearch(ev) {
    ev.preventDefault()
    this.router.navigate(['log-resaults']);
  }


}
