import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { MemberServer } from 'src/app/interfaces/member-server';
import { GroupServerService } from 'src/app/services/group-server.service';
import { MemberServerService } from 'src/app/services/member-server.service';
import { UtilService } from 'src/app/services/util.service';
import { AppAsyncValidators } from 'src/app/validators/async-validators';

@Component({
  selector: 'app-member-view-server',
  templateUrl: './member-view-server.component.html',
  styleUrls: ['./member-view-server.component.scss']
})
export class MemberViewServerComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal!: any;
  @ViewChild('userName') userName!: any;
  @ViewChild('email') email!: any;

  originalIdentifier = '';
  subscription: Subscription[] = []
  localSets = []
  iterableDiffer: any
  currLocalSetGroupId = ''
  member: MemberServer = this.memberService.getEmpty();
  tempPassword = ''
  memberGroups: any[] = []
  groups: any[] = []
  // addToOptions: any[] = [];
  // originalSetsMap = {}
  // globalProfileImgSet: Set
  // setMap = {
  //   global: [],
  // }


  // choosenOption;
  get isSubmitDisable() {
    const isUserNameValid = this.userName?.formControler?.valid
    const isEmailValid = this.email?.formControler?.valid
    return !(
      isEmailValid &&
      (isUserNameValid || (this.member.identifier && this.member.identifier == this.originalIdentifier)) &&
      (this.member.id || this.tempPassword)
    )
  }

  // updateLocalSets() {
  //   this.localSets.splice(0, this.localSets.length);
  //   Object.keys(this.setMap).forEach(key => {
  //     if (key !== 'global' && this.memberGroups.some(group => group.id === this.setMap[key][0].group.id)) {
  //       if (!this.localSets.length) this.currLocalSetGroupId = this.setMap[key][0].group.id
  //       this.localSets.push({ title: this.setMap[key][0].group.name, id: this.setMap[key][0].group.id, list: this.setMap[key] })
  //     }
  //   })

  //   const globalMemberSetIds = this.setMap.global.map(set => set.id);
  //   let localMemberSetIds = [];
  //   this.localSets.forEach(group => {
  //     group.list.forEach(set => localMemberSetIds.push(set.id))
  //   })

  //   const allMemberSetIds = [...globalMemberSetIds, ...localMemberSetIds]
  //   Object.keys(this.member.setMap).forEach(setId => {
  //     if (!allMemberSetIds.some(id => id === setId)) delete this.member.setMap[setId]
  //   })
  // }

  // get currOption() {
  //   return 'choose Group/Department/Organization';
  // }

  // set currOption(value) {
  //   this.onChooseOption(value);
  // }

  constructor(
    public memberService: MemberServerService,
    public groupService: GroupServerService,
    // public organizationalStructureService: OrganizationalStructureService,
    // public setService: SetsService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private modalService: NgbModal,
    private router: Router,
    public asyncValidators: AppAsyncValidators,

    // iterableDiffers: IterableDiffers
  ) {
    // this.iterableDiffer = iterableDiffers.find([]).create(null);
  }


  // ngDoCheck(): void {
  //   let changes = this.iterableDiffer.diff(this.memberGroups);
  //   if (changes) {
  //     this.updateSets()
  //   }
  // }

  async ngOnInit(): Promise<void> {

    const groups = await this.groupService.get().toPromise()
    this.groups = groups.map(group => ({ ...group, string: group.title }))

    this.subscription.push(
      this.route.paramMap.subscribe(async params => {
        const memberId = params.get('id');
        if (memberId) {
          this.member = await this.memberService.getById(memberId).toPromise();
          this.originalIdentifier = this.member.identifier;
          this.memberGroups = await this.memberService.getGroups(memberId).toPromise();
          if (this.groups.length) {
            this.memberGroups = this.memberGroups.map(memberGroup => this.groups.find(group => group.id === memberGroup.id))
          }
        }
      })
    )

    // this.subscription.push(
    //   this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
    //     this.addToOptions = organizationalStructure.map(child => ({
    //       id: child.id,
    //       type: child.type,
    //       name: child.name,
    //       string: `${child.type}: ${child.name}`
    //     }))
    //     this.sortAddToOptions();
    //     const fullMemberGroups = organizationalStructure.filter(child => child.members.some(memberId => memberId === this.member.id));
    //     this.memberGroups = this.addToOptions.filter(option => fullMemberGroups.some(group => group.id === option.id))
    //   })
    // )

    // this.subscription.push(
    //   this.setService.set$.subscribe(sets => {
    //     this.setMap = {
    //       global: [],
    //     }
    //     sets.forEach(set => {
    //       if (set.group.type === 'organization') {
    //         if (set.property.type === 'url') this.globalProfileImgSet = set;
    //         this.setMap.global.push(set);
    //         if (!this.member.setMap[set.id]) this.member.setMap[set.id] = set.default
    //       } else {
    //         if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set)
    //         else this.setMap[set.group.id] = [set]
    //       }
    //     })
    //     this.updateSets()
    //   })
    // )

    // this.organizationalStructureService.loadOrganizationalStructure()
    // this.memberService.loadMembers();
    // this.setService.loadSets();
  }

  ngOnDestroy(): void {

    this.subscription.forEach(sub => sub.unsubscribe())

    // if (this.member.id) {
    //   const savedMember = this.memberService.getMembers().find(member => member.id === this.member.id);
    //   if (this.member.identifier !== savedMember.identifier ||
    //     this.member.email !== savedMember.email) {
    //     const isWantSave = confirm('You forgot to save the change, want to save them?');
    //     if (isWantSave) this.saveUser();
    //   }
    // } else {
    //   if (this.member.email || this.member.identifier) {
    //     const isWantSave = confirm('You forgot to save the change, want to save them?');
    //     if (isWantSave) {
    //       console.log('here');
    //       this.navigateHere()
    //     }
    //   }
    // }
  }

  navigateHere() {
    // const router = this.router
    // function nav() {
    //   router.navigate(['member-view'])
    // }
    // setTimeout(nav, 500);
  }

  async saveUser() {
    if (this.member.id) {
      this.memberService.updateMember({ identifier: this.member.identifier, email: this.member.email, tempPassword: this.tempPassword }, this.member.id).toPromise()
    } else {
      this.member = await this.memberService.addMember({ email: this.member.email, identifier: this.member.identifier, tempPassword: this.tempPassword }).toPromise()
      // this.member = this.memberService.emptyMember;
    }
    this.memberService.updateGroups(this.member.id, this.memberGroups.map(group => group.id)).toPromise();
  }

  generateTempPassword() {
    this.tempPassword = this.utilService.generateTempPassword()
  }

  onChooseOption(option) {
    // this.addToOptions = this.addToOptions.filter(addToOption => addToOption.id !== option.id);
    // this.memberGroups = [...this.memberGroups, option];
  }

  // onRemoveOption(option) {
  //   const organizationStructure = this.organizationalStructureService.getOrganizationalStructure();
  //   const parentId = organizationStructure.find(group => group.id === option.id).parent;
  //   console.log(organizationStructure.find(group => group.id === parentId));

  //   console.log(parentId);

  //   console.log(organizationStructure.find(group => group.id === parentId));

  //   // if (organizationStructure.find(group => group.id === (organizationStructure.find(group => group.id === option.id).parent)).type !== 'department') {
  //   //   //open popup
  //   //   // this.choosenOption = option
  //   //   // this.open();
  //   // } else {
  //   //   this.memberGroups = this.memberGroups.filter(group => group.id !== option.id);
  //   //   this.addToOptions = [...this.addToOptions, option];
  //   // }
  // }

  sortAddToOptions() {
    // this.addToOptions = this.addToOptions.filter(option => !this.memberGroups.some(group => group.id === option.id))
    // this.addToOptions.sort((optionA, optionB) => {
    //   if (optionA.type > optionB.type) return 1
    //   if (optionA.type < optionB.type) return -1
    //   else return 0
    // })
  }

  // onPopupDelete(cb: Function) {
  //   this.memberGroups = this.memberGroups.filter(group => group.id !== this.choosenOption.id);
  //   this.addToOptions = [...this.addToOptions, this.choosenOption];
  //   cb('Close click')
  // }

  open() {
    this.modalService.open(this.modal).result.then((result) => {
    }, (reason) => {
    });
  }

  async updateSets() {
    // if (this.globalProfileImgSet) {
    //   if (this.originalSetsMap[this.globalProfileImgSet.id]) this.member.setMap[this.globalProfileImgSet.id] = this.originalSetsMap[this.globalProfileImgSet.id]
    //   else this.member.setMap[this.globalProfileImgSet.id] = this.globalProfileImgSet.default
    // if (!this.member.setMap[this.globalProfileImgSet.id]) this.member.setMap[this.globalProfileImgSet.id] = this.globalProfileImgSet.default
    // else this.member.setMap[this.globalProfileImgSet.id]= this.
  }
  // Object.keys(this.setMap).forEach(key => {
  //   this.setMap[key].forEach(set => {
  //     if (this.memberGroups.some(group => group.id === set.group.id)) {
  //       if (this.originalSetsMap[set.id]) this.member.setMap[set.id] = this.originalSetsMap[set.id]
  //       else this.member.setMap[set.id] = set.default
  //     }
  //   })
  // })
  // this.updateLocalSets()
  // }

}
