import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Set } from 'src/app/interfaces/set';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-sets',
  templateUrl: './sets.component.html',
  styleUrls: ['./sets.component.scss']
})
export class SetsComponent implements OnInit {


  setsMap = {}
  loggedInUserId = ''
  subscription: Subscription[] = []
  memberGroups = [];
  currGroupId = '';
  member = this.memberService.emptyMember



  constructor(
    private setService: SetsService,
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService,
    private location: Location,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
        }
      })
    )

    this.subscription.push(
      this.setService.set$.subscribe(async (sets) => {
        const member = await this.memberService.getMember(this.loggedInUserId).toPromise()
        this.member = member;
        const allGroups = await this.organizationalStructureService.getGroups().toPromise()
        const memberGroups = allGroups.filter(group => group.members.some(memberId => memberId === this.loggedInUserId))
        this.memberGroups = memberGroups;
        this.setsMap = {}
        sets.forEach(set => {
          if (!set.advancedOptions.isVisibaleOnlyAdmin && memberGroups.some(group => group.id === set.group.id)) {
            if (!this.currGroupId) this.currGroupId = set.group.id
            if (this.setsMap[set.group.id]) this.setsMap[set.group.id].push(set);
            else this.setsMap[set.group.id] = [set];
          }
        })
      })
    )
    this.setService.loadSets();

  }

  updateSets(){
    this.memberService.updateMemberSets(this.member.setMap,this.loggedInUserId);
    this.location.back();
  }

}
