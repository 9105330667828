import { Component } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';
import { ThemeOptions } from '../../theme-options';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-b2c-layout',
  templateUrl: './b2c-layout.component.html',
  animations: [
    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column'

          }),
        ]),
        query(':enter', [
          animate('100ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
        ], { optional: true }),

        query(':leave', [
          animate('100ms ease', style({ opacity: 0, transform: 'translateY(-20px)' })),
        ], { optional: true })
      ]),
    ])
  ]
})

export class B2cLayoutComponent {

  @select('config') public config$: Observable<any>;
  isShowSupport = true;
  constructor(
    public globals: ThemeOptions,
    public configActions: ConfigActions,
    public translateService: OtechI18nService,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        console.log(val);
        if (val.url.split('/')[1] === 'chat'){
          this.isShowSupport = false;
        }
          
        else this.isShowSupport = true;

      }

    });
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}



