import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArchiveItem } from 'src/app/interfaces/archive-item';
import { ArchiveListService } from 'src/app/services/archive-list.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

  subscriotion: Subscription[] = []
  archiveList: any[] = []
  search = ''

  constructor(
    private archiveListService: ArchiveListService
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.archiveListService.archiveList$.subscribe(archiveList => {
        this.updateArchiveList(archiveList)
      })
    )
    this.archiveListService.loadArchiveList();
  }

  updateArchiveList(archiveList) {
    this.archiveList = archiveList.map(archiveItem => ({ ...archiveItem, isCheck: false }));
  }

  filterArchiveList() {
    return this.archiveList.filter(archiveItem => (
      archiveItem.title.toLowerCase().includes(this.search.toLowerCase()) ||
      archiveItem.type.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

}
