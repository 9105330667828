<div class="row">

    <div class="mb-3 card">
        <div class="card-body">
            <h3 class="card-title">{{'generalInformation'|translatePipe}}</h3>
            <div class="row">
                <form class="col-lg-8 col-md-6">
                    <app-input-label #nameInput  [required]="true" name="name" [label]="'name'|translatePipe" [(value)]="name"></app-input-label>
                    <app-textarea-label name="description" [label]="'description'|translatePipe" [(value)]="description"></app-textarea-label>
                </form>
                <div class="col-lg-4 col-md-6">
                    <app-image-picker [label]="'logo'|translatePipe" [(value)]="logoUrl" ></app-image-picker>
                </div>
            </div>

            <button class="btn btn-success" [disabled]="isSubmitDisable" (click)="updateOrganizationalStructureData()">save</button>
        </div>
    </div>
</div>