<div dirControl class="main-card card mb-3">
    <div class="card-body">
        <h3 class="card-title">{{'role view'|translatePipe}}</h3>
        <app-textarea-label [label]="'description'|translatePipe" type="text" [(value)]="role.description">
        </app-textarea-label>
        <app-input-label [label]="'name'|translatePipe" type="text" [(value)]="role.name"></app-input-label>
        <div class="mb-3">
            <app-color-picker [label]="'color'|translatePipe" [(color)]="role.color"></app-color-picker>
        </div>
        <app-check-box-label name="isShowInAdministrators" [(isCheck)]="role.isShowInAdministrators"
            [label]="'show role on global administrators'|translatePipe"></app-check-box-label>
    </div>
</div>


<h3 class="card-title text-center">{{'premmissions'|translatePipe}}</h3>
<div dirControl class="row">
    <div *ngFor="let premmissionsOption of premmissionsOptions"
        class="col-12 col-sm-6 premmission-view text-center mb-4">
        <div class="main-card card">
            <div class="card-body">
                <div class="icon-container">
                    <i [ngClass]="premmissionsOptionsMap[premmissionsOption].icon"></i>
                </div>
                <p class="premmision-title mt-4">
                    {{premmissionsOption|translatePipe}}
                </p>
                <bSwitch [switch-animate]="false" switch-size="mini" name="side-select"
                    [ngModel]="premmissionsOptionsMap[premmissionsOption].isCheck"
                    (ngModelChange)="togglePremmision(premmissionsOption)">
                </bSwitch>
            </div>
        </div>
    </div>
</div>

<button (click)="onSave()" class="mb-2 me-2 btn-icon btn  btn-success col-12"><i
        class="pe-7s-config btn-icon-wrapper"> </i>Save
    Settings </button>