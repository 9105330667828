import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-card-management-table',
  templateUrl: './card-management-table.component.html',
  styleUrls: ['./card-management-table.component.scss']
})
export class CardManagementTableComponent implements OnInit {

  subscription: Subscription[] = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MembersService
  ) { }

  id = '';
  getCardsFrom = "";
  cards: any[] = [];
  search = "";

  ngOnInit(): void {
    this.memberService.loadMembers();
    this.subscription.push(
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
        this.getCardsFrom = this.router.url.split('/')[2];
        this.updateCards();
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(_ => {
        this.updateCards()
      })
    )
  }

  updateCards() {
    if (this.getCardsFrom === 'card') {
      this.cards = this.memberService.getAllCardsFromTamplate(this.id).map(card => ({ 
        ...card, 
        isCheck: false,
        user: card.member,
        title: card.card.title,
        structure: card.card.group.name,
        limits:'limits',
        options:'options'
      }))
    } else if (this.getCardsFrom === 'user') {

    }
  }

  filterCards() {
    return this.cards.filter(card => (
      card.member.identifier.toLowerCase().includes(this.search.toLowerCase()) ||
      card.card.title.toLowerCase().includes(this.search.toLowerCase()) ||
      card.card.group.name.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  getColumn(){
    return ['user','title','structure','limits','options']
  }

}
