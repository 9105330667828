<div dirControl class="overflow-hidden mb-3">

    <div class="row mb-3">
        <div class="col-12">
            <div class="main-card card">
                <div class="card-body greeting-and-time flex space-between">
                    <div class="">
                        <span *ngIf="time.hour>4 && time.hour<=11">Good Morninig</span>
                        <span *ngIf="time.hour>=12 && time.hour<=16">Good Afternoon</span>
                        <span *ngIf="time.hour>16 && time.hour<=23">Good Evening</span>
                        <span *ngIf="time.hour>= 0 && time.hour<=4">Good Night</span>
                        <span>, {{loggedInUserIdentifier}}</span>
                    </div>
                    <div class="time">
                        <div>
                            <i class="pe-7s-date"></i> <span>{{time.day}}/{{time.month}}/{{time.year}}</span>
                        </div>
                        <!-- <span>,</span> -->
                        <div>
                            <i class="pe-7s-clock"></i>
                            <span>{{time.hour>9?time.hour:'0'+time.hour}}:{{time.minute>9?time.minute:'0'+time.minute}}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row mb-3">
        <div   class="col-6 calandar-container" #calanderContainer >
            <div class="main-card card">
                <mwl-calendar-day-view [viewDate]="date" [events]="events" [refresh]="refresh">
                </mwl-calendar-day-view>
            </div>
        </div>
        <div class="col-6 last-register-and-pie-container">
            <div class="main-card card mb-3">
                <div class="card-body">
                    <div class="last-members">
                        <h3 class="card-title">{{'last registered'|translatePipe}}</h3>

                        <div class="" *ngFor="let member of lastRegisterted">
                            <div class="member">
                                <span>{{member.identifier}}</span>
                                <span>{{utilService.timestampToFormatDate(member.memberSince)}}</span>
                            </div>
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-card card">
                <div class="card-body">
                    <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                        [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend">
                    </canvas>
                </div>
            </div>
        </div>
    </div>

    <div class="main-card card mb-3">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType" (chartHover)="chartHovered($event)"></canvas>
        <div class="card-body">

        </div>
    </div>

    <div class="main-card card">
        <div class="card-body">
            <h3 class="card-title">{{'navigation'|translatePipe}}</h3>
            <div class="g-0 row">

                <div *ngFor="let dashbordItem of dashboardData" class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div [routerLink]="dashbordItem.routerLink" class="widget-chart widget-chart-hover">
                        <div class="icon-wrapper">
                            <div [ngClass]="'widget-numbers text-'+dashbordItem.color">
                                {{dashbordItem.title|translatePipe}}</div>
                            <div [ngClass]="'icon-wrapper-bg bg-'+dashbordItem.color"></div>
                            <i [ngClass]="dashbordItem.icon+' pb-3 text-'+dashbordItem.color"></i>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
</div>
<!-- <div class="col-12">

    <div class="mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title">
          <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
          Bandwidth Reports
        </div>
        <div class="btn-actions-pane-right">
          <button class="btn-wide btn btn-link btn-sm">View All</button>
          <button class="btn-wide btn-shadow btn btn-primary btn-sm">Add New Entry</button>
        </div>
      </div>
      <div class="widget-chart p-0">
        <apx-chart [series]="[
                  {
                    name: 'TEAM A',
                    type: 'column',
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
                  }, {
                    name: 'TEAM B',
                    type: 'area',
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
                  }, {
                    name: 'TEAM C',
                    type: 'line',
                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
                  }
                ]" [chart]="{
                  height: '350',
                  type: 'line',
                  stacked: false
                }" [stroke]="{
                  width: [0, 2, 5],
                  curve: 'smooth'
                }" [plotOptions]="{
                  bar: {
                    columnWidth: '50%'
                  }
                }" [fill]="{
                  opacity: [0.85, 0.25, 1],
                  gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: 'vertical',
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                    }
                }"
          [labels]="['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003']"
          [xaxis]="{
                  type: 'datetime'
                }" [yaxis]="{
                  title: {
                    text: 'Points'
                  },
                  min: 0
                }"></apx-chart>
        <div class="widget-chart-content">
          <div class="widget-description mt-0 text-warning">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
            <span class="ps-1">175.5%</span>
            <span class="text-muted opacity-8 ps-1">increased server resources</span>
          </div>
        </div>
      </div>
      <div class="pt-2 card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="widget-content">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-numbers fsize-3 text-muted">63%</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="text-muted opacity-6">Generated Leads</div>
                  </div>
                </div>
                <div class="widget-progress-wrapper mt-1">
                  <div class="progress-bar-sm progress-bar-animated-alt progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="63" aria-valuemin="0"
                      aria-valuemax="100" style="width: 63%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="widget-content">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-numbers fsize-3 text-muted">32%</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="text-muted opacity-6">Submitted Tickers</div>
                  </div>
                </div>
                <div class="widget-progress-wrapper mt-1">
                  <div class="progress-bar-sm progress-bar-animated-alt progress">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="32" aria-valuemin="0"
                      aria-valuemax="100" style="width: 32%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="widget-content">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-numbers fsize-3 text-muted">71%</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="text-muted opacity-6">Server Allocation</div>
                  </div>
                </div>
                <div class="widget-progress-wrapper mt-1">
                  <div class="progress-bar-sm progress-bar-animated-alt progress">
                    <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="71" aria-valuemin="0"
                      aria-valuemax="100" style="width: 71%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="widget-content">
              <div class="widget-content-outer">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-numbers fsize-3 text-muted">41%</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="text-muted opacity-6">Generated Leads</div>
                  </div>
                </div>
                <div class="widget-progress-wrapper mt-1">
                  <div class="progress-bar-sm progress-bar-animated-alt progress">
                    <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="41" aria-valuemin="0"
                      aria-valuemax="100" style="width: 41%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="row">
    <div class="col-sm-12 col-lg-6">
        <div class="card-hover-shadow-2x mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize fw-normal">
                    <i class="header-icon lnr-lighter icon-gradient bg-amy-crisp"> </i>
                    Timeline Example
                </div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn" ngbDropdown>
                    <button class="btn btn-icon btn-icon-only btn-link" ngbDropdownToggle>
                        <i class="pe-7s-menu btn-icon-wrapper"></i>
                    </button>
                    <div ngbDropdownMenu>
                        <ul class="nav flex-column">
                            <li class="nav-item-header nav-item">Activity</li>
                            <li class="nav-item"><a [routerLink]="" class="nav-link">Chat
                                    <div class="ms-auto badge rounded-pill bg-info">8</div>
                                </a></li>
                            <li class="nav-item"><a [routerLink]="" class="nav-link">Recover Password</a></li>
                            <li class="nav-item-header nav-item">My Account</li>
                            <li class="nav-item"><a [routerLink]="" class="nav-link">Settings
                                    <div class="ms-auto badge bg-success">New</div>
                                </a></li>
                            <li class="nav-item"><a [routerLink]="" class="nav-link">Messages
                                    <div class="ms-auto badge bg-warning">512</div>
                                </a></li>
                            <li class="nav-item"><a [routerLink]="" class="nav-link">Logs</a></li>
                            <li class="nav-item-divider nav-item"></li>
                            <li class="nav-item-btn nav-item">
                                <button class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="scroll-area-lg">
                <perfect-scrollbar [autoPropagation]="true">
                    <div class="p-4">
                        <div
                            class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                            <div class="dot-danger vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">All Hands Meeting</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-warning vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-success vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">Build the production release
                                            <div class="badge bg-danger ms-2">NEW</div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-primary vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">
                                            Something not important
                                            <div class="avatar-wrapper mt-2 avatar-wrapper-overlap">
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/1.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/2.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/3.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/4.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/5.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/9.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/7.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/8.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                                                    <div class="avatar-icon"><i>+</i></div>
                                                </div>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-warning vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-success vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">Build the production release
                                            <div class="badge bg-danger ms-2">NEW</div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-info vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">This dot has an info state</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-dark vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">This dot has a dark state</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-danger vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">All Hands Meeting</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-warning vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-success vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">Build the production release
                                            <div class="badge bg-danger ms-2">NEW</div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="dot-primary vertical-timeline-element">
                                <div>
                                    <span class="vertical-timeline-element-icon bounce-in"></span>
                                    <div class="vertical-timeline-element-content bounce-in">
                                        <h4 class="timeline-title">
                                            Something not important
                                            <div class="avatar-wrapper mt-2 avatar-wrapper-overlap">
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/1.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/2.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/3.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/4.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/5.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/9.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/7.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                                    <div class="avatar-icon"><img src="./assets/images/avatars/8.jpg"
                                                            alt=""></div>
                                                </div>
                                                <div class="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                                                    <div class="avatar-icon"><i>+</i></div>
                                                </div>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="d-block text-center card-footer">
                <button class="btn-shadow btn-wide btn-pill btn btn-focus">
                    <span class="badge badge-dot badge-dot-lg bg-warning badge-pulse">Badge</span>
                    View All Messages
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6">
        <div class="card-hover-shadow-2x mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize fw-normal"><i
                        class="header-icon lnr-database icon-gradient bg-malibu-beach"> </i>Tasks List
                </div>
                <div class="btn-actions-pane-right text-capitalize actions-icon-btn" ngbDropdown>
                    <button class="btn btn-icon btn-icon-only btn-link" ngbDropdownToggle>
                        <fa-icon [icon]="faTh"></fa-icon>
                    </button>
                    <div ngbDropdownMenu>
                        <button type="button" tabindex="0" class="dropdown-item"><i class="dropdown-icon lnr-inbox">
                            </i><span>Menus</span></button>
                        <button type="button" tabindex="0" class="dropdown-item"><i
                                class="dropdown-icon lnr-file-empty">
                            </i><span>Settings</span>
                        </button>
                        <button type="button" tabindex="0" class="dropdown-item"><i class="dropdown-icon lnr-book">
                            </i><span>Actions</span>
                        </button>
                        <div tabindex="-1" class="dropdown-divider"></div>
                        <div class="p-1 text-end">
                            <button class="me-2 btn-shadow btn-sm btn btn-link">View Details</button>
                            <button class="me-2 btn-shadow btn-sm btn btn-primary">Action</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="scroll-area-lg">
                <perfect-scrollbar [autoPropagation]="true">
                    <ul class="todo-list-wrapper list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="todo-indicator bg-warning"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox12"
                                                class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox12">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Wash the car
                                            <div class="badge bg-danger ms-2">Rejected</div>
                                        </div>
                                        <div class="widget-subheading"><i>Written by Bob</i></div>
                                    </div>
                                    <div class="widget-content-right widget-content-actions">
                                        <button class="border-0 btn-transition btn btn-outline-success">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                        <button class="border-0 btn-transition btn btn-outline-danger">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="todo-indicator bg-focus"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox1" class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox1">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Task with dropdown menu</div>
                                        <div class="widget-subheading">
                                            <div>By Johnny
                                                <div class="badge rounded-pill bg-info ms-2">NEW</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content-right widget-content-actions">
                                        <div class="d-inline-block" ngbDropdown placement="bottom-left">
                                            <button class="btn btn-icon btn-icon-only btn-link" ngbDropdownToggle>
                                                <i class="pe-7s-menu btn-icon-wrapper"></i>
                                            </button>
                                            <div ngbDropdownMenu>
                                                <ul class="nav flex-column">
                                                    <li class="nav-item-header nav-item">Activity</li>
                                                    <li class="nav-item"><a [routerLink]="" class="nav-link">Chat
                                                            <div class="ms-auto badge rounded-pill bg-info">8</div>
                                                        </a></li>
                                                    <li class="nav-item"><a [routerLink]="" class="nav-link">Recover
                                                            Password</a></li>
                                                    <li class="nav-item-header nav-item">My Account</li>
                                                    <li class="nav-item"><a [routerLink]="" class="nav-link">Settings
                                                            <div class="ms-auto badge bg-success">New</div>
                                                        </a></li>
                                                    <li class="nav-item"><a [routerLink]="" class="nav-link">Messages
                                                            <div class="ms-auto badge bg-warning">512</div>
                                                        </a></li>
                                                    <li class="nav-item"><a [routerLink]="" class="nav-link">Logs</a>
                                                    </li>
                                                    <li class="nav-item-divider nav-item"></li>
                                                    <li class="nav-item-btn nav-item">
                                                        <button
                                                            class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="todo-indicator bg-primary"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox4" class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox4">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left flex2">
                                        <div class="widget-heading">Badge on the right task</div>
                                        <div class="widget-subheading">This task has show on hover actions!</div>
                                    </div>
                                    <div class="widget-content-right widget-content-actions">
                                        <button class="border-0 btn-transition btn btn-outline-success">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                    </div>
                                    <div class="widget-content-right ms-3">
                                        <div class="badge rounded-pill bg-success">Latest Task</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="todo-indicator bg-info"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox2" class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox2">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left me-3">
                                        <div class="widget-content-left">
                                            <img width="42" class="rounded" src="./assets/images/avatars/1.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Go grocery shopping</div>
                                        <div class="widget-subheading">A short description for this todo item</div>
                                    </div>
                                    <div class="widget-content-right widget-content-actions">
                                        <button class="border-0 btn-transition btn btn-outline-success">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                        <button class="border-0 btn-transition btn btn-outline-danger">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="todo-indicator bg-warning"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox12"
                                                class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox12">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left">
                                        <div class="widget-heading">Wash the car
                                            <div class="badge bg-danger ms-2">Rejected</div>
                                        </div>
                                        <div class="widget-subheading"><i>Written by Bob</i></div>
                                    </div>
                                    <div class="widget-content-right widget-content-actions">
                                        <button class="border-0 btn-transition btn btn-outline-success">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                        <button class="border-0 btn-transition btn btn-outline-danger">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="todo-indicator bg-primary"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox4" class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox4">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left flex2">
                                        <div class="widget-heading">Badge on the right task</div>
                                        <div class="widget-subheading">This task has show on hover actions!</div>
                                    </div>
                                    <div class="widget-content-right widget-content-actions">
                                        <button class="border-0 btn-transition btn btn-outline-success">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                    </div>
                                    <div class="widget-content-right ms-3">
                                        <div class="badge rounded-pill bg-success">Latest Task</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="todo-indicator bg-success"></div>
                            <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left me-2">
                                        <div class="custom-checkbox custom-control form-check">
                                            <input type="checkbox" id="exampleCustomCheckbox3" class="form-check-input">
                                            <label class="form-label form-check-label"
                                                for="exampleCustomCheckbox3">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="widget-content-left flex2">
                                        <div class="widget-heading">Development Task</div>
                                        <div class="widget-subheading">Finish Vue ToDo List App</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <div class="badge bg-warning me-2">69</div>
                                    </div>
                                    <div class="widget-content-right">
                                        <button class="border-0 btn-transition btn btn-outline-success">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                        <button class="border-0 btn-transition btn btn-outline-danger">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </perfect-scrollbar>
            </div>
            <div class="d-block text-end card-footer">
                <button class="me-2 btn btn-link btn-sm">Cancel</button>
                <button class="btn btn-primary">Add Task</button>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="true" class="mb-3 card">

    <div class="g-0 row">
        <div dirControl class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
                    <i class="pe-7s-users text-dark opacity-8"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">{{'total members'|translatePipe}}</div>
                    <div class="widget-numbers"><span [countUp]="memberLength" [options]="myOpts"></span></div>

                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div dirControl class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
                    <i class="pe-7s-user text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">{{'total employees'|translatePipe}}</div>
                    <div class="widget-numbers"><span [countUp]="employeeLength" [options]="myOpts"></span>
                    </div>

                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div dirControl class="col-sm-12 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-success"></div>
                    <i class="pe-7s-note2 text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">{{'total customers'|translatePipe}}</div>
                    <div class="widget-numbers"><span [countUp]="customerLength" [options]="myOpts"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>