<div class="accounts-multy-select mt-4 mb-4" ngbDropdown>
    <form>
        <label [class.no-data]="!isFocus && text===''&& !values?.length" [for]="name">{{label | titleCase}}</label>
        <div [class.focus]="isFocus" class="accounts-container">
            <div class="accounts">
                <div *ngFor="let value of values" class="account">
                    <span>
                        {{value.fullName}}
                    </span>
                    <button (click)="removeValue(value)" ><i class="pe-7s-close"></i></button>
                </div>
            </div>
            <input autocomplete="off" [(ngModel)]="text" (focus)="isFocus=true" ngbDropdownToggle (blur)="isFocus=false"
                [name]="name" [id]="name" type="text">
        </div>
        <div *ngIf="filterAccounts.length" ngbDropdownMenu class="dropdown-container">
            <button *ngFor="let account of filterAccounts" ngbDropdown (click)="onChooseAccount(account)"
                class="dropdown-item">{{account.data.fullName}}</button>
        </div>
    </form>
</div>