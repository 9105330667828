import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-preview',
  templateUrl: './set-preview.component.html',
  styleUrls: ['./set-preview.component.scss']
})
export class SetPreviewComponent implements OnInit {

  @Input() type: string;
  @Input() value: string;


  constructor() { }

  ngOnInit(): void {
  }

}
