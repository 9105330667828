<!-- <div class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="account name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>candidate full name</mat-header-cell>
            <mat-cell class="asd" *matCellDef="let employee">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="employee.isCheck=!employee.isCheck" [checked]="employee.isCheck"
                            class="form-check-input" type="checkbox">
                    </div>
                </div>
                {{employee.data.fullName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="member identifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Member identifier</mat-header-cell>
            <mat-cell *matCellDef="let employee">
                {{employee.contactInfo.member?.identifier || ''}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Options</mat-header-cell>
            <mat-cell *matCellDef="let employee">
                <div class="options-container">
                    <a [routerLink]="'/account-view/account/'+employee.id"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i class="pe-7s-user btn-icon-wrapper">
                        </i></a>
                    <button (click)="deleteCandidate.emit(employee)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-delete-user btn-icon-wrapper"> </i></button>

                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <app-paginator [collectionSize]="candidates.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div> -->

<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="candidatesToShow" [dataSource]="candidates"
        [dataItemKeys]="['type']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'full name'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of candidatesToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.data.fullName}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member identifier'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of candidatesToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.contactInfo.member?.identifier}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of candidatesToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/account-view/account/'+dataItem.id"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i class="pe-7s-user btn-icon-wrapper">
                        </i></a>
                    <button (click)="deleteEmployee.emit(dataItem)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-delete-user btn-icon-wrapper"> </i></button>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="candidates.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>