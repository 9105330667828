import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';

// BOOTSTRAP COMPONENTS

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LaddaModule } from 'angular2-ladda';
import { NgxLoadingModule } from 'ngx-loading';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
// import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { CountUpModule } from 'countup.js-angular2';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NouisliderModule } from 'ng2-nouislider';
import { NgSelectModule } from '@ng-select/ng-select';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ChartsModule } from 'ng2-charts';

// ANGULAR MATERIAL COMPONENTS

import { MatCheckboxModule } from '@angular/material/checkbox';

// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { AppsLayoutComponent } from './Layout/apps-layout/apps-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { B2cLayoutComponent } from './Layout/b2c-layout/b2c-layout.component';
import { ThemeOptions } from './theme-options';
import { OptionsDrawerComponent } from './ThemeOptions/options-drawer/options-drawer.component';
// import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { DotsComponent } from './Layout/Components/header/elements/dots/dots.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { MegamenuComponent } from './Layout/Components/header/elements/mega-menu/mega-menu.component';
import { MegapopoverComponent } from './Layout/Components/header/elements/mega-menu/elements/megapopover/megapopover.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';
import { DrawerComponent } from './Layout/Components/header/elements/drawer/drawer.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';
import { FooterDotsComponent } from './Layout/Components/footer/elements/footer-dots/footer-dots.component';
import { FooterMenuComponent } from './Layout/Components/footer/elements/footer-menu/footer-menu.component';

// Pages

import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';
import { RegisterComponent } from './pages/register/register.component';

// Components

import { NgbdSortableHeaderDirective } from './DemoPages/Tables/dynamic/demo/sortable.directive';

// Apex Charts
import { NgApexchartsModule } from 'ng-apexcharts';
// Gauges Charts

import { GaugeModule } from 'angular-gauge';
import { TrendModule } from 'ngx-trend';

// Flexyc

import { OrganizationalStructureComponent } from './pages/organizational-structure/organizational-structure.component';
import { TreeContainerComponent } from './pages/organizational-structure/components/tree-container/tree-container.component';
import { TreeComponent } from './pages/organizational-structure/components/tree/tree.component';
// import { ModalComponent } from './components/modal/modal.component';
import { DescriptionAndLegendComponent } from './pages/organizational-structure/components/description-and-legend/description-and-legend.component';
import { TableComponent } from './pages/organizational-structure/components/table/table.component';
import { TableContainerComponent } from './pages/organizational-structure/components/table-container/table-container.component';
import { ControlButtonsTableComponent } from './pages/organizational-structure/components/control-buttons-table/control-buttons-table.component';
import { ControlButtonsTreeComponent } from './pages/organizational-structure/components/control-buttons-tree/control-buttons-tree.component';
import { OrganizationalStructureEditComponent } from './pages/organizational-structure-edit/organizational-structure-edit.component';
import { GeneralInformationComponent } from './pages/organizational-structure-edit/components/general-information/general-information.component';
import { GroupMembersComponent } from './pages/organizational-structure-edit/components/group-members/group-members.component';
import { ModalComponent } from './components/modal/modal.component';
import { OrganizationalStructureMembersComponent } from './pages/organizational-structure-members/organizational-structure-members.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MembersTableComponent } from './pages/organizational-structure-members/components/members-table/members-table.component';
import { DuplicateOptionComponent } from './pages/duplicate-option/duplicate-option.component';
import { SystemSettingsComponent } from './pages/system-settings/system-settings.component';
import { SystemSettingsGeneralInformationComponent } from './pages/system-settings/components/system-settings-general-information/system-settings-general-information.component';
import { AppI18N } from './i18n.module';
import { SearchMembersComponent } from './pages/search-members/search-members.component';
import { DatepickerRoutingModule } from './DemoPages/Forms/Widgets/datepicker/datepicker-routing.module';
import { PageTitleModule } from './Layout/Components/page-title/page-title.module';
import { MembersResaultsComponent } from './pages/members-resaults/members-resaults.component';
import { ManageMembersComponent } from './pages/manage-members/manage-members.component';
import { ManageMembersTableComponent } from './pages/manage-members/manage-members-table/manage-members-table.component';
import { MemberProfileViewComponent } from './pages/member-profile-view/member-profile-view.component';
import { InviteMemberComponent } from './pages/invite-member/invite-member.component';
import { GlobalSearchComponent } from './pages/global-search/global-search.component';
import { GlobalSearchResaultsComponent } from './pages/global-search-resaults/global-search-resaults.component';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { CardViewComponent } from './pages/card-view/card-view.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component';
import { GroupPickerComponent } from './components/group-picker/group-picker.component';
import { CardManagementComponent } from './pages/card-management/card-management.component';
import { CardTemplatePreviewComponent } from './pages/card-management/components/card-template-preview/card-template-preview.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { CardViewGiveComponent } from './pages/card-view-give/card-view-give.component';
import { CardUsageComponent } from './pages/card-usage/card-usage.component';
import { CardUsagePreviewComponent } from './pages/card-usage/card-usage-preview/card-usage-preview.component';
import { CardUsagePreviewUseInLimitComponent } from './pages/card-usage/card-usage-preview-use-in-limit/card-usage-preview-use-in-limit.component';
import { CardManagementTableComponent } from './pages/card-management-table/card-management-table.component';
import { CardMemngmentTableTableComponent } from './pages/card-management-table/card-memngment-table-table/card-memngment-table-table.component';
import { ItemsTableComponent } from './components/items-table/items-table.component';
import { EventManagementComponent } from './pages/event-management/event-management.component';
import { EventViewComponent } from './pages/event-view/event-view.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { FormatDateTimePipe } from './pipes/format-date-time.pipe';
import { EventAttendanceComponent } from './pages/event-attendance/event-attendance.component';
import { AttendanceMemberViewComponent } from './pages/event-attendance/attendance-member-view/attendance-member-view.component';
import { GuestListComponent } from './pages/event-attendance/guest-list/guest-list.component';
import { TooltipsPopoversRoutingModule } from './DemoPages/Components/tooltips-popovers/tooltips-popovers-routing.module';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';
import { SetViewComponent } from './pages/set-view/set-view.component';
import { PropertyListComponent } from './pages/property-list/property-list.component';
import { TitleCaseToSpacesPipe } from './pipes/title-case-to-spaces.pipe';
import { SetManagementComponent } from './pages/set-management/set-management.component';
import { SetManagementTableComponent } from './pages/set-management/set-management-table/set-management-table.component';
import { GroupPickerInputComponent } from './components/group-picker-input/group-picker-input.component';
import { CheckBoxLabelComponent } from './components/check-box-label/check-box-label.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { DropdownLabelComponent } from './components/dropdown-label/dropdown-label.component';
import { TextareaLabelComponent } from './components/textarea-label/textarea-label.component';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { ChatSettingsComponent } from './pages/chat-settings/chat-settings.component';
import { GroupChatSettingsComponent } from './pages/chat-settings/group-chat-settings/group-chat-settings.component';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { ProgressSpinnerRoutingModule } from './DemoPages/Material/ButtonsIndicators/progress-spinner/progress-spinner-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LogSearchComponent } from './pages/log-search/log-search.component';
import { LogResaultsComponent } from './pages/log-resaults/log-resaults.component';
import { LogResaultsTableComponent } from './pages/log-resaults/log-resaults-table/log-resaults-table.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PaginatorRoutingModule } from './DemoPages/Material/Datatable/paginator/paginator-routing.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GlobalAdministratorsComponent } from './pages/global-administrators/global-administrators.component';
import { ToggleMemberComponent } from './pages/global-administrators/toggle-member/toggle-member.component';
import { TooltipMemberComponent } from './pages/global-administrators/tooltip-member/tooltip-member.component';
import { LocalAdministratorsComponent } from './pages/local-administrators/local-administrators.component';
import { SetInputComponent } from './components/set-input/set-input.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { ArchiveTableComponent } from './pages/archive/archive-table/archive-table.component';
import { RecycleBinComponent } from './pages/recycle-bin/recycle-bin.component';
import { RecycleBinTableComponent } from './pages/recycle-bin/recycle-bin-table/recycle-bin-table.component';
import { BackupsComponent } from './pages/backups/backups.component';
import { MediaComponent } from './pages/media/media.component';
import { IframeYoutubePipe } from './pipes/iframe-youtube.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { CustomerServiceComponent } from './pages/customer-service/customer-service.component';
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component';
import { DigitalWalletComponent } from './pages/digital-wallet/digital-wallet.component';
import { SocialNetworkComponent } from './pages/social-network/social-network.component';
import { RegisterClubComponent } from './pages/register-club/register-club.component';
import { StepperRoutingModule } from './DemoPages/Material/Layout/stepper/stepper-routing.module';
import { MatStepperModule } from '@angular/material/stepper';
import { VerifyCodeComponent } from './components/verify-code/verify-code.component';
import { RegisterPasswordComponent } from './pages/register-club/register-password/register-password.component';
import { OrganizationHeaderComponent } from './components/organization-header/organization-header.component';
import { LoginOrganizationComponent } from './pages/login-organization/login-organization.component';
import { ForgotPasswordOrganizationComponent } from './pages/forgot-password-organization/forgot-password-organization.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { MemberProfileComponent } from './pages/member-profile/member-profile.component';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { SetPreviewComponent } from './components/set-preview/set-preview.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { MiniMenuComponent } from './components/mini-menu/mini-menu.component';
import { ClubSocialNetworkComponent } from './pages/club-social-network/club-social-network.component';
import { MemberViewComponent } from './components/member-view/member-view.component';
import { GroupViewComponent } from './pages/group-view/group-view.component';
import { GroupObjectViewComponent } from './components/group-object-view/group-object-view.component';
import { GroupInviteComponent } from './pages/group-invite/group-invite.component';
import { GroupMiniDataComponent } from './components/group-mini-data/group-mini-data.component';
import { ButtonBoxTreeContainerComponent } from './components/button-box-tree-container/button-box-tree-container.component';
import { OrganizationDashboardComponent } from './pages/organization-dashboard/organization-dashboard.component';
import { ButtonBoxTreeComponent } from './components/button-box-tree-container/button-box-tree/button-box-tree.component';
import { SidebarB2cComponent } from './Layout/Components/sidebar-b2c/sidebar-b2c.component';
import { CardsComponent } from './pages/cards/cards.component';
import { EventAttendanceProgressBarComponent } from './pages/event-attendance/event-attendance-progress-bar/event-attendance-progress-bar.component';
import { SetsComponent } from './pages/sets/sets.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { ChatComponent } from './pages/chat/chat.component';
import { SupportB2cComponent } from './Layout/Components/support-b2c/support-b2c.component';
import { UserControlPannelComponent } from './pages/user-control-pannel/user-control-pannel.component';
import { ModuleManagmentComponent } from './pages/module-managment/module-managment.component';
import { AccountViewComponent } from './pages/account-view/account-view.component';
import { DropdownMultySelectComponent } from './components/dropdown-multy-select/dropdown-multy-select.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PortalEventViewComponent } from './pages/portal-event-view/portal-event-view.component';
import { AccountsMultySelectComponent } from './components/accounts-multy-select/accounts-multy-select.component';
import { AttachFilesComponent } from './components/attach-files/attach-files.component';
import { PortalEventMiniViewComponent } from './pages/account-view/portal-event-mini-view/portal-event-mini-view.component';
import { AddEventMiniViewComponent } from './pages/account-view/add-event-mini-view/add-event-mini-view.component';
import { PortalPostsComponent } from './pages/portal-posts/portal-posts.component';
import { PostViewComponent } from './components/post-view/post-view.component';
import { FullScreenImgDirective } from './directives/full-screen-img.directive';
import { FullScreenImgComponent } from './components/full-screen-img/full-screen-img.component';
import { MembersMultySelectComponent } from './components/members-multy-select/members-multy-select.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ManageEmployeesComponent } from './pages/manage-employees/manage-employees.component';
import { ManageEmployeesTableComponent } from './pages/manage-employees/manage-employees-table/manage-employees-table.component';
import { PortalEventManagmentComponent } from './pages/portal-event-managment/portal-event-managment.component';
import { PortalManagmentEventViewComponent } from './pages/portal-event-managment/portal-managment-event-view/portal-managment-event-view.component';
import { RatePickerComponent } from './components/rate-picker/rate-picker.component';
import { PortalEventViewReadonlyComponent } from './pages/portal-event-view-readonly/portal-event-view-readonly.component';
import { ManageCostumersComponent } from './pages/manage-costumers/manage-costumers.component';
import { ManageCostumersTableComponent } from './pages/manage-costumers/manage-costumers-table/manage-costumers-table.component';
import { CountUpModule } from 'ngx-countup';
import { EmployeeDirectoryComponent } from './pages/employee-directory/employee-directory.component';
import { CustomerDirectoryComponent } from './pages/customer-directory/customer-directory.component';
import { PortalEventViewReadonlyAccountComponent } from './pages/portal-event-view-readonly-account/portal-event-view-readonly-account.component';
import { ManageCandidatesComponent } from './pages/manage-candidates/manage-candidates.component';
import { ManageCandidatesTableComponent } from './pages/manage-candidates/manage-candidates-table/manage-candidates-table.component';
import { OrganizationMediaComponent } from './pages/organization-media/organization-media.component';
import { OrganizationEccomerceComponent } from './pages/organization-eccomerce/organization-eccomerce.component';
import { EmployeePortalComponent } from './pages/employee-portal/employee-portal.component';
import { ManagePatientsComponent } from './pages/manage-patients/manage-patients.component';
import { ManagePatientsTableComponent } from './pages/manage-patients/manage-patients-table/manage-patients-table.component';
import { PostListComponent } from './components/post-list/post-list.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { CustomerPortalComponent } from './pages/customer-portal/customer-portal.component';
import { CandidateDirectoryComponent } from './pages/candidate-directory/candidate-directory.component';
import { CandidatePortalComponent } from './pages/candidate-portal/candidate-portal.component';
import { FlexycTableComponent } from './components/flexyc-table/flexyc-table.component';
import { PortalEmployeePostsComponent } from './pages/portal-employee-posts/portal-employee-posts.component';
import { PortalCostumerPostsComponent } from './pages/portal-costumer-posts/portal-costumer-posts.component';
import { PortalCandidatePostsComponent } from './pages/portal-candidate-posts/portal-candidate-posts.component';
import { PortalPatientsPostsComponent } from './pages/portal-patients-posts/portal-patients-posts.component';
import { HeaderB2cComponent } from './Layout/Components/header-b2c/header-b2c.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { OrganizationLandingPageComponent } from './pages/organization-landing-page/organization-landing-page.component';
import { DirControlDirective } from './directives/dir-control.directive';
import { appReducer, AppState } from './store/app.store';
import { FlexycMiniMenuComponent } from './components/flexyc-mini-menu/flexyc-mini-menu.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { StoreModule } from '@ngrx/store';
import { RoleViewComponent } from './pages/role-view/role-view.component';
import { RoleManagmentComponent } from './pages/role-managment/role-managment.component';
import { TimestampToTimePipe } from './pipes/timestamp-to-time.pipe';
import { TimestampMomentPipe } from './pipes/timestamp-moment.pipe';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TaskManagmentComponent } from './pages/task-managment/task-managment.component';
import { TaskManagmentTableComponent } from './pages/task-managment/task-managment-table/task-managment-table.component';
import { TaskStatusComponent } from './pages/task-managment/task-status/task-status.component';
import { TriggerViewComponent } from './pages/trigger-view/trigger-view.component';
import { TriggerManagmentComponent } from './pages/trigger-managment/trigger-managment.component';
import { TriggerManagmentTableComponent } from './pages/trigger-managment/trigger-managment-table/trigger-managment-table.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { TasksTableComponent } from './pages/tasks/tasks-table/tasks-table.component';
import { OrderSituationComponent } from './pages/task-managment/order-situation/order-situation.component';
import { ManageEmployeesServerComponent } from './pages/manage-employees-server/manage-employees-server.component';
import { ManageEmployeesTableServerComponent } from './pages/manage-employees-server/manage-employees-table-server/manage-employees-table-server.component';
import { AccountViewServerComponent } from './pages/account-view-server/account-view-server.component';
import { GroupPickerInputServerComponent } from './components/group-picker-input-server/group-picker-input-server.component';
import { GroupPickerButtonServerComponent } from './components/group-picker-input-server/group-picker-button-server/group-picker-button-server.component';
import { TreeServerComponent } from './components/tree-server/tree-server.component';
import { MembersMultySelectServerComponent } from './components/members-multy-select-server/members-multy-select-server.component';
import { PortalEventViewServerComponent } from './pages/portal-event-view-server/portal-event-view-server.component';
import { ManageCustomerServerComponent } from './pages/manage-customer-server/manage-customer-server.component';
import { ManageCandidateServerComponent } from './pages/manage-candidate-server/manage-candidate-server.component';
import { ManageCustomerTableServerComponent } from './pages/manage-customer-server/manage-customer-table-server/manage-customer-table-server.component';
import { ManageCandidateTableServerComponent } from './pages/manage-candidate-server/manage-candidate-table-server/manage-candidate-table-server.component';
import { AddEventMiniViewServerComponent } from './pages/account-view-server/add-event-mini-view-server/add-event-mini-view-server.component';
import { AccountEventViewServerComponent } from './pages/account-event-view-server/account-event-view-server.component';
import { AccountEventMiniViewServerComponent } from './pages/account-view-server/account-event-mini-view-server/account-event-mini-view-server.component';
import { ImagePickerServerComponent } from './components/image-picker-server/image-picker-server.component';
import { PieChartModule } from './DemoPages/Charts/chartjs/examples/pie-chart/pie-chart.module';
import { DashboardLineChartComponent } from './pages/dashboard/components/dashboard-line-chart/dashboard-line-chart.component';
import { StructureServerComponent } from './pages/structure-server/structure-server.component';
import { DescriptionAndLegendServerComponent } from './pages/structure-server/description-and-legend-server/description-and-legend-server.component';
import { StructureViewServerComponent } from './pages/structure-view-server/structure-view-server.component';
import { GroupManageMembersServerComponent } from './pages/group-manage-members-server/group-manage-members-server.component';
import { GroupManageMembersTableServerComponent } from './pages/group-manage-members-server/group-manage-members-table-server/group-manage-members-table-server.component';
import { GroupMemberServerComponent } from './pages/structure-view-server/group-member-server/group-member-server.component';
import { MemberViewServerComponent } from './pages/member-view-server/member-view-server.component';
import { ManageMembersServerComponent } from './pages/manage-members-server/manage-members-server.component';
import { ManageMemberTableServerComponent } from './pages/manage-members-server/manage-member-table-server/manage-member-table-server.component';

// Angular Material

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,
    PagesLayoutComponent,
    OptionsDrawerComponent,
    B2cLayoutComponent,
    SupportB2cComponent,

    // HEADER

    HeaderComponent,
    HeaderB2cComponent,
    DotsComponent,
    SearchBoxComponent,
    MegamenuComponent,
    MegapopoverComponent,
    UserBoxComponent,
    DrawerComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,
    SidebarB2cComponent,

    // FOOTER

    FooterComponent,
    FooterDotsComponent,
    FooterMenuComponent,

    // // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    // LoginComponent,
    RegisterBoxedComponent,

    // Tables
    NgbdSortableHeaderDirective,




    //Flexyc pages

    OrganizationalStructureComponent,
    OrganizationalStructureEditComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,


    //Flexyc pages - componnets

    TreeContainerComponent,
    TreeComponent,
    DescriptionAndLegendComponent,
    TableComponent,
    TableContainerComponent,
    ControlButtonsTableComponent,
    ControlButtonsTreeComponent,
    GeneralInformationComponent,
    GroupMembersComponent,
    ModalComponent,
    OrganizationalStructureMembersComponent,
    DashboardComponent,
    MembersTableComponent,
    DuplicateOptionComponent,
    SystemSettingsComponent,
    SystemSettingsGeneralInformationComponent,
    SearchMembersComponent,
    MembersResaultsComponent,
    ManageMembersComponent,
    ManageMembersTableComponent,
    MemberProfileViewComponent,
    InviteMemberComponent,
    GlobalSearchComponent,
    GlobalSearchResaultsComponent,
    TitleCasePipe,
    CardViewComponent,
    DatePickerComponent,
    RangeDatePickerComponent,
    GroupPickerComponent,
    CardManagementComponent,
    CardTemplatePreviewComponent,
    FormatDatePipe,
    CardViewGiveComponent,
    CardUsageComponent,
    CardUsagePreviewComponent,
    CardUsagePreviewUseInLimitComponent,
    CardManagementTableComponent,
    CardMemngmentTableTableComponent,
    ItemsTableComponent,
    EventManagementComponent,
    EventViewComponent,
    TimePickerComponent,
    DropdownInputComponent,
    FormatDateTimePipe,
    EventAttendanceComponent,
    AttendanceMemberViewComponent,
    GuestListComponent,
    SetViewComponent,
    PropertyListComponent,
    TitleCaseToSpacesPipe,
    SetManagementComponent,
    SetManagementTableComponent,
    GroupPickerInputComponent,
    CheckBoxLabelComponent,
    InputLabelComponent,
    DropdownLabelComponent,
    TextareaLabelComponent,
    ImagePickerComponent,
    ChatSettingsComponent,
    GroupChatSettingsComponent,
    MultiselectDropdownComponent,
    LogSearchComponent,
    LogResaultsComponent,
    LogResaultsTableComponent,
    PaginatorComponent,
    GlobalAdministratorsComponent,
    ToggleMemberComponent,
    TooltipMemberComponent,
    LocalAdministratorsComponent,
    SetInputComponent,
    ArchiveComponent,
    ArchiveTableComponent,
    RecycleBinComponent,
    RecycleBinTableComponent,
    BackupsComponent,
    MediaComponent,
    IframeYoutubePipe,
    SafeUrlPipe,
    CustomerServiceComponent,
    EcommerceComponent,
    DigitalWalletComponent,
    SocialNetworkComponent,
    RegisterClubComponent,
    VerifyCodeComponent,
    RegisterPasswordComponent,
    OrganizationHeaderComponent,
    LoginOrganizationComponent,
    ForgotPasswordOrganizationComponent,
    CalendarComponent,
    MemberProfileComponent,
    FormatTimePipe,
    SetPreviewComponent,
    ColorPickerComponent,
    MiniMenuComponent,
    ClubSocialNetworkComponent,
    MemberViewComponent,
    GroupViewComponent,
    GroupObjectViewComponent,
    GroupInviteComponent,
    GroupMiniDataComponent,
    ButtonBoxTreeContainerComponent,
    OrganizationDashboardComponent,
    ButtonBoxTreeComponent,
    CardsComponent,
    EventAttendanceProgressBarComponent,
    SetsComponent,
    GroupsComponent,
    ChatComponent,
    UserControlPannelComponent,
    ModuleManagmentComponent,
    AccountViewComponent,
    DropdownMultySelectComponent,
    ClickOutsideDirective,
    DirControlDirective,
    PortalEventViewComponent,
    AccountsMultySelectComponent,
    AttachFilesComponent,
    PortalEventMiniViewComponent,
    AddEventMiniViewComponent,
    PortalPostsComponent,
    PostViewComponent,
    FullScreenImgDirective,
    FullScreenImgComponent,
    MembersMultySelectComponent,
    ProfileComponent,
    ManageEmployeesComponent,
    ManageEmployeesTableComponent,
    PortalEventManagmentComponent,
    PortalManagmentEventViewComponent,
    RatePickerComponent,
    PortalEventViewReadonlyComponent,
    ManageCostumersComponent,
    ManageCostumersTableComponent,
    EmployeeDirectoryComponent,
    CustomerDirectoryComponent,
    PortalEventViewReadonlyAccountComponent,
    ManageCandidatesComponent,
    ManageCandidatesTableComponent,
    OrganizationMediaComponent,
    OrganizationEccomerceComponent,
    EmployeePortalComponent,
    ManagePatientsComponent,
    ManagePatientsTableComponent,
    PostListComponent,
    AccountInfoComponent,
    CustomerPortalComponent,
    CandidateDirectoryComponent,
    CandidatePortalComponent,
    FlexycTableComponent,
    PortalEmployeePostsComponent,
    PortalCostumerPostsComponent,
    PortalCandidatePostsComponent,
    PortalPatientsPostsComponent,
    LandingPageComponent,
    OrganizationLandingPageComponent,
    FlexycMiniMenuComponent,
    RoleViewComponent,
    RoleManagmentComponent,
    TimestampToTimePipe,
    TimestampMomentPipe,
    TaskViewComponent,
    TaskManagmentComponent,
    TaskManagmentTableComponent,
    TaskStatusComponent,
    TriggerViewComponent,
    TriggerManagmentComponent,
    TriggerManagmentTableComponent,
    TasksComponent,
    TasksTableComponent,
    OrderSituationComponent,
    ManageEmployeesServerComponent,
    ManageEmployeesTableServerComponent,
    AccountViewServerComponent,
    GroupPickerInputServerComponent,
    GroupPickerButtonServerComponent,
    TreeServerComponent,
    MembersMultySelectServerComponent,
    PortalEventViewServerComponent,
    ManageCustomerServerComponent,
    ManageCandidateServerComponent,
    ManageCustomerTableServerComponent,
    ManageCandidateTableServerComponent,
    AddEventMiniViewServerComponent,
    AccountEventViewServerComponent,
    AccountEventMiniViewServerComponent,
    ImagePickerServerComponent,
    DashboardLineChartComponent,
    StructureServerComponent,
    DescriptionAndLegendServerComponent,
    StructureViewServerComponent,
    GroupMemberServerComponent,
    GroupManageMembersServerComponent,
    GroupManageMembersTableServerComponent,
    MemberViewServerComponent,
    ManageMembersServerComponent,
    ManageMemberTableServerComponent,


  ],
  imports: [
    AppI18N,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    PieChartModule,
    MatIconModule,
    StoreModule.forRoot(appReducer),

    CommonModule, NgbModule, FormsModule, ReactiveFormsModule,
    DatepickerRoutingModule, PageTitleModule,


    CommonModule,
    StepperRoutingModule,
    FormsModule, ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCardModule,
    MatStepperModule,
    MatIconModule,
    PageTitleModule,



    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    ToastrModule.forRoot(),
    SlickCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CountUpModule,
    ImageCropperModule,
    NouisliderModule,
    NgSelectModule,
    // SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    // TextMaskModule,
    ClipboardModule,
    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,
    DragDropModule,
    CommonModule,
    ProgressSpinnerRoutingModule,
    FormsModule, ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    PageTitleModule,

    // Charts

    ChartsModule,
    NgApexchartsModule,
    GaugeModule.forRoot(),
    TrendModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginatorRoutingModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    PageTitleModule,


    // Angular Material Components

    MatCheckboxModule,

    CommonModule, NgbModule,
    TooltipsPopoversRoutingModule, PageTitleModule,
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
    ThemeOptions,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [this.devTool.isEnabled() ? this.devTool.enhancer() : f => f]
    );

  }
}
