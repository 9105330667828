<div dirControl class="main-card card  total-sets-card portal-event-view">
    <div class="card-body">
        <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper">
            <mat-step>
                <ng-template matStepLabel>
                    <!-- Password -->
                </ng-template>
                <div class="center-image">
                    <div class="col-8 col-sm-6 col-md-4 col-lg-2">
                        <app-image-picker [label]="'event image'|translatePipe" [(value)]="event.eventImage"></app-image-picker>
                    </div>
                </div>
                <!-- <app-dropdown-label name="process" label="Process" [(value)]="event.proccess"
                    [options]="proccessOptions">
                </app-dropdown-label> -->
                <div class="type-input-container mb-4">
                    <button (click)="open()" class="btn btn-primary">{{'choose type'|translatePipe}}</button>
                    <p *ngIf="event.type">{{event.type}}</p>
                    <button (click)="chooseType('')" class="remove-type" *ngIf="event.type">
                        <i class="pe-7s-close"></i>
                    </button>
                </div>
                <!-- <app-dropdown-label name="type" label="Event type" [(value)]="event.eventType" [options]="typeOptions">
                </app-dropdown-label> -->
                <!-- <app-dropdown-label name="type" label="Format" (valueChange)="onFormatChange($event)"
                    [value]="event.format" [options]="formatOptions">
                </app-dropdown-label> -->
                <app-input-label [(value)]="event.title" name="name" [label]="'event name'|translatePipe"></app-input-label>
                <!-- <app-accounts-multy-select [(values)]="event.eventParticipants" name=" participants"
                    label="event participants"> -->
                <!-- </app-accounts-multy-select> -->
                <app-members-multy-select-server [(values)]="event.participants" name="participants"
                    [label]="'event participants'|translatePipe"></app-members-multy-select-server>
                <app-date-picker [(timestamp)]="event.date" name="date" [label]="'event date'|translatePipe"></app-date-picker>
                <app-input-label [(value)]="event.location" name="location" [label]="'event location'|translatePipe">
                </app-input-label>
                <app-textarea-label [(value)]="event.description" name="description" [label]="'event description'|translatePipe">
                </app-textarea-label>
                <app-attach-files [(files)]="event.attachFiles" [label]="'attach files'|translatePipe"></app-attach-files>
                <app-check-box-label name="visibale-only-mamnagers" [(isCheck)]="event.isVisibleToAccount"
                    [label]="'PortalEventView.visible'|translatePipe">
                </app-check-box-label>

                <button (click)="saveAndContinue()" class="btn btn-primary" mat-button matStepperNext>{{
                    event.formatData?('next'|translatePipe):('save and continue'|translatePipe)
                    }}</button>
            </mat-step>
            <mat-step *ngIf="event.formatData">
                <ng-template matStepLabel>
                    <!-- Fill Set Data -->
                </ng-template>
                <ng-container *ngFor="let question of event.formatData.questions">
                    <app-set-input [set]="question" [(value)]="question.data"></app-set-input>
                </ng-container>
                <div class="btns-container">
                    <button class="btn btn-secondary" mat-button matStepperPrevious>{{'back'|translatePipe}}</button>
                    <button (click)="saveEvent()" class="btn btn-primary" mat-button matStepperNext>{{'save and continue'|translatePipe}}</button>
                </div>

            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <!-- Completed -->
                </ng-template>
                <div *ngIf="account" class="action-container">
                    <div class="action-list">
                        <div class="action">
                            <a *ngIf="account.member?.id" class="m-1" routerLink="/event-view">
                                <i class="pe-7s-date"></i>
                                <div class="action-description">
                                    <p>{{'PortalEventView.createEvent'|translatePipe}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="action">
                            <a *ngIf="account.email" class="m-1"
                                href="{{'mailTo:'+account.email}}">
                                <i class="pe-7s-mail"></i>
                                <div class="action-description">
                                    <p>{{'PortalEventView.email'|translatePipe}}</p>
                                    <p>{{account.email}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="action">
                            <a [href]="'https://wa.me/'+account.phone" *ngIf="account.phone"
                                class="m-1">
                                <i class="fa fa-fw"></i>
                                <div class="action-description">
                                    <p>{{'PortalEventView.whatsapp'|translatePipe}}</p>
                                    <p>{{account.phone}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="action">
                            <a *ngIf="account.phone" class="m-1"
                                href="{{'tel:'+account.phone}}">
                                <i class="pe-7s-call"></i>
                                <div class="action-description">
                                    <p>{{'call to'|translatePipe}} {{account.type|translatePipe}}</p>
                                    <p>{{account.phone}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex mt-4">
                    <button class="btn btn-secondary m-2" mat-button matStepperPrevious>{{'back'|translatePipe}}</button>
                    <a [routerLink]="'/account-view/account/'+event.accountId" class="btn btn-primary m-2">{{'account'|translatePipe}}</a>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>



<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Choose type</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div dir="rtl" class="modal-body">
        <div class="category-type-container">
            <div class="category-container">
                <div (click)="currCategory=typeKey" class="category" *ngFor="let typeKey of typeMapKeys">
                    {{typeKey}}
                </div>
            </div>
            <div class="divider"></div>
            <div class="type-container">
                <ng-container *ngIf="currCategory">
                    <div (click)="chooseType(type,c)" *ngFor="let type of typeMap[currCategory]" class="type">
                        <i class="fa fa-fw" [title]="type">{{getIconType(type)}}</i>
                        <span>{{type}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    </div>
</ng-template>
