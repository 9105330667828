import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-portal-event-view-readonly',
  templateUrl: './portal-event-view-readonly.component.html',
  styleUrls: ['./portal-event-view-readonly.component.scss']
})
export class PortalEventViewReadonlyComponent implements OnInit {

  event = null
  subscription: Subscription[] = [];
  account = null
  @Input() isFromAccount = false;


  get eventParticipantsStr() {
    return this.event?.eventParticipants.map(member => member.identifier).join(', ')
  }


  constructor(
    private route: ActivatedRoute,
    private portalEventService: PortalEventService,
    private portalAccountService: PortalAccountsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const accountId = params.get('accountId');
        const eventId = params.get('eventId');
        if (accountId) {
          this.event.accountId = accountId;
          this.account = await this.portalAccountService.getById(accountId).toPromise();
        }
        else if (eventId) {
          this.event = await this.portalEventService.getById(eventId).toPromise();
          this.account = await this.portalAccountService.getById(this.event.accountId).toPromise();
        }

      })
    )
  }

  deleteEvent() {
    this.router.navigate(['account-view', 'account', this.account.id]);
    this.portalEventService.delete(this.event.id).toPromise();
  }



}
