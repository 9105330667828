<div class="profile-container">
    <div class="mini-data-and-menu">

        <div class="mini-data mb-4">
            <img [src]="member.profileImg" alt="profile image">
            <p>{{member.identifier}}</p>
        </div>

        <app-mini-menu [buttons]="menuButtons"></app-mini-menu>
    </div>
    <div class="member-since">
        <p> <i class="pe-7s-clock"></i> Member since {{member.memberSince | formatDate}}</p>
    </div>
    <div class="main-card card mb-4">
        <div class="card-body">
            <h3 class="card-title">Profile</h3>
            <p class="set-priview" *ngFor="let set of setMap.global">
                <span class="span-title">{{set.title}}</span>:
                <app-set-preview [type]="set.property.type" [value]="member.setMap[set.id]"></app-set-preview>
            </p>
        </div>
    </div>

    <div class="main-card card mb-4">
        <div class="card-body">
            <h3 class="card-title">Member of</h3>
            <div class="row">
                <div (click)="onChooseGroup(group.id)" *ngFor="let group of memberGroups" class="col-3">
                    <app-group-object-view [group]="group"></app-group-object-view>
                </div>
            </div>
            <!-- <p *ngFor="let group of memberGroups">{{group.name}} ({{group.type}})</p> -->
        </div>
    </div>

    <div class="main-card card">
        <div class="card-body">

            <div class="tabs-btns mb-4">
                <button *ngFor="let group of memberGroups" [class.curr]="group.id===currLocalSetGroupId"
                    (click)="currLocalSetGroupId= group.id">
                    {{group.name}}
                </button>
            </div>
            <div *ngFor="let set of setMap[currLocalSetGroupId]">
                <p class="set-priview">
                    <span class=" span-title">{{set.title}}</span>:
                    <app-set-preview [type]="set.property.type" [value]="member.setMap[set.id]"></app-set-preview>
                </p>
            </div>
        </div>
    </div>