import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../store/app.store';

@Injectable({
  providedIn: 'root'
})
export class ChatService {


  constructor(
    private store: Store<AppState>,
  ) {

  }


  private _isTyping$ = new BehaviorSubject<boolean>(false)
  public isTyping$ = this._isTyping$.asObservable();
  public timeOut = null;

  chatsMap = {
    u102: [
      [
        {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }, {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1648546886931,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
      ],
      [
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1653384392191,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        },
      ],
      [
        {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1653469417329,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat101',
          from: {
            id: 'u101',
            identifier: 'omerAdam',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
          },
          sendAt: 1653469417329,
          type: 'text',
          data: 'hi i am omer adam the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1653469417329,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        },
        {
          id: 'chat102',
          from: {
            id: 'u102',
            identifier: 'eyalGolan',
            profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
          },
          sendAt: 1653469417329,
          type: 'text',
          data: 'hi i am eyal golan the famous singer in israel'
        }
      ]

    ],

  }

  getChats() {
    return this.chatsMap
  }

  onTyping() {
    if (this.timeOut) clearTimeout(this.timeOut)
    this._isTyping$.next(true)
    this.timeOut = setTimeout(() => {
      this._isTyping$.next(false);
    }, 300)
  }

  addChat(chatWithId) {
    this.chatsMap[chatWithId] = []
  }

  sendMsg(data, chatWith, loggedInMember) {
    const newMsg = {
      id: 'chat102',
      from: {
        id: loggedInMember.id,
        identifier: loggedInMember.identifier,
        profileImg: loggedInMember.profileImg
      },
      sendAt: Date.now(),
      type: 'text',
      data: data
    }



    if (this.chatsMap[chatWith].length) {
      const lastMsg = this.chatsMap[chatWith][this.chatsMap[chatWith].length - 1][0];
      if (this._checkIsSameDay(newMsg.sendAt, lastMsg.sendAt)) {
        console.log(this.chatsMap[chatWith][this.chatsMap[chatWith].length - 1]);

        this.chatsMap[chatWith][this.chatsMap[chatWith].length - 1].push(newMsg);
      } else {
        console.log(2);
        this.chatsMap[chatWith].push([newMsg]);
      }
    }

  }

  private _checkIsSameDay(ts1, ts2): boolean {
    var day1 = new Date(ts1).setHours(0, 0, 0, 0);
    var day2 = new Date(ts2).setHours(0, 0, 0, 0);
    return day1 === day2
  }



}
