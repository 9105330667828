

<mat-horizontal-stepper dirControl [linear]="true" #stepper="matHorizontalStepper">
    <mat-step>
        <ng-template matStepLabel>
            <!-- base data -->
        </ng-template>
        <h3 class="card-title">{{'triggerView'|translatePipe}}</h3>
        <app-input-label [label]="'title'|translatePipe" [(value)]="trigger.title"></app-input-label>
        <app-dropdown-label [label]="'timer'|translatePipe" [options]="timerOptions" (valueChange)="updateTimer($event)"
            [value]="trigger.timer"></app-dropdown-label>
        <app-date-picker [label]="'start date'|translatePipe" [(timestamp)]="trigger.startOn"></app-date-picker>
        <app-members-multy-select name="members" [label]="'members'|translatePipe" [(values)]="trigger.users">
        </app-members-multy-select>
        <app-dropdown-label [label]="'type'|translatePipe" [options]="typeOptions" (valueChange)="updateType($event)"
            [value]="trigger.type"></app-dropdown-label>

        <button class="btn btn-primary mb-2 mr-2" mat-button matStepperNext>{{'next'|translatePipe}}</button>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <!-- timer -->
        </ng-template>
        <ng-container *ngIf="trigger.timer==='once a week'">
            <h3 class="card-title">{{'time in week'|translatePipe}}</h3>
            <app-dropdown-label [label]="'day'|translatePipe" [options]="dayOptions" [(value)]="trigger.timeInWeek.day">
            </app-dropdown-label>
            <app-time-picker [label]="'time'|translatePipe" [(time)]="trigger.timeInWeek.time"></app-time-picker>
        </ng-container>
        <ng-container *ngIf="trigger.timer==='once a month'">
            <h3 class="card-title">{{'time in month'|translatePipe}}</h3>
            <app-dropdown-label [label]="'day in month'|translatePipe" [options]="monthDayOptions"
                [(value)]="trigger.timeInMonth.day"></app-dropdown-label>
            <app-time-picker [label]="'time'|translatePipe" [(time)]="trigger.timeInMonth.time"></app-time-picker>
        </ng-container>
        <ng-container *ngIf="trigger.timer==='once a day'">
            <h3 class="card-title">{{'time in day'|translatePipe}}</h3>
            <app-time-picker [label]="'time'|translatePipe" [(time)]="trigger.timeInDay.time"></app-time-picker>
        </ng-container>
        <div class="flex gap-10">
            <button class="btn btn-secondary" mat-button matStepperPrevious>{{'back'|translatePipe}}</button>
            <button class="btn btn-primary" mat-button matStepperNext>{{'next'|translatePipe}}</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <!-- trigger -->
        </ng-template>
        <ng-container *ngIf="trigger.type === 'task'">
            <app-textarea-label [label]="'content'|translatePipe" [(value)]="trigger.taskData.content">
            </app-textarea-label>
            <app-group-picker-input [label]="'group'|translatePipe" [(currGroup)]="trigger.taskData.group">
            </app-group-picker-input>
            <app-input-label type="number" [label]="'num of days'|translatePipe" [(value)]="trigger.taskData.numOfDays">
            </app-input-label>
            <app-rate-picker [label]="'priority'|translatePipe" [(value)]="trigger.taskData.priority"></app-rate-picker>
            <app-attach-files [label]="'files'|translatePipe" [(files)]="trigger.taskData.files"></app-attach-files>
        </ng-container>
        <ng-container *ngIf="trigger.type === 'notification'">
            <h3 class="card-title">{{'notification data'|translatePipe}}</h3>
            <app-textarea-label [label]="'content'|translatePipe" [(value)]="trigger.notificationData.content">
            </app-textarea-label>
        </ng-container>
        <div class="flex gap-10">
            <button class="btn btn-secondary" mat-button matStepperPrevious>{{'back'|translatePipe}}</button>
            <button class="btn btn-success" (click)="onSave()" mat-button matStepperNext>{{'save'|translatePipe}}</button>
        </div>
    </mat-step>

</mat-horizontal-stepper>