<div class="main-card card">
    <div class="card-body">
        <h3 class="card-title">Card view</h3>
        <form class="">
            <app-dropdown-input label="Member" [disabled]="!!editedId" [dropDownOpions]="getMemberToSearch()"
                (chooseOption)="onChooseMember($event)" [(text)]="member.identifier">
            </app-dropdown-input>

            <app-input-label label="Title" [(value)]="card.title" name="title" [disabled]="true"></app-input-label>

            <app-group-picker-input [disabled]="true" [(currGroup)]="card.group" name="group-2" [groups]="groupOptions">
            </app-group-picker-input>

            <div *ngIf="isHaveAdvancedOptions">
                <div class="divider" [ngClass]="slideSituation"></div>
                <button (click)="toggleSlideSituation()"
                    class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary mb-3">
                    <i *ngIf="slideSituation==='out'" class="pe-7s-angle-up btn-icon-wrapper"></i>
                    <i *ngIf="slideSituation==='in'" class="pe-7s-angle-down btn-icon-wrapper"></i>
                    {{'Advanced Options' | translatePipe}}
                </button>

                <div [@slide-vertical]="slideSituation" class="">

                    <div *ngIf="card.limitAmountOfTickets" class="row">
                        <ng-container *ngIf="editedId; else elseTemplate">
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox"
                                        (change)="card.limitAmountOfTickets.isCheck=!card.limitAmountOfTickets.isCheck"
                                        id="limitAmountOfTicketsCheck" class="form-check-input"
                                        [checked]="card.limitAmountOfTickets.isCheck">
                                </div>
                                <label for="limitAmountOfTicketsCheck" class="form-label form-check-label col-11 row">
                                    <div class="col-6">
                                        <app-input-label label="Limit by amount of tickets"
                                            [(value)]="card.limitAmountOfTickets.limit" name="limitAmountOfTickets"
                                            type="number" [min]="0">
                                        </app-input-label>
                                    </div>
                                    <div class="col-6">
                                        <app-input-label label="available tickets out of:" [(value)]="used"
                                            name="limitAmountOfTickets" type="number" [min]="0"
                                            [max]="card.limitAmountOfTickets.limit">
                                        </app-input-label>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div class="row">
                                <div class="col-1">
                                    <input type="checkbox"
                                        (change)="card.limitAmountOfTickets.isCheck=!card.limitAmountOfTickets.isCheck"
                                        id="limitAmountOfTicketsCheck" class="form-check-input"
                                        [checked]="card.limitAmountOfTickets.isCheck">
                                </div>
                                <label for="limitAmountOfTicketsCheck" class=" col-11">
                                    <app-input-label label="Limit by amount of tickets"
                                        [(value)]="card.limitAmountOfTickets.limit" name="limitAmountOfTickets"
                                        type="number" [min]="0">
                                    </app-input-label>
                                </label>
                            </div>
                        </ng-template>


                    </div>
                    <div *ngIf="card.startDate" class="row">
                        <div class="col-1">
                            <input type="checkbox" (change)="card.startDate.isCheck=!card.startDate.isCheck"
                                id="startDateCheck" class="form-check-input" [checked]="card.startDate.isCheck">
                        </div>
                        <label for="startDateCheck" class="form-label form-check-label col-11">
                            <app-date-picker label="Start date" [(timestamp)]="card.startDate.timestamp">
                            </app-date-picker>
                            
                        </label>
                    </div>
                    <div *ngIf="card.experationDate" class="row">
                        <div class="col-1">
                            <input type="checkbox" (change)="card.experationDate.isCheck=!card.experationDate.isCheck"
                                id="toDateCheck" class="form-check-input" [checked]="card.experationDate.isCheck">
                        </div>
                        <label for="toDateCheck" class="form-label form-check-label col-11">
                            <app-date-picker label="Experation date" [(timestamp)]="card.experationDate.timestamp">
                            </app-date-picker>
                        </label>
                    </div>
                    <div *ngIf="card.expiredAfter" class="row">
                        <div class="col-1">
                            <input type="checkbox" (change)="card.expiredAfter.isCheck=!card.expiredAfter.isCheck"
                                id="expiredAfterCheck" class="form-check-input" [checked]="card.expiredAfter.isCheck">
                        </div>
                        <label for="expiredAfterCheck" class="form-label form-check-label col-11">
                            <div class="row">
                                <div class="col-6">
                                    <app-input-label label="Limit by amount of tickets"
                                        [(value)]="card.expiredAfter.amount" name="expiredAfter" type="number"
                                        [min]="0">
                                    </app-input-label>
                                </div>
                                <div class="col-6">
                                    <app-dropdown-label [options]="expriedAfterTimeOptions" label="Units"
                                        [(value)]="card.expiredAfter.unit"></app-dropdown-label>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!!editedId; else elseTemplate">
                <button (click)="onAddCardToUser()" class="btn btn-success">Save</button>
            </ng-container>
            <ng-template #elseTemplate>
                <button (click)="onAddCardToUser()" class="btn btn-success">Add card to member</button>

            </ng-template>

        </form>
    </div>
</div>