import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-group-picker',
  templateUrl: './group-picker.component.html',
  styleUrls: ['./group-picker.component.scss']
})
export class GroupPickerComponent implements OnInit {
  @Output() onSelectChild = new EventEmitter<string>()
  @Input() disabled?: boolean = false


  constructor(
    private modalService: NgbModal,
    public organizationalStructureService: OrganizationalStructureService
  ) { }

  group = this.organizationalStructureService.emptyGroup;
  subscription: Subscription[] = []


  ngOnInit(): void {
    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.group = organizationalStructure[0];
      })
    )
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  open(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  selectChildAndClose(id, cb) {
    this.onSelectChild.emit(id);
    cb('Cross click')
  }

}
