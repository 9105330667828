import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss']
})
export class GroupViewComponent implements OnInit {

  subscription: Subscription[] = [];
 
  slideConfig = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };
  MEMBER_ROW = 4;
  GROUP_ROW = 3;
  isShowAllmembers = false;
  isShowAllSubGroups = false;
  faCalendarAlt = faCalendarAlt;

  constructor(
    private route: ActivatedRoute,
    private organizationalStructureService: OrganizationalStructureService,
    private memberService: MembersService,
    private router: Router,
  ) { }

  group: Group = this.organizationalStructureService.emptyGroup;
  groupMembers: Member[][] = []
  subGroups: Group[][] = []

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          this.group = await this.organizationalStructureService.getGroupById(id).toPromise()
          this.memberService.loadMembers();
          this.organizationalStructureService.loadOrganizationalStructure();
        }
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.groupMembers = [];
        const groupMembers = members.filter(member => this.group.members.some(memberId => memberId === member.id))
        groupMembers.forEach(member => {
          const groupMembersLenght = this.groupMembers.length
          if (!groupMembersLenght || this.groupMembers[groupMembersLenght - 1].length === this.MEMBER_ROW) this.groupMembers.push([member])
          else this.groupMembers[groupMembersLenght - 1].push(member);
        })
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(groups => {
        if (groups.length) {
          const groupChildren = this.getGroupChildren(this.group.id, groups);
          this.subGroups = []
          groupChildren.forEach(group => {
            const subGroupsLenght = this.subGroups.length
            if (!subGroupsLenght || this.subGroups[subGroupsLenght - 1].length === this.GROUP_ROW) this.subGroups.push([group])
            else this.subGroups[subGroupsLenght - 1].push(group);
          })
        }
      })
    )
  }

  onChooseMember(memberId) {
    this.router.navigate(['member-profile', memberId]);
  }

  onChooseGroup(groupId) {
    this.router.navigate(['group-view', groupId]);
  }

  getGroupChildren(groupId: string, groups: Group[]): Group[] {
    let children: Group[] = []
    const currGroup: Group = groups.find(group => group.id === groupId);
    if (!currGroup) return [...children]
    if (!currGroup.children) return [...children]
    currGroup.children.forEach(childId => {
      const child = groups.find(group => group.id === childId);
      children.push(child)
      const childChildren = this.getGroupChildren(child.id, groups);
      childChildren.forEach(childChild => {
        children.push(childChild)
      })
    })
    return children
  }

}
