import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.store';
import { exhaustMap, map, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private store: Store<AppState>
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth$ = this.store.select('auth').pipe(
      take(1),
    )

    const organizationId$ = this.store.select('organization').pipe(
      take(1),
      map(organization => organization.id)
    )

    return forkJoin([auth$, organizationId$]).pipe(
      take(1),
      exhaustMap(resList => {
        const [user, organizationId] = resList
        // if (!user.token) {
        //   const modifiedReq = req.clone({ setHeaders: {'orgId': organizationId } });
        //   return next.handle(modifiedReq)
        // }
        const modifiedReq = req.clone({ setHeaders: { 'token': user.token, 'orgId': organizationId } });
        return next.handle(modifiedReq)
      })
    )


    return this.store.select('auth').pipe(
      take(1),
      exhaustMap(user => {
        if (!user.id) {
          return next.handle(req)
        }
        const modifiedReq = req.clone({ setHeaders: { 'token': user.token } });
        return next.handle(modifiedReq)
      })
    )
  }


}
