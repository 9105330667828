<div class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let recycleBinItem">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="recycleBinItem.isCheck=!recycleBinItem.isCheck"
                            [checked]="recycleBinItem.isCheck" class="form-check-input" type="checkbox">
                    </div>
                </div>
                {{recycleBinItem.title}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
            <mat-cell *matCellDef="let recycleBinItem"> {{recycleBinItem.type}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="timeLeft">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Time left</mat-header-cell>
            <mat-cell *matCellDef="let recycleBinItem"> {{recycleBinItem.deleteAt}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Options</mat-header-cell>
            <mat-cell *matCellDef="let recycleBinItem">
                <div class="options-container">
                    <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger">
                        <i class="pe-7s-trash"></i>
                    </button>
                    <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning">
                        <i class="pe-7s-box1"></i>
                    </button>
                    <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-success">
                        <i class="fa fa-fw" aria-hidden="true" title="add"></i>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <app-paginator [collectionSize]="recycleBinList.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>

</div>