<div dirControl class="members card">
    <div class="card-body">
        <h3 class="card-title">{{'members'|translatePipe}}</h3>
        <div class="search-container" ngbDropdown>
            <form>
                <input id="member" ngbDropdownToggle name="member" type="text" autocomplete="off" class="form-control"
                    [placeholder]="'member name'|translatePipe" [(ngModel)]="searchMemberText">
            </form>
            <div *ngIf="searchMemberText && getMemberToSearch().length" ngbDropdownMenu>
                <button *ngFor="let member of getMemberToSearch()" (click)="onAddMember.emit(member.id)"
                    class="dropdown-item">{{member.identifier}}</button>
            </div>
        </div>

        <div class="member-list">
            <div *ngFor="let member of members" class="member-container">
                <span>{{member.identifier}}</span>
                <button (click)="onRemoveMember.emit(member.id)"
                    class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only">
                    <i class="fa fa-fw" aria-hidden="true" title="members"></i>
                </button>

            </div>
        </div>
        <div class="text-center">
            <a routerLink="members" class="btn btn-primary">{{'manage members'|translatePipe}}</a>
        </div>
    </div>
</div>