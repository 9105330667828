<div dirControl class="row mb-3">
    <div class="col-12 text-center mb-3">
        <p class="card-title group">{{'members of group'|translatePipe}}: {{group?.title}}</p>
        <p [ngClass]="parent?.type" class="card-title">{{'in '+parent?.type|translatePipe}}: {{parent?.title}}
        </p>
    </div>

    <div class="col-12">
        <div class="card search-card">
            <div class="card-body">
                <form>
                    <div class="position-relative form-group">
                        <form class="">
                            <div class="position-relative form-group search-container">
                                <label>
                                    <i class="pe-7s-search"></i>
                                </label>
                                <input id="search" name="search" (ngModelChange)="onSearchChange($event)"
                                    [ngModel]="search" type="text" class="form-control"
                                    [placeholder]="'search'|translatePipe">
                            </div>
                        </form>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="mb-5">
    <app-group-manage-members-table-server (removeMember)="openSureModal($event,true)"
        [members]="filterGroupMember$|async"></app-group-manage-members-table-server>
</div>

<div *ngIf="isHaveChecked()" class="header mb-3 card">
    <div class="card-body">
        <div class="row">
            <div class="col-6 p-3 text-center">
                <button (click)="unselectMembers()" class="btn btn-primary">Unselected Members</button>
            </div>
            <div class="col-6 p-3 text-center">
                <button (click)="removeMembers()" class="btn btn-danger">Delete Selected Members</button>
            </div>
        </div>
    </div>
</div>

<button (click)="open()" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw" aria-hidden="true"
        title="add"></i>
</button>


<ng-template #addMember let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Add administrators</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-dropdown-input (textChange)="onAddMemberSearchChange($event)" [text]="addMemberSearch" [dropDownOpions]="filteredNotGroupMembers$|async"
            (chooseOption)="openSureModal($event,false,close)">
        </app-dropdown-input>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

<ng-template #sureModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{isSureDelete?'Remove':'Add'}} member
        </h5>

        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            isSureDelete?
            'Are you sure you want to remove member':
            'Are you sure to add member'
            }} {{choosenMember.identifier}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" [class.btn-danger]="isSureDelete" [class.btn-primary]="!isSureDelete"
            (click)="onAcceptModal(close)">
            {{isSureDelete?'Remove':'Add'}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>