import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Member } from 'src/app/interfaces/member';
import { Group } from 'src/app/interfaces/group';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-card-usage',
  templateUrl: './card-usage.component.html',
  styleUrls: ['./card-usage.component.scss']
})
export class CardUsageComponent implements OnInit {
  subscription: Subscription[] = [];

  constructor(
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
  ) { }

  member: Member = this.memberService.emptyMember;
  organizationalStructure: Group[] = [this.organizationalStructureService.emptyGroup];

  ngOnInit(): void {
    this.subscription.push(
      this.memberService.members$.subscribe(_ => { })
    )
    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.organizationalStructure = organizationalStructure;
      })
    )

    this.subscription.push(
      this.route.paramMap.subscribe(params => {
        const id = params.get('id');
        if (id) {
          this.updateMember(id)
        }
      })
    )

    this.memberService.loadMembers()
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  getMemberToSearch() {
    return this.memberService.getMembers().filter(member => member.identifier.toLowerCase().includes(this.member.identifier));
  }

  onChooseMember(member) {
    this.member = member;
  }

  getCardImg(groupId) {
    return this.organizationalStructure.find(group => group.id === groupId)?.logoUrl
  }

  async useCard(cardId) {
    await this.memberService.useCardOfMember(this.member.id, cardId);
    this.updateMember(this.member.id)
  }

  async updateMember(id) {
    const member = await this.memberService.getMember(id).toPromise()
    this.member = member;
  }

}
