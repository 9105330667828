import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Structure } from 'src/app/interfaces/structure';
import { DepartmentServerService } from 'src/app/services/department-server.service';
import { GroupServerService } from 'src/app/services/group-server.service';
import { OrganizationServerService } from 'src/app/services/organization-server.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-structure-server',
  templateUrl: './structure-server.component.html',
  styleUrls: ['./structure-server.component.scss']
})
export class StructureServerComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal!: any;
  subscription: Subscription[] = [];
  structure: Structure = null
  currAction = ''
  isViewA = true
  askedDeltedStructure = null


  constructor(
    private organizationService: OrganizationServerService,
    private router: Router,
    private utilService: UtilService,
    private modalService: NgbModal,
    private groupService: GroupServerService,
    private departmentService: DepartmentServerService
  ) { }

  ngOnInit(): void {
    this.organizationService.loadStructure()
    this.subscription.push(
      this.organizationService.structure$.subscribe(structure => {
        this.structure = structure
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onSetCurrAction(action: string): void { // set action
    if (action === this.currAction) this.currAction = '';
    else this.currAction = action
  }

  toggleView() {
    this.isViewA = !this.isViewA
  }

  onHoveredButtonClick(data: { action: string, structure: Structure }) {
    const { action, structure } = data
    this.currAction = action;
    this.onSelectGroupChild(structure);
  }

  async onSelectGroupChild(structure: Structure) {
    // when you clicked on group on the tree / choose group and action on the table this function will decide what to do

    switch (this.currAction) {
      case 'add': // add child to the choosen group and navigate to edit him
        this.router.navigate(['structure-view-server/parent/' + structure.type + '_' + structure.id]);
        break;
      case 'edit': // navigate to edit choosen group
        if (structure.type === 'organization') this.router.navigate(['system_settings']);
        else this.router.navigate(['structure-view-server', structure.type + '_' + structure.id]);
        break;
      case 'delete': // saved the id in property and open are you sure delete organization
        this.askedDeltedStructure = structure
        this.open()
        break;
      case 'members': // navigate to group manege members
        if (structure.type === 'organization') this.router.navigate(['manage-members-server']);
        else this.router.navigate(['group-manage-members', structure.id]);
        break;
    }
  }

  async onDeleteStructure(cb) {
    if (this.askedDeltedStructure.type === 'group') await this.groupService.remove(this.askedDeltedStructure.id).toPromise()
    if (this.askedDeltedStructure.type === 'department') await this.departmentService.remove(this.askedDeltedStructure.id).toPromise()
    this.organizationService.loadStructure();
    if (cb) cb()
  }

  open() {
    this.modalService.open(this.modal.content).result.then((result) => {
    }, (reason) => {
    });
  }

}
