import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnInit, OnChanges {

  @Output() textChange = new EventEmitter<String>();
  @Output() chooseOption = new EventEmitter<any>();

  private _text: string;
  isFocus=false;
  @Input() label?: string;
  @Input() disabled?: boolean = false;
  @Input() placeholder?: string = '';
  @Input() name?: string = 'name';
  @Input() dropDownOpions: any[];
  @Input() set text(val: string) {
    this._text = val;
    this.textChange.emit(val);
  }
  get text() {
    return this._text;
  }

  options: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.options = [...this.dropDownOpions]
  }

  onChooseOption(option: any) {
    this.chooseOption.emit(option)
  }

  constructor() { }

  ngOnInit(): void {
    this.options = [...this.dropDownOpions]
  }

}
