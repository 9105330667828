import { Component, OnInit } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
// import { faRefresh } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
  styleUrls: ['./dots.component.scss']
})
export class DotsComponent implements OnInit {

  // faRefresh = faRefresh;
  faCalendarAlt = faCalendarAlt;
  isHaveUnReadNotifications = true;
  private _isNotificationOpen = false;

  get isNotificationOpen() {
    return this._isNotificationOpen
  }

  set isNotificationOpen(value) {
    this._isNotificationOpen = value
    if (value) {
      setTimeout(() => {
        this.notifications = this.notifications.map(notification => ({ ...notification, isRead: true }))
        this.isHaveUnReadNotifications = false
      }, 1000);
    }
  }

  notifications = [
    {
      type: 'data',
      isRead: false,
      settings: {
        title: 'sad',
        text: 'asd asd  sa dsa das d',
        timestamp: 1648636903993,
        imgUrl: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510913/tlvpk41thr71vyi676lf.jpg',
        link: ''
      }
    },
    {
      type: 'request',
      isRead: true,
      settings: {
        title: 'sad',
        text: 'asd asd  sa dsa das d',
        timestamp: 1648636903993,
        imgUrl: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510913/tlvpk41thr71vyi676lf.jpg',
        link: ''
      }
    }
  ]

  constructor(
    public translateService: OtechI18nService
  ) { }

  getClass() {
    if (this.translateService.locale === 'he') return 'language-icon flag flag-icon-background flag-icon-il'
    if (this.translateService.locale === 'en') return 'language-icon flag flag-icon-background flag-icon-us'
  }

  setLanguage(language: string) {
    this.translateService.locale = language
  }


  ngOnInit() {
  }

}
