import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit, AfterViewInit {

  private _value
  @Output() valueChange = new EventEmitter<string | number>();
  @Input() set value(val: string | number) {
    this._value = val;
    this.valueChange.emit(val);
  }
  get value() {
    return this._value;
  }

  elInputs = []

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.elInputs = [...this.elementRef.nativeElement.querySelectorAll('.verify-code')]
    this.elInputs.forEach((elInput, idx) => {
      elInput.addEventListener('keydown', e => {
        if (e.keyCode === 8 && e.target.value === '') this.elInputs[Math.max(0, idx - 1)].focus()

      })
      elInput.addEventListener('input', e => {
        const [first, ...rest] = e.target.value
        e.target.value = first ?? ''
        const isLastInputBox = idx === this.elInputs.length - 1
        const isInsertedContent = first !== undefined
        if (isInsertedContent && !isLastInputBox) {
          this.elInputs[idx + 1].focus()
          this.elInputs[idx + 1].value = rest.join('')
          this.elInputs[idx + 1].dispatchEvent(new Event('input'))
        }
        this.value =this.elInputs.map(input => input.value).join('');
      })
    })




  }


}






