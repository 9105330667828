<div class="support-container">
  <button (click)="isSupportOpen=!isSupportOpen" class="btn-info support-btn"><i class="pe-7s-info"></i></button>
  <div dirControl [@slide-vertical]="isSupportOpen?'out':'in'" [class.close]="!isSupportOpen" class="support">
    <perfect-scrollbar style="height: 480px;" [autoPropagation]="true">
      <div *ngFor="let chat of supportChat" [class.float-end]="chat.from.id===loggedInUserId">
        <div class="chat-box-wrapper" [class.chat-box-wrapper-right]="chat.from.id===loggedInUserId">
          <div>
            <div class="avatar-icon-wrapper me-1">
              <!-- <div class="badge badge-bottom btn-shine bg-success badge-dot badge-dot-lg"></div> -->
              <div class="avatar-icon avatar-icon-lg rounded">
                <img [src]="chat.from.profileImg" alt="image" />
              </div>
            </div>
          </div>
          <div>
            <div class="chat-box">
              <ng-container *ngIf="chat.type==='text'">
                {{chat.data}}
              </ng-container>
            </div>
            <small class="opacity-6">
              <fa-icon [icon]="faCalendarAlt" class="me-1"></fa-icon>
              {{chat.sendAt | formatDateTime}}
            </small>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="app-inner-layout__bottom-pane d-block text-center">
      <div class="mb-0 position-relative">
        <form (submit)="sendMsg($event)" class="input-container">
          <input autocomplete="off" name="new-msg" [(ngModel)]="newMsgText"
            [placeholder]="'messege'|translatePipe" type="text" class="form-control">
          <button class="btn btn-success">{{'send'|translatePipe}}</button>
        </form>
      </div>
    </div>
  </div>
</div>