import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { AccountEventServerService } from 'src/app/services/account-event-server.service';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-account-event-mini-view-server',
  templateUrl: './account-event-mini-view-server.component.html',
  styleUrls: ['./account-event-mini-view-server.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class AccountEventMiniViewServerComponent implements OnInit {

  @Input() isInManage = false;
  @Input() isFromAccount = false;
  @Input() event?: any;
  @Output() deleteAccountEvents = new EventEmitter<any>();

  isMenuOpen = false;


  constructor(
    private router: Router,
    // private portalEventService: PortalEventService,
    private accountEventService:AccountEventServerService
  ) { }

  ngOnInit(): void {
  }

  onChooseEvent() {
    if (this.isFromAccount) this.router.navigate(['portal-event-view', 'event', 'readonly','account', this.event.id]);
    else this.router.navigate(['portal-event-view', 'event', 'readonly', this.event.id]);
  }

  stopProp(ev) {
    ev.stopPropagation();
  }

  deleteEvent(event) {
    this.deleteAccountEvents.emit(event);
  }


}
