<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'set view'|translatePipe}}</h3>
        <form class="">

            <app-input-label #title [required]=" true" [label]="'title'|translatePipe" type="text" [(value)]="set.title">
            </app-input-label>

            <app-group-picker-input [(currGroup)]="set.group" [label]="'group'|translatePipe" [groups]="groupOptions"></app-group-picker-input>

            <ng-container [ngSwitch]="set.property.type">
                <app-input-label *ngSwitchCase="'number'" name="default" [label]="'default'|translatePipe" type="number"
                    [(value)]="set.default">
                </app-input-label>

                <app-date-picker *ngSwitchCase="'timestamp'" [label]="'default'|translatePipe" [(timestamp)]="set.default">
                </app-date-picker>

                <app-time-picker *ngSwitchCase="'time'" [(time)]="set.default" [label]="'default'|translatePipe" name="default">
                </app-time-picker>

                <div *ngSwitchCase="'check'">
                    <div class="flex gap-10 mb-4">
                        <label class="">{{'default'|translatePipe}}</label>
                        <div class="">
                            <input type="checkbox" (change)="set.default=!set.default" id="default"
                                class="form-check-input" [checked]="set.default">
                        </div>
                    </div>
                </div>

                <app-input-label *ngSwitchDefault [label]="'default'|translatePipe" name="default" type="text" [(value)]="set.default">
                </app-input-label>
            </ng-container>





            <!-- generic number  -->
            <div *ngIf="set.functionality.min" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox" (change)="set.functionality.min.isCheck=!set.functionality.min.isCheck"
                        id="min" class="form-check-input gap-check" [checked]="set.functionality.min.isCheck">
                    <label for="min" class="flexyc-input-container">
                        <app-input-label type="number" [label]="'min'|translatePipe" name="min" [(value)]="set.functionality.min.number">
                        </app-input-label>
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.max" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox" (change)="set.functionality.max.isCheck=!set.functionality.max.isCheck"
                        id="max" class="form-check-input gap-check" [checked]="set.functionality.max.isCheck">
                    <label for="max" class="flexyc-input-container">
                        <app-input-label type="number" [label]="'max'|translatePipe" name="max" [(value)]="set.functionality.max.number">
                        </app-input-label>
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.symbol" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox" (change)="set.functionality.symbol.isCheck=!set.functionality.symbol.isCheck"
                        id="symbol" class="form-check-input gap-check" [checked]="set.functionality.symbol.isCheck">
                    <label for="symbol" class="flexyc-input-container">
                        <div class="position-relative form-group mb-3 row">
                            <app-dropdown-label [label]="'symbol'|translatePipe" [(value)]="set.functionality.symbol.value"
                                [options]="['$','₪','%']">
                            </app-dropdown-label>
                        </div>

                        <div class="flex gap-10">
                            <label for="side-select">{{'symbolSide'|translatePipe}}</label>
                            <div>
                                <bSwitch name="side-select" [(ngModel)]="set.functionality.symbol.isLeft"
                                    switch-on-color="primary" switch-off-color="primary" [switch-off-text]="'right'|translatePipe"
                                    [switch-on-text]="'left'|translatePipe"></bSwitch>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.thousandCommas" class="position-relative form-group mb-3">
                <div class="flex gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.thousandCommas.isCheck=!set.functionality.thousandCommas.isCheck"
                        id="symbol" class="form-check-input" [checked]="set.functionality.thousandCommas.isCheck">
                    <label for="thousandCommas">
                        {{'thousandCommas'|translatePipe}}
                    </label>
                </div>
            </div>

            <!-- generic text and long text  -->
            <div *ngIf="set.functionality.minChar" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.minChar.isCheck=!set.functionality.minChar.isCheck" id="minChar"
                        class="form-check-input gap-check" [checked]="set.functionality.minChar.isCheck">
                    <label for="minChar" class="flexyc-input-container">
                        <app-input-label type="number" [label]="'minCharacters'|translatePipe" name="minChar"
                            [(value)]="set.functionality.minChar.number">
                        </app-input-label>
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.maxChar" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.maxChar.isCheck=!set.functionality.maxChar.isCheck" id="maxChar"
                        class="form-check-input gap-check" [checked]="set.functionality.maxChar.isCheck">
                    <label for="maxChar" class="flexyc-input-container">
                        <app-input-label type="number" [label]="'maxCharacters'|translatePipe" name="maxChar"
                            [(value)]="set.functionality.maxChar.number">
                        </app-input-label>
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.characters" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.characters.isCheck=!set.functionality.characters.isCheck"
                        id="maxChar" class="form-check-input gap-check"
                        [checked]="set.functionality.characters.isCheck">
                    <label for="characters" class="flexyc-input-container">
                        <!-- <div class="position-relative input-container form-group mb-3">
                            <label>Possible characters</label>
                            <form [formGroup]="charactersForm">
                                <ng-multiselect-dropdown name="characters" [placeholder]="'Select Options'"
                                    [settings]="dropdownSettings" [data]="characters" formControlName="characters"
                                    [disabled]="false" [settings]="dropdownSettings">
                                </ng-multiselect-dropdown>
                            </form>
                        </div> -->
                        <app-multiselect-dropdown [label]="'possible characters'|translatePipe"
                            [(data)]="set.functionality.characters.validCharacters" [options]="characters">
                        </app-multiselect-dropdown>
                    </label>
                </div>
            </div>

            <!-- generic date -->
            <div *ngIf="set.functionality.showDay" class="position-relative form-group mb-3">
                <div class="flex gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.showDay.isCheck=!set.functionality.showDay.isCheck" id="showDay"
                        class="form-check-input" [checked]="set.functionality.showDay.isCheck">
                    <label for="showDay" class="">
                        {{'showDay'|translatePipe}}
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.timePicker" class="position-relative form-group mb-3">
                <div class="flex gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.timePicker.isCheck=!set.functionality.timePicker.isCheck"
                        id="timePicker" class="form-check-input" [checked]="set.functionality.timePicker.isCheck">
                    <label for="timePicker" class="">
                        <app-time-picker [(time)]="set.functionality.timePicker.defaultTime" [label]="'default time'|translatePipe"
                            name="time"></app-time-picker>
                    </label>
                </div>
            </div>

            <div *ngIf="set.functionality.formatDate" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.formatDate.isCheck=!set.functionality.formatDate.isCheck"
                        id="formatDate" class="form-check-input gap-check"
                        [checked]="set.functionality.formatDate.isCheck">
                    <label for="formatDate" class="flexyc-input-container">
                        <app-dropdown-label [label]="'formatDate'|translatePipe" [(value)]="set.functionality.formatDate.format"
                            [options]="['d/m/yyyy','m/d/yyyy','Dth of Month, yyyy']">
                        </app-dropdown-label>
                    </label>
                </div>
            </div>

            <!-- generic time -->
            <div *ngIf="set.functionality.timeType" class="position-relative form-group mb-3">
                <div class="checkbox-flex-gap-10">
                    <input type="checkbox"
                        (change)="set.functionality.timeType.isCheck=!set.functionality.timeType.isCheck" id="timeType"
                        class="form-check-input gap-check" [checked]="set.functionality.timeType.isCheck">
                    <label for="timeType" class="flexyc-input-container">
                        <app-dropdown-label [label]="'formatTime'|translatePipe" [(value)]="set.functionality.timeType.value"
                            [options]="['12H','24H']">
                        </app-dropdown-label>
                    </label>
                </div>
            </div>

            <!-- generic checkbox -->
            <div *ngIf="set.functionality.color" class="position-relative form-group mb-3">
                <div class="flex gap-10">
                    <input type="checkbox" (change)="set.functionality.color.isCheck=!set.functionality.color.isCheck"
                        id="color" class="form-check-input" [checked]="set.functionality.color.isCheck">
                    <label for="color" class="">
                            <app-color-picker [(color)]="set.functionality.color.value" [label]="'color'|translatePipe"></app-color-picker>
                    </label>
                </div>
            </div>

            <div class="divider"></div>

            <button (click)="toggleSlideSituation()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary mb-3">
                <i *ngIf="slideSituation==='out'" class="pe-7s-angle-up btn-icon-wrapper"></i>
                <i *ngIf="slideSituation==='in'" class="pe-7s-angle-down btn-icon-wrapper"></i>
                {{'advanceOptions' | translatePipe}}
            </button>

            <div [@slide-vertical]="slideSituation" class="">
                <app-check-box-label [(isCheck)]="set.advancedOptions.isOnlyModifiedByAdministrators"
                    [label]="'setAdvanceOptions.adminEditOnly'|translatePipe" name="modified">
                </app-check-box-label>
                <app-check-box-label [(isCheck)]="set.advancedOptions.isAppearsInRegistration"
                    [label]="'setAdvanceOptions.registrationSet'|translatePipe" name="information">
                </app-check-box-label>
                <app-check-box-label [(isCheck)]="set.advancedOptions.isRequired" [label]="'setAdvanceOptions.required'|translatePipe"
                    name="required">
                </app-check-box-label>
                <app-check-box-label [(isCheck)]="set.advancedOptions.isShowMemberList"
                [label]="'setAdvanceOptions.searchable'|translatePipe" name="isShowMemberList">
                </app-check-box-label>
                <app-check-box-label [(isCheck)]="set.advancedOptions.isVisibaleOnlyAdmin"
                [label]="'setAdvanceOptions.adminVisibleOnly'|translatePipe" name="visible">
                </app-check-box-label>
            </div>
        </form>
        <button [disabled]="isSubmitDisable" (click)="onSave()" class="btn btn-success">{{'save'|translatePipe}}</button>
    </div>
</div>