<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Basic
      </div>
      <div class="card-body">
        <button mat-raised-button (click)="isLinear = true" id="toggle-linear">Enable linear mode</button>
        <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper">
          <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>Fill out your name</ng-template>
              <mat-form-field>
                <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
              </mat-form-field>
              <div>
                <button mat-button matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
              <ng-template matStepLabel>Fill out your address</ng-template>
              <mat-form-field>
                <input matInput placeholder="Address" formControlName="secondCtrl" required>
              </mat-form-field>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            You are now done.
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button (click)="stepper.reset()">Reset</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
  <div class="col-md-6">

    <div class="card main-card mb-3">
      <div class="card-header">
        Horizontal Stepper
      </div>
      <div class="card-body">
        <mat-horizontal-stepper>
          <mat-step label="Step 1">
            Content 1
          </mat-step>
          <mat-step label="Step 1">
            Content 2
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>