import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-eccomerce',
  templateUrl: './organization-eccomerce.component.html',
  styleUrls: ['./organization-eccomerce.component.scss']
})
export class OrganizationEccomerceComponent implements OnInit {

  constructor() { }

  wooComerceUrl = 'https://barpahima.co.il/product-category/%d7%a9%d7%99%d7%a8%d7%95%d7%aa%d7%99%d7%9d-%d7%9e%d7%a7%d7%a6%d7%95%d7%a2%d7%99%d7%99%d7%9d/'


  ngOnInit(): void {
  }

}
