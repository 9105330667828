import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { ValidationResult } from 'src/app/interfaces/validation-result';
import { AuthService } from 'src/app/services/auth.service';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-register-club',
  templateUrl: './register-club.component.html',
  styleUrls: ['./register-club.component.scss']
})
export class RegisterClubComponent implements OnInit, OnChanges {
  @ViewChild('userNameInput') userNameInput!: any;


  private _isAcceptTermOfService = false;
  subscription: Subscription[] = [];
  organization: Group
  invitedBy: Member
  confirmationFormGroup: FormGroup;
  identificationFormGroup: FormGroup;
  authenticationFormGroup: FormGroup;
  passwordFormGroup: FormGroup;
  dataFormGroup: FormGroup;
  isHaveAuth = true;
  isInvition = false;
  verifyCode = '';
  backendVerifyCode = '12345'
  isPasswordValid = false
  password = ''
  setList = [];
  userSetMap = {};

  email = '';
  isEmailValid = false;

  userName = '';
  minChar = 3

  get isAcceptTermOfService() {
    return this._isAcceptTermOfService
  }

  set isAcceptTermOfService(value) {
    this._isAcceptTermOfService = value;
    if (value) this.confirmationFormGroup.updateValueAndValidity();
    else this.confirmationFormGroup.setErrors({ 'incorrect': true });

  }
  get isUserNameValid() {
    if (this.userNameInput?.formControler?.valid && this.setDataValid) this.dataFormGroup.updateValueAndValidity();
    else this.dataFormGroup.setErrors({ 'incorrect': true });
    return !!this.userNameInput?.formControler?.valid
  }

  get setDataValid() {
    const setValids = this.setList.map(set => this.userSetMap[set.id].isValid);
    return !setValids.some(isSetValid => !isSetValid)
  }

  constructor(
    private route: ActivatedRoute,
    public organizationalStructureService: OrganizationalStructureService,
    public setService: SetsService,
    private formBuilder: FormBuilder,
    private memberService: MembersService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.confirmationFormGroup = this.formBuilder.group({});
    this.identificationFormGroup = this.formBuilder.group({});
    this.authenticationFormGroup = this.formBuilder.group({});
    this.passwordFormGroup = this.formBuilder.group({});
    this.dataFormGroup = this.formBuilder.group({});

    this.confirmationFormGroup.setErrors({ 'incorrect': true });
    this.identificationFormGroup.setErrors({ 'incorrect': true });
    this.authenticationFormGroup.setErrors({ 'incorrect': true });
    this.passwordFormGroup.setErrors({ 'incorrect': true });
    this.dataFormGroup.setErrors({ 'incorrect': true });


    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const organizationId = params.get('id');
        if (organizationId) {
          this.organization = await this.organizationalStructureService.getGroupById(organizationId).toPromise()
          this.setService.loadSets();
        }
      })
    )

    this.subscription.push(
      this.route.queryParams.subscribe(async (params) => {
        console.log(params);
        if (params?.isInvited === 'true') {
          this.isInvition = true;
          if (params?.invitedBy) {
            this.invitedBy = await this.memberService.getMember(params?.invitedBy).toPromise();
            console.log(this.invitedBy);
          }
        }



      })
    )

    this.subscription.push(
      this.setService.set$.subscribe(sets => {
        this.setList = sets.filter(set => set.group.id === this.organization.id && set.advancedOptions.isAppearsInRegistration);
        this.userSetMap = {}
        this.setList.forEach(set => {
          this.userSetMap[set.id] = {
            data: set.default,
            isValid: false
          };
        })
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('asdasd');

    if (changes.isAcceptTermOfService) {
      console.log('asdsad');

    }

  }

  isValidChange(value) {
    this.isEmailValid = value
    if (value) this.identificationFormGroup.updateValueAndValidity();
    else this.identificationFormGroup.setErrors({ 'incorrect': true });
  }

  isPasswordValidChange(value) {
    this.isPasswordValid = value;
    if (value) this.passwordFormGroup.updateValueAndValidity();
    else this.passwordFormGroup.setErrors({ 'incorrect': true });
  }

  verifyCodeChange(value) {
    this.verifyCode = value;
    if (value === this.backendVerifyCode) this.authenticationFormGroup.updateValueAndValidity();
    else this.authenticationFormGroup.setErrors({ 'incorrect': true });
  }


  sendCode() {
    // if need auth send a code
  }



  chageIdentification() {
    this.email = '';
  }

}

