import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-manage-costumers',
  templateUrl: './manage-costumers.component.html',
  styleUrls: ['./manage-costumers.component.scss']
})
export class ManageCostumersComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  costumers = []
  search = ''

  constructor(
    private accountService: PortalAccountsService,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.portalAccounts$.subscribe(accounts => {
        const costumers = accounts.filter(account => account.type === 'customer')
        this.updateCostumers(costumers);
      })
    )
    this.accountService.loadPortalAccounts();

  }

  updateCostumers(costumers) {
    this.costumers = costumers.map(costumer => ({ ...costumer, isCheck: false }));
  }

  filterCostumers() {
    return this.costumers.filter(costumer => (
      costumer.data.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      costumer.contactInfo.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  deleteCostumer(costumer) {
    this.accountService.delete(costumer.id).toPromise();
  }

  getNumOfSelected() {
    return this.costumers.reduce((acc, employee) => {
      if (employee.isCheck) acc++
      return acc
    }, 0)
  }

  unselect() {
    this.updateCostumers(this.costumers)
  }

  deleteSelected() {
    const selected = this.costumers.filter(costumer => costumer.isCheck)
    selected.forEach(costumer => {
      this.deleteCostumer(costumer)
    })

  }

}
