import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationResult } from 'src/app/interfaces/validation-result';
import { AppAsyncValidators } from 'src/app/validators/async-validators';
import { AppSyncValidators } from 'src/app/validators/sync-validators';

@Component({
  selector: 'app-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss']
})
export class InputLabelComponent implements OnInit, OnDestroy, OnChanges, OnChanges{

  private _value;
  private _isValid;
  subscription: Subscription;
  isFocus = false;
  @Input() syncValidators?: string[] = [];
  @Input() asyncValidators?: string[] = [];
  @Input() required?: boolean = false;
  @Input() email?: boolean = false;
  @Input() invalid?: boolean = false;
  @Input() disabled?: boolean = false;
  @Input() min?: number = 0;
  @Input() max?: number;
  @Input() minChar?: number;
  @Input() maxChar?: number;
  @Input() removeErrorMsg= false
  @Input() label: string
  @Input() name: string
  @Input() type: string
  @Input() validCharacters: any[];
  @Output() valueChange = new EventEmitter<string | number>();
  @Output() isValidChange = new EventEmitter<boolean>();
  @Input() set value(val: string | number) {
    this._value = val;
    this.valueChange.emit(val);
  }
  get value() {
    return this._value;
  }

  @Input() set isValid(val: boolean) {
    this._isValid = val;
    this.isValidChange.emit(val);
  }
  get isValid() {
    return this._isValid;
  }

  constructor(
    public asyncValidatorsService: AppAsyncValidators,
    public syncValidatorsService: AppSyncValidators
  ) { }

 



  formControler: FormControl;

  ngOnInit(): void {
    let syncValidators = this.syncValidators.map(name => this.syncValidatorsService.validator[name])
    const asyncValidators = this.asyncValidators.map(name => this.asyncValidatorsService.validator[name])
    if (this.required) syncValidators.push(Validators.required)
    if (this.email) syncValidators.push(Validators.email)
    if (this.min) syncValidators.push(this.validators.minNumberValidator)
    if (this.max) syncValidators.push(this.validators.maxNumberValidator)
    if (this.minChar) syncValidators.push(this.validators.minCharValidator)
    if (this.maxChar) syncValidators.push(this.validators.maxCharValidator)
    if (this.validCharactersValidator) syncValidators.push(this.validators.validCharactersValidator)
    this.formControler = new FormControl(this.value, syncValidators, asyncValidators);
    this.subscription = this.formControler.valueChanges.subscribe(newVal => {
      this.value = newVal
      if (this.isValid !== undefined) {
        this.isValid = !!this.formControler?.valid
      }
    })

    if (this.isValid !== undefined) {
      this.isValid = !!this.formControler?.valid
    }

  }

  


  ngOnChanges(changes: SimpleChanges): void {
    this.formControler?.setValue(this.value);
    // invalid

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public minNumberValidator = (control: AbstractControl): ValidationResult => {

    if (this.min && control.value < this.min) {
      return { "minNumberValidator": true };
    }
    return null;
  }

  public maxNumberValidator = (control: AbstractControl): ValidationResult => {
    if (this.max && control.value > this.max) {
      return { "maxNumberValidator": true };
    }
    return null;
  }

  public minCharValidator = (control: AbstractControl): ValidationResult => {

    if (this.minChar && (control.value + '').length < this.minChar) {
      return { "minCharValidator": true };
    }
    return null;
  }

  public maxCharValidator = (control: AbstractControl): ValidationResult => {
    if (this.maxChar && (control.value + '').length > this.maxChar) {
      return { "maxNumberValidator": true };
    }
    return null;
  }

  public validCharactersValidator = (control: AbstractControl): ValidationResult => {
    if (!this.validCharacters) return null
    let validChars = '\n '
    if (this.validCharacters.some(item => item.string === 'English letters')) validChars += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    if (this.validCharacters.some(item => item.string === 'Hebrew Letters')) validChars += 'אבגדהוזחטיכלמנסעפצקרשת'
    if (this.validCharacters.some(item => item.string === 'Numbers')) validChars += '0123456789'
    if (this.validCharacters.some(item => item.string === 'Symbols')) validChars += '~`!@#$%^&*()_-+=""{}[]:;*//.,|\\\'';
    const validCharArray = validChars.split('');
    (control.value + '').split('').forEach(char => {
      if (!validCharArray.some(validChar => validChar === char)) return { "validCharactersValidator": true }
    })
    return null;
  }

  public validators = {
    minNumberValidator: this.minNumberValidator,
    maxNumberValidator: this.maxNumberValidator,
    minCharValidator: this.minCharValidator,
    maxCharValidator: this.maxCharValidator,
    validCharactersValidator: this.validCharactersValidator,
  }
}
