<div class="row">
    <div class="col-12 mb-3">
        <div class="main-card card">
            <div class="card-body">
                <div class="event-groups">
                    <h3 class="card-title">{{'groups'|translatePipe}}</h3>
                    <div class="groups">
                        <!-- <p class="group-option" [class.curr]="currGroup.id===group.id" *ngFor="let group of groups"
                            (click)="currGroup = group">
                            {{group.name==='all'?('all'|translatePipe):(group.name| titleCase)}}
                        </p> -->
                        <p *ngFor="let key of resultsKeys" [class.curr]="currKey===key" class="group-option" (click)="onChooseKey(key)">
                            {{key | titleCase}} ({{results[key].length}})
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-3">
        <div class="main-card card menu">
            <div class="card-body">
                <div *ngFor="let key of resultsKeys" [class.currKey]="currKey===key" (click)="onChooseKey(key)">
                    <p>{{key | titleCase}} ({{results[key].length}})</p>
                </div>
            </div>
        </div>
    </div> -->
    <div class="col-12">
        <div class="main-card card">
            <div class="card-body">
                <ng-container [ngSwitch]="currKey">
                    <div class="row">
                        <ng-container *ngSwitchCase="'members'">
                            <div *ngFor="let member of results.members" (click)="navigate(member.id)"
                                class="member-view col-6 col-sm-4 col-md-3 col-lg-2">
                                <app-member-view [member]="member"></app-member-view>

                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'objects'">
                            <div *ngFor="let object of results.objects" class="col-6 col-sm-4 col-md-3 col-xl-2">
                                <div (click)="navigate(object.id)">
                                    <app-group-object-view [group]="object"></app-group-object-view>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'cards'">
                            <div *ngFor="let card of results.cards" class="col-12 mb-3 col-sm-6 col-lg-4">
                                <div (click)="navigate(card.id)" class="card-view">
                                    <app-card-usage-preview (useCard)=" navigate(card.id)"
                                        [cardImg]="getCardImg(card.card.group.id)" [card]="card">
                                    </app-card-usage-preview>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>