import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-picker-server',
  templateUrl: './image-picker-server.component.html',
  styleUrls: ['./image-picker-server.component.scss']
})
export class ImagePickerServerComponent implements OnInit {

  
  
  @Input() disabled?= false;
  @Input() isCircle?= false;
  @Input() label?= 'logo'
  @Input() set value(val: Blob|MediaSource) {
    this._value = val;
    this.valueChange.emit(val);
  }
  @Output() valueChange = new EventEmitter<Blob|MediaSource>();
  get value() {
    return this._value;
  }
  private _value;
  isLoader = false;

  constructor(
    public domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  getImg() {
    if(this.disabled) return 'https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif'
    return this.value ? (URL.createObjectURL(this.value)) : 'assets/images/upload-img.png'
  }

 async uploadImg(ev: any) {
    this.value = ev.target.files[0]
  }

}
