<div dirControl class="main-card card mb-4">
    <div class="card-body">
        <h3 class="card-title">{{'local administrators'|translatePipe}}</h3>
        <div dir="ltr" class="flipped">
            <div class="tree-container">
                <ul class="tree">
                    <li tree [group]="group" (onSelectChild)="onChooseGroup($event)"></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="currGroup">
    <div dirControl class="main-card card mb-3 administrators">
        <div class="card-body">
            <div class="space-between">

                <h3 class="card-title">
                    {{
                    currGroup.type==='organization'?
                    currGroup.name+' '+('global administrators'|translatePipe):
                    currGroup.name+' '+('local moderators'|translatePipe)
                    }}
                </h3>
                <button (click)="open()" class="add"><i class="fa fa-fw" aria-hidden="true" title="add"></i></button>
            </div>

            <div class="row">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2" *ngFor="let member of moderators">
                    <app-toggle-member [member]="member" (remove)="openSureModal($event,true)"></app-toggle-member>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #addMember let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            Add
            {{
            currGroup.type==='organization'?
            'administrator':
            'moderator'
            }}
        </h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-dropdown-input [(text)]="administratorSearch" [dropDownOpions]="notModerators"
            (chooseOption)="openSureModal($event,fasle,close)">
        </app-dropdown-input>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

<ng-template #sureModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{isSureDelete?'Remove':'Give'}}
            {{
            currGroup.type==='organization'?
            'admin':
            'moderator'
            }}
            permission
        </h5>

        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <p>
            are you sure you want to
            {{isSureDelete? 'remove':'give'}}
            {{
            currGroup.type==='organization'?
            'admin':
            'moderator'
            }}
            permission to {{choosenMember.identifier}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" [class.btn-danger]="isSureDelete" [class.btn-primary]="!isSureDelete"
            (click)="onToggleModerator(close)">
            {{isSureDelete?'Remove':'Give'}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>