import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-managment',
  templateUrl: './module-managment.component.html',
  styleUrls: ['./module-managment.component.scss']
})
export class ModuleManagmentComponent implements OnInit {

  constructor() { }

  modulesMap = {
    sets: {
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-menu'
    },
    cards: {
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-id'
    },
    events: {
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-date'
    },
    chat:{
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-chat'
    },
    ecommerce:{
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-cart'
    },
    digitalWallet:{
      isInPlan: true,
      isActive: false,
      depends: ['eCommerce'],
      iconClass:'pe-7s-wallet'
    },
    socialNetwork:{
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-comment'
    },
    media:{
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-film'
    },
    notification:{
      isInPlan: false,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-bell'
    },
    hrPortal:{
      isInPlan: false,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-network'
    },
    customerService:{
      isInPlan: true,
      isActive: true,
      depends: [],
      iconClass:'pe-7s-shopbag'
    },
  }

  moduleKeys= Object.keys(this.modulesMap);

  ngOnInit(): void {
  }

}
