<div (clickOutside)="close()" (click)="isOpen=true" class="values-container mt-4 mb-4">
    <label [class.no-values]="!values.length">{{label | titleCase}}</label>
    <div class="values">
        <div *ngFor="let value of values" class="value">
            <span>{{value}}</span>
        </div>
    </div>
    <div (click)="denineClose()" [class.open]="isOpen" class="options-container">
        <ng-container *ngIf="isLoader; else elseTemplate">
            <div class="spiner-container">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>
        <ng-template #elseTemplate>

            <div class="options-values-container">
                <div (click)="removeValue(value)" *ngFor="let value of values" class="option-value">
                    <span>{{value}}</span>
                    <button><i class="pe-7s-close"></i></button>
                </div>
            </div>
            <app-input-label [name]="label" [(value)]="search" [label]="'create or find '+label"></app-input-label>
            <div class="options">
                <perfect-scrollbar style="height: 98px;" [config]="config">
                    <div (click)="optionClick(option)" *ngFor="let option of filterOptions" [class.delete-mode]="isEdit"
                        class="option">
                        <span>
                            {{option}}
                        </span>
                        <button *ngIf="isEdit"><i class="pe-7s-close"></i></button>
                    </div>
                </perfect-scrollbar>
            </div>
            <button (click)="addOption()" [disabled]="!isAddLabelValid" class="btn btn-primary col-12 mb-2">Add
                {{label}}</button>
            <button (click)="isEdit=!isEdit" class="btn btn-primary col-12">
                {{
                isEdit?'Done':'Delete '+label
                }}

            </button>

        </ng-template>


    </div>
</div>