<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Basic
      </div>
      <div class="card-body">
        <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next"></ngb-datepicker>
        <div class="divider"></div>
        <div class="text-center">
          <button class="btn me-2 btn-sm btn-primary" (click)="selectToday()">Select Today</button>
          <button class="btn me-2 btn-sm btn-info" (click)="dp.navigateTo()">To current month</button>
          <button class="btn btn-sm btn-danger" (click)="dp.navigateTo({year: 2013, month: 2})">To Feb 2013</button>
          <div class="divider"></div>
        </div>
        <pre>Month: {{ date.month }}.{{ date.year }}</pre>
        <pre>Model: {{ model | json }}</pre>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Dropdown Datepicker
      </div>
      <div class="card-body">
        <form class="form-inline">
          <div class="">
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                #d="ngbDatepicker">
              <button class="btn btn-primary" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </form>
        <div class="divider"></div>
        <pre>Model: {{ model | json }}</pre>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Multiple Months
      </div>
      <div class="card-body table-responsive">
        <ngb-datepicker [displayMonths]="displayMonths" [navigation]="navigation" [showWeekNumbers]="showWeekNumbers">
        </ngb-datepicker>
        <div class="divider"></div>
        <form class="form-inline">
          <div class="">
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [displayMonths]="displayMonths"
                [navigation]="navigation" [showWeekNumbers]="showWeekNumbers" ngbDatepicker #d2="ngbDatepicker">
              <button class="btn btn-primary" (click)="d2.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </form>
        <div class="divider"></div>
        <div class="row">
          <div class="col-md-4">
            <select class="form-select kt-input" [(ngModel)]="displayMonths">
              <option [ngValue]="1">One month</option>
              <option [ngValue]="2">Two months</option>
              <option [ngValue]="3">Three months</option>
            </select>
          </div>
          <div class="col-md-4">
            <select class="form-select kt-input" [(ngModel)]="navigation">
              <option value="none">Without navigation</option>
              <option value="select">With select boxes</option>
              <option value="arrows">Without select boxes</option>
            </select>
          </div>
          <div class="col-md-4">
            <select class="form-select kt-input" [(ngModel)]="showWeekNumbers">
              <option [ngValue]="true">Week numbers</option>
              <option [ngValue]="false">No week numbers</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Disabled Datepicker
      </div>
      <div class="card-body">
        <ngb-datepicker [(ngModel)]="model2" [disabled]="disabled"></ngb-datepicker>
        <div class="divider"></div>
        <div class="text-center">
          <button class="btn btn-sm btn-{{disabled ? 'danger' : 'success'}}" (click)="disabled = !disabled">
            {{ disabled ? "disabled" : "enabled"}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Range Selection
      </div>
      <div class="card-body table-responsive">
        <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
        <div class="divider"></div>
        <div class="row">
          <div class="col-md-4">
            <pre>From: {{ fromDate | json }} </pre>
          </div>
          <div class="col-md-4">
            <pre>To: {{ toDate | json }} </pre>
          </div>
        </div>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Footer Template
      </div>
      <div class="card-body">
        <form class="form-inline">
          <div class="">
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model3" ngbDatepicker
                [footerTemplate]="footerTemplate" #d3="ngbDatepicker">
              <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </form>
        <ng-template #footerTemplate>
          <hr class="my-0">
          <button class="btn btn-primary btn-sm m-2 float-start" (click)="model3 = today; d3.close()">Today</button>
          <button class="btn btn-secondary btn-sm m-2 float-end" (click)="d3.close()">Close</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>