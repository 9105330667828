import { Component, OnInit } from '@angular/core';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-support-b2c',
  templateUrl: './support-b2c.component.html',
  styleUrls: ['./support-b2c.component.scss'],
  animations: [
    slideVerticalAnimation
  ]

})
export class SupportB2cComponent implements OnInit {

  faStar = faStar;
  faBusinessTime = faBusinessTime;
  faCog = faCog;
  faCalendarAlt = faCalendarAlt;
  faSearch = faSearch;

  subscription: Subscription[] = []

  newMsgText = ''
  isSupportOpen = false;
  loggedInUserId = "";


  supportChat = [
    {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }, {
      id: 'chat101',
      from: {
        id: 'u101',
        identifier: 'omerAdam',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am omer adam the famous singer in israel'
    },
    {
      id: 'chat102',
      from: {
        id: 'u102',
        identifier: 'eyalGolan',
        profileImg: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg'
      },
      sendAt: 1648546886931,
      type: 'text',
      data: 'hi i am eyal golan the famous singer in israel'
    }
  ]


  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) this.loggedInUserId = auth.id
      })

    )
  }

  sendMsg(ev) {
    ev.preventDefault();
    //send msg
    this.newMsgText = '';
  }


}
