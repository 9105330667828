<div dirControl class="main-card card  total-sets-card">
    <div class="card-body">
        <h3 class="card-title text-center">{{'sets'|translatePipe}}</h3>

        <div class="tabs-btns mb-4">
            <button *ngFor="let groupSets of memberGroups" [class.curr]="groupSets.id===currGroupId"
                (click)="currGroupId= groupSets.id">
                {{groupSets.name}}
            </button>
        </div>
        <ng-container *ngFor="let set of setsMap[currGroupId]" >
            <app-set-input [set]="set" [(value)]="member.setMap[set.id]" ></app-set-input>
        </ng-container>
        
        <button (click)="updateSets()" class="btn btn-success">{{'save'|translatePipe}}</button>
    </div>
</div>