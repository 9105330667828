import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  loggedInUserId = ''
  member: Member = this.memberService.emptyMember
  groups: Group[] = [];
  subscription: Subscription[] = [];


  constructor(
    private memberService: MembersService,
    private router: Router,
    private organizationStructureService: OrganizationalStructureService,
    private store: Store<AppState>,
  ) { }

  async ngOnInit(): Promise<void> {

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
        }
      })
    )

    this.member = await this.memberService.getMember(this.loggedInUserId).toPromise();
    const allGroups = await this.organizationStructureService.getGroups().toPromise()
    const organization = allGroups.find(group => group.type === 'organization');
    if (organization && organization.members.find(memberId => memberId === this.loggedInUserId)) {
      this.groups = [organization]
    }else{
      // array of all the groups/department that not sun of other department/group
    }

   
  }

  onChooseGroup(groupId) {
    this.router.navigate(['group-view', groupId]);
  }

}
