import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription } from 'rxjs';
import slideVertical from 'src/app/animations/slide-vertical.animation';
import { Set } from 'src/app/interfaces/set';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-set-view',
  templateUrl: './set-view.component.html',
  styleUrls: ['./set-view.component.scss'],
  animations: [
    slideVertical
  ]
})
export class SetViewComponent implements OnInit {

  @ViewChild('title') title!: any;

  groupOptions = [];
  set: Set = this.setService.getEmptySet();
  subscription: Subscription[] = [];
  slideSituation = "in"
  characters: Array<any> = [
    { id: 'c101', string: 'English letters' },
    { id: 'c102', string: 'Hebrew Letters' },
    { id: 'c103', string: 'Numbers' },
    { id: 'c104', string: 'Symbols' },
  ];
  charactersForm: FormGroup;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableCheckAll: true,
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  get isSubmitDisable() {
    return !this.title?.formControler?.valid;
  }



  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalVariablesService,
    private setService: SetsService,
    private organizationalStructureService: OrganizationalStructureService,
    private fb: FormBuilder,
    private location: Location
  ) { }

  ngOnInit(): void {

    this.charactersForm = this.fb.group({
      characters: [[]]
    });


    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const id = params.get('id');
        if (id) {
          this.set = await this.setService.getSet(id).toPromise();
          if (this.set.functionality.characters) {
            const formCharacters = this.set.functionality.characters.validCharacters.map(validCharacter => {
              return this.characters.find(character => character.item_text === validCharacter)
            })
            this.charactersForm = this.fb.group({
              characters: [formCharacters]
            });
          }
        } else {
          const propertyName = params.get('property');
          if (propertyName) {
            const property = this.globalService.properties.find(property => property.name === propertyName);
            this.set.property = property
            this.set.default = this.set.property.default
            switch (property.name) {
              case 'number':
                this.set.functionality.min = {
                  isCheck: false,
                  number: 0
                }
                this.set.functionality.max = {
                  isCheck: false,
                  number: 100
                }
                this.set.functionality.thousandCommas = {
                  isCheck: false,
                }
                this.set.functionality.symbol = {
                  isCheck: false,
                  value: '$',
                  isLeft: true,
                }
                break;
              case 'text':
              case 'longText':
                this.set.functionality.minChar = {
                  isCheck: false,
                  number: 0
                }
                this.set.functionality.maxChar = {
                  isCheck: false,
                  number: 100
                }
                this.set.functionality.characters = {
                  isCheck: false,
                  validCharacters: []
                }
                break;
              case 'date':
                this.set.functionality.showDay = {
                  isCheck: false,
                }
                this.set.functionality.timePicker = {
                  isCheck: false,
                  defaultTime: { hour: 8, minute: 0 }
                }
                this.set.functionality.formatDate = {
                  isCheck: false,
                  format: 'd/m/yyyy'
                }
                break;
              case 'time':
                this.set.functionality.timeType = {
                  isCheck: false,
                  value: '24H'
                }
                break;
              case 'check':
                this.set.functionality.color = {
                  isCheck: false,
                  value: '#000000'
                }
                break;
            }
          }
        }
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.groupOptions = organizationalStructure.map(group => ({
          name: group.name,
          id: group.id,
          type: group.type
        }))
        if (!this.set.group?.id) this.set.group = this.groupOptions.find(group => group.type === 'organization')
        else this.set.group = this.groupOptions.find(group => group.id === this.set.group.id);
      })
    )
    this.organizationalStructureService.loadOrganizationalStructure();
  }

  toggleSlideSituation() {
    this.slideSituation = this.slideSituation === 'in' ? 'out' : 'in'
  }

  onSave() {
    if (this.set.functionality.timePicker) {
      delete this.set.functionality.timePicker.defaultTime.second
    }
    if (this.set.property.type === 'time') {
      delete this.set.default.second
    }

    if (!this.set.id) this.setService.addSet(this.set).toPromise();
    else this.setService.editSet(this.set).toPromise();
    this.location.back()
  }
}
