import { Action } from '@ngrx/store';

export enum ActionTypes {
    setOrganization = 'setOrganization',
}



export class setOrganization implements Action {
    readonly type = ActionTypes.setOrganization;
    constructor(
        public id: string,
        public title: string
    ) { }
}
