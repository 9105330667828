import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Set } from 'src/app/interfaces/set';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-set-management',
  templateUrl: './set-management.component.html',
  styleUrls: ['./set-management.component.scss']
})
export class SetManagementComponent implements OnInit {

  subscription: Subscription[] = []
  sets: Set[] = [];
  search = '';

  constructor(
    private setService: SetsService
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.setService.set$.subscribe(sets => {
        this.updateSets(sets)
      })
    )
    this.setService.loadSets();
  }

  updateSets(sets: Set[]) {
    this.sets = sets.map(set => ({ ...set, isCheck: false }));
  }

  filterSets() {
    return this.sets.filter(set => (
      set.title.toLowerCase().includes(this.search.toLowerCase()) ||
      set.property.type.toLowerCase().includes(this.search.toLowerCase()) ||
      set.group.name.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

}
