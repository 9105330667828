import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-landing-page',
  templateUrl: './organization-landing-page.component.html',
  styleUrls: ['./organization-landing-page.component.scss']
})
export class OrganizationLandingPageComponent implements OnInit {

  landingPageUrl="https://www.youtube.com/embed?listType=playlist&list=UUa3CTPhyGlCa5b7bqcK0JgA"


  constructor() { }

  ngOnInit(): void {
  }

}
