import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from '../interfaces/group';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationalStructureService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }
  // private BASE_URL = '/api/organizationalStructure'
  private BASE_URL = 'https://api.flexyc.com/organizationalStructure'
  public emptyGroup: Group = {
    id: '', type: 'group', name: '', description: '', logoUrl: '', parent: "", members: [], children: [], chatSettings: {
      isAllowChat: false, lastActivity: Date.now()
    }
  }
  private _organizationalStructure$ = new BehaviorSubject<Group[]>([])
  public organizationalStructure$ = this._organizationalStructure$.asObservable();

  public loadOrganizationalStructure() {
    this.http.get<Group[]>(this.BASE_URL)
      .subscribe(organizationalStructure => {
        this._organizationalStructure$.next(organizationalStructure);
      })
  }

  public getGroups() {
    return this.http.get<Group[]>(this.BASE_URL)
  }

  public getGroupById(id: string) {
    return this.http.get<Group>(`${this.BASE_URL}/${id}`)
  }

  public getOrganizationalStructure() {
    return this._organizationalStructure$.getValue();
  }

  public async addGroup(idParent: string, name?: string, description?: string) {
    const parent = await this.getGroupById(idParent).toPromise()
    const child = {
      name: parent.type === 'organization' ? 'New Department' : 'New Group',
      type: parent.type === 'organization' ? 'department' : 'group',
      parent: parent.id,
      description: '',
      logoUrl: '',
      members: [],
      children: [],
      chatSettings: {
        isAllowChat: false,
        lastActivity: Date.now()
      }
    }
    return this.http.post<Group>(this.BASE_URL, child).pipe(
      map(fullChild => {
        const newParent: Group = {
          ...parent,
          children: [...parent.children, fullChild.id]
        }
        this._updateGroup(newParent).toPromise()
        return fullChild
      })
    )
  }

  public async removeGroup(id: string) {
    const removedGroup = await this.getGroupById(id).toPromise();
    const parent = await this.getGroupById(removedGroup.parent).toPromise();
    const updateParent: Group = {
      ...parent,
      children: parent.children.filter(child => child !== id)
    }
    await this._updateGroup(updateParent).toPromise()
    return this.http.delete<Group>(`${this.BASE_URL}/${removedGroup.id}`)
  }

  public async updateGroupById(id: string, data: any) {
    const beforeChanges = await this.getGroupById(id).toPromise();
    const { name, description, logoUrl } = data
    const afterChanges: Group = {
      ...beforeChanges,
      logoUrl,
      name,
      description
    }
    return this._updateGroup(afterChanges)

  }

  public async addGroupMember(groupId: string, memberId: string) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      members: [...beforeChanges.members, memberId]
    }
    return this._updateGroup(afterChanges);
  }

  public async removeGroupMember(groupId: string, memberId: string) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      members: beforeChanges.members.filter(member => member !== memberId)
    }
    return this._updateGroup(afterChanges);
  }

  public async toggleGroupIsAllowChat(groupId: string) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      chatSettings: {
        ...beforeChanges.chatSettings,
        isAllowChat: !beforeChanges.chatSettings.isAllowChat,
      }
    }
    return this._updateGroup(afterChanges);
  }

  public async removeGroupMembers(groupId: string, memberIds: string[]) {
    const beforeChanges = await this.getGroupById(groupId).toPromise();
    const afterChanges: Group = {
      ...beforeChanges,
      members: beforeChanges.members.filter(member => !memberIds.some(memberId => memberId === member))
    }
    return this._updateGroup(afterChanges)
  }

  private _updateGroup(group: Group) {
    return this.http.put<Group>(`${this.BASE_URL}/${group.id}`, group).pipe(
      map(result => {
        this.loadOrganizationalStructure();
        console.log(group);
        return group
      })
    )
  }
}
