import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { is } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],
})
export class SystemSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('generalInformation') generalInformation!: any;
  isAfterViewInit = false;
  group: Group = this.organizationalStructureService.emptyGroup
  subscriotion!: Subscription
  isPublic = true;
  isEnableFreeze = false;
  isRequireLeave = false;
  contacts = [
    {
      type: "whatsapp",
      text: "054-5454545"
    },
    {
      type: "email",
      text: "israelIsraeli@gmail.com"
    },
    {
      type: "phone",
      text: "054-5454545"
    },
  ];

  get isSubmitDisable() {
    const isNameValid = this.generalInformation?.nameInput?.formControler?.valid
    return this.isAfterViewInit  && !isNameValid
  }

  constructor(
    private organizationalStructureService: OrganizationalStructureService,
  ) { }

  ngOnInit(): void {
    this.organizationalStructureService.loadOrganizationalStructure();
    this.subscriotion = this.organizationalStructureService.organizationalStructure$.subscribe((organizationalStructure: Group[]) => {
      this.group = organizationalStructure[0];
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isAfterViewInit = true
    }, 1000);
  }

  getIcon(type: string) {
    switch (type) {
      case 'whatsapp':
        return ''
      case 'phone':
        return ''
      case 'email':
        return ''
      default:
        break;
    }
  }

  getText(type: string) {
    switch (type) {
      case 'whatsapp':
        return 'WhatsApp'
      case 'phone':
        return 'Phone'
      case 'email':
        return 'Email'
      default:
        break;
    }
  }

  removeContact(idx: number) {
    this.contacts = this.contacts.filter((contact, contactIdx) => contactIdx !== idx)
  }

  addContact(type: string) {
    this.contacts = [...this.contacts, { type, text: '' }]
  }
}
