import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Event } from '../interfaces/event';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private BASE_URL = 'https://api.flexyc.com/events';
  private _event$ = new BehaviorSubject<Event[]>([])
  public event$ = this._event$.asObservable();
  public emptyEvent: Event = {
    info: {
      group: {
        id: '',
        type: '',
        name: ''
      },
      timestamp: Date.now(),
      title: '',
      time: {
        hour: 0,
        minute: 0,
        second: 0
      },
      description: '',
      length: {
        unit: 'hours',
        amount: 1
      },
      image: {
        location: 'above',
        url: ''
      }
    },
    invites: {
      members: [],
      guests: []
    },
    id: ''
  }

  constructor(private http: HttpClient) { }

  public loadEvents() {
    this.http.get<Event[]>(this.BASE_URL)
      .subscribe(events => {
        this._event$.next(events);
      })
  }

  public addEvent(event: Event) {
    return this.http.post<Event>(this.BASE_URL, event).pipe(
      map(event => {
        this.loadEvents();
        return event
      })
    )
  }

  public editEvent(event: Event) {
    return this.http.put<Event>(`${this.BASE_URL}/${event.id}`, event).pipe(
      map(event => {
        this.loadEvents();
        return event
      })
    )
  }

  public getEvent(id) {
    return this.http.get<Event>(`${this.BASE_URL}/${id}`)
  }

  public deleteEvent(id: string) {
    return this.http.delete<Event>(`${this.BASE_URL}/${id}`).pipe(
      map(_ => {
        this.loadEvents();
      })
    )
  }

  public async addInvitesMemberToEvent(member, eventId) {
    const event = await this.getEvent(eventId).toPromise();
    const afterChanges: Event = {
      ...event,
      invites: {
        ...event.invites,
        members: [...event.invites.members, member]
      }
    }
    this.editEvent(afterChanges).toPromise()
  }

  public async removeInvitesMemberToEvent(member, eventId) {
    console.log(member, eventId);

    const event = await this.getEvent(eventId).toPromise();
    console.log(event);

    const afterChanges: Event = {
      ...event,
      invites: {
        ...event.invites
      }
    }
    if (member.type === 'guest') afterChanges.invites.guests = afterChanges.invites.guests.filter(guest => guest.email !== member.email)
    else afterChanges.invites.members = afterChanges.invites.members.filter(inviteMember => inviteMember.id !== member.id)
    this.editEvent(afterChanges).toPromise()
  }
}
