<div dirControl class="row mb-3">
    <div class="col-12">
        <h3 class="card-title text-center mt-4">{{'total'|translatePipe}} {{sets.length}} {{'sets'|translatePipe}}</h3>
    </div>
    <div class="col-12">
        <div class=" card">
            <div class="card-body">
                <form class="mb-3">
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-set-management-table [sets]="filterSets()"></app-set-management-table>