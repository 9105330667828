import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-user-control-pannel',
  templateUrl: './user-control-pannel.component.html',
  styleUrls: ['./user-control-pannel.component.scss']
})
export class UserControlPannelComponent implements OnInit {

  @ViewChild('userNameInput') userNameInput!: any;


  loggedInUserId = ''
  member = this.memberService.emptyMember
  subscription: Subscription[] = [];
  currPassword = '';
  isCurrPasswordValid = false;
  newEmail = '';
  isNewEmailValid = false;
  newPassword: ''
  isNewPasswordValid = false;
  isVerifyCodeOpen=false;
  verifyCode=''


  newUserName = '';
  get isnewUserNameValid() {
    return this.userNameInput?.formControler?.valid
  }

  constructor(
    private memberService: MembersService,
    private store: Store<AppState>,
  ) { }


  async ngOnInit(): Promise<void> {

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
        }
      })
    )

    const member = await this.memberService.getMember(this.loggedInUserId).toPromise();
    this.member = member;
    this.newUserName = member.identifier;
    this.newEmail = member.email;
  }

  onSave(){
    if(!this.isCurrPasswordValid) return;
    //send valid craddential 
    this.isVerifyCodeOpen=true;
  }

  sendCode(){
    console.log('SEND');
    
  }





}
