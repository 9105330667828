import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {i18nConfig, SomeConfig} from "./shared";
import {TextDirectionByLocaleDirective} from "./text-direction.directive";
import {TranslatePipe} from './translate.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    TextDirectionByLocaleDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TranslatePipe,
    TextDirectionByLocaleDirective
  ]
})
export class OtechI18nModule {
  static forRoot(config: SomeConfig): ModuleWithProviders<OtechI18nModule> {
    return {
      ngModule: OtechI18nModule,
      providers: [
        {provide: i18nConfig, useValue: config}
      ]
    };
  }

}
