import { Component, OnInit } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';
import { AppState } from 'src/app/store/app.store';
import { ThemeOptions } from '../../../../../theme-options';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  faCalendar = faCalendar;
  subscription: Subscription[] = []
  loggedInMember = this.memberService.emptyMember

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    public globals: ThemeOptions,
    private store: Store<AppState>,
    private memberService: MembersService
  ) { }

  ngOnInit() {

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInMember = await this.memberService.getMember(auth.id).toPromise();
        }
      })
    )
  }

}
