import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Set } from 'src/app/interfaces/set';

@Component({
  selector: 'app-set-input',
  templateUrl: './set-input.component.html',
  styleUrls: ['./set-input.component.scss']
})
export class SetInputComponent implements OnInit {

  private _value;
  private _isValid;
  @Input() set: Set
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<any>();
  @Input() set value(val: any) {
    this._value = val;
    this.valueChange.emit(val);
  }
  @Input() set isValid(val: boolean) {
    this._isValid = val;
    this.isValidChange.emit(val);
  }
  get isValid() {
    return this._isValid;
  }
  get value() {
    return this._value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
