import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-forgot-password-organization',
  templateUrl: './forgot-password-organization.component.html',
  styleUrls: ['./forgot-password-organization.component.scss']
})
export class ForgotPasswordOrganizationComponent implements OnInit {

  subscription: Subscription[] = [];
  organization: Group

  email = ''
  isEmailValid = false;

  constructor(
    public organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const organizationId = params.get('id');
        if (organizationId) {
          this.organization = await this.organizationalStructureService.getGroupById(organizationId).toPromise()
        }
      })
    )
  }

}
