<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" [src]="loggedInMember.profileImg" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>

              <!-- logout -->
              <ul class="nav flex-column">
                <li class="nav-item-header nav-item">My Account</li>
                <li class="nav-item"><a [routerLink]="" class="nav-link">Logout
                  </a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="widget-content-left ms-3 header-user-info">
          <div class="widget-heading">{{loggedInMember.identifier}}</div>
        </div>
        <div class="widget-content-right header-user-info ms-3">
          <!-- <button type="button" class="btn btn-shadow p-1 btn-info btn-sm" placement="bottom"
            ngbTooltip="Tooltip Example!">
            <fa-icon [icon]="faCalendar" class="me-1 ms-1"></fa-icon>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="header-btn-lg">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()">
      <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
  </div> -->
</div>