<div class="color-picker">
    <input [id]="name" [name]="name" type="color" [(ngModel)]="color">
    <div *ngIf="label">
        <label [for]="name">{{label}}</label>
    </div>
    <!-- <div class="color-picker col-9"> -->
    <!-- <label [for]="name">
            <i #icon class="fa fa-fw" aria-hidden="true" title="color"></i>
        </label> -->
    <!-- </div> -->
</div>