<div class="organizational-structure">
    <app-description-and-legend (toggleView)="toggleView()"></app-description-and-legend> 
    <div class="main-card card mb-3">
        <div class="card-body">
            <div *ngIf="isViewA; else viewB" class="view-a"> 
                <!-- view a tree view -->
                <app-tree-container [isShowButtons]="true" (onSetCurrAction)="onSetCurrAction($event)" [action]="currAction"
                    [group]="organizationalStructure[0]" (onSelectChild)="onSelectGroupChild($event)">
                </app-tree-container>
            </div>
        </div>
    </div>
    <ng-template #viewB>
            <!-- view b table view -->
        <app-table-container (onSetCurrAction)="onSetCurrAction($event)"
            (onSelectGroupChild)="onSelectGroupChild($event)"
            [group]="organizationalStructure[0]"></app-table-container>
    </ng-template>

    <div modal #modal title="Delete organization group/department"
        info="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam molestiae aliquam voluptates esse totam aliquid 
    nesciunt recusandae eveniet ut fugit ipsum mollitia officiis facere sed ipsam, praesentium nulla, numquam reprehenderit?"
        (accept)="onDeleteOrganizationalStructureChild($event)"></div>


</div>