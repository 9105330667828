import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CloudinaryService } from 'src/app/services/cloudinary.service';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {


  @Input() disabled?= false;
  @Input() isCircle?= false;
  @Input() label?= 'logo'
  @Input() set value(val: string) {
    this._value = val;
    this.valueChange.emit(val);
  }
  @Output() valueChange = new EventEmitter<string>();
  get value() {
    return this._value;
  }
  private _value;
  isLoader = false;

  constructor(
    public domSanitizer: DomSanitizer,
    private cludinaryService: CloudinaryService
  ) { }

  ngOnInit(): void {
  }

  getImg() {
    if(this.disabled) return 'https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif'
    return this.value || 'assets/images/upload-img.png'
  }

  async uploadImg(ev: any) {
    this.isLoader = true
    try {
      const newImg = await this.cludinaryService.uploadImg(ev);
      this.value = newImg
      this.isLoader = false
    } catch (err) {
    }
  }

}
