import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CalendarEvent, CalendarEventAction, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth, startOfDay } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { Group } from 'src/app/interfaces/group';
import { EventsService } from 'src/app/services/events.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store/app.store';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  green: {
    primary: '#3ac47d',
    secondary: '#58cd91'
  }
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  subscription: Subscription[] = [];
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [];

  refresh: Subject<any> = new Subject();
  events: any[] = [];
  activeDayIsOpen = false;
  groupId = ''
  memberGroups: Group[] = [];
  memberGroupColorMap: any = {};
  loggedInUserId = ''

  constructor(
    private modal: NgbModal,
    private eventService: EventsService,
    private route: ActivatedRoute,
    private organizationStructureService: OrganizationalStructureService,
    private utilService: UtilService,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const groupId = params.get('id');
        if (groupId) {
          this.groupId = groupId
          this.eventService.loadEvents();
        } else {
          // take member groups
          this.organizationStructureService.loadOrganizationalStructure();
        }
      })
    )

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
          this.organizationStructureService.loadOrganizationalStructure();
        }
      })

    )

    this.subscription.push(
      this.eventService.event$.subscribe(events => {
        let filteredEvents = []
        if (this.groupId) filteredEvents = events.filter(event => event.info.group.id === this.groupId);
        else filteredEvents = events.filter(event => this.memberGroups.some(group => group.id === event.info.group.id));
        const calanderEvents = filteredEvents.map(event => {
          const { hour, minute } = event.info.time
          const startTimeInMs = hour * 60 * 60 * 1000 + minute * 60 * 1000
          const { unit, amount } = event.info.length;
          const lengthInMs = unit === 'minutes' ? amount * 1000 * 60 : amount * 1000 * 60 * 60
          let color = colors.red;
          if (this.groupId) {
            switch (event.info.group.type) {
              case 'department':
                color = colors.yellow
                break;
              case 'group':
                color = colors.green
                break;
            }
          } else {
            color = this.memberGroupColorMap[event.info.group.id]
            console.log(this.memberGroupColorMap[event.info.group.id]);

          }
          return {
            start: new Date(startOfDay(new Date(event.info.timestamp)).getTime() + startTimeInMs),
            end: new Date(startOfDay(new Date(event.info.timestamp)).getTime() + startTimeInMs + lengthInMs),
            title: event.info.title,
            color: color,
            actions: this.actions,
            allDay: false,
            resizable: {
              beforeStart: false,
              afterEnd: false
            },
            draggable: false,
            id: event.id,
            description: event.info.description,
            groupId: event.info.group.id,
            image: event.info.image
          }
        })
        this.events = calanderEvents
      })
    )

    this.subscription.push(
      this.organizationStructureService.organizationalStructure$.subscribe(groups => {
        this.memberGroups=[]
        groups.forEach(group => {
          if (group.members.some(memberId => memberId === this.loggedInUserId)) {
            this.memberGroups.push(group)
            const primary = this.utilService.generateColor()
            this.memberGroupColorMap[group.id] = {
              primary: primary,
              secondary: primary + '88'
            }
          }
        })
        this.eventService.loadEvents();
      })
    )
  }

  onChangeColor(color, groupId) {
    this.memberGroupColorMap[groupId].primary = color;
    this.memberGroupColorMap[groupId].secondary = color + '88';

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

}
