<!-- <div class="input-container form-group mb-4">
    <label for="group">{{label | titleCase}}</label>

    <div class="row">
        <div class="col-11">
            <select [disabled]="disabled" id="group" [(ngModel)]="currGroup" class="form-control form-select"
                name="group">
                <option *ngFor="let group of groups" [ngValue]="group">{{group.type | titleCase}}: {{group.name}}
                </option>
            </select>
        </div>
        <div class="col-1">
            <app-group-picker [disabled]="disabled" (onSelectChild)="onSelectChild($event)"></app-group-picker>
        </div>
    </div>
</div> -->

<div class="input-container flexyc-input form-group mb-4">
    <label for="group">{{label | translatePipe | titleCase}}</label>

    <div class="group-picker-input">
        <div class="select-container">
            <select [disabled]="disabled" id="group" [(ngModel)]="currGroup" class="form-control form-select"
                name="group">
                <option *ngFor="let group of groups" [ngValue]="group">{{group.type |translatePipe | titleCase}}:
                    {{group.name}}
                </option>
            </select>
        </div>
        <app-group-picker [disabled]="disabled" (onSelectChild)="onSelectChild($event)"></app-group-picker>
    </div>
</div>