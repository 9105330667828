import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TriggerService } from 'src/app/services/trigger.service';

@Component({
  selector: 'app-trigger-managment',
  templateUrl: './trigger-managment.component.html',
  styleUrls: ['./trigger-managment.component.scss']
})
export class TriggerManagmentComponent implements OnInit {

  triggers = [];
  search = '';
  subscriotion: Subscription[] = []
  actionsOptions=['remove']

  constructor(
    private triggerService: TriggerService
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.triggerService.triggers$.subscribe(triggers => {
        this.updateTriggers(triggers);
      })
    )
    this.triggerService.loadTriggers();
  }

  updateTriggers(triggers) {
    this.triggers = triggers.map(trigger => ({ ...trigger, isCheck: false }));
  }

  getNumOfSelected() {
    return this.triggers.reduce((acc, trigger) => {
      if (trigger.isCheck) acc++
      return acc
    }, 0)
  }

  filterTriggers() {
    return this.triggers.filter(trigger => (
      trigger.title.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  removeTrigger(trigger){
    this.triggerService.removeTrigger(trigger.id).toPromise();
  }

}
