import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  @ViewChild('description') description;
  @Input() account;
  @Input() accountEvents;

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  openDescription() {
    this.modalService.open(this.description).result.then((result) => {
    }, (reason) => {
    });
  }

}
