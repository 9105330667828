import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCaseToSpaces'
})
export class TitleCaseToSpacesPipe implements PipeTransform {

  transform(value: string): string {
    const capitalLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    const charArr = value.split('');
    const withSpaces = charArr.reduce((acc, char, idx) => {
      if (idx === 0) acc = char;
      else if (capitalLetters.some(letter => letter === char)) acc = acc + ' ' + char.toLowerCase();
      else acc = acc + char
      return acc
    }, '')
    return withSpaces
  }
}
