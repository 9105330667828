import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { i18nConfig, SomeConfig } from "./shared";

@Injectable({
  providedIn: 'root'
})
export class OtechI18nService {
  keys: SomeConfig;
  private _onLocaleChange$ = new BehaviorSubject<boolean>(true);
  public onLocaleChange$ = this._onLocaleChange$.asObservable().pipe(
    share()
  );

  public dir$ = this.onLocaleChange$.pipe(
    map(_ => {
      return this.locale == 'he' ? 'rtl' : 'ltr'
    }),
    share()
  );

  set locale(val: string) {
    localStorage.setItem('_locale', val);
    this._onLocaleChange$.next(false);
  }

  get locale() {
    return localStorage.getItem('_locale') ?? 'en'
  }

  loadLocale() {
    this._onLocaleChange$.next(false);
  }




  constructor(private injector: Injector) {
    this.keys = injector.get(i18nConfig);
    if ('en' in this.keys) {
    }
    if ('he' in this.keys) {
    }
    if (this.locale === '')
      this.locale = 'he';
  }


  getTranslated(key: string) {
    const values = key.split('.');
    let curr: any = this.keys[this.locale] ?? {};
    for (const part of values) {
      curr = curr[part];
      if (curr === undefined) {
        break;
      }
    }
    return curr ?? key;
  }
}
