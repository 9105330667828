import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountServerService } from 'src/app/services/account-server.service';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-manage-candidate-server',
  templateUrl: './manage-candidate-server.component.html',
  styleUrls: ['./manage-candidate-server.component.scss']
})
export class ManageCandidateServerComponent implements OnInit {

  subscriotion: Subscription[] = []
  options = ['delete']
  candidates = []
  search = ''

  constructor(
    private accountService: AccountServerService,
    private globalVariableService:GlobalVariablesService
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.accountService.accounts$.subscribe(accounts => {
        this.updateCandidates(accounts);
      })
    )
    this.accountService.loadAccounts('Candidate');
  }

  updateCandidates(candidates) {
    this.candidates = candidates.map(candidates => ({ ...candidates,type:"Candidate", isCheck: false }));
  }

  filterCandidates() {
    return this.candidates.filter(candidate => (
      candidate.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
      candidate.member?.identifier?.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  async deleteCandidates(candidate) {
    await this.accountService.delete(this.globalVariableService.AccountTypes["Candidate"],[candidate]).toPromise();
    this.accountService.loadAccounts('Candidate');
    // this.accountService.delete(candidate.id).toPromise();
  }

  getNumOfSelected() {
    return this.candidates.reduce((acc, candidate) => {
      if (candidate.isCheck) acc++
      return acc
    }, 0)
  }

  unselect() {
    this.updateCandidates(this.candidates)
  }

  deleteSelected() {
    const selected = this.candidates.filter(candidate => candidate.isCheck)
    selected.forEach(candidate => {
      this.deleteCandidates(candidate)
    })
  }
}
