import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-usage-preview-use-in-limit',
  templateUrl: './card-usage-preview-use-in-limit.component.html',
  styleUrls: ['./card-usage-preview-use-in-limit.component.scss']
})
export class CardUsagePreviewUseInLimitComponent implements OnInit {

  @Input() limit: number
  @Input() used: number
  limitArr : any[] = []
  progressVal:number = 0;
  progressType = "danger"

  constructor() { }

  ngOnInit(): void {
    this.limit = +this.limit
    this.used = +this.used
    this.limitArr = Array(this.limit).fill('a');
    this.progressVal = this.used/this.limit*100;
    if(this.progressVal> 33 && this.progressVal <= 66) this.progressType = 'warning'
    if(this.progressVal > 66) this.progressType = 'success'
  }

}
