import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  @ViewChild('icon') elIcon!: ElementRef;

  private _color
  @Input() label?: string;
  @Input() name?: string = 'color';
  @Output() colorChange = new EventEmitter<string>();
  @Input() set color(val: string) {
    if (this.elIcon) {
      this.elIcon.nativeElement.style.color = val;
      this.elIcon.nativeElement.style.background = this.utilService.invertColor(val);
    }
    this._color = val;
    this.colorChange.emit(val);
  }
  get color() {
    if (this.elIcon) {
      this.elIcon.nativeElement.style.color = this._color;
      this.elIcon.nativeElement.style.background = this.utilService.invertColor(this._color);
    }
    return this._color;
  }

  constructor(
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
  }





}
