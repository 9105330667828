import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampToTime'
})
export class TimestampToTimePipe implements PipeTransform {

  transform(value: number): string {
    const date = new Date(value)
    let minutes = date.getMinutes()
    const minutesString = minutes > 9 ? '' + minutes : '0' + minutes;
    return `${date.getHours()}:${minutesString}`
  }
}
