<ng-template #popup let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">This page on building</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum suscipit soluta, ullam quibusdam non dolorem
        dolorum voluptates placeat ab recusandae ratione explicabo? Minus voluptatibus dignissimos in eaque qui nulla
        amet?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            Return
        </button>
    </div>
</ng-template>