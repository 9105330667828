<!-- <div class="form-group mb-4 input-container">
    <label [class.input-empty]="!input.value" [for]="name">{{label}}</label>
    <input #input [type]="type" [id]="name" [(ngModel)]="value" [name]="name" class="form-control">
</div> -->

<div class="form-group flexyc-input mb-4 input-container">
    <form>
        <span *ngIf="required">*</span>
        <label [class.disabled]="disabled" [class.input-empty]="value === ''  && !isFocus" [for]="name">{{label |
            titleCase}}</label>
        <ng-container *ngIf="disabled; else elseTemplate">
            <input [ngClass]="{withoutError:removeErrorMsg}"  (focus)="isFocus = true" (blur)="isFocus= false" [min]="min" [max]="max"
                disabled autocomplete="off" [type]="type" [id]="name" [formControl]="formControler" [name]="name"
                class="form-control">
        </ng-container>
        <ng-template #elseTemplate>
            <input  [ngClass]="{withoutError:removeErrorMsg}" (focus)="isFocus = true" (blur)="isFocus= false" [min]="min" [max]="max" autocomplete="off"
                [type]="type" [id]="name" [formControl]="formControler" [name]="name" class="form-control">
        </ng-template>
    </form>
</div>