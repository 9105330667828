<div dirControl class="card calendar">
    <div class="card-body">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        {{'previous'|translatePipe}}
                    </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                        {{'today'|translatePipe}}
                    </div>
                    <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        {{'next'|translatePipe}}
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>

            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                        [class.active]="view === CalendarView.Month">
                        {{'month'|translatePipe}}
                    </div>
                    <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                        [class.active]="view === CalendarView.Week">
                        {{'week'|translatePipe}}
                    </div>
                    <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                        [class.active]="view === CalendarView.Day">
                        {{'day'|translatePipe}}
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div *ngIf="!groupId" class="colors-container">
            <div class="mb-2" *ngFor="let group of memberGroups">
                <app-color-picker (colorChange)="onChangeColor($event,group.id)" [name]="group.id" [label]="group.name"
                    [(color)]="memberGroupColorMap[group.id].primary"></app-color-picker>
            </div>
        </div>
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                (eventClicked)="handleEvent('Clicked', $event.event)" [headerTemplate]="'ssuns'">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
            </mwl-calendar-day-view>
        </div>

    </div>


    <ng-template #modalContent let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">{{modalData?.event.title | titleCase}} event</h5>
            <button type="button" class="btn-close" (click)="close()">
            </button>
        </div>
        <div class="modal-body">
            <div class="data-container" [ngClass]="modalData?.event.image.location">
                <div *ngIf="modalData?.event.image.url" class="img-container">
                    <img [src]="modalData?.event.image.url" alt="event image">
                </div>
                <div class="data">
                    <p>start: {{modalData?.event.start.getTime() | formatDateTime}}</p>
                    <p>end: {{modalData?.event.end.getTime() | formatDateTime}}</p>
                    <div class="divider"></div>
                    <p>description: {{modalData?.event.description}}</p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="close()">
                Close
            </button>
        </div>
    </ng-template>
</div>