import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ChangeMemberStatusData } from 'src/app/interfaces/change-member-status-data';
import { MembersService } from 'src/app/services/members.service';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-manage-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss']
})
export class ManageMembersComponent implements OnInit {

  @ViewChild('messageInfo', { static: true }) messageInfo: any;


  options = ['suspension', 'freeze', 'remove', 'send messege','mail']

  subscriotion: Subscription[] = []
  members: any[] = []
  search = '';
  currAction = 'remove'
  setsToList = []
  selectedMembersIds: string[] = [];
  message=''
  messageTitle=''

  constructor(
    private membersService: MembersService,
    private setService: SetsService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.membersService.members$.subscribe(members => {
        this.updateMembers(members);
      })
    )
    this.subscriotion.push(
      this.setService.set$.subscribe(sets => {
        this.setsToList = sets.filter(set => set.advancedOptions.isShowMemberList)
      })
    )
    this.membersService.loadMembers();
    this.setService.loadSets();
  }

  updateMembers(members) {
    this.members = members.map(member => ({ ...member, isCheck: false }));
  }

  filterMembers() {
    return this.members.filter(member => (
      member.identifier.toLowerCase().includes(this.search.toLowerCase()) ||
      this.formatDate(member.memberSince).includes(this.search)
    ))
  }

  formatDate(timestamp: number) {
    const date = new Date(timestamp)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  getNumOfSelected() {
    return this.members.reduce((acc, member) => {
      if (member.isCheck) acc++
      return acc
    }, 0)
  }

  removeMember(id: string) {
    this.membersService.removeMember(id).subscribe(_ => { });
  }

  changeMemberStatus(data: ChangeMemberStatusData) {
    const { id, status } = data;
    this.membersService.changeMemberStatus(id, status).subscribe(_ => { });
  }

  onSelectedMembersAction() {
    const selectedMembers = this.members.filter(member => member.isCheck);
    const selectedMemberIds = selectedMembers.map(member => member.id);
    if (this.currAction === 'remove') {
      selectedMemberIds.forEach(id => {
        this.removeMember(id);
      })
    }
    else if (this.currAction === 'send messege') {
      this.selectedMembersIds = selectedMemberIds;
      this.modal.open(this.messageInfo, { size: 'sm' });
    }
    else {
      selectedMemberIds.forEach(id => {
        this.changeMemberStatus({ id: id, status: this.currAction });
      })
    }
    this.members = this.members.map(member => ({ ...member, isCheck: false }));
  }
}

