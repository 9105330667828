<div dirControl class="mb-3 text-center">
    <p [ngClass]="currType" class="card-title">
        <span *ngIf="currId">{{currType|translatePipe}}: {{currObject?.title}}</span>
        <span *ngIf="!currId">New {{currType}}</span>
    </p>
    <p [ngClass]="parent.type" class="card-title">{{'in '+parent.type|translatePipe}}:
        {{parent.title}}
    </p>
</div>

<div class="row">
    <div [class.col-12]="currType==='department'" [class.col-8]="currType==='group'">
        <div class="row">

            <div class="mb-3 card">
                <div class="card-body">
                    <h3 class="card-title">{{'generalInformation'|translatePipe}}</h3>
                    <div class="row">
                        <form *ngIf="currObject" class="col-lg-8 col-md-6">
                            <app-input-label [(isValid)]="isTitleValid" [required]="true" name="name"
                                [label]="'name'|translatePipe" [(value)]="currObject.title"></app-input-label>
                            <app-textarea-label name="description" [label]="'description'|translatePipe"
                                [(value)]="currObject.description"></app-textarea-label>
                        </form>
                        <!-- <div class="col-lg-4 col-md-6">
                            <app-image-picker [label]="'logo'|translatePipe" [(value)]="logoUrl"></app-image-picker>
                        </div> -->
                    </div>

                    <button class="btn btn-success" [disabled]="!isTitleValid" (click)="onSaveData()">
                        save
                    </button>
                </div>
            </div>
        </div>
        <div class="sub-group row">
            <div class="card">
                <div class="card-body">
                    <div dirControl class="">
                        <h3 class="card-title">{{'sub groups'|translatePipe}}</h3>
                    </div>
                    <div *ngIf="isViewA;else viewB">
                        <!-- <perfect-scrollbar style="height: 480px;"> -->
                            <div class="flipped">
                                <div class="tree-container">
                                    <ul class="tree">
                                        <li tree-server [isShowButtons]="false" [group]="currStructure"
                                            (onHoveredButtonClick)="onHoveredButtonClick($event)"></li>
                                    </ul>
                                </div>
                            </div>
                        <!-- </perfect-scrollbar> -->
                    </div>

                    <ng-template #viewB>
                        <app-table-container (onSetCurrAction)="onSetCurrAction($event)"
                            (onSelectGroupChild)="onSelectGroupChild($event)" [group]="group"></app-table-container>
                    </ng-template>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="currType==='group'" app-group-members-server class="col-4" [groupId]="currId"></div>


</div>