import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FullScreenImgService } from 'src/app/services/full-screen-img.service';

@Component({
  selector: 'app-full-screen-img',
  templateUrl: './full-screen-img.component.html',
  styleUrls: ['./full-screen-img.component.scss']
})
export class FullScreenImgComponent implements OnInit {

  constructor(
    private fullScreenImgService: FullScreenImgService
  ) { }

  imgSrc = ''
  subscription: Subscription

  ngOnInit(): void {
    this.subscription = this.fullScreenImgService.img$.subscribe(imgSrc => this.imgSrc = imgSrc)
  }

  closeImg(){
    this.fullScreenImgService.onCloseImage();
  }

}
