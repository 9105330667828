import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Structure } from 'src/app/interfaces/structure';
import { GroupServerService } from 'src/app/services/group-server.service';
import { MemberServerService } from 'src/app/services/member-server.service';
import { OrganizationServerService } from 'src/app/services/organization-server.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-group-manage-members-server',
  templateUrl: './group-manage-members-server.component.html',
  styleUrls: ['./group-manage-members-server.component.scss']
})
export class GroupManageMembersServerComponent implements OnInit, OnDestroy {

  @ViewChild('addMember', { static: true }) addMember: any;
  @ViewChild('sureModal', { static: true }) sureModal: TemplateRef<any>;

  subscription: Subscription[] = []
  groupId = 0;
  parent: Structure = null
  group: Structure = null
  groupMembers: any[] = []
  allMembers: any[] = []
  search = ''
  addMemberSearch = ''
  filterGroupMember$ = new BehaviorSubject<any[]>([]);
  filteredNotGroupMembers$ = new BehaviorSubject<any[]>([]);
  choosenMember: any = null
  isSureDelete = false;

  constructor(
    private organizationService: OrganizationServerService,
    private groupService: GroupServerService,
    private memberService: MemberServerService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.memberService.loadMembers();

    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        this.groupId = +params.get('id');
        this.organizationService.loadStructure();
        this.groupMembers = await this.groupService.getMembers(this.groupId).toPromise();
        this.updateFilterGroupMembers();
      })
    )

    this.subscription.push(
      this.organizationService.structure$.subscribe(structure => {
        if (this.groupId && structure) {
          this.parent = this.utilService.findParentInStructure(this.groupId, 'group', structure)
          this.group = this.parent?.children.find(child => child.id === this.groupId)
        }
      })
    )

    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.allMembers = members
        this.updateFilteredNotGroupMembers()
      })
    )
  }

  updateFilterGroupMembers() {
    const filterGroupMembers = this.groupMembers
      .filter(member => member.identifier.toLowerCase().includes(this.search.toLowerCase()))
    this.filterGroupMember$.next(filterGroupMembers)
  }

  updateFilteredNotGroupMembers() {
    const filteredNotGroupMembers = this.allMembers.filter(member => (
      member.identifier.toLowerCase().includes(this.addMemberSearch.toLowerCase()) &&
      !this.groupMembers.some(groupMember => groupMember.id === member.id)
    ))
    this.filteredNotGroupMembers$.next(filteredNotGroupMembers)
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe())
  }

  onSearchChange(value: string) {
    this.search = value;
    this.updateFilterGroupMembers();
  }

  onAddMemberSearchChange(value: string) {
    this.addMemberSearch = value;
    this.updateFilteredNotGroupMembers();
  }

  openSureModal(member: any, isSureDelete: boolean, closeFn?: CallableFunction) {
    this.choosenMember = member
    this.isSureDelete = isSureDelete
    this.modal.open(this.sureModal, { size: 'sm' });
    if (closeFn) closeFn()
  }

  async onAcceptModal(cb) {
    cb();
    if (this.isSureDelete) await this.groupService.removeMember(this.groupId, this.choosenMember.id).toPromise();
    else await this.groupService.addMember(this.groupId, this.choosenMember.id).toPromise();
    this.groupMembers = await this.groupService.getMembers(this.groupId).toPromise();
    this.updateFilterGroupMembers();
    this.updateFilteredNotGroupMembers();

    // this.updateEditedGroup();
  }

  open() {
    this.modal.open(this.addMember, { size: 'lg' });
  }

  isHaveChecked() {
    return this.groupMembers.find(member => member.isCheck)
  }

  unselectMembers() {
    this.groupMembers = this.groupMembers.map(member => ({ ...member, isCheck: false }))
    this.updateFilterGroupMembers()
  }

  async removeMembers() {
    const askDeleteMembersIds = this.groupMembers.filter(member => member.isCheck).map(member => member.id);
    await this.groupService.removeMembers(this.groupId, askDeleteMembersIds).toPromise();
    this.groupMembers = await this.groupService.getMembers(this.groupId).toPromise();
    this.updateFilterGroupMembers();
    this.updateFilteredNotGroupMembers();
  }



}
