<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="card main-card mb-3">
  <div class="card-header">
    Paginator
  </div>
  <div class="card-body">
    <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
</div>
<div class="card main-card mb-3">
  <div class="card-header">
    Paginator Config
  </div>
  <div class="card-body">
    <mat-form-field>
      List length:
      <input matInput [(ngModel)]="length">
    </mat-form-field>
    <mat-form-field>
      Page size:
      <input matInput [(ngModel)]="pageSize">
    </mat-form-field>
    <mat-form-field>
      Page size options:
      <input matInput [ngModel]="pageSizeOptions" (ngModelChange)="setPageSizeOptions($event)">
    </mat-form-field>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event">
    </mat-paginator>
    <div *ngIf="pageEvent">
      <h5>Page Change Event Properties</h5>
      <div>List length: {{pageEvent.length}}</div>
      <div>Page size: {{pageEvent.pageSize}}</div>
      <div>Page index: {{pageEvent.pageIndex}}</div>
    </div>
  </div>