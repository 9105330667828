import { Location } from '@angular/common';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Member } from 'src/app/interfaces/member';
import { Group } from 'src/app/interfaces/group';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';


@Component({
  selector: 'app-organizational-structure-edit',
  templateUrl: './organizational-structure-edit.component.html',
  styleUrls: ['./organizational-structure-edit.component.scss']
})
export class OrganizationalStructureEditComponent implements OnInit {

  @ViewChild('modal') modal!: any;


  group: Group = { ...this.organizationalStructureService.emptyGroup }
  groupParent: Group = { ...this.organizationalStructureService.emptyGroup }
  currAction = '';
  askedDeltedChildId = ''
  isYouSureDeleteModalOpen = false;
  editedGroupId = ''
  isViewA = true;
  editedGroupMembers: Member[] = [];
  subscriotion !: Subscription;



  constructor(
    private route: ActivatedRoute,
    private organizationalStructureService: OrganizationalStructureService,
    private membersService: MembersService,
    private location: Location,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.organizationalStructureService.loadOrganizationalStructure();
    this.route.paramMap.subscribe(async (params) => {
      const childId = params.get('id');
      const parentId = params.get('parentId');
      if (childId) {
        this.editedGroupId = childId;
        this.updateEditedGroup();
      } else if(parentId){
        console.log(parentId);
        this.groupParent = await this.organizationalStructureService.getGroupById(parentId).toPromise();
        this.group.type = this.groupParent.type==='organization'?'department':'group'
        
      }
      // else this.location.go('editor');
        
    })

    this.membersService.loadMembers();
    this.subscriotion = this.membersService.members$.subscribe(members => {
      this.updateMembers();
    })
  }

  updateMembers() {
    this.editedGroupMembers = this.membersService.getMembers().filter(allMembersMember => {
      return this.group.members.find(memberId => memberId === allMembersMember.id)
    })
  }

  async updateEditedGroup() {
    this.group = await this.organizationalStructureService.getGroupById(this.editedGroupId).toPromise();
    this.updateMembers();
    this.groupParent = await this.organizationalStructureService.getGroupById(this.group.parent).toPromise();
  }

  async onAddMemberToGroup(memberId: string) {
    this.group = await (await this.organizationalStructureService.addGroupMember(this.group.id, memberId)).toPromise()
    this.updateMembers();
  }

  async onRemoveMemberFromGroup(memberId: string) {
    this.group = await (await this.organizationalStructureService.removeGroupMember(this.group.id, memberId)).toPromise()
    this.updateMembers();
  }

  onSetCurrAction(action: string): void {
    this.currAction = action
  }

  onCloseYouSureDeleteModal() {
    this.isYouSureDeleteModalOpen = false;
    this.askedDeltedChildId = '';
  }

  async onSelectGroupChild(id: string) {
    switch (this.currAction) {
      case 'add':
        const newChild = await (await this.organizationalStructureService.addGroup(id)).toPromise();
        this.router.navigate(['editor', newChild.id]);
        break;
      case 'edit':
        // move to edit
        this.router.navigate(['editor', id]);
        break;
      case 'delete':
        this.askedDeltedChildId = id
        this.open()
        break;
      case 'members':
        this.router.navigate(['editor', id, 'members']);
        // move to members
        break;
      default:
        break;
    }
  }

  async onDeleteGroupChild(cb: Function) {
    const x = await (await this.organizationalStructureService.removeGroup(this.askedDeltedChildId)).toPromise()
    console.log();

    this.updateEditedGroup();
    this.askedDeltedChildId = ''
    cb('Close click')
  }

  toggleView() {
    this.isViewA = !this.isViewA
  }

  open() {
    this.modalService.open(this.modal.content).result.then((result) => {
    }, (reason) => {
    });
  }
}
