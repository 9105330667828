import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-portal-event-mini-view',
  templateUrl: './portal-event-mini-view.component.html',
  styleUrls: ['./portal-event-mini-view.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class PortalEventMiniViewComponent implements OnInit {

  @Input() isInManage = false;
  @Input() isFromAccount = false;
  @Input() event?: any;
  isMenuOpen = false;


  constructor(
    private router: Router,
    private portalEventService: PortalEventService,
  ) { }

  ngOnInit(): void {
  }

  onChooseEvent() {
    if (this.isFromAccount) this.router.navigate(['portal-event-view', 'event', 'readonly','account', this.event.id]);
    else this.router.navigate(['portal-event-view', 'event', 'readonly', this.event.id]);
  }

  stopProp(ev) {
    ev.stopPropagation();
  }

  deleteEvent(eventId) {
    this.portalEventService.delete(eventId).toPromise();
  }

}
