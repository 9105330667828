import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit {

  task = this.taskService.getEmpty();
  subscription: Subscription[] = []

  constructor(
    private taskService: TaskService,
    private route: ActivatedRoute,
  ) { }



  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async params => {
        const taskId = params.get('id');
        if (taskId) {
          this.task = await this.taskService.getTask(taskId).toPromise()

        }
      })
    )
  }

  async onSave() {
    if (this.task.id) await this.taskService.editTask(this.task).toPromise();
    else await this.taskService.addTask(this.task).toPromise();
  }

}
