import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Set } from '../interfaces/set';

@Injectable({
  providedIn: 'root'
})
export class SetsService {
  private BASE_URL = 'https://api.flexyc.com/sets';
  private _set$ = new BehaviorSubject<Set[]>([])
  public set$ = this._set$.asObservable();


  constructor(
    private http: HttpClient
  ) { }

  public loadSets() {
    this.http.get<Set[]>(this.BASE_URL)
      .subscribe(sets => {
        this._set$.next(sets);
      })
  }

  public addSet(set: Set) {
    delete set.id
    return this.http.post<Set>(this.BASE_URL, set).pipe(
      map(set => {
        this.loadSets();
        return set
      })
    )
  }

  public getSet(id: string) {
    return this.http.get<Set>(`${this.BASE_URL}/${id}`)
  }

  public getSets() {
    return this.http.get<Set[]>(this.BASE_URL)
  }

  public editSet(set: Set) {
    return this.http.put<Set>(`${this.BASE_URL}/${set.id}`, set).pipe(
      map(set => {
        this.loadSets();
        return set
      })
    )
  }

  public deleteSet(id: string) {
    return this.http.delete<Set>(`${this.BASE_URL}/${id}`).pipe(
      map(_ => {
        this.loadSets();
      })
    )
  }

  public getEmptySet(): Set {
    return {
      id: '',
      property: {
        name: '',
        type: '',
        default: '',
        placeholder: '',
      },
      title: '',
      group: {
        id: '',
        name: '',
        type: ''
      },
      default: '',
      advancedOptions: {
        isOnlyModifiedByAdministrators: false,
        isAppearsInRegistration: false,
        isRequired: false,
        isShowMemberList: false,
        isVisibaleOnlyAdmin: false
      },
      functionality: {}
    }
  }
}
