<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'chat settings'|translatePipe}}</h3>
        <div class="mb-3">
            <app-check-box-label name="direct" [(isCheck)]="chatSettings.isAllowDirect"
                [label]="'ChatSettings.allowDirect'|translatePipe">
            </app-check-box-label>
            <app-check-box-label name="group" [(isCheck)]="chatSettings.isAllowGroup"
                [label]="'ChatSettings.allowGroup'|translatePipe">
            </app-check-box-label>
        </div>
        <app-dropdown-input (chooseOption)="onChooseOption($event)" label="Search objects" [(text)]="search"
            [dropDownOpions]="filterGroups">
        </app-dropdown-input>

        <div class="row">
            <div class="col-11 row">
                <div class="col-4">
                    <span>{{'object name'|translatePipe}}</span>
                </div>
                <div class="col-4">
                    <span>{{'users in chat'|translatePipe}}</span>
                </div>
                <div class="col-4">
                    <span>{{'last activity'|translatePipe}}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>

        <app-group-chat-settings *ngFor="let group of (filterGroups.length?filterGroups:groups)"
            (toggleIsAllowChat)="toggleIsAllowChat($event)" [group]="group"></app-group-chat-settings>


    </div>
</div>