import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-task-managment',
  templateUrl: './task-managment.component.html',
  styleUrls: ['./task-managment.component.scss']
})
export class TaskManagmentComponent implements OnInit {

  @ViewChild('filterPop', { static: true }) elFilter: any;

  popFilters= {
    startDate: null,
    status: '',
    users: []
  }

  filter = {
    startDate: null,
    status: '',
    users: []
  };

  actionData={
    status:'',
    priority:1,
    startDate:Date.now(),
    deadline:Date.now(),
    object:{
      name:'',
      id:'',
      type:""
    },
    members:[]
  }


  currAction='status';
  actionOptions=['status','priority','start date','objects','deadline','add members','delete'];
  statusOptions=['','pending','work on it','stuck','review','done','no need','canceled']
  tasks: any[] = [];
  search = '';
  subscriotion: Subscription[] = [];

  get filterUsersString(){ return this.filter.users.map(user=>user.identifier).join(", ") }

  constructor(
    private taskService: TaskService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.taskService.tasks$.subscribe(tasks => {
        this.updateTasks(tasks);
      })
    )
    this.taskService.loadTasks();
  }

  updateTasks(tasks) {
    this.tasks = tasks.map(task => ({ ...task, isCheck: false, isDanger:Date.now()>task.deadline }));
  }

  getNumOfSelected() {
    return this.tasks.reduce((acc, member) => {
      if (member.isCheck) acc++
      return acc
    }, 0)
  }

  filterTasks() {
    return this.tasks.filter(task => (
      task.title.toLowerCase().includes(this.search.toLowerCase()) &&
      (this.filter.startDate? task.startDate===this.filter.startDate: true) &&
      (this.filter.status? task.status===this.filter.status: true) &&
      (this.filter.users.length? task.members.some(member=>this.filter.users.some(user=>user.id===member.id)): true) 
    ))
  }

  updateTask(task) {
    this.taskService.editTask(task).toPromise();
  }

  openFilter() {
    this.modal.open(this.elFilter, { size: 'lg' });
  }

  setFilter(cb?){
    if(cb) cb();
    if(this.popFilters.startDate) this.filter.startDate=this.popFilters.startDate;
    if(this.popFilters.status) this.filter.status=this.popFilters.status;
    if(this.popFilters.users.length) this.filter.users=this.popFilters.users; 
  }

  removeTask(task){
    this.taskService.removeTask(task.id).toPromise();
  }

  removeFilterStartDate(){this.filter.startDate=null}

  removeFilterStatus(){this.filter.status=''}
  
  removeFilterUsers(){this.filter.users=[]}

  onGoAction(){
    this.updateTasks(this.tasks);
  }
}
