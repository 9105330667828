import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent implements OnInit, OnChanges {

  @Output() updateTask = new EventEmitter<any>();
  @Input() tasks: any[];
  tasksToShow=[];
  page = 1;
  pageSize = 25;


  constructor() { }

  ngOnInit(): void {
    this.setTasksToShow()
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.setTasksToShow()
  }

  getClass() {
    return `checkbox-container ${this.tasks.find(task => task.isCheck) ? 'checked' : ''}`
  }

  setTasksToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.tasksToShow = this.tasks?.slice(start, start + this.pageSize);
  }

  updateTaskStatus(status,task){
    task.status = status;
    this.updateTask.emit(task);
  }

}
