import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArchiveItem } from '../interfaces/archive-item';

@Injectable({
  providedIn: 'root'
})
export class ArchiveListService {
  private BASE_URL = 'https://api.flexyc.com/archiveList';
  private _archiveList$ = new BehaviorSubject<ArchiveItem[]>([])
  public archiveList$ = this._archiveList$.asObservable();

  constructor(private http: HttpClient) { }

  public loadArchiveList() {
    this.http.get<ArchiveItem[]>(this.BASE_URL)
      .subscribe(archiveList => {
        this._archiveList$.next(archiveList);
      })
  }
}
