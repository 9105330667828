<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'customer service managment'|translatePipe}}</h3>

        <div class="flex gap-10">
            <label  for="status">{{'customer service status'|translatePipe}}:</label>
            <div class="">
                <bSwitch [switch-animate]="false" switch-size="mini" name="status"
                    (ngModelChange)="isStatusActive=!isStatusActive" [ngModel]="isStatusActive">
                </bSwitch>
            </div>
        </div>

        <app-dropdown-input [label]="'support user name'|translatePipe" [dropDownOpions]="members"
            (chooseOption)="onChooseMember($event)" [(text)]="memberSearch">
        </app-dropdown-input>

        <app-textarea-label [label]="'messege'|translatePipe" [(value)]="costumerServiceMessage"></app-textarea-label>

        <button [disabled]="!supportMember" class="btn btn-success">{{'save'|translatePipe}}</button>
    </div>
</div>