import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings.component.html',
  styleUrls: ['./chat-settings.component.scss']
})
export class ChatSettingsComponent implements OnInit {

  chatSettings = {
    isAllowDirect: false,
    isAllowGroup: false
  }

  _search = ''
  get search() {
    return this._search
  }
  set search(val) {
    this._search = val
    this.updateFilterGroups();
  }

  groups: Group[] = [];
  filterGroups: any[] = []



  constructor(
    private organizationalStructureService: OrganizationalStructureService
  ) { }

  async ngOnInit(): Promise<void> {
    // this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
    //   this.groups = organizationalStructure;
    //   this.updateFilterGroups();
    // })
    // this.organizationalStructureService.loadOrganizationalStructure();
    this.groups = await this.organizationalStructureService.getGroups().toPromise();
    this.updateFilterGroups();
  }

  onChooseOption(option) {
    this.search = option.identifier
  }

  updateFilterGroups() {
    if (!this.search) this.filterGroups = []
    else this.filterGroups = this.groups.filter(group => group.name.includes(this.search)).map(group => ({ ...group, identifier: group.name }))
  }

  async toggleIsAllowChat(groupId) {
    (await this.organizationalStructureService.toggleGroupIsAllowChat(groupId)).toPromise();
  }

}
