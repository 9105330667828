import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization-media',
  templateUrl: './organization-media.component.html',
  styleUrls: ['./organization-media.component.scss']
})
export class OrganizationMediaComponent implements OnInit {

  constructor() { }

  youtubeUrl="https://www.youtube.com/embed?listType=playlist&list=UUa3CTPhyGlCa5b7bqcK0JgA"

  ngOnInit(): void {
  }

}
