import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Set } from 'src/app/interfaces/set';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-set-management-table',
  templateUrl: './set-management-table.component.html',
  styleUrls: ['./set-management-table.component.scss']
})
export class SetManagementTableComponent implements OnInit {
  @Input() sets: any[] = []
  dataSource = new MatTableDataSource
  displayedColumns = ['title'];
  pageSize = 25;
  setsToShow = [];
  private _page = 1;

  get page() {
    return this._page
  }

  set page(value) {
    this._page = value;
    this.setSetsToShow()
  }

  constructor(
    private setService: SetsService
  ) { }

  ngOnInit(): void {
   this.setSetsToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSetsToShow()
  }

  getClass() {
    return `checkbox-container ${this.sets.find(set => set.isCheck) ? 'checked' : ''}`
  }

  setSetsToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.setsToShow = this.sets?.slice(start, start + this.pageSize);
  }

  deleteSet(id: string) {
    this.setService.deleteSet(id).toPromise();
  }

}
