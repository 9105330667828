import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-attendance-member-view',
  templateUrl: './attendance-member-view.component.html',
  styleUrls: ['./attendance-member-view.component.scss']
})
export class AttendanceMemberViewComponent implements OnInit {

  constructor(
    private memberService: MembersService
  ) { }

  imgUrl = "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif"

  @Input() member: any;
  @Output() removeMember = new EventEmitter<any>();


  async ngOnInit(): Promise<void> {
    if (this.member.type === 'member') {
      const member = await this.memberService.getMember(this.member.id).toPromise();
      if (member.profileImg) this.imgUrl = member.profileImg
    }
  }

}
