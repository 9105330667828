import { Injectable } from '@angular/core';
import { Structure } from '../interfaces/structure';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  public generateId() {
    return "id" + Math.random().toString(16).slice(2);
  }

  public generateTempPassword() {
    return Math.random().toString(16).slice(2);
  }
  public generateColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
  }

  public getRandomNum(max) {
    return Math.floor(Math.random() * max);
  }


  public invertColor(hex) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + this._padZero(r) + this._padZero(g) + this._padZero(b);
  }

  private _padZero(str, len?) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  findChildInStructure(id: number, type: string, structure: Structure) {
    if (!structure) return null;
    let resultStructure = null;
    if (structure?.id === id && structure?.type === type) return structure
    else structure.children?.forEach(child => {
      if (child.id === id && child.type === type) resultStructure = child;
      else if (!resultStructure) resultStructure = this.findChildInStructure(id, type, child);
    })
    return resultStructure;
  }

  findParentInStructure(id: number, type: string, structure: Structure) {
    if (!structure) return null;
    let resultParentStructure = null;
    if (structure?.children?.some(child => child.id === id && child.type === type)) return structure;
    else structure?.children?.forEach(child => {
      if (!resultParentStructure) resultParentStructure = this.findParentInStructure(id, type, child)
    })
    return resultParentStructure;
  }

  public structureToArr(structure, arr = []): any[] {
    const { id, type, title } = structure;
    arr.push({ id, type, title })
    if (!structure.children) return arr;
    structure.children.forEach(child => {
      const childArr = this.structureToArr(child)
      childArr.forEach(ArrItem => {
        arr.push(ArrItem)
      })
    });
    return arr
  }

  public timestampToFormatDate(timestamp) {
    const date = new Date(timestamp)
    let minutes = date.getMinutes()
    const minutesString = minutes > 9 ? '' + minutes : '0' + minutes;
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${minutesString}`
  }
}
