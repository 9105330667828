<div class="input-group flexyc-input mb-4 date-picker" ngbDropdown>
    <label [class.input-empty]="!input.value" [for]="name" *ngIf="label">
        <ng-container *ngIf="input.value; else placeholder">
            {{label | titleCase}}
        </ng-container>
        <ng-template #placeholder>
            d/m/yyyy
        </ng-template>
    </label>
    <input #input (blur)="updateDate($event)" autocomplete="off" class="form-control" [ngModel]="dateString" [id]="name"
        [name]="name">
    <button ngbDropdownToggle class="btn btn-primary" type="button">
        <i class="fa fa-calendar"></i>
    </button>
    <div ngbDropdownMenu>
        <div class="card-body">
            <ngb-datepicker #dp [(ngModel)]="ngbDate" name="toModal" (navigate)="date = $event.next">
            </ngb-datepicker>
        </div>
    </div>
</div>