import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Group } from 'src/app/interfaces/group';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-duplicate-option',
  templateUrl: './duplicate-option.component.html',
  styleUrls: ['./duplicate-option.component.sass']
})
export class DuplicateOptionComponent implements OnInit {
  // organizationalStructure: Group = { ...this.organizationalStructureService.emptyOrganizationalStructure }
  // organizationalStructureParent: Group = { ...this.organizationalStructureService.emptyOrganizationalStructure }
  // editedOrganizationalStructureId = '';
  // isCopyInformation = false;
  // isCopySubGroup = false;
  // isCopyMembers = false;
  // isCopySubGroupMembers = false;
  // isCopyModule = false;


  constructor(
    private organizationalStructureService: OrganizationalStructureService,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit(): void {
    // this.organizationalStructureService.loadOrganizationalStructure();
    // this.route.paramMap.subscribe(params => {
    //   const childId = params.get('id');
    //   if (childId) {
    //     this.editedOrganizationalStructureId = childId;
    //     this.updateEditedOrganizationalStructure();
    //   } else this.location.go('editor');
    // })
  }

  // updateEditedOrganizationalStructure() {
  //   this.organizationalStructureService.getOrganizationalStructureById(this.editedOrganizationalStructureId).subscribe(child => {
  //     this.organizationalStructure = child;
  //     this.organizationalStructureService.getOrganizationalStructureById(child.parent).subscribe(parent => {
  //       this.organizationalStructureParent = parent;
  //     })
  //   })
  // }

  // toggleIsCopyInformation() {
  //   this.isCopyInformation = !this.isCopyInformation
  // }

  // toggleIsCopySubGroup() {
  //   this.isCopyInformation = !this.isCopyInformation
  //   if(!this.isCopyInformation) this.isCopySubGroupMembers=false;
  // }

  // toggleIsCopyMembers() {
  //   this.isCopyInformation = !this.isCopyInformation
  // }

  // toggleIsCopySubGroupMembers() {
  //   this.isCopyInformation = !this.isCopyInformation
  // }

  // toggleIsCopyCopyModule() {
  //   this.isCopyInformation = !this.isCopyInformation
  // }





}
