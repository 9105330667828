<div class="event-container mb-2">

    <div *ngIf="!(isInManage||isFromAccount)" (click)="(stopProp($event))" class="menu-container">
        <button (click)="isMenuOpen=!isMenuOpen" class="btn menu-open-btn">
            <i class="fa fa-fw"></i>
        </button>
        <div (clickOutside)="isMenuOpen=false" [@slide-vertical]="isMenuOpen?'out':'in'" [class.close]="!isMenuOpen"
            class="menu">
            <a [routerLink]="'/portal-event-view/event/'+event.id">
                <i class="fa fa-fw"></i>
                <span>Edit</span>

            </a>
            <button (click)="deleteEvent(event.id)">
                <i class="fa fa-fw"></i>
                <span>Delete</span>
            </button>
        </div>
    </div>

  

    <div (click)="onChooseEvent()" [class.inManage]="isInManage" class="event">
        <img [src]="event.eventImage" alt="event image">
        <p class="event-name">{{event.eventName | titleCase}}</p>
        <p>{{event.eventDate | formatDate}}</p>
    </div>
</div>