import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  @ViewChild('landingPageUrlInput') landingPageUrlInput!: any;

  isStatusActive = false;
  landingPageUrl = ''

  get isInputValid() {
    const isValid = this.landingPageUrlInput?.formControler?.valid
    return (isValid)
  }

  constructor() { }

  ngOnInit(): void {
  }

}
