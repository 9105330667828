import {InjectionToken} from "@angular/core";

export interface SomeConfig {
  [p: string]: {
    [p: string]: string | any
  }
}


export const i18nConfig = new InjectionToken<SomeConfig>('i18n data');
