import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-digital-wallet',
  templateUrl: './digital-wallet.component.html',
  styleUrls: ['./digital-wallet.component.scss']
})
export class DigitalWalletComponent implements OnInit {

  @ViewChild('categoryUrlInput') categoryUrlInput!: any;

  isStatusActive = false;
  categoryUrl = ''

  get isInputValid() {
    const isValid = this.categoryUrlInput?.formControler?.valid
    return (isValid)
  }


  constructor() { }

  ngOnInit(): void {
  }

}
