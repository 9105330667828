import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Structure } from 'src/app/interfaces/structure';
import { OrganizationServerService } from 'src/app/services/organization-server.service';

@Component({
  selector: 'app-group-picker-button-server',
  templateUrl: './group-picker-button-server.component.html',
  styleUrls: ['./group-picker-button-server.component.scss']
})
export class GroupPickerButtonServerComponent implements OnInit {

  @Output() onSelectChild = new EventEmitter<number>()
  @Input() disabled?: boolean = false


  constructor(
    private modalService: NgbModal,
    public organizationService: OrganizationServerService
  ) { }

  structure: Structure = null
  subscription: Subscription[] = []


  ngOnInit(): void {
    this.subscription.push(
      this.organizationService.structure$.subscribe(structure => {
        if (structure) this.structure = structure;
      })
    )
    this.organizationService.loadStructure();
  }

  open(content) {
    this.modalService.open(content, {
      size: 'lg'
    });
  }

  selectChildAndClose(id, cb) {
    console.log(id);
    
    this.onSelectChild.emit(id);
    cb('Cross click')
  }


}
