<div class="main-card card mb-3">
    <div class="card-body">
        <h3 class="card-title">filter by</h3>
        <app-input-label (valueChange)="dataChange('searchText',$event)" [value]="searchText" label="Search event">
        </app-input-label>
        <div class="type-input-container mb-4">
            <button (click)="open()" class="btn btn-primary">Choose type</button>
            <p *ngIf="searchType">{{searchType}}</p>
            <button (click)="chooseType('')" class="remove-type" *ngIf="searchType">
                <i class="pe-7s-close"></i>
            </button>
        </div>
        <div class="row">
            <div class="col-1">
                <input type="checkbox" (change)="dataChange('isSearchByDate',!isSearchByDate)" id="time"
                    class="form-check-input" [checked]="isSearchByDate">
            </div>
            <label for="time" class="form-label form-check-label col-11 row">
                <div class="row mb-3 col-11">
                    <div class="col-6">
                        <app-date-picker label="From" (timestampChange)="dataChange('searchFrom',$event)"
                            [timestamp]="searchFrom">
                        </app-date-picker>
                    </div>
                    <div class="col-6" ngbDropdown>
                        <app-date-picker label="To" (timestampChange)="dataChange('searchTo',$event)"
                            [timestamp]="searchTo"></app-date-picker>
                    </div>
                </div>
                <div class="col-1">
                    <app-range-date-picker [(fromTimestamp)]="searchFrom" [(toTimestamp)]="searchTo">
                    </app-range-date-picker>
                </div>
            </label>
        </div>
        <div class="btns">
            <a [routerLink]="'/portal-event-view/account/'+account.id" class="btn btn-primary">Add event</a>
            <a [routerLink]="'/account-view/account/'+account.id" class="btn btn-secondary">Account</a>
        </div>
    </div>

</div>

<div class="events-container">
    <div *ngFor="let event of accountFilterEvents" class="event mb-3">
        <app-portal-managment-event-view [event]="event"></app-portal-managment-event-view>
    </div>
</div>


<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Choose type</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div dir="rtl" class="modal-body">
        <div class="category-type-container">
            <div class="category-container">
                <div (click)="currCategory=typeKey" class="category" *ngFor="let typeKey of typeMapKeys">
                    {{typeKey}}
                </div>
            </div>
            <div class="divider"></div>
            <div class="type-container">
                <ng-container *ngIf="currCategory">
                    <div (click)="chooseType(type,c)" *ngFor="let type of typeMap[currCategory]" class="type">
                        <i class="fa fa-fw" [title]="type">{{getIconType(type)}}</i>
                        <span>{{type}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    </div>
</ng-template>