import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Member } from 'src/app/interfaces/member';

@Component({
  selector: 'app-toggle-member',
  templateUrl: './toggle-member.component.html',
  styleUrls: ['./toggle-member.component.scss']
})
export class ToggleMemberComponent implements OnInit {

  constructor() { }

  @Input() member: Member
  @Output() remove = new EventEmitter<Member>();


  ngOnInit(): void {
  }

}
