<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="membersToShow" [dataSource]="members" [dataItemKeys]="['identifier']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member since'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.memberSince | formatDate}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'moderator'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.isAdministrator?'yes':'no'|translatePipe}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/member-view-server/'+dataItem.id" class="btn btn-primary">Profile</a>
                        <button class="btn btn-danger mx-3"
                            (click)="removeMember.emit(dataItem)">Delete</button>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="members.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>