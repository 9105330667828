import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  @ViewChild('youtubeUrlInput') youtubeUrlInput!: any;

  isStatusActive = false;
  youtubeUrl = ''

  get isInputValid() {
    const isUserNameValid = this.youtubeUrlInput?.formControler?.valid
    return (isUserNameValid)
  }

  constructor() { }

  ngOnInit(): void {
  }

}
