import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group } from 'src/app/interfaces/group';

@Component({
  selector: 'app-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss']
})
export class TableContainerComponent implements OnInit {
  @Input() group !: Group;
  @Output() onSetCurrAction = new EventEmitter<string>();
  @Output() onSelectGroupChild = new EventEmitter<string>();



  constructor() { }

  ngOnInit(): void {
  }

}
