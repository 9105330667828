import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-manage-costumers-table',
  templateUrl: './manage-costumers-table.component.html',
  styleUrls: ['./manage-costumers-table.component.scss']
})
export class ManageCostumersTableComponent implements OnInit {

  dataSource = new MatTableDataSource
  setsColumns: string[] = []
  page = 1;
  pageSize = 25;
  costumersToShow=[];
  // displayedColumns = ['account name', 'member identifier', 'options'];
  @Input() costumers: any[];
  @Output() deleteEmployee = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
    this.setCostumersToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCostumersToShow()
  }

  getClass() {
    return `checkbox-container ${this.costumers.find(member => member.isCheck) ? 'checked' : ''}`
  }

  setCostumersToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.costumersToShow =  this.costumers?.slice(start, start + this.pageSize);
  }
}
