<div dirControl class="">
    <h3 class="text-center card-title">{{'my profile'|translatePipe}}</h3>
    <div class="member-container">
        <div class="col-3">
            <app-member-view [member]="member"></app-member-view>
        </div>
    </div>
    <div *ngIf="member" class="main-card card mb-4">
        <div class="card-body">
            <div class="authData">
                <p>{{'user name'|translatePipe}}: {{member.identifier}}</p>
                <p>{{'email'|translatePipe}}: {{member.email}}</p>
                <p>{{'member since'|translatePipe}}: {{member.memberSince | formatDate}}</p>
            </div>
            <a routerLink="/user-control-pannel" class="btn btn-primary  float-right"> <i class="fa fa-fw"
                    aria-hidden="true" title="edit"></i>
                {{'edit'|translatePipe}}</a>
        </div>
    </div>

    <div *ngIf="member" class="main-card card mb-4">
        <div class="card-body">
            <div class="setData">
                <div class="tabs-btns mb-4">
                    <button *ngFor="let group of memberGroups" [class.curr]="group.id===currSetGroupId"
                        (click)="currSetGroupId= group.id">
                        {{group.name}}
                    </button>
                </div>
                <div *ngFor="let set of setMap[currSetGroupId]">
                    <p class="set-priview">
                        <span class=" span-title">{{set.title}}</span>:
                        <app-set-preview [type]="set.property.type" [value]="member.setMap[set.id]"></app-set-preview>
                    </p>
                </div>
            </div>
            <a routerLink="/sets" class="btn btn-primary  float-right"> <i class="fa fa-fw" aria-hidden="true"
                    title="edit"></i>
                {{'edit'|translatePipe}}</a>
        </div>
    </div>

    <div *ngIf="member" class="main-card card mb-4">
        <div class="card-body row">
            <div class="col-lg-4 col-md-6 col-12 mb-4" *ngFor="let card of member.cards.slice(0,4)">
                <app-card-usage-preview [isShowUseButton]="false" (useCard)="useCard($event)"
                    [cardImg]="getCardImg(card.card.group.id)" [card]="card">
                </app-card-usage-preview>
            </div>
            <div *ngIf="member.cards.length>4" class="">
                <a routerLink="/cards" class="btn btn-primary  float-right">{{'see more'|translatePipe}}</a>
            </div>

        </div>
    </div>
</div>