import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.scss']
})
export class CustomerServiceComponent implements OnInit {

  constructor(private memberService: MembersService) { }

  isStatusActive = false;
  supportMember;
  members = []
  subscription: Subscription[] = []
  memberSearch = ''
  costumerServiceMessage='';

  ngOnInit(): void {
    this.subscription.push(
      this.memberService.members$.subscribe(members => {
        this.members = members
      })
    )
    this.memberService.loadMembers();
  }


  onChooseMember(member) {
    this.supportMember = member
    this.memberSearch = member.identifier
  }



}
