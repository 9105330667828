import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent implements OnInit {

  private _search = '';
  get search() { return this._search }
  set search(val) {
    this.currCategory = 'all'
    this._search = val
  }
  currCategory = 'all'
  propertyMap = {
    premade: {
      firstName: {
        type: 'short-text',
        classColor:this.getColor(),
        default: '',
        placeholder: 'First Name'
      },
      lastName: {
        type: 'short-text',
        classColor:this.getColor(),
        default: '',
        placeholder: 'Last Name'
      },
      profilePicture: {
        type: 'url',
        classColor:this.getColor(),
        default: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif',
        placeholder: ''
      },
      birthDate: {
        type: 'timestamp',
        classColor:this.getColor(),
        default: Date.now(),
        placeholder: 'd/m/yyyy'
      },
      email: {
        type: 'email',
        classColor:this.getColor(),
        default: '',
        placeholder: 'Email'
      },
      phone: {
        type: 'phone',
        classColor:this.getColor(),
        default: '',
        placeholder: 'Phone number'
      },
    },
    generic: {
      number: {
        type: 'number',
        classColor:this.getColor(),
        default: 0,
        placeholder: ''
      },
      text: {
        type: 'short-text',
        classColor:this.getColor(),
        default: '',
        placeholder: ''
      },
      longText: {
        type: 'long-text',
        classColor:this.getColor(),
        default: '',
        placeholder: ''
      },
      date: {
        type: 'timestamp',
        classColor:this.getColor(),
        default: Date.now(),
        placeholder: 'd/m/yyyy'
      },
      time: {
        type: 'time',
        classColor:this.getColor(),
        default: { hour: 0, minute: 0 },
        placeholder: ''
      },
      check: {
        type: 'check',
        classColor:this.getColor(),
        default: false,
        placeholder: ''
      },
    },
    auto: {
      registered: {
        type: 'timestamp',
        classColor:this.getColor(),
        default: Date.now(),
        placeholder: '',
        depends: []
      },
      lastSeen: {
        type: 'timestamp',
        classColor:this.getColor(),
        default: Date.now(),
        placeholder: '',
        depends: []
      },
      age: {
        type: 'number',
        classColor:this.getColor(),
        default: 0,
        placeholder: '',
        depends: ['birthDate']
      },
      fullName: {
        type: 'short-text',
        classColor:this.getColor(),
        default: '',
        placeholder: '',
        depends: ['firstName', 'lastName']
      }
    }
  }

  get premadeKeys() { return Object.keys(this.propertyMap.premade) }
  get genericKeys() { return Object.keys(this.propertyMap.generic) }
  get autoKeys() { return Object.keys(this.propertyMap.auto) }

  constructor(
    private router: Router,
    private utilService: UtilService
  ) { }

  onPropertyClick(property) {
    console.log(property);
    this.router.navigate(['set-view', 'property', property]);

  }

  getTypeIcon(type) {
    switch (type) {
      case 'short-text':
        return ''
      case 'long-text':
        return ''
      case 'timestamp':
        return ''
      case 'date':
        return ''
      case 'url':
        return ''
      case 'email':
        return ''
      case 'number':
        return ''
      case 'time':
        return ''
      case 'phone':
        return ''
      case 'check':
        return ''
      default:
        return ''
    }
  }

  getColor() {
    const colors = [
      'red',
      'pink',
      'purple',
      'purple2',
      'indigo',
      'blue',
      'blue2',
      'cyan',
      'teal',
      'green',
      'green2',
      'lime',
      'amber',
      // 'yellow',
      'orange',
      'orange2',
      'grey',
      'grey2',
    ]
    return colors[this.utilService.getRandomNum(colors.length)]
  }

  ngOnInit(): void {
  }
}
