<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="main-card mb-3 card">
  <div class="card-body">
    <h5 class="card-title">Tooltips</h5>
    <div class="text-center">
      <button id="exButton1" type="button" class="btn me-2 btn-primary" placement="top" ngbTooltip="Tooltip on top">
        Tooltip on top
      </button>
      <button id="exButton2" type="button" class="btn me-2 btn-primary" placement="end" ngbTooltip="Tooltip on right">
        Tooltip on right
      </button>
      <button id="exButton3" type="button" class="btn me-2 btn-primary" placement="bottom"
        ngbTooltip="Tooltip on bottom">
        Tooltip on bottom
      </button>
      <button id="exButton4" type="button" class="btn btn-primary" placement="start" ngbTooltip="Tooltip on left">
        Tooltip on left
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Gradient Colors</h5>
        <div class="text-center">
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-vicious-stance"
            popoverClass="popover-bg bg-vicious-stance">bg-vicious-stance</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-midnight-bloom"
            popoverClass="popover-bg bg-midnight-bloom">bg-midnight-bloom</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-night-sky" popoverClass="popover-bg bg-night-sky">bg-night-sky</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-slick-carbon"
            popoverClass="popover-bg bg-slick-carbon">bg-slick-carbon</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-asteroid" popoverClass="popover-bg bg-asteroid">bg-asteroid</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-royal" popoverClass="popover-bg bg-royal">bg-royal</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-warm-flame"
            popoverClass="popover-bg bg-warm-flame text-dark">bg-warm-flame</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-night-fade"
            popoverClass="popover-bg bg-night-fade text-dark">bg-night-fade</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-sunny-morning"
            popoverClass="popover-bg bg-sunny-morning text-dark">bg-sunny-morning</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-tempting-azure"
            popoverClass="popover-bg bg-tempting-azure text-dark">bg-tempting-azure</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-amy-crisp"
            popoverClass="popover-bg bg-amy-crisp text-dark">bg-amy-crisp</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-heavy-rain"
            popoverClass="popover-bg bg-heavy-rain text-dark">bg-heavy-rain</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-mean-fruit"
            popoverClass="popover-bg bg-mean-fruit text-dark">bg-mean-fruit</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-malibu-beach"
            popoverClass="popover-bg bg-malibu-beach">bg-malibu-beach</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-deep-blue"
            popoverClass="popover-bg bg-deep-blue text-dark">bg-deep-blue</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-ripe-malin" popoverClass="popover-bg bg-ripe-malin">bg-ripe-malin</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-arielle-smile"
            popoverClass="popover-bg bg-arielle-smile">bg-arielle-smile</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-plum-plate" popoverClass="popover-bg bg-plum-plate">bg-plum-plate</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-happy-fisher"
            popoverClass="popover-bg bg-happy-fisher text-dark">bg-happy-fisher</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-happy-itmeo" popoverClass="popover-bg bg-happy-itmeo">bg-happy-itmeo</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-mixed-hopes" popoverClass="popover-bg bg-mixed-hopes">bg-mixed-hopes</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-strong-bliss"
            popoverClass="popover-bg bg-strong-bliss">bg-strong-bliss</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-grow-early" popoverClass="popover-bg bg-grow-early">bg-grow-early</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-love-kiss" popoverClass="popover-bg bg-love-kiss">bg-love-kiss</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-premium-dark"
            popoverClass="popover-bg bg-premium-dark">bg-premium-dark</button>
          <button class="me-2 mb-2 btn btn-secondary"
            ngbPopover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
            popoverTitle="Popover with bg-happy-green" popoverClass="popover-bg bg-happy-green">bg-happy-green</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Solid Colors</h5>
        <div class="text-center">
          <button type="button" popoverTitle="Primary popover" ngbPopover="Popover with primary background"
            popoverClass="bg-primary popover-bg" class="me-2 mb-2 btn btn-primary">Primary</button>
          <button type="button" popoverTitle="Secondary popover" ngbPopover="Popover with secondary background"
            popoverClass="bg-secondary popover-bg" class="me-2 mb-2 btn btn-secondary">Secondary</button>
          <button type="button" popoverTitle="Success popover" ngbPopover="Popover with success background"
            popoverClass="bg-success popover-bg" class="me-2 mb-2 btn btn-success">Success</button>
          <button type="button" popoverTitle="Info popover" ngbPopover="Popover with info background"
            popoverClass="bg-info popover-bg" class="me-2 mb-2 btn btn-info">Info</button>
          <button type="button" popoverTitle="Warning  popover" ngbPopover="Popover with warning  background"
            popoverClass="bg-warning  popover-bg text-dark" class="me-2 mb-2 btn btn-warning ">Warning</button>
          <button type="button" popoverTitle="Danger popover" ngbPopover="Popover with danger background"
            popoverClass="bg-danger popover-bg" class="me-2 mb-2 btn btn-danger">Danger</button>
          <button type="button" popoverTitle="Focus popover" ngbPopover="Popover with focus background"
            popoverClass="bg-focus popover-bg" class="me-2 mb-2 btn btn-focus">Focus</button>
          <button type="button" popoverTitle="Alternate popover" ngbPopover="Popover with alternate background"
            popoverClass="bg-alternate popover-bg" class="me-2 mb-2 btn btn-alternate">Alternate</button>
          <button type="button" popoverTitle="Light popover" ngbPopover="Popover with light background"
            popoverClass="bg-light popover-bg text-dark" class="me-2 mb-2 btn btn-light">Light</button>
          <button type="button" popoverTitle="Dark popover" ngbPopover="Popover with dark background"
            popoverClass="bg-dark popover-bg" class="me-2 mb-2 btn btn-dark">Dark</button>
        </div>
      </div>
    </div>
  </div>
</div>