<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'media managment'|translatePipe}}</h3>

        <div class="flex gap-10 mb-5">
            <label class="" for="status">{{'media status'|translatePipe}}:</label>
            <div class="">
                <bSwitch [switch-animate]="false" switch-size="mini" name="side-select"
                    (ngModelChange)="isStatusActive=!isStatusActive" [ngModel]="isStatusActive">
                </bSwitch>
            </div>
        </div>

        <app-input-label #youtubeUrlInput [name]="'url'" [label]="'youtube URL'" type="text" [(value)]="youtubeUrl"
            [syncValidators]="['youtubeUrl']">
        </app-input-label>

        <ng-container *ngIf="isInputValid && isStatusActive">
            <div class="iframe-container mb-4">
                <iframe class="responsive-iframe" [src]="youtubeUrl | iframeYoutube | safeUrl" allowfullscreen></iframe>
            </div>
        </ng-container>

        <button [disabled]="isStatusActive && !isInputValid" class="btn btn-success">{{'save'|translatePipe}}</button>
    </div>


</div>