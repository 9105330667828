<button [disabled]="disabled" (click)="open(content)" class="btn btn-primary icon-container group-picker-button" type="button">
    <i class="vsm-icon pe-7s-network"></i>
</button>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Choose a group</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 480px;" [config]="config">
            <div class="flipped">
                <div class="tree-container">
                    <ul class="tree">
                        <li tree [group]="group" (onSelectChild)="selectChildAndClose($event,d)"></li>
                    </ul>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</ng-template>