<div class="" ngbDropdown>
    <button ngbDropdownToggle class="btn btn-primary" type="button">
        <i class="fa fa-calendar"></i>
    </button>
    <div ngbDropdownMenu>
        <div class="card-body table-responsive">
            <div class="btns-container row mb-2">
                <div class="col-4">
                    <button (click)="chooseYesterday()"
                        class="btn btn-primary col-12">Yesterday</button>
                </div>
                <div class="col-4">
                    <button (click)="chooseLastWeek()"
                        class="btn btn-primary col-12">Last
                        week</button>
                </div>
                <div class="col-4">
                    <button (click)="chooseLastMonth()"
                        class="btn btn-primary col-12">Last
                        month</button>
                </div>
            </div>
            <div class="">
                <ngb-datepicker #dp [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden">
                </ngb-datepicker>
                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused"
                        [class.range]="isRange(date)"
                        (click)="onDateSelection(date)"
                        [class.faded]="isHovered(date) || isInside(date)"
                        (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                    </span>
                </ng-template>
            </div>
        </div>
    </div>
</div>
