import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-control-buttons-tree',
  templateUrl: './control-buttons-tree.component.html',
  styleUrls: ['./control-buttons-tree.component.scss']
})
export class ControlButtonsTreeComponent implements OnInit {
  @Input() action !: string;
  @Output() onSetCurrAction = new EventEmitter<string>()



  constructor() { }

  ngOnInit(): void {
  }

}
