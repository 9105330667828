import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iframeYoutube'
})
export class IframeYoutubePipe implements PipeTransform {

  transform(value: string): string {
    const isList = value.includes('list=');
    if(isList) return `https://www.youtube.com/embed?listType=playlist&list=${value.split('list=')[1]}`
    return `https://www.youtube.com/embed/${value.split('watch?v=')[1]}`
  }

}

// list youtube https://www.youtube.com/watch?v=ePOMhbqX2MM&list=PLy8rm9-NIsVWESWlVgKabYnEcneyeFfy5
// list iframe https://www.youtube.com/embed?listType=playlist&list=UUa3CTPhyGlCa5b7bqcK0JgA
// video youtube https://www.youtube.com/watch?v=94A8ZyVXql4
// video iframe https://www.youtube.com/embed/tgbNymZ7vqY
