<div dirControl class="main-card card mb-3 account-view">
    <button *ngIf="account.id" class="swich-mode">
        <i (click)="toggleIsEdit()" class="fa fa-fw" aria-hidden="true" title="edit"></i>
    </button>
    <div class="card-body">
        <h3 class="card-title text-center">{{account.type+ ' view'|translatePipe}}</h3>
        <ng-container *ngIf="(!account.id) || isEdit; else dataShow">
            <div class="row">
                <div class="col-sm-4 col-lg-2 data">
                    <app-image-picker [isCircle]="true" [label]="'profile image'|translatePipe" [(value)]="account.data.profileImg">
                    </app-image-picker>
                    <app-input-label name="fullname" [label]="'full name'|translatePipe" [(value)]="account.data.fullName">
                    </app-input-label>
                    <app-input-label name="title" [label]="'title'|translatePipe" [(value)]="account.data.title"></app-input-label>
                    <button (click)="openDescription()" class="btn btn-primary mb-4 col-12">{{'edit description'|translatePipe}}</button>
                </div>
                <div class="col-sm-4 col-lg-5 contact-info">
                    <h3 class="card-title text-center">{{'contact info'|translatePipe}}</h3>
                    <app-dropdown-input [label]="'connect to member'|translatePipe" (chooseOption)="chooseMember($event)"
                        [(text)]="memberIdentidier" [dropDownOpions]="filteredMembers"></app-dropdown-input>
                    <app-input-label name="email" [label]="'email'|translatePipe" [(value)]="account.contactInfo.email"></app-input-label>
                    <app-input-label name="phone" [label]="'phone'|translatePipe" [(value)]="account.contactInfo.phone"></app-input-label>
                    <app-dropdown-label [label]="'gender'|translatePipe" [(value)]="account.contactInfo.gender"
                        [options]="['male','female','other']"></app-dropdown-label>
                    <app-input-label name="address" [label]="'address'|translatePipe" [(value)]="account.contactInfo.address">
                    </app-input-label>
                </div>
                <div class="col-sm-4 col-lg-5 type-info">
                    <ng-container [ngSwitch]="type">
                        <ng-container *ngSwitchCase="'candidate'">
                            <h3 class="card-title text-center">{{'candidate info'|translatePipe}}</h3>
                            <app-date-picker [label]="'hirring date'|translatePipe" [(timestamp)]="account.typeData.hiringDate">
                            </app-date-picker>
                            <app-group-picker-input [label]="'devision'|translatePipe" (currGroupChange)="onGroupChange($event)"
                                [currGroup]="account.typeData.object">
                            </app-group-picker-input>
                            <div class="flex gap-10 mb-4 mt-4">
                                <div class="">
                                    {{'candidate status'|translatePipe}}:
                                </div>
                                <div class="">
                                    <bSwitch [switch-animate]="false" switch-size="mini" name="side-select"
                                        [(ngModel)]="account.typeData.candidateStatus">
                                    </bSwitch>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'employee'">
                            <h3 class="card-title text-center">{{'employee info'|translatePipe}}</h3>
                            <app-input-label name="idNumber" [label]="'id number'|translatePipe" [(value)]="account.typeData.idNumber">
                            </app-input-label>
                            <app-date-picker [label]="'start date'|translatePipe" [(timestamp)]="account.typeData.hiringDate">
                            </app-date-picker>
                            <app-group-picker-input [label]="'devision'|translatePipe" (currGroupChange)="onGroupChange($event)"
                                [currGroup]="account.typeData.object">
                            </app-group-picker-input>
                            <!-- <app-dropdown-multy-select label="direct managers" [(values)]="account.typeData.directManagers">
                        </app-dropdown-multy-select> -->
                            <!-- <app-dropdown-multy-select label="part of teams" [(values)]="account.typeData.partOfTeams">
                        </app-dropdown-multy-select> -->
                            <app-members-multy-select [label]="'direct managers'|translatePipe" name="direct-manager"
                                [(values)]="account.typeData.directManagers"></app-members-multy-select>

                            <div class="flex gap-10 mb-4 mt-4">
                                <div class="">
                                    {{'employee status'|translatePipe}}:
                                </div>
                                <div class="">
                                    <bSwitch [switch-animate]="false" switch-size="mini" name="side-select"
                                        [(ngModel)]="account.typeData.employeeStatus">
                                    </bSwitch>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'customer'">
                            <h3 class="card-title text-center">{{'customer info'|translatePipe}}</h3>
                            <app-input-label name="idNumber" [label]="'id number'|translatePipe" [(value)]="account.typeData.idNumber">
                            </app-input-label>
                            <app-date-picker [label]="'start date'|translatePipe" [(timestamp)]="account.typeData.startDate">
                            </app-date-picker>
                            <app-group-picker-input [label]="'sector'|translatePipe" (currGroupChange)="onGroupChange($event)"
                                [currGroup]="account.typeData.object">
                            </app-group-picker-input>
                            <!-- <app-dropdown-multy-select label="direct managers" [(values)]="account.typeData.directManagers">
                        </app-dropdown-multy-select> -->
                            <!-- <app-dropdown-multy-select label="part of teams" [(values)]="account.typeData.partOfTeams">
                        </app-dropdown-multy-select> -->
                            <app-members-multy-select [label]="'secondery contact'|translatePipe" name="direct-manager"
                                [(values)]="account.typeData.seconderyContact"></app-members-multy-select>

                            <div class="flex gap-10 mb-4 mt-4">
                                <div class="">
                                    {{'company status'|translatePipe}}:
                                </div>
                                <div class="">
                                    <bSwitch [switch-animate]="false" switch-size="mini" name="side-select"
                                        [(ngModel)]="account.typeData.companyStatus">
                                    </bSwitch>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'patient'">
                            <h3 class="card-title text-center">{{'patient info'|translatePipe}}</h3>
                            <app-input-label name="idNumber" [label]="'id number'|translatePipe" [(value)]="account.typeData.idNumber">
                            </app-input-label>
                            <app-input-label name="Diagnosis" [label]="'diagnosis'|translatePipe" [(value)]="account.typeData.diagnosis">
                            </app-input-label>
                            <app-group-picker-input [label]="'department'|translatePipe" (currGroupChange)="onGroupChange($event)"
                                [currGroup]="account.typeData.object">
                            </app-group-picker-input>
                            <!-- <app-dropdown-multy-select label="direct managers" [(values)]="account.typeData.directManagers">
                        </app-dropdown-multy-select> -->
                            <!-- <app-dropdown-multy-select label="part of teams" [(values)]="account.typeData.partOfTeams">
                        </app-dropdown-multy-select> -->
                        <app-dropdown-label [(value)]="account.typeData.status" [label]="'patient status'|translatePipe" [options]="['KYP','Treatment','Acclamation']" ></app-dropdown-label>
                          
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div class="spinner-container">
                                <mat-spinner></mat-spinner>
                            </div>
                        </ng-container>
                    </ng-container>


                </div>
            </div>
            <button (click)="saveAccount()" class="btn btn-success">{{'save'|translatePipe}}</button>
        </ng-container>

        <ng-template #dataShow>
            <div class="row data-show">
                <div class="col-12 col-lg-4 data text-center mb-4">
                    <img class="mb-4" [src]="account.data.profileImg" alt="account image">
                    <p class="account-full-name">{{account.data.fullName}}</p>
                    <div class="title-and-description">
                        <p>{{account.data.title}}</p>
                        <button (click)="openDescription()" class="btn btn-info">
                            <i class="pe-7s-info"></i>
                        </button>
                    </div>

                    <div class="btns-container">
                        <a *ngIf="account.contactInfo.phone" class="m-1 btn btn-success"
                            href="{{'tel:'+account.contactInfo.phone}}">
                            <i class="fa fa-fw"></i>
                        </a>
                        <a *ngIf="account.contactInfo.email" class="m-1 btn btn-success"
                            href="{{'mailTo:'+account.contactInfo.email}}">
                            <i class="fa fa-fw"></i>
                        </a>
                        <a [href]="'https://wa.me/'+account.contactInfo.phone" *ngIf="account.contactInfo.phone"
                            class="m-1 btn btn-success">
                            <i class="fa fa-fw"></i>
                        </a>
                        <!-- <button *ngIf="account.contactInfo.email" class="btn btn-success">Email</button> -->
                        <a routerLink="/chat" *ngIf="account.contactInfo.member.id" class="m-1 btn btn-success">
                            <i class="pe-7s-chat"></i>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-lg-4 contact-info">
                    <h3 class="card-title text-center">{{'contact info'|translatePipe}}</h3>
                    <div class="table-key-value-container mb-4">
                        <div class="table-keys">
                            <div *ngIf="memberIdentidier" class="icon-text-container key-container">
                                <i class="pe-7s-users"></i>
                                <p> {{'user'|translatePipe}}</p>
                            </div>
                            <div *ngIf="account.contactInfo.email" class="icon-text-container key-container">
                                <i class="pe-7s-mail"></i>
                                <p>{{'email'|translatePipe}}</p>
                            </div>
                            <div *ngIf="account.contactInfo.phone" class="icon-text-container key-container">
                                <!-- <i class="fa fa-fw"></i> -->
                                <i class="pe-7s-call"></i>
                                <p>{{'phone'|translatePipe}}</p>
                            </div>
                            <div class="icon-text-container key-container">
                                <ng-container [ngSwitch]="account.contactInfo.gender">
                                    <i *ngSwitchCase="'male'" class="pe-7s-male"></i>
                                    <i *ngSwitchCase="'female'" class="pe-7s-female"></i>
                                    <i *ngSwitchDefault class="fa fa-fw"></i>
                                </ng-container>
                                <p>{{'gender'|translatePipe}}</p>
                            </div>
                            <div *ngIf="account.contactInfo.address" class="icon-text-container key-container">
                                <i class="pe-7s-map-marker"></i>
                                <p>{{'home address'|translatePipe}}</p>
                            </div>
                        </div>
                        <div class="table-values">
                            <div *ngIf="memberIdentidier" class="value-container">
                                <p>{{memberIdentidier}}
                            </div>

                            <div *ngIf="account.contactInfo.email" class="value-container">
                                <p>{{account.contactInfo.email}}</p>
                            </div>


                            <div *ngIf="account.contactInfo.phone" class="value-container">
                                <p> {{account.contactInfo.phone}}</p>
                            </div>


                            <div class="value-container">
                                <p> {{account.contactInfo.gender|translatePipe}}</p>
                            </div>

                            <div *ngIf="account.contactInfo.address" class="value-container">
                                <p>{{account.contactInfo.address}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 type-info">
                    <ng-container [ngSwitch]="type">
                        <ng-container *ngSwitchCase="'candidate'">
                            <h3 class="card-title text-center">{{'candidate info'|translatePipe}}</h3>
                            <div class="table-key-value-container mb-4">
                                <div class="table-keys">
                                   
                                    <div class=" key-container">
                                        <p>{{'hirring date'|translatePipe}}</p>
                                    </div>
                                    <div class="key-container">
                                        <p>{{'devision'|translatePipe}}</p>
                                    </div>
                                    <div class=" key-container">
                                        <p>{{'candidate status'|translatePipe}}</p>
                                    </div>
                                </div>
                                <div class="table-values">
                                    <div class="value-container">
                                        <p>{{account.typeData.hiringDate | formatDate}}</p>
                                    </div>
                                    <div class="value-container">
                                        <p> {{account.typeData.object.type |translatePipe}}: {{account.typeData.object.name}}</p>
                                    </div>
                                    <div class="value-container">
                                        <p>{{account.typeData.candidateStatus?('active'|translatePipe):('inavtive'|translatePipe)}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <p *ngIf="account.typeData.idNumber"><span>ID number:</span> {{account.typeData.idNumber}}
                            </p>
                            <p><span>Hirring date:</span> {{account.typeData.hiringDate | formatDate}}</p>
                            <p><span>Object:</span> {{account.typeData.object.type}}: {{account.typeData.object.name}}
                            </p>
                            <p *ngIf="account.typeData.directManagers.length"><span>Direct managers:</span>
                                {{directManagerShowData}}</p>
                            <p><span>Employee status:</span> {{account.typeData.employeeStatus?'Active':'Inavtive'}}</p> -->
                        </ng-container>
                        <ng-container *ngSwitchCase="'employee'">
                            <h3 class="card-title text-center">{{'employee info'|translatePipe}}</h3>
                            <div class="table-key-value-container mb-4">
                                <div class="table-keys">
                                    <div *ngIf="account.typeData.idNumber" class="key-container">
                                        <p>{{'id number'|translatePipe}}</p>
                                    </div>
                                    <div class=" key-container">
                                        <p>{{'start date'|translatePipe}}</p>
                                    </div>
                                    <div class="key-container">
                                        <p>{{'devision'|translatePipe}}</p>
                                    </div>
                                    <div *ngIf="account.typeData.directManagers.length" class="key-container">
                                        <p>{{'direct managers'|translatePipe}}</p>
                                    </div>
                                    <div class=" key-container">
                                        <p>{{'employee status'|translatePipe}}</p>
                                    </div>
                                </div>
                                <div class="table-values">
                                    <div *ngIf="account.typeData.idNumber" class="value-container">
                                        <p>{{account.typeData.idNumber}}
                                    </div>

                                    <div class="value-container">
                                        <p>{{account.typeData.hiringDate | formatDate}}</p>
                                    </div>


                                    <div class="value-container">
                                        <p> {{account.typeData.object.type | translatePipe}}: {{account.typeData.object.name}}</p>
                                    </div>


                                    <div *ngIf="account.typeData.directManagers.length" class="value-container">
                                        <p> {{directManagerShowData}}</p>
                                    </div>

                                    <div class="value-container">
                                        <p>{{account.typeData.employeeStatus?('active'|translatePipe):('inavtive'|translatePipe)}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <p *ngIf="account.typeData.idNumber"><span>ID number:</span> {{account.typeData.idNumber}}
                            </p>
                            <p><span>Hirring date:</span> {{account.typeData.hiringDate | formatDate}}</p>
                            <p><span>Object:</span> {{account.typeData.object.type}}: {{account.typeData.object.name}}
                            </p>
                            <p *ngIf="account.typeData.directManagers.length"><span>Direct managers:</span>
                                {{directManagerShowData}}</p>
                            <p><span>Employee status:</span> {{account.typeData.employeeStatus?'Active':'Inavtive'}}</p> -->
                        </ng-container>
                        <ng-container *ngSwitchCase="'customer'">
                            <h3 class="card-title text-center">{{'customer info'|translatePipe}}</h3>
                            <div class="table-key-value-container mb-4">
                                <div class="table-keys">
                                    <div *ngIf="account.typeData.idNumber" class="key-container">
                                        <p>{{'id number'|translatePipe}}</p>
                                    </div>
                                    <div class=" key-container">
                                        <p>{{'start date'|translatePipe}}</p>
                                    </div>
                                    <div class="key-container">
                                        <p>{{'sector'|translatePipe}}</p>
                                    </div>
                                    <div *ngIf="account.typeData.seconderyContact.length" class="key-container">
                                        <p>{{'secondery contact'|translatePipe}}</p>
                                    </div>
                                    <div class=" key-container">
                                        <p>{{'company status'|translatePipe}}</p>
                                    </div>
                                </div>
                                <div class="table-values">
                                    <div *ngIf="account.typeData.idNumber" class="value-container">
                                        <p>{{account.typeData.idNumber}}
                                    </div>

                                    <div class="value-container">
                                        <p>{{account.typeData.startDate | formatDate}}</p>
                                    </div>


                                    <div class="value-container">
                                        <p> {{account.typeData.object.type| translatePipe}}: {{account.typeData.object.name}}</p>
                                    </div>


                                    <div *ngIf="account.typeData.seconderyContact.length" class="value-container">
                                        <p> {{seconderyContactShowData}}</p>
                                    </div>

                                    <div class="value-container">
                                        <p>{{account.typeData.companyStatus?('active'|translatePipe):('inactive'|translatePipe)}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'patient'">
                            <h3 class="card-title text-center">{{'patient info'|translatePipe}}</h3>
                            <div class="table-key-value-container mb-4">
                                <div class="table-keys">
                                    <div *ngIf="account.typeData.idNumber" class="key-container">
                                        <p>{{'id number'|translatePipe}}</p>
                                    </div>

                                    <div *ngIf="account.typeData.diagnosis" class="key-container">
                                        <p>{{'diagnosis'|translatePipe}}</p>
                                    </div>
                                    <div class="key-container">
                                        <p>{{'department'|translatePipe}}</p>
                                    </div>
                                    <div class=" key-container">
                                        <p>{{'patient status'|translatePipe}}</p>
                                    </div>
                                </div>
                                <div class="table-values">
                                    <div *ngIf="account.typeData.idNumber" class="value-container">
                                        <p>{{account.typeData.idNumber}}
                                    </div>
                                    <div *ngIf="account.typeData.diagnosis" class="value-container">
                                        <p>{{account.typeData.diagnosis}}
                                    </div>

                                    <div class="value-container">
                                        <p> {{account.typeData.object.type | translatePipe}}: {{account.typeData.object.name}}</p>
                                    </div>

                                    <div class="value-container">
                                        <p>{{account.typeData.status}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div class="spinner-container">
                                <mat-spinner></mat-spinner>
                            </div>
                        </ng-container>
                    </ng-container>


                </div>
            </div>

        </ng-template>
    </div>
</div>

<ng-container *ngIf="account.id" class="events-container">
    <div dirControl class="main-card card mb-3">
        <div class="card-body">
            <h3 class="card-title text-center">{{'recent events'|translatePipe}}</h3>
            <div class="row mb-3">
                <div *ngFor="let event of accountEvents.slice(-5)" class="col-6 col-sm-4 col-lg-2 mb-3">
                    <app-portal-event-mini-view [event]="event"></app-portal-event-mini-view>
                </div>
                <div class="col-6 col-sm-4 col-lg-2 mb-3">
                    <app-add-event-mini-view [currAccountId]="account.id"></app-add-event-mini-view>
                </div>
            </div>
            <a *ngIf="accountEvents.length>5" [routerLink]="'/portal-event-managment/'+account.id" class="see-more btn btn-primary">
                {{'see more'|translatePipe}}
            </a>

        </div>
    </div>
</ng-container>

<ng-template #description let-c="close" let-d="dismiss">
    <div class="modal-header">

        <h4 class="modal-title">
            {{
            (!account.id) || isEdit?
            'Edit description':
            'Description'
            }}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="(!account.id) || isEdit; else descriptionText">
            <app-textarea-label [(value)]="account.description" label="description" name="description">
            </app-textarea-label>
        </ng-container>
        <ng-template #descriptionText>
            <p>{{account.description}}</p>
        </ng-template>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
    </div>
</ng-template>


