import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(time: any): string {
    const { hour, minute } = time;;
    const hourStr = hour > 9 ? (hour + '') : ('0' + hour)
    const minuteStr = minute > 9 ? (minute + '') : ('0' + minute)
    return `${hourStr}:${minuteStr}`;
  }

}
