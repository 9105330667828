import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  constructor(
    private memberService: MembersService,
    private organizationalStructureService: OrganizationalStructureService
  ) { }

  private MAX_ON_ROW = 4;
  loggedInUserId = 'u101'
  cardsRows: any[] = [];
  organizationalStructure: Group[] = []
  isShowAllCards = false;
  slideConfig = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };

  async ngOnInit(): Promise<void> {

    this.organizationalStructure = await this.organizationalStructureService.getGroups().toPromise();
    const member = await this.memberService.getMember(this.loggedInUserId).toPromise()
    const cards = member.cards;
    cards.forEach(card => {
      const cardsRowsLengh = this.cardsRows.length
      if (!cardsRowsLengh || this.cardsRows[cardsRowsLengh - 1].length === this.MAX_ON_ROW) this.cardsRows.push([card])
      else this.cardsRows[cardsRowsLengh - 1].push(card);
    })

  }

  getCardImg(groupId) {
    return this.organizationalStructure.find(group => group.id === groupId)?.logoUrl
  }

}
