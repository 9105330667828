import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// // Pages

import { OrganizationalStructureComponent } from './pages/organizational-structure/organizational-structure.component';
import { OrganizationalStructureEditComponent } from './pages/organizational-structure-edit/organizational-structure-edit.component';
import { OrganizationalStructureMembersComponent } from './pages/organizational-structure-members/organizational-structure-members.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DuplicateOptionComponent } from './pages/duplicate-option/duplicate-option.component';
import { SystemSettingsComponent } from './pages/system-settings/system-settings.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SearchMembersComponent } from './pages/search-members/search-members.component';
import { MembersResaultsComponent } from './pages/members-resaults/members-resaults.component';
import { ManageMembersComponent } from './pages/manage-members/manage-members.component';
import { MemberProfileViewComponent } from './pages/member-profile-view/member-profile-view.component';
import { InviteMemberComponent } from './pages/invite-member/invite-member.component';
import { GlobalSearchComponent } from './pages/global-search/global-search.component';
import { GlobalSearchResaultsComponent } from './pages/global-search-resaults/global-search-resaults.component';
import { CardViewComponent } from './pages/card-view/card-view.component';
import { CardManagementComponent } from './pages/card-management/card-management.component';
import { CardViewGiveComponent } from './pages/card-view-give/card-view-give.component';
import { CardUsageComponent } from './pages/card-usage/card-usage.component';
import { CardManagementTableComponent } from './pages/card-management-table/card-management-table.component';
import { EventManagementComponent } from './pages/event-management/event-management.component';
import { EventViewComponent } from './pages/event-view/event-view.component';
import { EventAttendanceComponent } from './pages/event-attendance/event-attendance.component';
import { SetViewComponent } from './pages/set-view/set-view.component';
import { PropertyListComponent } from './pages/property-list/property-list.component';
import { SetManagementComponent } from './pages/set-management/set-management.component';
import { ChatSettingsComponent } from './pages/chat-settings/chat-settings.component';
import { LogSearchComponent } from './pages/log-search/log-search.component';
import { LogResaultsComponent } from './pages/log-resaults/log-resaults.component';
import { GlobalAdministratorsComponent } from './pages/global-administrators/global-administrators.component';
import { LocalAdministratorsComponent } from './pages/local-administrators/local-administrators.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { RecycleBinComponent } from './pages/recycle-bin/recycle-bin.component';
import { BackupsComponent } from './pages/backups/backups.component';
import { MediaComponent } from './pages/media/media.component';
import { CustomerServiceComponent } from './pages/customer-service/customer-service.component';
import { EcommerceComponent } from './pages/ecommerce/ecommerce.component';
import { DigitalWalletComponent } from './pages/digital-wallet/digital-wallet.component';
import { SocialNetworkComponent } from './pages/social-network/social-network.component';
import { RegisterClubComponent } from './pages/register-club/register-club.component';
import { LoginOrganizationComponent } from './pages/login-organization/login-organization.component';
import { ForgotPasswordOrganizationComponent } from './pages/forgot-password-organization/forgot-password-organization.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { MemberProfileComponent } from './pages/member-profile/member-profile.component';
import { ClubSocialNetworkComponent } from './pages/club-social-network/club-social-network.component';
import { GroupViewComponent } from './pages/group-view/group-view.component';
import { GroupInviteComponent } from './pages/group-invite/group-invite.component';
import { OrganizationDashboardComponent } from './pages/organization-dashboard/organization-dashboard.component';
import { B2cLayoutComponent } from './Layout/b2c-layout/b2c-layout.component';
import { CardsComponent } from './pages/cards/cards.component';
import { SetsComponent } from './pages/sets/sets.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { ChatComponent } from './pages/chat/chat.component';
import { UserControlPannelComponent } from './pages/user-control-pannel/user-control-pannel.component';
import { ModuleManagmentComponent } from './pages/module-managment/module-managment.component';
import { AccountViewComponent } from './pages/account-view/account-view.component';
import { PortalEventViewComponent } from './pages/portal-event-view/portal-event-view.component';
import { PortalPostsComponent } from './pages/portal-posts/portal-posts.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ManageEmployeesComponent } from './pages/manage-employees/manage-employees.component';
import { PortalEventManagmentComponent } from './pages/portal-event-managment/portal-event-managment.component';
import { PortalEventViewReadonlyComponent } from './pages/portal-event-view-readonly/portal-event-view-readonly.component';
import { ManageCostumersComponent } from './pages/manage-costumers/manage-costumers.component';
import { EmployeeDirectoryComponent } from './pages/employee-directory/employee-directory.component';
import { CustomerDirectoryComponent } from './pages/customer-directory/customer-directory.component';
import { PortalEventViewReadonlyAccountComponent } from './pages/portal-event-view-readonly-account/portal-event-view-readonly-account.component';
import { ManageCandidatesComponent } from './pages/manage-candidates/manage-candidates.component';
import { OrganizationMediaComponent } from './pages/organization-media/organization-media.component';
import { OrganizationLandingPageComponent } from './pages/organization-landing-page/organization-landing-page.component';
import { OrganizationEccomerceComponent } from './pages/organization-eccomerce/organization-eccomerce.component';
import { EmployeePortalComponent } from './pages/employee-portal/employee-portal.component';
import { ManagePatientsComponent } from './pages/manage-patients/manage-patients.component';
import { CustomerPortalComponent } from './pages/customer-portal/customer-portal.component';
import { CandidateDirectoryComponent } from './pages/candidate-directory/candidate-directory.component';
import { CandidatePortalComponent } from './pages/candidate-portal/candidate-portal.component';
import { PortalEmployeePostsComponent } from './pages/portal-employee-posts/portal-employee-posts.component';
import { PortalCostumerPostsComponent } from './pages/portal-costumer-posts/portal-costumer-posts.component';
import { PortalCandidatePostsComponent } from './pages/portal-candidate-posts/portal-candidate-posts.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AuthGuard } from './auth/auth.guard';
import { RoleViewComponent } from './pages/role-view/role-view.component';
import { RoleManagmentComponent } from './pages/role-managment/role-managment.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TaskManagmentComponent } from './pages/task-managment/task-managment.component';
import { TriggerViewComponent } from './pages/trigger-view/trigger-view.component';
import { TriggerManagmentComponent } from './pages/trigger-managment/trigger-managment.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { AccountViewServerComponent } from './pages/account-view-server/account-view-server.component';
import { PortalEventViewServerComponent } from './pages/portal-event-view-server/portal-event-view-server.component';
import { ManageCustomerServerComponent } from './pages/manage-customer-server/manage-customer-server.component';
import { ManageCandidateServerComponent } from './pages/manage-candidate-server/manage-candidate-server.component';
import { AccountEventViewServerComponent } from './pages/account-event-view-server/account-event-view-server.component';
import { ManageEmployeesServerComponent } from './pages/manage-employees-server/manage-employees-server.component';
import { StructureServerComponent } from './pages/structure-server/structure-server.component';
import { StructureViewServerComponent } from './pages/structure-view-server/structure-view-server.component';
import { GroupManageMembersServerComponent } from './pages/group-manage-members-server/group-manage-members-server.component';
import { MemberViewServerComponent } from './pages/member-view-server/member-view-server.component';
import { ManageMembersServerComponent } from './pages/manage-members-server/manage-members-server.component';
// import { ChatComponent } from './DemoPages/Applications/chat/chat.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'apps',
        loadChildren: () => import('./DemoPages/Applications/Applications.module').then(m => m.ApplicationModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./DemoPages/Charts/Charts.module').then(m => m.ChartModule)
      },
      {
        path: 'components',
        loadChildren: () => import('./DemoPages/Components/Components.module').then(m => m.ComponentsDrawerModule)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./DemoPages/Dashboards/Dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: 'elements',
        loadChildren: () => import('./DemoPages/Elements/Elements.module').then(m => m.ElementsModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./DemoPages/Forms/Elements/form-elements.module').then(m => m.FormElementModule)
      },
      {
        path: 'form-widgets',
        loadChildren: () => import('./DemoPages/Forms/Widgets/forms-widgets.module').then(m => m.FormWidgetsModule)
      },
      {
        path: 'button-indicators',
        loadChildren: () => import('./DemoPages/Material/ButtonsIndicators/buttonsIndicators.module').then(m => m.MateriaButoonIndicatorslModule)
      },
      {
        path: 'layout',
        loadChildren: () => import('./DemoPages/Material/Layout/layout.module').then(m => m.MaterialLayoutModule)
      },
      {
        path: 'form-controls',
        loadChildren: () => import('./DemoPages/Material/FormControls/formcontrols.module').then(m => m.MaterialFormControlModule)
      },
      {
        path: 'material',
        loadChildren: () => import('./DemoPages/Material/Material.module').then(m => m.MaterialModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./DemoPages/Widgets/Widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'tables/bootstrap',
        loadChildren: () => import('./DemoPages/Tables/tables-main/tables-main.module').then(m => m.TablesMainModule)
      },
      {
        path: 'editor',
        component: OrganizationalStructureComponent
      },
      {
        path: 'structure-server',
        component: StructureServerComponent
      },
      {
        path: 'structure-view-server/parent/:parent',
        component: StructureViewServerComponent
      },
      {
        path: 'structure-view-server/:structure',
        component: StructureViewServerComponent
      },
      {
        path: 'editor/:id',
        component: OrganizationalStructureEditComponent
      },
      {
        path: 'editor/parent/:parentId',
        component: OrganizationalStructureEditComponent
      },
      {
        path: 'editor/:id/members',
        component: OrganizationalStructureMembersComponent
      },
      {
        path: 'group-manage-members/:id',
        component: GroupManageMembersServerComponent
      },
      {
        path: 'duplicate/:id',
        component: DuplicateOptionComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'system_settings',
        component: SystemSettingsComponent
      },
      {
        path: 'search-members',
        component: SearchMembersComponent
      },
      {
        path: 'members-resaults',
        component: MembersResaultsComponent
      },
      {
        path: 'manage-members',
        component: ManageMembersComponent
      },
      {
        path: 'manage-members-server',
        component: ManageMembersServerComponent
      },
      {
        path: 'member-view/:id',
        component: MemberProfileViewComponent
      },
      {
        path: 'member-view',
        component: MemberProfileViewComponent
      },
      {
        path: 'member-view-server/:id',
        component: MemberViewServerComponent
      },
      {
        path: 'member-view-server',
        component: MemberViewServerComponent
      },
      {
        path: 'task-view',
        component: TaskViewComponent
      },
      {
        path: 'task-view/:id',
        component: TaskViewComponent
      },
      {
        path: 'trigger-view',
        component: TriggerViewComponent
      },
      {
        path: 'trigger-view/:id',
        component: TriggerViewComponent
      },
      {
        path: 'trigger-view/:id',
        component: TriggerViewComponent
      },
      {
        path: 'trigger-managment',
        component: TriggerManagmentComponent
      },
      {
        path: 'task-managment',
        component: TaskManagmentComponent
      },
      {
        path: 'invite-member',
        component: InviteMemberComponent
      },
      {
        path: 'global-search',
        component: GlobalSearchComponent
      },
      {
        path: 'log-search',
        component: LogSearchComponent
      },
      {
        path: 'log-resaults',
        component: LogResaultsComponent
      },
      {
        path: 'global-search-resaults',
        component: GlobalSearchResaultsComponent
      },
      {
        path: 'card-view/:id',
        component: CardViewComponent
      },
      {
        path: 'card-management',
        component: CardManagementComponent
      },
      {
        path: 'card-management/card/:id',
        component: CardManagementTableComponent
      },
      {
        path: 'card-management/user/id',
        component: CardManagementTableComponent
      },
      {
        path: 'card-view',
        component: CardViewComponent
      },
      {
        path: 'role-view/:id',
        component: RoleViewComponent
      },
      {
        path: 'role-managment',
        component: RoleManagmentComponent
      },
      {
        path: 'role-view',
        component: RoleViewComponent
      },
      {
        path: 'card-give/:id',
        component: CardViewGiveComponent
      },
      {
        path: 'account-view/type/:type',
        component: AccountViewComponent
      },
      {
        path: 'account-view-server/type/:type',
        component: AccountViewServerComponent
      },
      {
        path: 'employees',
        component: ManageEmployeesComponent
      },
      {
        path: 'employees-server',
        component: ManageEmployeesServerComponent
      },
      {
        path: 'customers',
        component: ManageCostumersComponent
      },
      {
        path: 'customers-server',
        component: ManageCustomerServerComponent
      },
      {
        path: 'candidates',
        component: ManageCandidatesComponent
      },
      {
        path: 'candidates-server',
        component: ManageCandidateServerComponent
      },
      {
        path: 'patients',
        component: ManagePatientsComponent
      },
      {
        path: 'portal-event-view/account/:accountId',
        component: PortalEventViewComponent
      },
      {
        path: 'account-event-view-server/account/:typeAndId',
        component: AccountEventViewServerComponent
      },
      {
        path: 'portal-event-view-server/account/:accountId',
        component: PortalEventViewServerComponent
      },
      {
        path: 'portal-event-view/event/readonly/:eventId',
        component: PortalEventViewReadonlyComponent
      },
      {
        path: 'portal-event-view/event/:eventId',
        component: PortalEventViewComponent
      },
      {
        path: 'portal-event-view-server/event/:eventId',
        component: AccountEventViewServerComponent
      },
      {
        path: 'portal-event-managment/:accountId',
        component: PortalEventManagmentComponent
      },
      {
        path: 'account-view/account/:id',
        component: AccountViewComponent
      },
      {
        path: 'account-view-server/account/:typeAndId',
        component: AccountViewServerComponent
      },
      {
        path: 'portal-posts',
        component: PortalPostsComponent
      },
      {
        path: 'portal-employee-posts',
        component: PortalEmployeePostsComponent
      },
      {
        path: 'portal-customer-posts',
        component: PortalCostumerPostsComponent
      },
      {
        path: 'portal-candidate-posts',
        component: PortalCandidatePostsComponent
      },
      {
        path: 'portal-patient-posts',
        component: PortalCandidatePostsComponent
      },
      {
        path: 'card-give/edit/:cardId',
        component: CardViewGiveComponent
      },
      {
        path: 'card-usage',
        component: CardUsageComponent
      },
      {
        path: 'card-usage/:id',
        component: CardUsageComponent
      },
      {
        path: 'event-management',
        component: EventManagementComponent
      },
      {
        path: 'event-view',
        component: EventViewComponent
      },
      {
        path: 'event-view/:id',
        component: EventViewComponent
      },
      {
        path: 'event-attendance/:id',
        component: EventAttendanceComponent
      },
      {
        path: 'set-management',
        component: SetManagementComponent
      },
      {
        path: 'set-view/:id',
        component: SetViewComponent
      },
      {
        path: 'set-view/property/:property',
        component: SetViewComponent
      },
      {
        path: 'chat-settings',
        component: ChatSettingsComponent
      },
      {
        path: 'property-list',
        component: PropertyListComponent
      },
      {
        path: 'custoumer-service',
        component: CustomerServiceComponent
      },
      {
        path: 'media',
        component: MediaComponent
      },
      {
        path: 'landing-page',
        component: LandingPageComponent
      },
      {
        path: 'ecommerce',
        component: EcommerceComponent
      },
      {
        path: 'social-network',
        component: SocialNetworkComponent
      },
      {
        path: 'digital-wallet',
        component: DigitalWalletComponent
      },
      {
        path: 'global-search-resaults',
        component: GlobalSearchResaultsComponent
      },
      {
        path: 'global-administrators',
        component: GlobalAdministratorsComponent
      },
      {
        path: 'local-administrators',
        component: LocalAdministratorsComponent
      },
      {
        path: 'archive',
        component: ArchiveComponent
      },
      {
        path: 'recycle-bin',
        component: RecycleBinComponent
      },
      {
        path: 'module-memnegment',
        component: ModuleManagmentComponent
      },
      {
        path: 'backups',
        component: BackupsComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },

    ]
  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [
      // User Pages
      {
        path: 'login', component: LoginComponent
      },
      {
        path: 'register', component: RegisterComponent
      },

      {
        path: 'forgot-password', component: ForgotPasswordComponent
      },
      {
        path: 'forgot-password/:id', component: ForgotPasswordOrganizationComponent
      },
      {
        path: 'forgot-password/:id', component: ForgotPasswordOrganizationComponent
      },
      {
        path: 'register/:id', component: RegisterClubComponent
      },
      {
        path: 'login/:id', component: LoginOrganizationComponent
      },
    ]
  },
  {
    path: '',
    component: B2cLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'calendar/:id', component: CalendarComponent
      },
      {
        path: 'profile', component: ProfileComponent
      },
      {
        path: 'cards', component: CardsComponent
      },
      {
        path: 'sets', component: SetsComponent
      },
      {
        path: 'groups', component: GroupsComponent
      },
      {
        path: 'tasks', component: TasksComponent
      },
      {
        path: 'organization-media', component: OrganizationMediaComponent
      },
      {
        path: 'organization-landing-page', component: OrganizationLandingPageComponent
      },
      {
        path: 'organization-ecommerce', component: OrganizationEccomerceComponent
      },
      {
        path: 'chat', component: ChatComponent
      },
      {
        path: 'calendar', component: CalendarComponent
      },
      {
        path: 'user-control-pannel', component: UserControlPannelComponent
      },
      {
        path: 'group-view/:id', component: GroupViewComponent
      },
      {
        path: 'dashboard/:id', component: OrganizationDashboardComponent
      },
      {
        path: 'group-invite/:id', component: GroupInviteComponent
      },
      {
        path: 'member-profile/:id', component: MemberProfileComponent
      },
      {
        path: 'club-social-network', component: ClubSocialNetworkComponent
      },
      {
        path: 'portal-event-view/event/readonly/account/:eventId',
        component: PortalEventViewReadonlyAccountComponent
      },
      {
        path: 'employee-directory',
        component: EmployeeDirectoryComponent
      },
      {
        path: 'employee-portal',
        component: EmployeePortalComponent
      },
      {
        path: 'customer-directory',
        component: CustomerDirectoryComponent
      },
      {
        path: 'customer-portal',
        component: CustomerPortalComponent
      },
      {
        path: 'candidate-directory',
        component: CandidateDirectoryComponent
      },
      {
        path: 'candidate-portal',
        component: CandidatePortalComponent
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
