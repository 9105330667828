<div dirControl class="header card mb-3">
    <div class="card-body">
        <h3 class="card-title">{{'InviteMember.email'|translatePipe}}</h3>

        <app-input-label type="text" [label]="'email'|translatePipe" name="email" [(value)]="email">
        </app-input-label>

        <div class="checkbox-flex-gap-10">
            <input (change)="isHaveName = !isHaveName" [checked]="isHaveName" class="form-check-input gap-check" type="checkbox">
            <div class="flexyc-input-container">
                <app-input-label type="text" [label]="'name'|translatePipe" name="name" [(value)]="name">
                </app-input-label>
            </div>
        </div>

        <button [disabled]="isSendDisable()" class="btn btn-success">{{'send'|translatePipe}}</button>
    </div>
</div>
<!-- <div class="header card">
    <div class="card-body">
        <h3 class="card-title">Invite via link</h3>
        <div class="input-group mb-3">
            <label>link</label>
            <input type="text" class="form-control" [value]="link"
                placeholder="content will be copied after click the copy button">
            <button class="btn btn-primary" type="button" ngxClipboard [cbContent]="link"
                (cbOnSuccess)="isCopied = true" (cbOnError)="onCopyFailure()"
                [class.btn-success]="isCopied">Copy</button>
        </div>

        <div class="row mb-3">
            <div class="col-2">
                <app-input-label type="number" label="Limit usage" name="limit" [(value)]="linkLimit">
                </app-input-label>
            </div>
        </div>
        <button class="btn btn-success">generate</button>
    </div>

</div> -->