import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { OtechI18nModule } from 'projects/otech-i18n/src/lib/otech-i18n.module';
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.store';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-sidebar-b2c',
  templateUrl: './sidebar-b2c.component.html',
  styleUrls: ['./sidebar-b2c.component.scss']

})
export class SidebarB2cComponent implements OnInit {
  public extraParameter: any;

  loggedInUser = {
    "id": "",
    "identifier": "Guest",
    "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif",
  }

  subscription: Subscription[] = []

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private i18nService: OtechI18nService,
    public translateService: OtechI18nService,
    private store: Store<AppState>,
    private memberService: MembersService
  ) { }

  setLanguage() {
    if (this.i18nService.locale == 'en') { this.i18nService.locale = 'he' }
    else { this.i18nService.locale = 'en' }
  }

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });


    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) this.loggedInUser = await this.memberService.getMember(auth.id).toPromise()
      })

    )


    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }


}
