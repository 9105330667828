<div dirControl class="main-card card  total-sets-card portal-event-view">
    <div *ngIf="event" class="card-body">
        <div class="imgs-container">
            <div class="">
                <img class="profile-image" *ngIf="account?.data?.profileImg" [src]="account?.data?.profileImg" alt="">
                <h3 class="card-title text-center">
                    <p>{{account?.data?.fullName}}</p>
                </h3>
            </div>
            <div class="">
                <img *ngIf="event?.eventImage" [src]="event?.eventImage" alt="">
                <h3 class="card-title text-center">
                    <p>{{event?.eventType || event?.eventName}}</p>
                </h3>
            </div>
        </div>
        <!-- <p>Name: {{event?.eventName}}</p>
        <p *ngIf="event.eventParticipants.length">Participants: {{eventParticipantsStr}}</p>
        <p *ngIf="event.eventDate">Date: {{event.eventDate | formatDate}}</p>
        <p *ngIf="event.eventLocation">Location: {{event.eventLocation}}</p>
        <p *ngIf="event.eventDescription">Description: {{event.eventDescription}}</p>
        <p>is visibale to account: {{event.isVisivaleToAccount}}</p> -->

        <div class="main-data-container">

            <div class="table-key-value-container">
                <div class="table-keys">
                    <div *ngIf="event.eventType" class="icon-text-container key-container">
                        <i class="pe-7s-file"></i>
                        <p>{{'type'|translatePipe}}</p>
                    </div>
                    <div *ngIf="event.eventLocation" class="icon-text-container key-container">
                        <i class="pe-7s-map-marker"></i>
                        <p>{{'event location'|translatePipe}}</p>
                    </div>
                    <div *ngIf="event.eventParticipants.length" class="icon-text-container key-container">
                        <i class="pe-7s-users"></i>
                        <p>{{'event participants'|translatePipe}}</p>
                    </div>
                    <div *ngIf="!isFromAccount" class="icon-text-container key-container">
                        <i class="pe-7s-look"></i>
                        <p>{{'Visible only managers'|translatePipe}}</p>
                    </div>
                    <ng-container *ngIf="event.formatData">


                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="icon-text-container key-container">
                                <i class="pe-7s-file"></i>
                                <p>{{question.title}}</p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="table-values">
                    <div *ngIf="event.eventType" class="value-container">
                        <p>{{event.eventType}}</p>
                    </div>
                    <div *ngIf="event.eventLocation" class="value-container">
                        <p>{{event.eventLocation}}</p>
                    </div>
                    <div *ngIf="event.eventParticipants.length" class="value-container">
                        <p>{{eventParticipantsStr}}</p>
                    </div>
                    <div *ngIf="!isFromAccount" class="value-container">
                        <p>{{event.isVisivaleToAccount? 'True':'False'}}</p>
                    </div>
                    <ng-container *ngIf="event.formatData">
                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="value-container">
                                <p>
                                    <app-set-preview [type]="question.property.type" [value]="question.data">
                                    </app-set-preview>
                                </p>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="event.eventDescription" class="description">
                <div class="table-key-value-container">
                    <div class="table-keys">

                        <div class="icon-text-container key-container">
                            <i class="pe-7s-note"></i>
                            <p>Description</p>
                        </div>
                    </div>
                    <div class="table-values">

                        <div class="value-container">
                            <p>{{event.eventDescription}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isFromAccount" class="btns-container mt-3">
            <a [routerLink]="'/account-view/account/'+account?.id" class="btn btn-primary ">Account</a>
            <a [routerLink]="'/portal-event-view/event/'+event?.id" class="btn btn-secondary">Edit</a>
            <button (click)="deleteEvent()" class="btn btn-danger">Delete</button>
        </div>

        <!-- <ng-container *ngIf="event.formatData">
            <div class="divider"></div>

            <div class="format-data-container">
                <div class="table-key-value-container">
                    <div class="table-keys">
                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="icon-text-container key-container">
                                <i class="pe-7s-file"></i>
                                <p>{{question.title}}</p>
                            </div>
                        </ng-container>

                    </div>
                    <div class="table-values">
                        <ng-container *ngFor="let question of event.formatData.questions">
                            <div *ngIf="question.data" class="value-container">
                                <p>
                                    <app-set-preview [type]="question.property.type" [value]="question.data">
                                    </app-set-preview>
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container> -->

    </div>
</div>
