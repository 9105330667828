import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RecycleBinListService } from 'src/app/services/recycle-bin-list.service';

@Component({
  selector: 'app-recycle-bin',
  templateUrl: './recycle-bin.component.html',
  styleUrls: ['./recycle-bin.component.scss']
})
export class RecycleBinComponent implements OnInit {

  subscriotion: Subscription[] = []
  recycleBinList: any[] = []
  search = ''

  constructor(
    private recycleBinListService: RecycleBinListService
  ) { }

  ngOnInit(): void {
    this.subscriotion.push(
      this.recycleBinListService.recycleBinList$.subscribe(recycleBinList => {
        this.updateRecycleBinList(recycleBinList)
      })
    )
    this.recycleBinListService.loadRecycleBinList();
  }

  updateRecycleBinList(recycleBinList) {
    this.recycleBinList = recycleBinList.map(recycleBinItem => ({ ...recycleBinItem, isCheck: false }));
  }

  filterRecycleBinList() {
    return this.recycleBinList.filter(recycleBinItem => (
      recycleBinItem.title.toLowerCase().includes(this.search.toLowerCase()) ||
      recycleBinItem.type.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

}
