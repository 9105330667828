<div class="mb-4">
    <h3 class="card-title text-center">{{'cards'|translatePipe}}</h3>
    <ng-container *ngIf="isShowAllCards || cardsRows.length<2; else cardsCarusel">
        <div class="row">
            <ng-container *ngFor="let cards of cardsRows">
                <div class="col-6 mb-4" *ngFor="let card of cards">
                    <app-card-usage-preview [isShowUseButton]="false" (useCard)="useCard($event)" [cardImg]="getCardImg(card.card.group.id)"
                        [card]="card">
                    </app-card-usage-preview>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #cardsCarusel>
        <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let cards of cardsRows">
                <div class="row">
                    <div class="col-6 mb-4" *ngFor="let card of cards">
                        <app-card-usage-preview [isShowUseButton]="false" (useCard)="useCard($event)" [cardImg]="getCardImg(card.card.group.id)"
                            [card]="card">
                        </app-card-usage-preview>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
    </ng-template>
    <button *ngIf="cardsRows.length>=2" (click)="isShowAllCards=!isShowAllCards" class="btn btn-primary">
        {{
        isShowAllCards?
        ('show less'|translatePipe):
        ('view all cards'|translatePipe)
        }}
    </button>
</div>