import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {

  @ViewChild('wooComerceUrlInput') wooComerceUrlInput!: any;

  isStatusActive = true;
  wooComerceUrl = 'https://barpahima.co.il/product-category/%d7%a9%d7%99%d7%a8%d7%95%d7%aa%d7%99%d7%9d-%d7%9e%d7%a7%d7%a6%d7%95%d7%a2%d7%99%d7%99%d7%9d/'

  get isInputValid() {
    const isValid = this.wooComerceUrlInput?.formControler?.valid
    return (isValid)
  }

  constructor() { }

  ngOnInit(): void {
  }

}
