import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortalPostService {

  private BASE_URL = 'https://api.flexyc.com/portalPosts';
  private _portalPosts$ = new BehaviorSubject<any[]>([])
  public portalPosts$ = this._portalPosts$.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  public loadPortalPosts() {
    this.http.get<any[]>(this.BASE_URL).pipe(
      tap(portalPosts => {
        this._portalPosts$.next(portalPosts);
      })
    ).toPromise()
  }

  public add(post) {
    post.createAt = Date.now();
    post.editedAt = null
    return this.http.post<any>(this.BASE_URL, post).pipe(
      tap(post => {
        this.loadPortalPosts();
      })
    )
  }

  public delete(postId) {
    return this.http.delete<any>(`${this.BASE_URL}/${postId}`).pipe(
      map(_ => {
        this.loadPortalPosts();
      })
    )
  }

  public edit(post) {
    post.editedAt = Date.now();
    return this.http.put<any>(`${this.BASE_URL}/${post.id}`, post).pipe(
      tap(set => {
        this.loadPortalPosts();
      })
    )
  }
}
