import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent implements OnInit {

  @Output() updateStatus = new EventEmitter<any>();
  @ViewChild('chooseTaskPop', { static: true }) elChooseTask: any;
  @Input() status: string;

  statusConfig = [
    {
      title:"pending",
      color: "#bdbdbd",
    },
    {
      title:"work on it",
      color: "#ffca28",
    },
    {
      title:"stuck",
      color: "#ef5350",
    },
    {
      title:"review",
      color: "#66bb6a",
    },
    {
      title:"done",
      color: "#98ee99",
    },
    {
      title:"no need",
      color: "#29b6f6",
    },
    {
      title:"canceled",
      color: "#8d8d8d",
    },
  ]


  constructor(
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  getColorStatus(){
    const currColor = this.statusConfig.find(statusConfigItem=>statusConfigItem.title===this.status);
    return currColor.color;
  }

  open(){
    this.modal.open(this.elChooseTask, { size: 'md' });
  }

  chooseStatus(status,cb?){
    if(cb) cb();
    this.updateStatus.emit(status.title);
    // this.status = status.title
  }

}
