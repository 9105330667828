import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private BASE_URL = 'https://api.flexyc.com/tasks';
  private _tasks$ = new BehaviorSubject<any[]>([])
  public tasks$ = this._tasks$.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  getEmpty() {
    return {
      title: '',
      group: {
        type: '',
        id: '',
        name: ''
      },
      members: [],
      files: [],
      content: '',
      startDate: Date.now(),
      deadline: Date.now(),
      priority: 1,
      id: "",
      status:'pending'
    }
  }

  public loadTasks() {
    this.http.get<any[]>(this.BASE_URL)
      .subscribe(tasks => {
        this._tasks$.next(tasks);
      })
  }

  public getTask(id: string) {
    return this.http.get<any>(`${this.BASE_URL}/${id}`)
  }

  public addTask(task) {
    delete task.id;
    return this.http.post<any>(this.BASE_URL, task).pipe(
      tap(_ => {
        this.loadTasks();
      })
    )
  }

  public editTask(task) {
    return this.http.put<any>(`${this.BASE_URL}/${task.id}`, task).pipe(
      tap(_ => {
        this.loadTasks();
      })
    )
  }

  public removeTask(taskId: string) {
    return this.http.delete<any>(`${this.BASE_URL}/${taskId}`).pipe(
      tap(_=>{
        this.loadTasks();
      })
    ) 
  }


}
