import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {

  // slideConfig2 = {
  //   className: 'center',
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: '0',
  //   slidesToShow: 1,
  //   speed: 500,
  //   dots: true,
  // };

  email = ''

  constructor() {
  }

  ngOnInit() {
  }

}
