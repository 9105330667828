import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { ChartType } from 'ng-apexcharts';
import { Label } from 'ng2-charts';
import { Subject, Subscription } from 'rxjs';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { UtilService } from 'src/app/services/util.service';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('calanderContainer') calanderContainer!: any;
  faCog = faCog;
  faTh = faTh;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faArrowRight = faArrowRight;
  faDotCircle = faDotCircle;
  faCheck = faCheck
  faTrash = faTrash
  faCalendarAlt = faCalendarAlt
  faArrowLeft = faArrowLeft
  timeInterval = null

  myOpts = {
    prefix: '',
    suffix: '',
    duration: 2,
  };
  date = new Date()
  refresh: Subject<any> = new Subject();
  events = [];
  lastRegisterted = []
  subscriotion: Subscription[] = []
  memberLength = 0;
  employeeLength = 0;
  customerLength = 0;
  loggedInUserIdentifier = ''
  greetings = ""
  time = {
    year: 0,
    month: 0,
    day: 0,
    hour: 0,
    minute: 0
  }

  dashboardData = [
    {
      icon: 'pe-7s-network',
      title: 'organization',
      color: 'red',
      routerLink: '/editor'
    },
    {
      icon: 'pe-7s-users',
      title: 'members',
      color: 'pink',
      routerLink: '/manage-members'
    },
    {
      icon: 'pe-7s-user',
      title: 'employees',
      color: 'blue',
      routerLink: '/employees'
    },
    {
      icon: 'pe-7s-add-user',
      title: 'recruitment',
      color: 'green',
      routerLink: '/candidates'
    },
    {
      icon: 'pe-7s-note2',
      title: 'customers',
      color: 'blue2',
      routerLink: '/customers'
    },
    {
      icon: 'pe-7s-date',
      title: 'events',
      color: 'indigo',
      routerLink: '/event-management'
    },
    {
      icon: 'pe-7s-id',
      title: 'cards',
      color: 'purple2',
      routerLink: '/card-management'
    },
    {
      icon: 'pe-7s-chat',
      title: 'Chat',
      color: 'red',
      routerLink: '/chat-settings'
    },
    {
      icon: 'pe-7s-menu',
      title: 'sets',
      color: 'purple',
      routerLink: '/set-management'
    },
    {
      icon: 'pe-7s-cart',
      title: 'ecommerce',
      color: 'blue2',
      routerLink: '/ecommerce'
    },
    {
      icon: 'pe-7s-display2',
      title: 'hrPortal',
      color: 'teal',
      routerLink: '/portal-posts'
    },
    {
      icon: 'pe-7s-display1',
      title: 'crmPortal',
      color: 'green',
      routerLink: '/portal-posts'
    },
    {
      icon: 'pe-7s-comment',
      title: 'socialNetwork',
      color: 'blue',
      routerLink: '/social-network'
    },
    {
      icon: 'pe-7s-film',
      title: 'media',
      color: 'green2',
      routerLink: '/media'
    },
    {
      icon: 'pe-7s-shopbag',
      title: 'customerService',
      color: 'grey2',
      routerLink: '/custoumer-service'
    },
    {
      icon: 'pe-7s-timer',
      title: 'Automation',
      color: 'amber',
      routerLink: '/trigger-managment'
    },
    {
      icon: 'pe-7s-global',
      title: 'search',
      color: 'cyan',
      routerLink: '/global-search'
    },
    {
      icon: 'pe-7s-key',
      title: 'administrators',
      color: 'green',
      routerLink: '/local-administrators'
    },
    {
      icon: 'pe-7s-check',
      title: 'Tasks',
      color: 'red',
      routerLink: '/task-managment'
    },
    {
      icon: 'pe-7s-config',
      title: 'settings',
      color: 'grey2',
      routerLink: '/system_settings'
    },
  ]

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  }



  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };
  public barChartLabels: Label[] = ['Groups members'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [65], label: 'Series A' },
    { data: [28], label: 'Series B' },
    { data: [28], label: 'Series B' },
    { data: [28], label: 'Series B' },
    { data: [28], label: 'Series B' },
    { data: [28], label: 'Series B' },
    { data: [28], label: 'Series B' },
  ];

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }



  public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: [
        '#ef5350',
        '#ec407a',
        '#ab47bc',
        '#7e57c2',
        '#5c6bc0',
        '#42a5f5',
        '#29b6f6',
        '#26c6da',
        '#26a69a',
        '#66bb6a',
        '#9ccc65',
        '#d4e157',
        '#ffca28',
        '#ffee58',
        '#ffa726',
        '#ff7043',
        '#bdbdbd',
        '#78909c',
      ],
    },
  ]

  constructor(
    private memberService: MembersService,
    private accountService: PortalAccountsService,
    public utilService: UtilService,
    private organizationStructureService: OrganizationalStructureService,
    private store: Store<AppState>,

  ) { }

  ngOnInit(): void {
    this.memberService.loadMembers();
    this.organizationStructureService.loadOrganizationalStructure();
    this.accountService.loadPortalAccounts();
    this.updateTime();
    this.timeInterval = setInterval(() => {
      this.updateTime();
    }, 1000)

    this.subscriotion.push(
      this.store.select('auth').subscribe(async (auth) => {
        if (auth.id) {
          const currMember = await this.memberService.getMember(auth.id).toPromise()
          this.loggedInUserIdentifier = currMember.identifier
        }
      })
    )



    this.subscriotion.push(
      this.memberService.members$.subscribe(members => {
        this.memberLength = members.length
        this.lastRegisterted = members.sort((member1, member2) => member1.memberSince - member2.memberSince).slice(0, 5);

      })
    )

    this.subscriotion.push(
      this.accountService.portalAccounts$.subscribe(accounts => {
        this.customerLength = 0;
        this.employeeLength = 0;
        accounts.forEach(account => {
          if (account.type === 'employee') this.employeeLength++;
          else if (account.type === 'costumer') this.customerLength++;
        })
      })
    )

    this.subscriotion.push(
      this.organizationStructureService.organizationalStructure$.subscribe(groups => {
        this.barChartData = groups
          .filter(group => group.type === 'group')
          .map(group => ({
            label: group.name,
            data: [group.members.length]
          }))
      })
    )
  }

  ngAfterViewInit(): void {
    this.calanderContainer.nativeElement.scrollTop = 533
  }

  ngOnDestroy(): void {
    this.subscriotion.forEach(sub => {
      sub.unsubscribe();
    })
  }

  updateTime() {
    const date = new Date()
    this.time.year = date.getFullYear();
    this.time.month = date.getMonth() + 1;
    this.time.day = date.getDate();
    this.time.hour = date.getHours();
    this.time.minute = date.getMinutes();
  }

}
