<div dirControl class="main-card card mb-4">
    <div class="card-body">
        <h3 class="card-title">{{'global administrators'|translatePipe}}</h3>
    </div>
</div>

<div dirControl class="main-card card mb-4 administrators">
    <div class="card-body">
        <div class="space-between">
            <h3 class="card-title">{{'administrators'|translatePipe}}</h3>
            <button (click)="open()" class="add"><i class="fa fa-fw" aria-hidden="true" title="add"></i></button>
        </div>
        <div class="row">
            <div class="col-6 col-sm-4 col-md-3 col-lg-2" *ngFor="let member of membersMap.administrators">
                <app-toggle-member [member]="member" (remove)="openSureModal($event)"></app-toggle-member>
            </div>
        </div>

    </div>
</div>

<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'moderators'|translatePipe}}</h3>
        <div class="row">
            <div class="col-6 col-sm-4 col-md-3 col-lg-2" *ngFor="let member of membersMap.moderators">
                <app-tooltip-member [member]="member"></app-tooltip-member>
            </div>
        </div>
    </div>
</div>

<ng-template #addMember let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Add administrators</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <app-dropdown-input [(text)]="administratorSearch" [dropDownOpions]="filteredNotAdministrators"
            (chooseOption)="openSureModal($event,close)">
        </app-dropdown-input>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

<ng-template #sureModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">
            {{isSureDelete?'Remove':'Give'}} admin permission
        </h5>

        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <p>
            {{
            isSureDelete?
            'Are you sure you want to remove admin permission to':
            'Are you sure to give admin permission to'
            }} {{choosenMember.identifier}}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" [class.btn-danger]="isSureDelete" [class.btn-primary]="!isSureDelete"
            (click)="onToggleIsAdministrator(close)">
            {{isSureDelete?'Remove':'Give'}}
        </button>
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>