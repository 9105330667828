<div dirControl class="row">

    <div class="col-md-6">
        <div class="main-card card mb-3">
            <div class="card-body">
                <div class="flex gap-10">
                    <div class="flex-1">
                        <app-dropdown-input [label]="'member'|translatePipe" [(text)]="searchMember" [dropDownOpions]="searchOptions"
                            (chooseOption)="onChooseUser($event)" name="member">
                        </app-dropdown-input>
                    </div>
                    <div class="">
                        <button class="btn btn-icon btn-success">
                            <i class="fa fa-fw" aria-hidden="true" title="send"></i>
                            <span>{{'send'|translatePipe}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="main-card card mb-3">
            <div class="card-body">
                <h3 class="card-title">{{event.invites.members.length + event.invites.guests.length}} {{'EventAttendance.invitedM&G'|translatePipe}}</h3>
                <app-event-attendance-progress-bar [inviteMapKeys]="inviteMapKeys"  [inviteMap]="inviteMap"  [length]="event.invites.members.length + event.invites.guests.length" ></app-event-attendance-progress-bar>
            </div>
        </div>
    </div>
</div>



<div dirControl *ngFor="let key of inviteMapKeys" class="main-card card mb-3">
    <div class="card-body">
        <div class="row mb-3 title-row">
            <h3 class="card-title">
                <span [ngClass]="key">{{key | translatePipe}}</span>
                <span *ngIf="inviteMap[key].listMember.length"> ({{inviteMap[key].listMember.length}} {{'users'|translatePipe}})</span>
                <span *ngIf="inviteMap[key].listMember.length"> ({{inviteMap[key].listGuest.length}} {{'guests'|translatePipe}})</span>

            </h3>
            <div *ngIf="isMoreThatLimit(inviteMap[key])" class="col-6 btn-container">
                <button class="btn btn-pill btn-outline-primary"
                    (click)="inviteMap[key].isShowAll= !inviteMap[key].isShowAll">
                    <ng-container *ngIf="inviteMap[key].isShowAll; else showLess">
                        {{'show less'|translatePipe}}
                    </ng-container>
                    <ng-template #showLess>
                        {{'show more'|translatePipe}}
                    </ng-template>
                </button>
            </div>
        </div>
        <div class="row">
            <!-- members under limit -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="let invited of inviteMap[key].listMember.slice(0,LIMIT)">
                <app-attendance-member-view (removeMember)="removeMember($event)" [member]="invited">
                </app-attendance-member-view>
            </div>
            <!-- if show all the rest of members -->
            <ng-container *ngIf="inviteMap[key].isShowAll">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                    *ngFor="let invited of inviteMap[key].listMember.slice(LIMIT-1,inviteMap[key].listMember.length)">
                    <app-attendance-member-view (removeMember)="removeMember($event)" [member]="invited">
                    </app-attendance-member-view>
                </div>
            </ng-container>

            <!-- if show less and have less members from limit -->
            <ng-container *ngIf="!inviteMap[key].isShowAll && inviteMap[key].listMember.length<LIMIT">
                <!-- if have more guests that guest limit show guest list -->
                <ng-container *ngIf="inviteMap[key].listGuest.length>LIMIT_GUEST; else allGuestUnderLimit">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <app-guest-list (openModal)="open(key)" [guests]="inviteMap[key].listGuest"></app-guest-list>
                    </div>
                </ng-container>

                <!-- if have less from guest limit show guests until the limit -->
                <ng-template #allGuestUnderLimit>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                        *ngFor="let invited of inviteMap[key].listGuest.slice(0,LIMIT-inviteMap[key].listMember.length)">
                        <app-attendance-member-view (removeMember)="removeMember($event)" [member]="invited">
                        </app-attendance-member-view>
                    </div>
                </ng-template>
            </ng-container>

            <!-- if show all  -->
            <ng-container *ngIf="inviteMap[key].isShowAll">
                <!-- if have more guests that guest limit show guest list -->
                <ng-container *ngIf="inviteMap[key].listGuest.length>LIMIT_GUEST; else allGuestUnderLimit">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                        <app-guest-list (openModal)="open(key)" [guests]="inviteMap[key].listGuest"></app-guest-list>
                    </div>
                </ng-container>

                <!-- if have less from guest limit show all guests -->
                <ng-template #allGuestUnderLimit>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="let invited of inviteMap[key].listGuest">
                        <app-attendance-member-view (removeMember)="removeMember($event)" [member]="invited">
                        </app-attendance-member-view>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>


<ng-template #content let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">{{currAttendance}} guests</h5>
        <button type="button" class="btn-close" (click)="close()">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 250px;" [config]="config">
            <div class="guest-list">
                <div *ngFor="let guest of inviteMap[currAttendance].listGuest" class="guest-container">
                    <span>{{guest.email}}</span>
                    <button (click)="removeMember(guest)"
                        class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only">
                        <i class="fa fa-fw" aria-hidden="true" title="members"></i>
                    </button>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">
            Close
        </button>
    </div>
</ng-template>

