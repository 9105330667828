import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-situation',
  templateUrl: './order-situation.component.html',
  styleUrls: ['./order-situation.component.scss']
})
export class OrderSituationComponent implements OnInit {

  constructor() { }

  @Input() situation: number;


  ngOnInit(): void {
  }

}
