import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'div [app-group-members]',
  templateUrl: './group-members.component.html',
  styleUrls: ['./group-members.component.scss']
})
export class GroupMembersComponent implements OnInit {
  @Input() members!: Member[];
  @Input() groupId!: String;
  @Output() onAddMember = new EventEmitter<string>()
  @Output() onRemoveMember = new EventEmitter<string>()


  searchMemberText = '';

  constructor(private membersService: MembersService) { }

  ngOnInit(): void {
  }

  getMemberToSearch() {
    return this.membersService.getMembers().filter(allMembersMember => {
      return allMembersMember.identifier.toLowerCase().includes(this.searchMemberText.toLowerCase()) &&
        !this.members.find(member => member.id === allMembersMember.id)
    })
  }
}
