<div dirControl class="main-card card">
    <div class="card-body">
        <h3 class="card-title">{{'cardView'|translatePipe}}</h3>
        <form class="">
            <app-input-label [label]="'title'|translatePipe" type="text" [(value)]="card.title"></app-input-label>
            <app-group-picker-input [label]="'group'|translatePipe" [(currGroup)]="card.group" [groups]="groupOptions"></app-group-picker-input>

            <div class="divider" [ngClass]="slideSituation"></div>
            <button (click)="toggleSlideSituation()" class="mb-2 me-2 btn-icon btn-pill btn btn-outline-primary mb-3">
                <i *ngIf="slideSituation==='out'" class="pe-7s-angle-up btn-icon-wrapper"></i>
                <i *ngIf="slideSituation==='in'" class="pe-7s-angle-down btn-icon-wrapper"></i>
                {{'advanceOptions' | translatePipe}}
            </button>

            <div [@slide-vertical]="slideSituation" class="">
                <div class="checkbox-flex-gap-10">
                        <input type="checkbox"
                            (change)="card.limitAmountOfTickets.isCheck=!card.limitAmountOfTickets.isCheck"
                            id="limitAmountOfTicketsCheck" class="form-check-input gap-check"
                            [checked]="card.limitAmountOfTickets.isCheck">
                    <label for="limitAmountOfTicketsCheck" class="flexyc-input-container">
                        <app-input-label name="limitAmountOfTickets" [label]="'CardViewAdvancedOptions.limit'|translatePipe" type="number"
                            [(value)]="card.limitAmountOfTickets.limit"></app-input-label>
                    </label>

                </div>
                <div class="checkbox-flex-gap-10">
                        <input type="checkbox" (change)="card.startDate.isCheck=!card.startDate.isCheck"
                            id="startDateCheck" class="form-check-input gap-check" [checked]="card.startDate.isCheck">
                    <label for="startDateCheck" class="flexyc-input-container">
                        <app-date-picker [label]="'CardViewAdvancedOptions.startDate'|translatePipe" [(timestamp)]="card.startDate.timestamp">
                        </app-date-picker>
                    </label>
                </div>
                <div class="checkbox-flex-gap-10">
                        <input type="checkbox" (change)="card.experationDate.isCheck=!card.experationDate.isCheck"
                            id="toDateCheck" class="form-check-input gap-check" [checked]="card.experationDate.isCheck">
                    <label for="toDateCheck" class="flexyc-input-container">
                        <app-date-picker [label]="'CardViewAdvancedOptions.experationDate'|translatePipe" [(timestamp)]="card.experationDate.timestamp">
                        </app-date-picker>
                    </label>
                </div>
                <div class="checkbox-flex-gap-10">
                        <input type="checkbox" (change)="card.expiredAfter.isCheck=!card.expiredAfter.isCheck"
                            id="expiredAfterCheck" class="form-check-input gap-check" [checked]="card.expiredAfter.isCheck">
                    <label for="expiredAfterCheck" class="flexyc-input-container">
                        <div class="flex gap-10">
                            <div class="flex-1">
                                <app-input-label name="expiredAfter" [label]="'CardViewAdvancedOptions.expiredAfter'|translatePipe" type="number"
                                    [(value)]="card.expiredAfter.amount"></app-input-label>
                            </div>
                            <div class="flex-1">
                                <app-dropdown-label [label]="'units'|translatePipe" [(value)]="card.expiredAfter.unit"
                                    [options]="expriedAfterTimeOptions"></app-dropdown-label>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <button (click)="onSave()" class="btn btn-success">{{'save'|translatePipe}}</button>
        </form>
    </div>
</div>