import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-attach-files',
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.scss']
})
export class AttachFilesComponent implements OnInit {

  private _files = []
  @Output() filesChange = new EventEmitter<any[]>();
  @Input() label

  @Input() set files(files: any[]) {
    this._files = files;
    this.filesChange.emit(files);
  }

  get files() {
    return this._files;
  }

  constructor(
    public domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  onUploadFiles(event) {
    const newFiles = Object.keys(event.target.files).map(key => event.target.files[key])
    this.files = [...this.files, ...newFiles]
  }

  removeFile(fileIdx) {
    this.files = this.files.filter((file, idx) => idx !== fileIdx);
  }

}
