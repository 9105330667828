import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppState } from 'src/app/store/app.store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit {
  @ViewChild('chatWrapper') elChatWrapper;
  @ViewChild('audioOption') audioPlayerRef: ElementRef;

  loggedInMember = this.memberService.emptyMember
  toggleMobileSidebar: any;
  faStar = faStar;
  faBusinessTime = faBusinessTime;
  faCog = faCog;
  faCalendarAlt = faCalendarAlt;
  faSearch = faSearch;
  loggedInUserId = "";
  member: Member = this.memberService.emptyMember;
  memberGroups: Group[] = []
  newMsgText = '';
  currChat = null
  chatsWithArr = [];
  chatsWithFilterArr = [];
  isShowChatsWith = true
  allMemberOfmemberGroups: Member[] = []
  filteredMemberOfmemberGroups: Member[] = []
  subscription: Subscription[] = [];
  private _searchChatWith = '';
  private _searchNewChatWith = '';
  isTyping$ = this.chatService.isTyping$
  scrollBehavihor = 'auto';

  get searchChatWith() {
    return this._searchChatWith
  }

  set searchChatWith(val) {
    this._searchChatWith = val
    this.setChatsWithFilterArr();
  }

  get searchNewChatWith() {
    return this._searchNewChatWith
  }

  set searchNewChatWith(val) {
    this._searchNewChatWith = val
    this.setFilteredMemberOfmemberGroups()
  }

  chatsMap = this.chatService.getChats()



  constructor(
    private memberService: MembersService,
    private organizationStructureService: OrganizationalStructureService,
    private elementRef: ElementRef,
    private store: Store<AppState>,
    private chatService: ChatService,
    public sanitizer: DomSanitizer,
  ) { }


  async ngOnInit(): Promise<void> {

    this.subscription.push(
      this.store.select('auth').subscribe(async auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
          this.loggedInMember = await this.memberService.getMember(this.loggedInUserId).toPromise();
        }
      })
    )

    this.member = await this.memberService.getMember(this.loggedInUserId).toPromise();
    const allGroups = await this.organizationStructureService.getGroups().toPromise()
    this.memberGroups = allGroups.filter(group => group.members.some(memberId => memberId === this.loggedInUserId))
    const allMemberIdsOfmemberGroups = this.memberGroups.reduce((acc, group) => {
      group.members.forEach(memberId => {
        if (!acc.some(accMemberId => accMemberId === memberId)) acc.push(memberId)
      })
      return acc
    }, [])
    const allMembers = await this.memberService.getMembersPromise();
    this.allMemberOfmemberGroups = allMembers.filter(member => allMemberIdsOfmemberGroups.some(memberId => memberId === member.id) && member.id !== this.loggedInUserId)
    this.setFilteredMemberOfmemberGroups()
    this.memberGroups.forEach(group => {
      if (!this.chatsMap[group.id]) this.chatService.addChat(group.id)
    })
    this.setChatWithArr()
  }

  ngAfterViewInit(): void {


  }

  newMsgTextChange(newMsg) {
    this.newMsgText = newMsg
    this.chatService.onTyping();
  }

  sendMsg(ev) {
    ev.preventDefault();
    this.chatService.sendMsg(this.newMsgText, this.currChat.id, this.loggedInMember);
    this.newMsgText = '';
    this.scrollBehavihor = 'smooth'
    setTimeout(() => {
      this.scrollChatWrapper();
      this.onAudioPlay();
    }, 1)
  }

  scrollChatWrapper() {
    this.elChatWrapper.nativeElement.scrollTop = this.elChatWrapper.nativeElement.scrollHeight
    setTimeout(() => {
      this.scrollBehavihor = 'auto';
    }, 200)
    // this.elChatWrapper.scrollTop = this.elChatWrapper.scrollHeight;
  }

  getChatData(id) {
    let lastMsgAt = 0
    if (this.chatsMap[id][this.chatsMap[id]]) {
      lastMsgAt = this.chatsMap[id][this.chatsMap[id].length - 1][this.chatsMap[id][this.chatsMap[id].length - 1].length - 1]?.sendAt
    }
    const order = lastMsgAt ? Date.now() - lastMsgAt : Date.now()
    const member = this.allMemberOfmemberGroups.find(member => member.id === id)
    if (member) return {
      name: member.identifier,
      imgUrl: member.profileImg,
      id,
      order,
      email: member.email,
      type:'member'
    }
    const group = this.memberGroups.find(group => group.id === id)
    if (group) return {
      name: group.name,
      imgUrl: group.logoUrl,
      id,
      order,
      type:'group'
    }
  }

  createNewChat(member) {
    this.isShowChatsWith = true;
    this.searchNewChatWith = '';
    const currChat = {
      name: member.identifier,
      imgUrl: member.profileImg,
      id: member.id,
      order: Date.now(),
      email: member.email
    }
    this.currChat = currChat
    this.chatsMap[member.id] = [];
    this.setChatWithArr();
  }

  returnToChatWith() {
    this.isShowChatsWith = true;
    this.searchNewChatWith = '';
  }

  chooseChat(chatWith) {
    this.currChat = chatWith;
    // this.scrollChatWrapper();
    setTimeout(() => {
      this.scrollChatWrapper();
    }, 10);

  }

  setChatsWithFilterArr() {
    this.chatsWithFilterArr = this.chatsWithArr.filter(chatWith => chatWith.name.toLowerCase().includes(this.searchChatWith.toLocaleLowerCase()))
  }

  setFilteredMemberOfmemberGroups() {
    this.filteredMemberOfmemberGroups = this.allMemberOfmemberGroups.filter(member => member.identifier.toLowerCase().includes(this.searchNewChatWith.toLocaleLowerCase()))
  }

  setChatWithArr() {
    this.chatsWithArr = Object.keys(this.chatsMap).map(key => this.getChatData(key));
    console.log(this.chatsWithArr);

    this.setChatsWithFilterArr();
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }
}
