<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="membersToShow" [dataSource]="members"
        [dataItemKeys]="['identifier','email']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member since'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.memberSince | formatDate}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'moderator'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.isAdministrator?'yes':'no'|translatePipe}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of membersToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <div class="options-container">
                            <a [routerLink]="'/member-view-server/'+dataItem.id"
                                class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i
                                    class="pe-7s-user btn-icon-wrapper">
                                </i></a>
                            <button (click)="changeMemberStatus.emit({id:dataItem.id,status:'suspension'})"
                                class="mb-2 me-2 btn-icon btn-icon-only btn btn-focus"><i
                                    class="pe-7s-tools btn-icon-wrapper">
                                </i></button>
                            <button (click)="console.log(123)"
                                class="mb-2 me-2 btn-icon btn-icon-only btn btn-secondary"><i
                                    class="pe-7s-mail btn-icon-wrapper">
                                </i></button>
                            <button (click)="removeMember.emit(dataItem.id)"
                                class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                                    class="pe-7s-delete-user btn-icon-wrapper"> </i></button>

                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="members.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>