import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Member } from 'src/app/interfaces/member';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-global-administrators',
  templateUrl: './global-administrators.component.html',
  styleUrls: ['./global-administrators.component.scss']
})
export class GlobalAdministratorsComponent implements OnInit {

  @ViewChild('addMember', { static: true }) addMember: any;
  @ViewChild('sureModal', { static: true }) sureModal: TemplateRef<any>;
  membersMap = {
    administrators: [],
    moderators: [],
    notAdministrators: [],
  };
  choosenMember = this.memberService.emptyMember;
  isSureDelete = false;
  filteredNotAdministrators = []
  private _administratorSearch = '';
  get administratorSearch() {
    return this._administratorSearch
  }
  set administratorSearch(value) {
    this._administratorSearch = value
    this.filteredNotAdministrators = this.membersMap.notAdministrators.filter(member => member.identifier.toLowerCase().includes(value.toLowerCase()))
  }

  constructor(
    private memberService: MembersService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.memberService.members$.subscribe(members => {
      this.membersMap = {
        administrators: [],
        moderators: [],
        notAdministrators: []
      };
      members.forEach(member => {
        if (member.isAdministrator) this.membersMap.administrators.push(member);
        else this.membersMap.notAdministrators.push(member);
        if (member.moderatorOf.length) this.membersMap.moderators.push(member);
      })
      this.filteredNotAdministrators = this.membersMap.notAdministrators.filter(member => member.identifier.toLowerCase().includes(this.administratorSearch.toLowerCase()))
    })
    this.memberService.loadMembers();
  }

  async onToggleIsAdministrator(closeFn) {
    closeFn()
    await this.memberService.toggleMemberIsAdministrator(this.choosenMember.id).toPromise();
  }

  open() {
    this.modal.open(this.addMember, { size: 'lg' });
  }

  openSureModal(member: Member, closeFn?: CallableFunction) {
    this.choosenMember = member
    this.isSureDelete = member.isAdministrator
    this.modal.open(this.sureModal, { size: 'sm' });
    if (closeFn) closeFn()
  }

  async onAddAdministrators(member) {
    await this.onToggleIsAdministrator(member.id);
    this.memberService.loadMembers();
  }

  

}
