import { Component, OnInit } from '@angular/core';
import {
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  startOfDay,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarView
} from 'angular-calendar';
import { EventsService } from 'src/app/services/events.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  green: {
    primary: '#3ac47d',
    secondary: '#58cd91'
  }
};

@Component({
  selector: 'app-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.scss']
})
export class EventManagementComponent implements OnInit {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  constructor(
    private modal: NgbModal,
    private eventService: EventsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.eventService.event$.subscribe(events => {
      const calanderEvents = events.map(event => {

        events.forEach(event => {
          if (!this.groups.some(group => group.id === event.info.group.id)) {
            this.groups = [...this.groups, event.info.group]
          }
        })

        const { hour, minute } = event.info.time
        const startTimeInMs = hour * 60 * 60 * 1000 + minute * 60 * 1000
        const { unit, amount } = event.info.length;
        const lengthInMs = unit === 'minutes' ? amount * 1000 * 60 : amount * 1000 * 60 * 60
        let color = colors.red;
        switch (event.info.group.type) {
          case 'department':
            color = colors.yellow
            break;
          case 'group':
            color = colors.green
            break;
        }
        return {
          start: new Date(startOfDay(new Date(event.info.timestamp)).getTime() + startTimeInMs),
          end: new Date(startOfDay(new Date(event.info.timestamp)).getTime() + startTimeInMs + lengthInMs),
          title: event.info.title,
          color: color,
          actions: this.actions,
          allDay: false,
          resizable: {
            beforeStart: false,
            afterEnd: false
          },
          draggable: false,
          id: event.id,
          description: event.info.description,
          groupId: event.info.group.id,
          image:event.info.image
        }
      })
      this.events = calanderEvents
    })

    this.eventService.loadEvents();
  }

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.router.navigate(['event-view', event.id]);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('delete', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-area-chart"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.router.navigate(['event-attendance', event.id]);
      }
    }
  ];
  refresh: Subject<any> = new Subject();
  events: any[] = [];
  activeDayIsOpen = false;
  currGroup = {
    type: 'all',
    id: 'all',
    name: 'all'
  }
  groups = [this.currGroup];
  get filterEvents() {
    if (this.currGroup.id === 'all') return this.events
    else return this.events.filter(event => event.groupId === this.currGroup.id)
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  deleteEvent(eventId, cb?) {
    this.eventService.deleteEvent(eventId).toPromise();
    if (cb) cb();
  }

}
