import { Component, Directive, NgModule, ElementRef, Renderer2, ViewChild, OnInit, HostListener } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { OtechI18nService } from 'projects/otech-i18n/src/lib/otech-i18n.service';

@Directive({
  selector: '[dirControl]',
})
export class DirControlDirective implements OnInit {

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private translateService: OtechI18nService
  ) {
    this.translateService.locale


  }

  ngOnInit(): void {
    this.translateService.dir$.subscribe(dir => {
      this._renderer.setAttribute(this._elRef.nativeElement, 'dir', dir);
    })
    this.translateService.loadLocale();

  }


}
