import { Component, OnInit } from '@angular/core';
import slideVerticalAnimation from 'src/app/animations/slide-vertical.animation';

@Component({
  selector: 'app-flexyc-mini-menu',
  templateUrl: './flexyc-mini-menu.component.html',
  styleUrls: ['./flexyc-mini-menu.component.scss'],
  animations: [
    slideVerticalAnimation
  ]
})
export class FlexycMiniMenuComponent implements OnInit {

  isMenuOpen=false

  constructor() { }

  ngOnInit(): void {
  }

  stopProp(ev) {
    ev.stopPropagation();
  }

}
