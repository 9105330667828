import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';
import { MembersService } from 'src/app/services/members.service';


@Component({
  selector: 'app-search-members',
  templateUrl: './search-members.component.html',
  styleUrls: ['./search-members.component.scss']
})
export class SearchMembersComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private memberService: MembersService,
    private globalVariableService: GlobalVariablesService,
    private router: Router
  ) {

  }

  textOptions = ['same', 'contain', 'end', 'start'];
  numberOptions = ['equal', 'greater', 'lower', 'greater equeal', 'lower equal'];
  fromTimestamp = Date.now() - 1000 * 60 * 60 * 24 * 7;
  toTimestamp = Date.now();
  filterName = '';
  isSavedFilterMobileOpen=false;

  userName = {
    option: 'contain',
    text: ''
  }

  email = {
    option: 'contain',
    text: ''
  }

  choosenFilter!: any;

  set savedFilter(val: any[]) {
    const stringsArray = val.map(obj => JSON.stringify(obj))
    localStorage.setItem('_saved_filter', stringsArray.join('/'));
  }

  get savedFilter(): any[] {
    const stringFilters = localStorage.getItem('_saved_filter')
    if(!stringFilters) return []
    const stringsArray = stringFilters?.split('/')
    return stringsArray?.map(string => JSON.parse(string)) ?? []
  }

  addNewFilter(cb: any) {
    if (!this.savedFilter.find(filter => filter.name === this.filterName)) {
      const newFilter = {
        name: this.filterName,
        data: {
          fromTimestamp: this.fromTimestamp,
          toTimestamp: this.toTimestamp,
          userName: this.userName,
          email: this.email
        }
      }
      this.savedFilter = [...this.savedFilter, newFilter]
      cb('Close click')
    }
    this.filterName = ''
  }

  disableSaveFilterButton() {
    return this.savedFilter.some(filter => filter.name === this.filterName)
  }

  onDeleteFilter() {
    if (!this.choosenFilter) return;
    this.savedFilter = this.savedFilter.filter(filter => filter.name !== this.choosenFilter.name)
  }

  onChooseFilter(filter) {
    this.choosenFilter = filter;
  }

  onLoadFilter() {
    if (!this.choosenFilter) return;
    const { userName, email, toTimestamp, fromTimestamp } = this.choosenFilter.data
    this.userName = { ...userName }
    this.email = { ...email }
    this.toTimestamp = toTimestamp
    this.fromTimestamp = fromTimestamp
    this.isSavedFilterMobileOpen=false
  }

  onRenameFilter(cb) {
    this.savedFilter = this.savedFilter.map(filter => {
      if (filter.name !== this.choosenFilter.name) return filter;
      const newFilter = {
        ...filter,
        name: this.filterName
      }
      this.choosenFilter = newFilter;
      return newFilter
    })
    cb('Close click')
  }

  openRename(content) {
    if (!this.choosenFilter) return false;
    this.open(content)
  }

  




  ngOnInit(): void {
    this.memberService.loadMembers();
  }


  clearSearch() {
    this.fromTimestamp = Date.now() - 1000 * 60 * 60 * 24 * 7;
    this.toTimestamp = Date.now();
    this.userName = {
      option: 'contain',
      text: ''
    }

    this.email = {
      option: 'contain',
      text: ''
    }
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
    }, (reason) => {
    });
  }

  getFilteredMembers(ev) {
    ev.preventDefault()
    const filteredMembers = this.memberService.getFilterMembers(this.userName, this.email, this.fromTimestamp, this.toTimestamp);
    this.globalVariableService.memberSearchResults = filteredMembers;
    this.router.navigate(['members-resaults']);
  }

}
