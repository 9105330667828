import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-candidate-posts',
  templateUrl: './portal-candidate-posts.component.html',
  styleUrls: ['./portal-candidate-posts.component.scss']
})
export class PortalCandidatePostsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
