import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TaskService } from 'src/app/services/task.service';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  subscription: Subscription[]=[];
  loggedInUserId='';
  tasks=[]

  constructor(
    private store: Store<AppState>,
    private taskService:TaskService,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.store.select('auth').subscribe(auth => {
        if (auth.id) {
          this.loggedInUserId = auth.id
          this.taskService.loadTasks();
        }
      })
    )

    this.subscription.push(
      this.taskService.tasks$.subscribe(tasks=>{
        if(this.loggedInUserId){
         this.tasks = tasks.filter(task=>(task.members.some(member=>member.id===this.loggedInUserId))&&task.startDate<=Date.now()); 
        }
      })
    )
  }

  updateTask(task) {
    this.taskService.editTask(task).toPromise();
  }

}
