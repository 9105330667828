import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent implements OnInit {

  constructor() { }

  @Input() guests: any[]
  @Output() openModal= new EventEmitter();

  public LIMIT= 5

  ngOnInit(): void {
  }

}
