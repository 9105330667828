<div dirControl class="row mb-3">
    <div class="col-12">
        <h3 class="card-title text-center mt-4">{{'total'|translatePipe}} {{cards.length}} {{'cards'|translatePipe}}</h3>
    </div>
    <div class="col-12">
        <div class="main-card card">
            <div class="card-body">
                <form>
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-card-memngment-table-table [cards]="filterCards()"></app-card-memngment-table-table>
<!-- <app-items-table [items]="filterCards()" [displayedColumns]="getColumn()" ></app-items-table> -->