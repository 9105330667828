<div dirControl class="main-card card  total-sets-card">
    <div class="card-body">

        <h3 class="card-title text-center">{{'user Control Panel'|translatePipe}}</h3>


        <ng-container *ngIf="isVerifyCodeOpen; else elseTemplate">
            <p>{{'what a code did you receive'|translatePipe}}?</p>
            <app-verify-code [(value)]="verifyCode"></app-verify-code>
            <ul class="verify-btns">
                <li>{{'code has been send to'|translatePipe}} {{newEmail}}. <span (click)="isVerifyCodeOpen=false">{{'change it'|translatePipe}}</span> </li>
                <li>{{'what code'|translatePipe}}? <span (click)="sendCode()">{{'i did not received anything'|translatePipe}}...</span> </li>
            </ul>
            <div class="btns-container">
               <button class="btn btn-success">{{'save'|translatePipe}}</button>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <app-input-label [syncValidators]="['password']" [(isValid)]="isCurrPasswordValid" [required]="true"
                [label]="'password'|translatePipe" [(value)]="currPassword" type="password"> </app-input-label>

            <app-input-label #userNameInput [asyncValidators]="['usernameTaken']" name="newUserName" [required]="true"
                [label]="'user name'|translatePipe" [(value)]="newUserName" type="text"> </app-input-label>

            <app-input-label [(isValid)]="isNewEmailValid" [email]="true" name="newEmail" [required]="true"
                [label]="'email'|translatePipe" [(value)]="newEmail" type="email"></app-input-label>

            <app-register-password labelPassword="new password" labelRepeatPassword="repeat new password"
                [(repeatPassword)]="newPassword" [(isPasswordValid)]="isNewPasswordValid">
            </app-register-password>

            <button (click)="onSave()" [disabled]="!isCurrPasswordValid" class="btn btn-success">{{'next'|translatePipe}}</button>
        </ng-template>
    </div>
</div>