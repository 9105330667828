<div class="btns mb-3" *ngIf="limit>3 && limit<=10">
    <button [class.used]="idx < used" *ngFor="let item of limitArr;let idx = index"></button>
</div>

<div class="double-btns mb-3" *ngIf="limit===2">
    <button class="btn" [class.used]="used>0"></button>
    <button class="btn" [class.used]="used>1"></button>
</div>
<div class=" mb-3" *ngIf="limit>10">
    <p>
        <ngb-progressbar [type]="progressType" [value]="progressVal" [striped]="true" [animated]="true">
            <i>{{used}} {{'outOf'|translatePipe}} {{limit}} {{'used'|translatePipe}}</i>
        </ngb-progressbar>
    </p>
</div>

<p *ngIf="limit>=2" class="text-center">{{used}} {{'outOf'|translatePipe}} {{limit}} {{'used'|translatePipe}}</p>