<div dirControl>

    <div dirControl class="mb-3">
        <h3 class="card-title text-center mt-4">{{'total'|translatePipe}} {{triggers.length}}
            {{'triggers'|translatePipe}}</h3>
        <!-- <a routerLink="">View user statistics...</a> -->
        <div class="">
            <div class=" card">
                <div class="card-body">
                    <form class="mb-3">
                        <div class="position-relative form-group search-container">
                            <label>
                                <i class="pe-7s-search"></i>
                            </label>
                            <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                                [placeholder]="'search'|translatePipe">
                        </div>
                    </form>
                    <!-- <div class="flex gap-10">
                    <button (click)="openFilter()" class="btn btn-primary">{{'filter'|translatePipe}}</button>
                    <button (click)="removeFilterStartDate()" class="remove-filter-btn" *ngIf="filter.startDate" >
                        <span>{{filter.startDate|formatDate}}</span>
                        <i class="pe-7s-close"></i>
                    </button>
                    <button (click)="removeFilterStatus()" class="remove-filter-btn" *ngIf="filter.status" >
                        <span >{{filter.status}}</span>
                        <i class="pe-7s-close"></i>
                    </button>
                    <button (click)="removeFilterUsers()" class="remove-filter-btn" *ngIf="filter.users.length" >
                        <span>{{filterUsersString}}</span>
                        <i class="pe-7s-close"></i>
                    </button>
                </div> -->
                </div>
            </div>
        </div>
    </div>






    <!-- <app-task-managment-table [tasks]="filterTasks()" (updateTask)="updateTask($event)"></app-task-managment-table> -->
    <app-trigger-managment-table [triggers]="filterTriggers()" (removeTrigger)="removeTrigger($event)">
    </app-trigger-managment-table>


    <div *ngIf="getNumOfSelected()" class="mb-3 card col-6">
        <div class="card-body">
            <form class="flex gap-10">
                <div class="flex-1">
                    <app-dropdown-label [label]="'actions'|translatePipe" [options]="actionsOptions" [(value)]="currAction"></app-dropdown-label>
                </div>
                <div>
                    <button (click)="onSelectedMembersAction()" class="btn btn-primary">{{'go'|translatePipe}}</button>
                </div>
            </form>
        </div>
    </div>



    <a routerLink="/trigger-view" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw"
            aria-hidden="true" title="add"></i></a>
</div>