import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TriggerService } from 'src/app/services/trigger.service';

@Component({
  selector: 'app-trigger-view',
  templateUrl: './trigger-view.component.html',
  styleUrls: ['./trigger-view.component.scss']
})
export class TriggerViewComponent implements OnInit {

  trigger: any = this.triggerService.getEmpty();
  subscription: Subscription[] = [];
  timerOptions = ['once a week', 'once a month', 'once a day'];
  typeOptions = ['notification', 'task'];
  dayOptions = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  monthDayOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];



  constructor(
    private triggerService: TriggerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async params => {
        const triggerId = params.get('id');
        if (triggerId) {
          this.trigger = await this.triggerService.getTrigger(triggerId).toPromise()

        }
      })
    )
  }



  updateTimer(timer) {
    this.trigger.timer = timer;
    if (this.trigger.timer === 'once a week' && !this.trigger.timeInWeek) {
      delete this.trigger.timeInMonth;
      delete this.trigger.timeInDay;
      this.trigger.timeInWeek = {
        day: 'sunday',
        time: { hour: 8, minute: 0 }
      }
    } else if (this.trigger.timer === 'once a month' && !this.trigger.timeInMonth) {
      delete this.trigger.timeInWeek;
      delete this.trigger.timeInDay;
      this.trigger.timeInMonth = {
        day: '1',
        time: { hour: 8, minute: 0 }
      }
    } else if (this.trigger.timer === 'once a day' && !this.trigger.timeInDay) {
      delete this.trigger.timeInWeek;
      delete this.trigger.timeInMonth;
      this.trigger.timeInDay = {
        time: { hour: 8, minute: 0 }
      }
    }
  }

  updateType(type) {
    this.trigger.type = type;
    if (this.trigger.type === 'notification' && !this.trigger.notificationData) {
      delete this.trigger.taskData;
      this.trigger.notificationData = {
        title: '',
        content: ''
      }
    } else if (this.trigger.type === 'task' && !this.trigger.taskData) {
      delete this.trigger.notificationData;
      const x = {
        title: '',
        group: {
          type: '',
          id: '',
          name: ''
        },
        files: [],
        content: '',
        startDate: Date.now(),
        deadline: Date.now(),
        priority: 1,
        id: "",
        status: 'pending'
      }
      this.trigger.taskData = {
        title: '',
        group: {
          type: '',
          id: '',
          name: '',
        },
        files: [],
        content: '',
        numOfDays:1,
        priority:1,
      }
    }
  }

  async onSave() {
    if (this.trigger.id) await this.triggerService.editTrigger(this.trigger).toPromise();
    else await this.triggerService.addTrigger(this.trigger).toPromise();
  }

}
