import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Structure } from '../interfaces/structure';
import { GlobalVariablesService } from './global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'organization/';
  private _structure$ = new BehaviorSubject<Structure>(null);
  public structure$ = this._structure$.asObservable();

  // LGBLW6J61G5ZTSWA
  
  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
    ) { }

    loadStructure(){
      this.getStracture().pipe(
        tap(structure=>this._structure$.next(structure))
      ).toPromise();
    }

    getStracture(){
      return this.http.get<Structure>(this.BASE_URL+'structure',{headers:{ouid:"LGBLW6J61G5ZTSWA"}});
    }
}
