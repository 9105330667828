import { Component, DoCheck, IterableDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Member } from 'src/app/interfaces/member';
import { Set } from 'src/app/interfaces/set';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationalStructureService } from 'src/app/services/organizational-structure.service';
import { SetsService } from 'src/app/services/sets.service';
import { UtilService } from 'src/app/services/util.service';
import { AppAsyncValidators } from 'src/app/validators/async-validators';

@Component({
  selector: 'app-member-profile-view',
  templateUrl: './member-profile-view.component.html',
  styleUrls: ['./member-profile-view.component.scss']
})
export class MemberProfileViewComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('modal') modal!: any;
  @ViewChild('userName') userName!: any;
  @ViewChild('email') email!: any;

  subscription: Subscription[] = []
  localSets = []
  iterableDiffer: any
  currLocalSetGroupId = ''
  member: Member = this.memberService.emptyMember;
  addToOptions: any[] = [];
  tempPassword: string = '';
  memberGroups: any[] = [];
  originalSetsMap = {}
  globalProfileImgSet: Set
  setMap = {
    global: [],
  }


  // choosenOption;
  get isSubmitDisable() {
    const isUserNameValid = true
    const isEmailValid = this.email?.formControler?.valid
    return !(isEmailValid && isUserNameValid)
  }

  updateLocalSets() {
    this.localSets.splice(0, this.localSets.length);
    Object.keys(this.setMap).forEach(key => {
      if (key !== 'global' && this.memberGroups.some(group => group.id === this.setMap[key][0].group.id)) {
        if (!this.localSets.length) this.currLocalSetGroupId = this.setMap[key][0].group.id
        this.localSets.push({ title: this.setMap[key][0].group.name, id: this.setMap[key][0].group.id, list: this.setMap[key] })
      }
    })

    const globalMemberSetIds = this.setMap.global.map(set => set.id);
    let localMemberSetIds = [];
    this.localSets.forEach(group => {
      group.list.forEach(set => localMemberSetIds.push(set.id))
    })

    const allMemberSetIds = [...globalMemberSetIds, ...localMemberSetIds]
    Object.keys(this.member.setMap).forEach(setId => {
      if (!allMemberSetIds.some(id => id === setId)) delete this.member.setMap[setId]
    })
  }

  // get currOption() {
  //   return 'choose Group/Department/Organization';
  // }

  // set currOption(value) {
  //   this.onChooseOption(value);
  // }

  constructor(
    public memberService: MembersService,
    public organizationalStructureService: OrganizationalStructureService,
    public setService: SetsService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private modalService: NgbModal,
    private router: Router,
    public asyncValidators: AppAsyncValidators,

    iterableDiffers: IterableDiffers
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }


  ngDoCheck(): void {
    let changes = this.iterableDiffer.diff(this.memberGroups);
    if (changes) {
      this.updateSets()
    }
  }

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(params => {
        const memberId = params.get('id');
        if (memberId) {
          this.subscription.push(
            this.memberService.getMember(memberId).subscribe(member => {
              this.originalSetsMap = {...member.setMap}
              this.member = member;
              this.updateSets();
            }))
        }
      })
    )

    this.subscription.push(
      this.organizationalStructureService.organizationalStructure$.subscribe(organizationalStructure => {
        this.addToOptions = organizationalStructure.map(child => ({
          id: child.id,
          type: child.type,
          name: child.name,
          string: `${child.type}: ${child.name}`
        }))
        this.sortAddToOptions();
        const fullMemberGroups = organizationalStructure.filter(child => child.members.some(memberId => memberId === this.member.id));
        this.memberGroups = this.addToOptions.filter(option => fullMemberGroups.some(group => group.id === option.id))
      })
    )

    this.subscription.push(
      this.setService.set$.subscribe(sets => {
        this.setMap = {
          global: [],
        }
        sets.forEach(set => {
          if (set.group.type === 'organization') {
            if (set.property.type === 'url') this.globalProfileImgSet = set;
            this.setMap.global.push(set);
            if (!this.member.setMap[set.id]) this.member.setMap[set.id] = set.default
          } else {
            if (this.setMap[set.group.id]) this.setMap[set.group.id].push(set)
            else this.setMap[set.group.id] = [set]
          }
        })
        this.updateSets()
      })
    )

    this.organizationalStructureService.loadOrganizationalStructure()
    this.memberService.loadMembers();
    this.setService.loadSets();
  }

  ngOnDestroy(): void {

    if (this.member.id) {
      const savedMember = this.memberService.getMembers().find(member => member.id === this.member.id);
      if (this.member.identifier !== savedMember.identifier ||
        this.member.email !== savedMember.email) {
        const isWantSave = confirm('You forgot to save the change, want to save them?');
        if (isWantSave) this.saveUser();
      }
    } else {
      if (this.member.email || this.member.identifier) {
        const isWantSave = confirm('You forgot to save the change, want to save them?');
        if (isWantSave) {
          console.log('here');
          this.navigateHere()
        }
      }
    }
  }

  navigateHere() {
    // const router = this.router
    // function nav() {
    //   router.navigate(['member-view'])
    // }
    // setTimeout(nav, 500);
  }

  async saveUser() {
    if (this.member.id) {
      this.memberService.updateMember(this.member).toPromise()
    } else {
      this.memberService.addMember(this.member)
      this.member = this.memberService.emptyMember;
    }
  }

  generateTempPassword() {
    this.tempPassword = this.utilService.generateTempPassword()
  }

  onChooseOption(option) {
    this.addToOptions = this.addToOptions.filter(addToOption => addToOption.id !== option.id);
    this.memberGroups = [...this.memberGroups, option];
  }

  // onRemoveOption(option) {
  //   const organizationStructure = this.organizationalStructureService.getOrganizationalStructure();
  //   const parentId = organizationStructure.find(group => group.id === option.id).parent;
  //   console.log(organizationStructure.find(group => group.id === parentId));

  //   console.log(parentId);

  //   console.log(organizationStructure.find(group => group.id === parentId));

  //   // if (organizationStructure.find(group => group.id === (organizationStructure.find(group => group.id === option.id).parent)).type !== 'department') {
  //   //   //open popup
  //   //   // this.choosenOption = option
  //   //   // this.open();
  //   // } else {
  //   //   this.memberGroups = this.memberGroups.filter(group => group.id !== option.id);
  //   //   this.addToOptions = [...this.addToOptions, option];
  //   // }
  // }

  sortAddToOptions() {
    this.addToOptions = this.addToOptions.filter(option => !this.memberGroups.some(group => group.id === option.id))
    this.addToOptions.sort((optionA, optionB) => {
      if (optionA.type > optionB.type) return 1
      if (optionA.type < optionB.type) return -1
      else return 0
    })
  }

  // onPopupDelete(cb: Function) {
  //   this.memberGroups = this.memberGroups.filter(group => group.id !== this.choosenOption.id);
  //   this.addToOptions = [...this.addToOptions, this.choosenOption];
  //   cb('Close click')
  // }

  open() {
    this.modalService.open(this.modal).result.then((result) => {
    }, (reason) => {
    });
  }

  async updateSets() {
    if (this.globalProfileImgSet) {
      if (this.originalSetsMap[this.globalProfileImgSet.id]) this.member.setMap[this.globalProfileImgSet.id] = this.originalSetsMap[this.globalProfileImgSet.id]
      else this.member.setMap[this.globalProfileImgSet.id] = this.globalProfileImgSet.default
      // if (!this.member.setMap[this.globalProfileImgSet.id]) this.member.setMap[this.globalProfileImgSet.id] = this.globalProfileImgSet.default
      // else this.member.setMap[this.globalProfileImgSet.id]= this.
    }
    Object.keys(this.setMap).forEach(key => {
      this.setMap[key].forEach(set => {
        if (this.memberGroups.some(group => group.id === set.group.id)) {
          if (this.originalSetsMap[set.id]) this.member.setMap[set.id] = this.originalSetsMap[set.id]
          else  this.member.setMap[set.id] = set.default
        }
      })
    })
    this.updateLocalSets()
  }
}
