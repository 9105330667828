<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="candidatesToShow" [dataSource]="candidates"
        [dataItemKeys]="['type']">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'full name'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of candidatesToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.fullName}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'member identifier'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of candidatesToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.member?.identifier}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of candidatesToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/account-view-server/account/'+this.accountService.joinTypeAndId(globalVariableService.AccountTypes['Candidate'],dataItem.id)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i class="pe-7s-user btn-icon-wrapper">
                        </i></a>
                    <button (click)="deleteCandidates.emit(dataItem)"
                        class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-delete-user btn-icon-wrapper"> </i></button>
                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="candidates.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>
