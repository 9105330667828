import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() label?: string
  @Input() name?: string = 'date'
  @Input() set timestamp(val: number) {
    this._timestamp = val;
    this.timestampChange.emit(val);
  }

  @Output() timestampChange = new EventEmitter<Number>();

  get timestamp() {
    return this._timestamp;
  }
  private _timestamp;
  ngbHelperDate: NgbDate;
  date: { year: number, month: number };
  get ngbDate() {
    this.updateNgbHelperDateByTimestamp(this.timestamp);
    return this.ngbHelperDate
  }
  set ngbDate(ngbDate) {
    this.ngbHelperDate = ngbDate
    this.timestamp = this.convertNgbHelperDateToTimestamp()
  }
  get dateString() {
    const date = new Date(this.timestamp);
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${day}/${month}/${year}`
  }
  set dateString(string: string) {
    const [day, month, year] = string.split('/')
    const date = new Date(+year, +month - 1, +day, 0, 0, 0, 0);
    this.timestamp = date.getTime();
    this.updateNgbHelperDateByTimestamp(date.getTime());
    this.ngbDate = new NgbDate(+year, +month, +day)
  }




  constructor(
    calendar: NgbCalendar,

  ) {
    this.ngbHelperDate = calendar.getToday();
  }

  ngOnInit(): void {
    if (!this.name) this.name = this.label ? this.label : 'date'
  }


  convertNgbHelperDateToTimestamp() {
    const timestamp = new Date(this.ngbHelperDate.year, this.ngbHelperDate.month - 1, this.ngbHelperDate.day).setHours(0, 0, 0, 0);
    return timestamp
  }

  updateNgbHelperDateByTimestamp(timestamp) {
    const date = new Date(new Date(timestamp).setHours(0, 0, 0, 0))
    this.ngbHelperDate.year = date.getFullYear()
    this.ngbHelperDate.month = date.getMonth() + 1
    this.ngbHelperDate.day = date.getDate()
  }

  updateDate(ev) {
    this.dateString = ev.target.value
  }

}
