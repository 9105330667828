<div dir="ltr" class="inputs-container mb-4">
    <div class="limit-width">

        <div class="input-container">
            <input class="verify-code" type="text">
        </div>
        <div class="input-container">
            <input class="verify-code" type="text">
        </div>
        <div class="input-container">
            <input class="verify-code" type="text">
        </div>
        <div class="input-container">
            <input class="verify-code" type="text">
        </div>
        <div class="input-container">
            <input class="verify-code" type="text">
        </div>
    </div>
</div>