import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AccountEventServerService } from 'src/app/services/account-event-server.service';
import { AccountServerService } from 'src/app/services/account-server.service';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';
import { MemberServerService } from 'src/app/services/member-server.service';
import { MembersService } from 'src/app/services/members.service';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';
import { SetsService } from 'src/app/services/sets.service';

@Component({
  selector: 'app-account-view-server',
  templateUrl: './account-view-server.component.html',
  styleUrls: ['./account-view-server.component.scss']
})
export class AccountViewServerComponent implements OnInit {

  @ViewChild('description') description;


  private _memberIdentidier = '';
  subscription: Subscription[] = [];
  account: any = this.accountService.getEmpty('');
  accountEvents = [];
  isEdit = false
  members = [];
  filteredMembers = [];
  emailSetId = '';
  phoneSetId = '';
  type = ''
  currProccess = 'onboarding';
  baseAccount = null

  proccessOptions = ['onboarding', 'development']

  get memberIdentidier() {
    return this._memberIdentidier
  }

  set memberIdentidier(value) {
    this._memberIdentidier = value;
    this.filterMembers()
  }

  get directManagerShowData() {
    return this.account.typeData.directManagers.map(directManager => directManager.identifier).join(', ')
  }
  get seconderyContactShowData() {
    return this.account.typeData.secondaryContacts.map(contact => contact.identifier).join(', ')
  }

  get accountTypeAndId(){
    return this.accountService.joinTypeAndId(this.globalVariableService.AccountTypes[this.account.type],this.account.id)
  }

  constructor(
    private route: ActivatedRoute,
    private accountService:AccountServerService,
    private accountEventService: AccountEventServerService,
    private memberService: MemberServerService,
    private setService: SetsService,
    private modalService: NgbModal,
    private router: Router,
    private globalVariableService: GlobalVariablesService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const type = params.get('type');
        const typeAndId = params.get('typeAndId');
        if (type) {
          this.type = type
          this.account = this.accountService.getEmpty(type);
        } else if (typeAndId) {
          const {type,id} = this.accountService.splitTypeAndId(typeAndId);
          const account: any = await this.accountService.getAccounts(this.globalVariableService.AccountTypes[type],+id).toPromise();
          switch (type) {
            case this.globalVariableService.AccountTypes['Employee']:
              this.type = "employee";
              account.type="Employee"
              break;
            case this.globalVariableService.AccountTypes['Customer']:
              this.type = "customer";
              account.type="Customer"
              break;
            case this.globalVariableService.AccountTypes['Candidate']:
              this.type = "candidate";
              account.type="Candidate"
              break;
          
            default:
              break;
          } 
          this.account = account
          if(account.member) this.memberIdentidier=account.member.identifier
          this.baseAccount = JSON.parse(JSON.stringify(this.account));
          this.getAccountEvent();
                    
        }
      })
    )

    this.members = await this.memberService.getMembers().toPromise();
    this.filterMembers();
    // const sets = await this.setService.getSets().toPromise();
    // sets.forEach(set => {
    //   if (set.property.type === 'phone') this.phoneSetId = set.id
    //   else if (set.property.type === 'email') this.emailSetId = set.id
    // })
  }

  filterMembers() {
    this.filteredMembers = this.members.filter(member => member.identifier.toLowerCase().includes(this.memberIdentidier.toLowerCase()))
  }

  toggleIsEdit() {
    if (this.isEdit) {
      this.account = JSON.parse(JSON.stringify(this.baseAccount));
      this.isEdit = false;
    } else {
      this.isEdit = true
    }
  }

  chooseMember(member) {
    this.memberIdentidier = member.identifier
    this.account.member = {
      id: member.id,
      identifier: member.identifier
    }
  }

  async saveAccount() {
    if (this.account.id) {
      await this.accountService.add(this.account).toPromise();
      this.baseAccount = JSON.parse(JSON.stringify(this.account));
      this.isEdit = false
    }
    else{
      const account:any = await this.accountService.add(this.account).toPromise()
      const TypeNumber = account.type
      const typeString = this.globalVariableService.AccountTypes[TypeNumber];
      this.account = {...account, type:typeString}
      this.router.navigate(['account-view-server', 'account', this.accountService.joinTypeAndId(TypeNumber,this.account.id)]);
    }
    //  this.account = await this.accountService.add(this.account)
    // this.baseAccount = JSON.parse(JSON.stringify(this.account));
    // this.isEdit = false
  }

  async getAccountEvent(){
    this.accountEvents = await this.accountEventService.getAccountEvent(this.globalVariableService.AccountTypes[this.account.type],this.account.id).toPromise();
  }

  async deleteAccountEvents(event){
    await this.accountEventService.delete([event]).toPromise();
    this.accountEvents = this.accountEvents.filter(accountEvent=>accountEvent.id!=event.id);
  }



  // setAccountEventMap() {
  //   const accountEventMap = {
  //     recent: [],
  //     onboarding: [],
  //     development: [],
  //     termination: [],
  //   }

  //   this.accountEvents.forEach(event => {
  //     this.proccessOptions = ['onboarding', 'development']
  //     if (!event.proccess) accountEventMap.recent.push(event)
  //     else {
  //       accountEventMap[event.proccess].push(event);
  //       if (event.proccess === 'termination') this.proccessOptions = ['onboarding', 'development', 'termination']
  //     }
  //   })

  //   this.accountEventMap = accountEventMap;

  // }

  async updateDirectManagers(groupId) {
    // const members = await this.memberService.getMembers().toPromise()
    // this.account.typeData.directManagers = members.filter(member => member.moderatorOf.some(moderatorOfListGroupId => moderatorOfListGroupId === groupId))
  }

  onGroupChange(group) {
    if (group?.id && this.account.typeData?.group?.id !== group.id) {
      
      this.account.typeData.group = group;
      this.updateDirectManagers(group.id);
    }
  }

  openDescription() {
    this.modalService.open(this.description).result.then((result) => {
    }, (reason) => {
    });
  }


}
