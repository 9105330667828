import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-social-network',
  templateUrl: './social-network.component.html',
  styleUrls: ['./social-network.component.scss']
})
export class SocialNetworkComponent implements OnInit {
  @ViewChild('popup', { static: true }) popup: TemplateRef<any>;


  constructor(
    private modal: NgbModal,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.modal.open(this.popup, { size: 'lg' });
    this.location.back()
  }
}
