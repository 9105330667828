import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampMoment'
})
export class TimestampMomentPipe implements PipeTransform {

  transform(value: number): string {
    if (this.checkIsToday(value)) return 'today'
    if (this.checkIsYesterday(value)) return 'yesterday'
    const date = new Date(value)
    const monthStr = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : '' + (date.getMonth() + 1)
    const dayStr = (date.getDate() < 10) ? '0' + date.getDate() : '' + (date.getDate())
    return `${dayStr}/${monthStr}/${date.getFullYear()}`
  }

  checkIsToday(ts): boolean {
    var today = new Date().setHours(0, 0, 0, 0);
    var thatDay = new Date(ts).setHours(0, 0, 0, 0);
    return today === thatDay
  }

  checkIsYesterday(ts) {
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var yesterdayTimestamp = yesterday.setHours(0, 0, 0, 0)
    var thatDay = new Date(ts).setHours(0, 0, 0, 0);
    return yesterdayTimestamp === thatDay
  }

}
