<div class="fexyc-table">
    <div *ngFor="let key of dataItemKeys;let idx = index" class="col-container">
        <div #ref class="">
            <!-- <template [ngTemplateOutlet]="templateVariable" [ngOutletContext]="{item: item}"></template> -->
            <ng-content [select]="[key]"></ng-content>
        </div>
        <div *ngIf="!ref.children.length" [class.first]="idx===0" class="col">
            <div class="col-header cell">
                <div *ngIf="idx===0" [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="selectAll=!selectAll" [checked]="selectAll" class="form-check-input"
                            type="checkbox">
                    </div>
                </div>
                {{key | translatePipe | titleCaseToSpaces | titleCase}}
            </div>
            <div (mouseover)="hoveredIdx=rowIdx" (mouseleave)="hoveredIdx=-1"
                *ngFor="let dataItem of dataForPage;let rowIdx = index" [class.bg-danger]="dataItem.isDanger" [class.hoverd]="rowIdx===hoveredIdx"
                class="cell">
                <div *ngIf="idx===0" [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="dataItem.isCheck=!dataItem.isCheck" [checked]="dataItem.isCheck"
                            class="form-check-input" type="checkbox">
                    </div>
                </div>
                <!-- <div class="checkbox-spacer"></div> -->
                <span>
                    {{dataItem[key]?.identifier || dataItem[key]?.title || dataItem[key]}}
                </span>
            </div>
        </div>
        <div *ngIf="idx===0" class="col-placeholder">
            <div class="col">
                <div class="col-header">
                    <div [class]="getClass()">
                        <div class="checkbox custom-checkbox custom-control form-check">
                            <input (change)="selectAll=!selectAll" [checked]="selectAll" class="form-check-input"
                                type="checkbox">
                        </div>
                    </div>
                    {{key | translatePipe | titleCaseToSpaces | titleCase}}
                </div>
                <div *ngFor="let dataItem of dataForPage" class="cell">
                    <div *ngIf="idx===0" [class]="getClass()">
                        <div class="checkbox custom-checkbox custom-control form-check">
                            <input (change)="dataItem.isCheck=!dataItem.isCheck" [checked]="dataItem.isCheck"
                                class="form-check-input" type="checkbox">
                        </div>
                    </div>
                    <span>
                        {{dataItem[key]?.identifier || dataItem[key]?.identifier || dataItem[key]}}
                    </span>

                </div>
            </div>
        </div>
    </div>
    <ng-content select="[options]"></ng-content>
    <div class="col last-palce">
        <div class="col-header cell">

        </div>
        <div (mouseover)="hoveredIdx=rowIdx" (mouseleave)="hoveredIdx=-1"
            *ngFor="let dataItem of dataForPage;let rowIdx = index" [class.hoverd]="rowIdx===hoveredIdx" [class.bg-danger]="dataItem.isDanger" class="cell">
        </div>
    </div>
</div>