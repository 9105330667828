import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MultySelectService {

  private BASE_URL = 'https://api.flexyc.com/multySelect';
  private _multySelect$ = new BehaviorSubject<any[]>([]);
  public multySelect$ = this._multySelect$.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  public loadMultySelect() {
    this.http.get<any[]>(this.BASE_URL)
      .subscribe(multySelect => {
        this._multySelect$.next(multySelect);
      })
  }

  public updateMultySelectKey(editedMultySelect) {
    return this.http.put(`${this.BASE_URL}/${editedMultySelect.id}`, editedMultySelect).pipe(
      tap(_ => {
        this.loadMultySelect();
      })
    )
  }

  public add(key) {
    const newMultySelect = {
      "key": key,
      "values": [],
    }
    return this.http.post(`${this.BASE_URL}`, newMultySelect).pipe(
      tap(_ => {
        // this.loadMultySelect();
      })
    )
  }
}
