import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Structure } from 'src/app/interfaces/structure';
import { DepartmentServerService } from 'src/app/services/department-server.service';
import { GroupServerService } from 'src/app/services/group-server.service';
import { OrganizationServerService } from 'src/app/services/organization-server.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-structure-view-server',
  templateUrl: './structure-view-server.component.html',
  styleUrls: ['./structure-view-server.component.scss']
})
export class StructureViewServerComponent implements OnInit, OnDestroy {

  subscription: Subscription[] = []
  currObject = null;
  currId = 0
  currType = ''
  parent = {
    id: 0,
    type: '',
    title: ''
  }
  isTitleValid = false;
  structure: Structure = null
  isViewA = true

  get currStructure() {
    return this.utilService.findChildInStructure(this.currId, this.currType, this.structure)
  }

  constructor(
    private organizationService: OrganizationServerService,
    private groupService: GroupServerService,
    private route: ActivatedRoute,
    private departmentService: DepartmentServerService,
    private utilService: UtilService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.organizationService.loadStructure();
    this.subscription.push(
      this.route.paramMap.subscribe(async (params) => {
        const structure = params.get('structure');
        const parent = params.get('parent');

        if (structure) {
          const [type, id] = structure.split('_');
          this.currId = +id
          this.currType = type;
          let parentStructure = null;
          if (this.structure) {
            parentStructure = this.utilService.findParentInStructure(+id, type, this.structure)
          } else {
            const allStructure = await this.organizationService.getStracture().toPromise();
            parentStructure = this.utilService.findParentInStructure(+id, type, allStructure)
          }
          this.parent = {
            id: parentStructure.id,
            type: parentStructure.type,
            title: parentStructure.title,
          }
          if (type === 'group') {
            this.currObject = await this.groupService.getById(id).toPromise();
          } else if (type === 'department') {
            this.currObject = await this.departmentService.getById(id).toPromise();
          }
        }
        else if (parent) {
          const [type, id] = parent.split('_');
          let parentStructure = null;
          if (this.structure) {
            parentStructure = this.utilService.findChildInStructure(+id, type, this.structure)
          } else {
            const allStructure = await this.organizationService.getStracture().toPromise();
            parentStructure = this.utilService.findChildInStructure(+id, type, allStructure);

          }
          this.parent = {
            id: parentStructure.id,
            type: parentStructure.type,
            title: parentStructure.title,
          }
          this.currType = this.parent.type === 'organization' ? 'department' : 'group'
          this.currObject = {
            title: '',
            description: ''
          }
        }
      })
    )

    this.subscription.push(
      this.organizationService.structure$.subscribe(structure => {
        if (structure) {
          this.structure = structure
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe())
  }

  async onSaveData() {
    if (this.currType === 'group') {
      if (this.currId) {
        // group edit
        await this.groupService
          .update({
            title: this.currObject.title,
            description: this.currObject.description,
            parentId: this.parent.id,
            isParentGroup: this.parent.type === 'group'
          }, this.currId).toPromise();
        this.organizationService.loadStructure();
      } else {
        // group add
        const newGroup: any = await this.groupService
          .add({
            groupTitle: this.currObject.title,
            groupDescription: this.currObject.description,
            parentID: this.parent.id,
            isParentGroup: this.parent.type === 'group'
          }).toPromise()
        this.organizationService.loadStructure();
        this.location.go('structure-view-server/group_' + newGroup.id)
        this.currObject = newGroup
        this.currId = newGroup.id
      }
    } else {
      if (this.currId) {
        //department edit
        await this.departmentService.update({
          title: this.currObject.title,
          description: this.currObject.description
        }, this.currId).toPromise();
        this.organizationService.loadStructure();
      } else {
        // department add 
        const newDepartment: any = await this.departmentService.add({
          title: this.currObject.title,
          description: this.currObject.description
        }).toPromise();
        this.organizationService.loadStructure();
        this.location.go('structure-view-server/department_' + newDepartment.id)
        this.currObject = newDepartment
        this.currId = newDepartment.id
      }
    }
  }

  toggleView() {
    this.isViewA = !this.isViewA
  }
}
