import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, tap } from 'rxjs/operators';
import { AppState } from '../store/app.store';
import { ErrorHandlerService } from './error-handler.service';
import { GlobalVariablesService } from './global-variables.service';
import * as AuthActions from '../store/actions/auth.actions'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private BASE_URL = this.globalVariablesService.location + 'auth/'
  private BASE_URL = 'https://localhost:44318/api/members/'
  private organizationId = ''
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private globalVariablesService: GlobalVariablesService,
    private store: Store<AppState>
  ) {
    store.select('organization').subscribe((organization) =>
      this.organizationId = organization.id
    );
  }

  public signup(email: string, password: string) {
    return this.http.post<any>(this.BASE_URL + 'signup', { email, password }).pipe(
      catchError(error => {
        this.errorHandler.openToast({ title: 'not signup', type: 'error', message: `not signup` })
        throw 'an error';
      }),
      tap(signupData => {
        this._handleAuthentication(signupData.token)
      })
    )
  }

  public login(memberIdentifier: string, password: string) {
    return this.http.post<any>(this.BASE_URL + 'login', { identifier:memberIdentifier, password }).pipe(
      catchError(error => {
        if(error.status!==200){
          this.errorHandler.openToast({ title: 'not signup', type: 'error', message: `not signup` })
        }
        throw 'an error';
      }),
      tap(loginData => {
        // this._handleAuthentication(loginData.token)
      })
    )
  }

  public autoLogin() {
    const userData = JSON.parse(localStorage.getItem('auth'));
    if (!userData) return;
    if (userData.token) this.store.dispatch(new AuthActions.login(userData.token))
  }

  public logout() {
    this.store.dispatch(new AuthActions.logout());
    localStorage.removeItem('auth');
  }

  private _handleAuthentication(token) {
    this.store.dispatch(new AuthActions.login(token))
    const authData = {token}
    localStorage.setItem('auth', JSON.stringify(authData))
  }
}
