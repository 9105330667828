import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-club-social-network',
  templateUrl: './club-social-network.component.html',
  styleUrls: ['./club-social-network.component.scss']
})
export class ClubSocialNetworkComponent implements OnInit {


  membersMap = {
    online: [],
    offline: []
  }
  onlineLength = 0;
  offlineLength = 0;

  private MAX_IN_PAGE = 12

  slideConfig = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };

  constructor(
    private memberService: MembersService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.memberService.members$.subscribe(members => {
      members.forEach(member => {

        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
        this.addMemberToOfflineMembers(member);
        this.addMemberToOnlineMembers(member);
      })
    })
    this.memberService.loadMembers();
  }


  addMemberToOnlineMembers(member) {
    const onlineLenght = this.membersMap.online.length
    if (!onlineLenght || this.membersMap.online[onlineLenght - 1].length === this.MAX_IN_PAGE) this.membersMap.online.push([member])
    else this.membersMap.online[onlineLenght - 1].push(member)
    this.onlineLength++;
  }

  addMemberToOfflineMembers(member) {
    const offlineLenght = this.membersMap.offline.length
    if (!offlineLenght || this.membersMap.offline[offlineLenght - 1].length === this.MAX_IN_PAGE) this.membersMap.offline.push([member])
    else this.membersMap.offline[offlineLenght - 1].push(member)
    this.offlineLength++;
  }

  onChooseMember(memberId){
    this.router.navigate(['member-profile', memberId]);
  }

}
