import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-usage-preview',
  templateUrl: './card-usage-preview.component.html',
  styleUrls: ['./card-usage-preview.component.scss']
})
export class CardUsagePreviewComponent implements OnInit {
  @Input() card: any
  @Input() cardImg: string
  @Input() isShowUseButton?: boolean = true

  @Output() useCard = new EventEmitter<string>();
  now = Date.now();
  constructor() { }

  ngOnInit(): void {
  }

}
