<div class="members-multy-select flexyc-input mt-4 mb-4" ngbDropdown>
    <form>
        <label [class.no-data]="!isFocus && text===''&& !values?.length" [for]="name">{{label | titleCase}}</label>
        <div [class.focus]="isFocus" class="members-container">
            <!-- <div class="members"> -->
                <div *ngFor="let value of values" class="member">
                    <span>
                        {{value.identifier}}
                    </span>
                    <button (click)="removeValue(value)" ><i class="pe-7s-close"></i></button>
                </div>
            <!-- </div> -->
            <input class="member-multy-select-input" autocomplete="off" [(ngModel)]="text" (focus)="isFocus=true" ngbDropdownToggle (blur)="isFocus=false"
                [name]="name" [id]="name" type="text">
        </div>
        <div *ngIf="filterMembers.length" ngbDropdownMenu class="dropdown-container">
            <button *ngFor="let member of filterMembers" ngbDropdown (click)="onChooseMember(member)"
                class="dropdown-item">{{member.identifier}}</button>
        </div>
    </form>
</div>
