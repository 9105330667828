import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-view',
  templateUrl: './member-view.component.html',
  styleUrls: ['./member-view.component.scss']
})
export class MemberViewComponent implements OnInit {

  @Input() member?: any;

  constructor() { }

  ngOnInit(): void {
  }

}
