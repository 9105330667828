<app-organization-header [organization]="organization"></app-organization-header>
<div class="login-container">

    <ng-container *ngIf="isShowAuth; else loginInputs">
        <div dirControl class="">
            <p>{{'what a code did you receive'|translatePipe}}?</p>
            <app-verify-code dir="ltr" [(value)]="verifyCode"></app-verify-code>
            <ul class="verify-btns">
                <li>{{'code has been send to'|translatePipe}} {{email}}. <span (click)="isShowAuth=false">{{'change
                        user'|translatePipe}}</span></li>
                <li>{{'what code'|translatePipe}}? <span (click)="sendCode()">{{'i did not received
                        anything'|translatePipe}}...</span></li>
            </ul>
            <div class="text-center">
                <button (click)="submitVerifyCode()" [disabled]="verifyCode.length<5"
                    class="btn btn-success mb-4">{{'submit'|translatePipe}}</button>
            </div>
        </div>
    </ng-container>


    <ng-template #loginInputs>
        <div dirControl class="">
            <h3 class="card-title text-center">{{'welcome back to'|translatePipe}} {{organization?.name}}
                {{'club'|translatePipe}}</h3>
            <app-input-label [(isValid)]="isEmailValid" [label]="'email'|translatePipe" name="email" [required]="true"
                [email]="true" type="email" [(value)]="email">
            </app-input-label>
            <app-input-label [(isValid)]="isPasswordValid" [label]="'password'|translatePipe" name="password"
                [required]="true" type="password" [syncValidators]="['password']" [(value)]="password">
            </app-input-label>
            <div class="text-center">
                <button (click)="login()" [disabled]="!isPasswordValid || !isEmailValid"
                    class="btn btn-success mb-4">{{'sign in'|translatePipe}}</button>
            </div>
            <ul>
                <li>
                    {{'new user'|translatePipe}}? <a [routerLink]="'/register/'+organization?.id">{{'sign up
                        here'|translatePipe}}</a>
                </li>
                <li>
                    {{'forgot your password'|translatePipe}}? <a
                        [routerLink]="'/forgot-password/'+organization?.id">{{'recover your account'|translatePipe}}</a>
                </li>
            </ul>
        </div>

    </ng-template>


</div>

