import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: number): string {
    const date = new Date(value)
    const monthStr = (date.getMonth() + 1<10)? '0'+(date.getMonth() + 1):''+(date.getMonth() + 1)
    const dayStr = (date.getDate()<10)? '0'+date.getDate():''+(date.getDate())
    return `${dayStr}/${monthStr}/${date.getFullYear()}`
  }

}
