import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountServerService } from 'src/app/services/account-server.service';
import { GlobalVariablesService } from 'src/app/services/global-variables.service';

@Component({
  selector: 'app-manage-candidate-table-server',
  templateUrl: './manage-candidate-table-server.component.html',
  styleUrls: ['./manage-candidate-table-server.component.scss']
})
export class ManageCandidateTableServerComponent implements OnInit {

  dataSource = new MatTableDataSource
  setsColumns: string[] = []
  page = 1;
  pageSize = 25;
  // displayedColumns = ['account name', 'member identifier', 'options'];
  @Input() candidates: any[];
  @Output() deleteCandidates = new EventEmitter<any>();
  candidatesToShow = []


  constructor(
    public globalVariableService:GlobalVariablesService,
    public accountService:AccountServerService
  ) { }

  ngOnInit(): void {
    this.setCandidatesToShow()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCandidatesToShow()
  }

  getClass() {
    return `checkbox-container ${this.candidates.find(member => member.isCheck) ? 'checked' : ''}`
  }

  setCandidatesToShow() {
    const start = (this.page - 1) * this.pageSize;
    this.candidatesToShow = this.candidates?.slice(start, start + this.pageSize);
  }

}
