import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';

@Component({
  selector: 'app-accounts-multy-select',
  templateUrl: './accounts-multy-select.component.html',
  styleUrls: ['./accounts-multy-select.component.scss']
})
export class AccountsMultySelectComponent implements OnInit {

  @Input() label: string
  @Input() name: string
  isFocus = false;
  accounts = [];
  filterAccounts = [];
  subscription: Subscription[] = []

  private _text = ''
  private _values = []
  @Output() valuesChange = new EventEmitter<any[]>();
  @Input() set values(values: any[]) {
    this._values = values;
    this.valuesChange.emit(values);
  }

  set text(value) {
    this._text = value
    this.setFilterAccounts()
  }

  get text() {
    return this._text
  }

  get values() {
    return this._values;
  }



  constructor(
    private portalAccountService: PortalAccountsService,
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.portalAccountService.portalAccounts$.subscribe(portalAccount => {
        this.accounts = portalAccount
        console.log(this.accounts);

      })
    )
    this.portalAccountService.loadPortalAccounts();
    this.setFilterAccounts()
  }


  setFilterAccounts() {
    console.log(this.accounts);

    this.filterAccounts = this.accounts.filter(account =>
      account.data.fullName.toLowerCase().includes(this.text.toLocaleLowerCase()) &&
      !this.values.some(value => value.id === account.id)
    )
  }

  onChooseAccount(account) {
    this.values = [...this.values, { fullName: account.data.fullName, id: account.id }]
    this.text = '';
    this.setFilterAccounts()
  }
  
  removeValue(value) {
    this.values = this.values.filter(valuesItem => valuesItem.id !== value.id);
    this.setFilterAccounts()
  }


}
