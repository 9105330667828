<div dirControl class="members card">
    <div class="card-body">
        <h3 class="card-title">{{'members'|translatePipe}}</h3>
        <div class="search-container" ngbDropdown>
            <form>
                <input id="member" [disabled]="!groupId" ngbDropdownToggle name="member" type="text" autocomplete="off"
                    class="form-control" [placeholder]="'member name'|translatePipe"
                    (ngModelChange)="onSearchMemberTextChange($event)" [ngModel]="searchMemberText">
            </form>
            <div *ngIf="searchMemberText && (searchMembers$|async)?.length" ngbDropdownMenu>
                <button *ngFor="let member of (searchMembers$|async)" (click)="addMemberToGroup(member.id)"
                    class="dropdown-item">{{member.identifier}}</button>
            </div>
        </div>

        <div class="member-list">
            <div *ngFor="let member of groupMembers" class="member-container">
                <span>{{member.identifier}}</span>
                <button (click)="removeMemberToGroup(member.id)"
                    class="border-0 btn-transition btn-pill btn btn-outline-danger btn-icon btn-icon-only">
                    <i class="fa fa-fw" aria-hidden="true" title="members"></i>
                </button>

            </div>
        </div>

        <div *ngIf="groupId" class="text-center">
            <a [routerLink]="'/group-manage-members/'+groupId" class=" btn btn-primary">{{'manage members'|translatePipe}}</a>
        </div>
    </div>
</div>