<!-- <div *ngIf="false" class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let set">
                <div [class]="getClass()">
                    <div class="checkbox custom-checkbox custom-control form-check">
                        <input (change)="set.isCheck=!set.isCheck" [checked]="set.isCheck" class="form-check-input"
                            type="checkbox">
                    </div>
                </div>
                {{set.title}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>type</mat-header-cell>
            <mat-cell *matCellDef="let set"> {{set.property.type}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="structure">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Structure</mat-header-cell>
            <mat-cell *matCellDef="let set"> {{set?.group?.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="default value">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Default value</mat-header-cell>
            <mat-cell *matCellDef="let set">
                {{set.default}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Options</mat-header-cell>
            <mat-cell *matCellDef="let set">
                <div class="options-container">
                    <a [routerLink]="'/set-view/'+set.id" class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning"><i
                            class="fa fa-fw" aria-hidden="true" title="edit"></i></a>
                    <button (click)="deleteSet(set.id)" class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-trash btn-icon-wrapper">
                        </i></button>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  
    <app-paginator [collectionSize]="sets.length" [(pageSize)]="pageSize" [(page)]="page" ></app-paginator>
</div> -->

<div *ngIf="true" dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="setsToShow" [dataSource]="sets"
        [dataItemKeys]="displayedColumns">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'type'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of setsToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.property.type | translatePipe }}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'structure'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of setsToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.group.type|translatePipe|titleCase}}: {{dataItem.group.name|titleCase}} 
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        {{'options'|translatePipe}}
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of setsToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/set-view/'+dataItem.id" class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning"><i
                            class="fa fa-fw" aria-hidden="true" title="edit"></i></a>
                    <button (click)="deleteSet(dataItem.id)" class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                            class="pe-7s-trash btn-icon-wrapper">
                        </i></button>

                    </div>
                </div>
            </div>

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="sets.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>

<a routerLink="/property-list" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw" aria-hidden="true" title="add"></i></a>
