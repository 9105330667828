import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariablesService } from './global-variables.service';

@Injectable({
  providedIn: 'root'
})
export class GroupServerService {

  private BASE_URL = this.globalVariableService.LOCATION + 'Group';


  constructor(
    private http: HttpClient,
    private globalVariableService: GlobalVariablesService,
  ) { }

  get() {
    return this.http.get<any[]>(this.BASE_URL)
  }

  getById(id: string | number) {
    return this.http.get<any>(this.BASE_URL + '/' + id);
  }

  update(updateGroupData: { title: string, description: string, parentId: number, isParentGroup: boolean }, groupId: number) {
    return this.http.put(this.BASE_URL + '/' + groupId, updateGroupData)
  }

  add(addGroupData: { groupTitle: string, groupDescription: string, parentID: number, isParentGroup: boolean }) {
    return this.http.post(this.BASE_URL, addGroupData)
  }

  remove(groupId: number | string) {
    return this.http.delete(this.BASE_URL + '/' + groupId);
  }

  getMembers(groupId: number | string) {
    return this.http.get<any>(this.BASE_URL + '/' + groupId + '/members');
  }

  addMember(groupId: number, memberId: number) {
    return this.http.post(this.BASE_URL + '/member', { groupID: groupId, memberID: memberId })
  }

  removeMember(groupId: number, memberId: number) {
    return this.http.delete(this.BASE_URL + '/member', { body: { groupID: groupId, memberID: memberId } })
  }

  removeMembers(groupId: number, memberIds: number[]) {
    return this.http.delete(this.BASE_URL + '/members', { body: { groupID: groupId, memberIDs: memberIds } })
  }


}
