<div dirControl class="row mb-3">
    <div class="col-12">
        <h3 class="card-title text-center mt-4">{{'total'|translatePipe}} {{employees.length}}
            {{'employees'|translatePipe}}</h3>
    </div>
    <div class="col-12">
        <div class=" card">
            <div class="card-body">
                <form class="mb-3">
                    <div class="position-relative form-group search-container">
                        <label>
                            <i class="pe-7s-search"></i>
                        </label>
                        <input id="search" name="search" [(ngModel)]="search" type="text" class="form-control"
                            [placeholder]="'search'|translatePipe">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-manage-employees-table-server (deleteEmployee)="deleteEmployee($event)" [employees]="filterEmployees()">
</app-manage-employees-table-server>

<div *ngIf="getNumOfSelected()">
    <div class="flex">
        <button (click)="deleteSelected()" class="btn btn-danger m-1">Delete employees</button>
        <button (click)="unselect()" class="btn btn-secondary m-1">unselect</button>
    </div>
</div>

<a routerLink="/account-view/type/employee" class="flow btn-icon btn-icon-only btn btn-success"><i class="fa fa-fw"
        aria-hidden="true" title="add"></i></a>