import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, forkJoin } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { AppState } from "../store/app.store";

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        private store: Store<AppState>,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> | UrlTree {
        const auth$ = this.store.select('auth').pipe(
            take(1),
            map(user => {
                return !!user.token
            })
        )

        const organizationId$ = this.store.select('organization').pipe(
            take(1),
            map(organization => organization.id)
        )

        return forkJoin([auth$, organizationId$]).pipe(
            map(resList => {
                const [isAuth, organizationId] = resList
                
                if (isAuth) return true;
                if (organizationId) this.router.navigate(['/login/'+organizationId])
                else this.router.navigate(['/login'])
            })
        )
    }

}