import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group } from 'src/app/interfaces/group';

@Component({
  selector: 'app-button-box-tree-container',
  templateUrl: './button-box-tree-container.component.html',
  styleUrls: ['./button-box-tree-container.component.scss']
})
export class ButtonBoxTreeContainerComponent implements OnInit {
  @Input() group !: Group;
  @Output() onChooseGroup = new EventEmitter<string>();


  constructor(
  ) { }

  ngOnInit(): void {
  }

}
