import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TriggerService {

  private BASE_URL = 'https://api.flexyc.com/triggers';
  private _triggers$ = new BehaviorSubject<any[]>([])
  public triggers$ = this._triggers$.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  public getEmpty(){
    return {
      id:"",
      title:'',
      timer:'once a week',
      timeInWeek:{
        day:'sunday',
        time:{hour:8,minute:0}
      },
      startOn:Date.now(),
      type:'notification',
      users:[],
      notificationData:{
        content:''
      }
    }
  }

  public loadTriggers() {
    this.http.get<any[]>(this.BASE_URL)
      .subscribe(triggers => {
        this._triggers$.next(triggers);
      })
  }

  public getTrigger(id: string) {
    return this.http.get<any>(`${this.BASE_URL}/${id}`)
  }

  public addTrigger(trigger) {
    delete trigger.id;
    return this.http.post<any>(this.BASE_URL, trigger).pipe(
      tap(_ => {
        this.loadTriggers();
      })
    )
  }

  public editTrigger(trigger) {
    return this.http.put<any>(`${this.BASE_URL}/${trigger.id}`, trigger).pipe(
      tap(_ => {
        this.loadTriggers();
      })
    )
  }

  public removeTrigger(triggerId: string) {
    return this.http.delete<any>(`${this.BASE_URL}/${triggerId}`).pipe(
      tap(_=>{
        this.loadTriggers();
      })
    ) 
  }

}
