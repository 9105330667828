import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss']
})
export class RangeDatePickerComponent implements OnInit {

  @Input() set fromTimestamp(val: number) {
    this._fromTimestamp = val;
    this.fromTimestampChange.emit(val);
  }

  @Output() fromTimestampChange = new EventEmitter<Number>();

  get fromTimestamp() {
    return this._fromTimestamp;
  }

  private _fromTimestamp;


  @Input() set toTimestamp(val: number) {
    this._toTimestamp = val;
    this.toTimestampChange.emit(val);
  }

  @Output() toTimestampChange = new EventEmitter<Number>();

  get toTimestamp() {
    return this._toTimestamp;
  }

  private _toTimestamp;





  hoveredDate: NgbDate;
  date: { year: number, month: number };
  ngbHelperDateFrom: NgbDate;
  ngbHelperDateTo: NgbDate;


  get fromDate() {
    this.updateNgbHelperDateFromByTimestamp(this.fromTimestamp);
    return this.ngbHelperDateFrom
  }

  set fromDate(ngbDate) {
    this.ngbHelperDateFrom = ngbDate
    this.fromTimestamp = this.convertNgbHelperDateFromToTimestamp()
  }

  get toDate() {
    this.updateNgbHelperDateToByTimestamp(this.toTimestamp);
    return this.ngbHelperDateTo
  }

  set toDate(ngbDate) {
    this.ngbHelperDateTo = ngbDate
    this.toTimestamp = this.convertNgbHelperDateToToTimestamp()
  }

  constructor(
    private calendar: NgbCalendar,

  ) {
    this.ngbHelperDateFrom = calendar.getToday();
    this.ngbHelperDateTo = calendar.getToday();
  }

  ngOnInit(): void {
  }

  convertNgbHelperDateFromToTimestamp() {
    const timestamp = new Date(this.ngbHelperDateFrom.year, this.ngbHelperDateFrom.month - 1, this.ngbHelperDateFrom.day).setHours(0, 0, 0, 0);
    return timestamp
  }

  updateNgbHelperDateFromByTimestamp(timestamp) {
    const date = new Date(new Date(timestamp).setHours(0, 0, 0, 0))
    this.ngbHelperDateFrom.year = date.getFullYear()
    this.ngbHelperDateFrom.month = date.getMonth() + 1
    this.ngbHelperDateFrom.day = date.getDate()
  }

  convertNgbHelperDateToToTimestamp() {
    console.log(this.ngbHelperDateTo);
    if(!this.ngbHelperDateTo){
      return 0
    }
    const timestamp = new Date(this.ngbHelperDateTo.year, this.ngbHelperDateTo.month - 1, this.ngbHelperDateTo.day).setHours(0, 0, 0, 0);
    return timestamp
  }

  updateNgbHelperDateToByTimestamp(timestamp) {
    const date = new Date(new Date(timestamp).setHours(0, 0, 0, 0))
    if(this.ngbHelperDateTo){
      this.ngbHelperDateTo.year = date.getFullYear()
      this.ngbHelperDateTo.month = date.getMonth() + 1
      this.ngbHelperDateTo.day = date.getDate()
    }
  }


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }



  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  chooseYesterday() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd');
    this.toDate = this.calendar.getToday();
  }

  chooseLastWeek() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 7);
    this.toDate = this.calendar.getToday();
  }

  chooseLastMonth() {
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);
    this.toDate = this.calendar.getToday();
  }

}
