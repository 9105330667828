import { Location } from '@angular/common';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent implements OnInit {

  subscription: Subscription[] = [];
  premmissionsOptions = [
    "cards", "members", "sets", "events"
  ]

  premmissionsOptionsMap: any = {
    cards: {
      isCheck: false,
      icon: 'pe-7s-id'
    },
    members: {
      isCheck: false,
      icon: 'pe-7s-users'
    },
    sets: {
      isCheck: false,
      icon: 'pe-7s-menu'
    },
    events: {
      isCheck: false,
      icon: 'pe-7s-date'
    }
  }




  constructor(
    private roleService: RoleService,
    private route: ActivatedRoute,
    private location: Location
  ) { }
  role = this.roleService.getEmptyRole();

  ngOnInit(): void {
    this.subscription.push(
      this.route.paramMap.subscribe(async params => {
        const roleId = params.get('id');
        if (roleId) {
          this.role = await this.roleService.getRole(roleId).toPromise();
          this.updatePremmissionsMap()
        }
      })
    )
  }

  togglePremmision(premmision) {
    this.premmissionsOptionsMap[premmision].isCheck = !this.premmissionsOptionsMap[premmision].isCheck;
    if (this.premmissionsOptionsMap[premmision].isCheck) this.role.premmissions = [...this.role.premmissions, premmision];
    else this.role.premmissions = this.role.premmissions.filter(premmisionItem => premmisionItem !== premmision)

  }

  updatePremmissionsMap() {
    this.premmissionsOptionsMap = {
      cards: {
        isCheck: false,
        icon: 'pe-7s-id'
      },
      members: {
        isCheck: false,
        icon: 'pe-7s-users'
      },
      sets: {
        isCheck: false,
        icon: 'pe-7s-menu'
      },
      events: {
        isCheck: false,
        icon: 'pe-7s-date'
      }
    }
    this.role.premmissions.forEach(premmission => {
      this.premmissionsOptionsMap[premmission].isCheck = true;
    });
  }

  async onSave(){
    if(this.role.id){
      await this.roleService.edit(this.role).toPromise();
    }else{
      await this.roleService.add(this.role);
    }
    this.location.back();
  }
}
