import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  public memberSearchResults = null;
  public globalSearchResults = {
    objects: [
      {
        "id": "os101",
        "type": "organization",
        "name": "vgames",
        "parent": "",
        "description": "",
        "logoUrl": "",
        "members": [
          "u106",
          "u101"
        ],
        "children": [
          "IETrEGD",
          "kuD-V-5",
          "0qkfovh"
        ]
      },
      {
        "name": "New Group",
        "type": "group",
        "parent": "os102",
        "description": "",
        "logoUrl": "",
        "members": [
          "u101"
        ],
        "children": [
          "JwzYYlg",
          "R-Dxgx_"
        ],
        "id": "SG8AARi"
      },
      {
        "name": "New Group",
        "type": "group",
        "parent": "SG8AARi",
        "description": "",
        "logoUrl": "",
        "members": [
          "u103",
          "u102"
        ],
        "children": [],
        "id": "JwzYYlg"
      },
      {
        "name": "New Group",
        "type": "group",
        "parent": "SG8AARi",
        "description": "",
        "logoUrl": "",
        "members": [],
        "children": [],
        "id": "R-Dxgx_"
      },
      {
        "name": "New Group",
        "type": "group",
        "parent": "os104",
        "description": "",
        "logoUrl": "",
        "members": [],
        "children": [],
        "id": "pJ0bgCk"
      },

    ],
    members: [
      {
        "id": "u101",
        "identifier": "user 1 sadsad sadsad hghj",
        "memberSince": 1644485980473,
        "lastSeen": 1645347538468,
        "email": "mashusdad@gmail.com",
        "isModerator": false,
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1633877993/f1hf5jwvzbibsc82p4pj.jpg",
        "status": "freeze"
      },
      {
        "id": "u102",
        "identifier": "user 2",
        "memberSince": 1644485980473,
        "lastSeen": 1645347538468,
        "email": "mashu@gmail.com",
        "isModerator": false,
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510770/u4qbs8fa1wfzz9s72jdq.jpg",
        "status": "freeze"
      },
      {
        "id": "u103",
        "identifier": "user 3",
        "memberSince": 1644485980473,
        "lastSeen": 1645347538468,
        "email": "mashu@gmail.com",
        "isModerator": true,
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510718/pz1tkukesptfyzuttlzb.jpg"
      },
      {
        "id": "u104",
        "identifier": "user 4",
        "memberSince": 1644485980473,
        "lastSeen": 1645347538468,
        "email": "mashu@gmail.com",
        "isModerator": true,
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510658/oicgtlwuclkrjeeibhaf.jpg"
      },
      {
        "id": "u105",
        "identifier": "user 5",
        "memberSince": 1644485980473,
        "lastSeen": 1645347538468,
        "email": "mashu@gmail.com",
        "isModerator": false,
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510434/nn4uhdwnuia5kii4dk6b.png"
      },
      {
        "id": "u106",
        "identifier": "user 6",
        "memberSince": 1644485980473,
        "lastSeen": 1645347538468,
        "email": "mashu@gmail.com",
        "isModerator": false,
        "profileImg": "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510554/fov0t5mwpbzojdsya0kv.jpg"
      }
    ],
    sets: [
    ],
    events: [],
    cards: [
      {
        "addAt": 1645962357857,
        "card": {
          "title": "all",
          "group": {
            "id": "os101",
            "type": "organization",
            "name": "vgames"
          },
          "limitAmountOfTickets": {
            "isCheck": true,
            "limit": 15
          },
          "startDate": {
            "isCheck": true,
            "timestamp": 1644789600000
          },
          "experationDate": {
            "isCheck": true,
            "timestamp": 1645912800000
          },
          "expiredAfter": {
            "isCheck": true,
            "amount": 3,
            "unit": "years"
          },
          "id": "c101"
        },
        "used": 13,
        "id": "idc5ffa02839141"
      },
      {
        "addAt": 1646058850866,
        "card": {
          "title": "Cools card",
          "group": {
            "id": "os101",
            "type": "organization",
            "name": "vgames"
          },
          "limitAmountOfTickets": {
            "isCheck": true,
            "limit": 10
          },
          "startDate": {
            "isCheck": true,
            "timestamp": 1649883600000
          },
          "id": "x8kHd1q"
        },
        "used": 6,
        "id": "id2c06de3b4229a"
      }
    ]
  };
  public logResaults = [
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
    {
      appliedBy: {
        id: "m101",
        identifier: 'user 1'
      },
      appliedOn: {
        id: 'c101',
        type: 'card-template',
        title: 'card 1'
      },
      logType: 'card template Change',
      description: 'card template title changed from "card 1" to "Best card"',
      logTime: 1647170110231
    },
  ]

  public properties = [
    {
      name: 'firstName',
      type: 'short-text',
      default: '',
      placeholder: 'First Name'
    },
    {
      name: 'lastName',
      type: 'short-text',
      default: '',
      placeholder: 'Last Name'
    },
    {
      name: 'profilePicture',
      type: 'url',
      default: 'https://res.cloudinary.com/dif8yy3on/image/upload/v1600550202/earzjsmule9up1ab6bta.gif',
      placeholder: ''
    },
    {
      name: 'birthDate',
      type: 'timestamp',
      default: Date.now(),
      placeholder: 'd/m/yyyy'
    },
    {
      name: 'email',
      type: 'email',
      default: '',
      placeholder: 'Email'
    },
    {
      name: 'phone',
      type: 'phone',
      default: '',
      placeholder: 'Phone number'
    },
    {
      name: 'number',
      type: 'number',
      default: 0,
      placeholder: ''
    },
    {
      name: 'text',
      type: 'short-text',
      default: '',
      placeholder: ''
    },
    {
      name: 'longText',
      type: 'long-text',
      default: '',
      placeholder: ''
    },
    {
      name: 'date',
      type: 'timestamp',
      default: Date.now(),
      placeholder: 'd/m/yyyy'
    },
    {
      name: 'time',
      type: 'time',
      default: { hour: 0, minute: 0 },
      placeholder: ''
    },
    {
      name: 'check',
      type: 'check',
      default: false,
      placeholder: ''
    },
    {
      name: 'registered',
      type: 'timestamp',
      default: Date.now(),
      placeholder: '',
      depends: []
    },
    {
      name: 'lastSeen',
      type: 'timestamp',
      default: Date.now(),
      placeholder: '',
      depends: []
    },
    {
      name: 'age',
      type: 'number',
      default: 0,
      placeholder: '',
      depends: ['birthDate']
    },
    {
      name: 'fullName',
      type: 'short-text',
      default: '',
      placeholder: '',
      depends: ['firstName', 'lastName']
    }
  ]

  LOCATION = "https://flexyc.oversight.co.il/api/";

  get AccountTypes(){
    enum Types {
      Employee,
      Candidate,
      Customer
    };

    return Types
  }

  get ObjectTypes(){
    enum Types
    {
        organization,
        department,
        group
    }
    return Types;
  }

  public httpOptionsFormData = {
    headers: new HttpHeaders({
     "Content-Type": "multipart/form-data"
    })
  };

  


  constructor() { }



}
