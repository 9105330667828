<div *ngIf="false" class="row">
    <div class="col-8">
        <div class="main-card card">
            <div class="card-body">
                <h3 class="card-title">{{'Log search'| translatePipe}}</h3>
                <form class="mb-3">
                    <div class="row mb-3">
                        <div class="col-4">
                            <app-dropdown-label name="searchOptionsValue" label="Search options"
                                [(value)]="value.option" [options]="textOptions">
                            </app-dropdown-label>
                        </div>
                        <div class="col-8">
                            <app-input-label label="Value" name="value" type="text" [(value)]="value.text">
                            </app-input-label>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div *ngFor="let searchCategory of searchFrom" class="custom-checkbox custom-control form-check">
                        <app-check-box-label [name]="searchCategory.name" [label]="searchCategory.name"
                            [(isCheck)]="searchCategory.isCheck"></app-check-box-label>
                    </div>
                    <div class="divider"></div>

                    <div class="mb-3">
                        <div class="checkbox-flex-gap-10">
                            <input type="checkbox" (change)="addedFiltersChecker.time=!addedFiltersChecker.time"
                                id="time" class="form-check-input gap-check" [checked]="addedFiltersChecker.time">
                            <label for="time" class="flexyc-input-container">
                                <div class="flex gap-10">
                                    <div class="flex-1">
                                        <app-date-picker label="Value time" [(timestamp)]="fromTimeStamp">
                                        </app-date-picker>
                                    </div>
                                    <div class="flex-1">
                                        <app-date-picker label="To" [(timestamp)]="toTimeStamp"></app-date-picker>
                                    </div>
                                    <div class="">
                                        <div class="mb-4">
                                            <app-range-date-picker [(fromTimestamp)]="fromTimeStamp"
                                                [(toTimestamp)]="toTimeStamp"></app-range-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="checkbox-flex-gap-10">
                            <input type="checkbox" (change)="addedFiltersChecker.group=!addedFiltersChecker.group"
                                id="group" class="form-check-input gap-check" [checked]="addedFiltersChecker.group">
                            <label for="group" class="flexyc-input-container">
                                <div class="mt-3">
                                    <app-group-picker-input label="part of structure" [(currGroup)]="currGroup"
                                        name="group-2" [groups]="groupsOptions">
                                    </app-group-picker-input>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div class="btns-container row">
                        <div class="right col-8">
                            <button (click)="onSearch($event)" class="mb-2 me-2 btn-icon btn btn-primary"><i
                                    class="fa fa-fw"></i>Search</button>
                            <button (click)="clearSearch()" class="mb-2 me-2 btn-icon btn btn-danger"><i
                                    class="fa fa-fw"></i>Clear</button>
                        </div>
                        <div class="left col-4">
                            <button (click)="open(content1)" class="mb-2 me-2 btn-icon btn btn-primary"><i
                                    class="fa fa-fw"></i>Save
                                Filter</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="main-card card saved-filter-container">
            <div class="card-body">
                <h3 class="card-title">{{'savedFilter'| translatePipe}}</h3>
                <div class="btns-container row">
                    <div class="col-4">
                        <button (click)="onLoadFilter()" class="mb-2 me-2 btn-icon btn btn-success col-12"><i
                                class="fa fa-fw"></i>Load</button>
                    </div>
                    <div class="col-4">
                        <button (click)="open(content2)" class="mb-2 me-2 btn btn-primary col-12">Rename</button>
                    </div>
                    <div class="col-4">
                        <button (click)="onDeleteFilter()" class="mb-2 me-2 btn-icon btn btn-danger col-12"><i
                                class="fa fa-fw"></i>Delete</button>
                    </div>
                    <div class="divider"></div>
                    <div class="filter-container">
                        <div *ngFor="let filter of savedFilter" [class.choosen]="filter.name===choosenFilter?.name"
                            (click)="onChooseFilter(filter)" class="col-12 filters">
                            <p class="mb-2 mt-2">{{filter.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div dirControl class="log-container">
    <div class="search-condition-container">
        <div class="main-card card">
            <div class="card-body">
                <div class="space-between">
                    <h3 class="card-title">{{'log search'| translatePipe}}</h3>
                    <button (click)="isSavedFilterMobileOpen=true" class="btn btn-primary saved-filter-toggle-btn"><i
                            class="fa fa-fw"></i>{{'saved filter'|translatePipe}} </button>

                </div>
                <form class="">
                    <div class="flex gap-10">
                        <div class="flex-1">
                            <app-dropdown-label name="searchOptionsValue" [label]="'options'|translatePipe"
                                [(value)]="value.option" [options]="textOptions">
                            </app-dropdown-label>
                        </div>
                        <div class="flex-1">
                            <app-input-label [label]="'value'|translatePipe" name="value" type="text"
                                [(value)]="value.text">
                            </app-input-label>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div *ngFor="let searchCategory of searchFrom" class="custom-checkbox custom-control form-check">
                        <app-check-box-label [name]="searchCategory.name" [label]="searchCategory.name|translatePipe"
                            [(isCheck)]="searchCategory.isCheck"></app-check-box-label>
                    </div>
                    <div class="divider"></div>

                    <div class="mb-3">
                        <div class="checkbox-flex-gap-10">
                            <input type="checkbox" (change)="addedFiltersChecker.time=!addedFiltersChecker.time"
                                id="time" class="form-check-input gap-check" [checked]="addedFiltersChecker.time">
                            <label for="time" class="flexyc-input-container">
                                <div class="flex gap-10 mb-3">
                                    <app-date-picker [label]="'from'|translatePipe" [(timestamp)]="fromTimeStamp">
                                    </app-date-picker>
                                    <app-date-picker [label]="'to'|translatePipe" [(timestamp)]="toTimeStamp">
                                    </app-date-picker>
                                    <div class="mb-4">
                                        <app-range-date-picker [(fromTimestamp)]="fromTimeStamp"
                                        [(toTimestamp)]="toTimeStamp"></app-range-date-picker>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="checkbox-flex-gap-10">
                                <input type="checkbox" (change)="addedFiltersChecker.group=!addedFiltersChecker.group"
                                    id="group" class="form-check-input gap-check" [checked]="addedFiltersChecker.group">
                            <label for="group" class="form-label form-check-label flexyc-input-container mt-2">
                                <app-group-picker-input [label]="'object'|translatePipe" [(currGroup)]="currGroup"
                                    name="group-2" [groups]="groupsOptions">
                                </app-group-picker-input>
                            </label>
                        </div>
                    </div>

                    <div class="btns-container row">
                        <div class="right col-8">
                            <button (click)="onSearch($event)" class="mb-2 me-2 btn-icon btn btn-primary"><i
                                    class="fa fa-fw"></i>{{'search'|translatePipe}}</button>
                            <button (click)="clearSearch()" class="mb-2 me-2 btn-icon btn btn-danger"><i
                                    class="fa fa-fw"></i>{{'clear'|translatePipe}}</button>
                        </div>
                        <div class="left col-4">
                            <button (click)="open(content1)" class="mb-2 me-2 btn-icon btn btn-primary"><i
                                    class="fa fa-fw"></i>{{'save filter'|translatePipe}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div [class.mobileClose]="!isSavedFilterMobileOpen" class="saved-filter-container">
        <div class="main-card card saved-filter-container">
            <div class="card-body">
                <div class="space-between">
                    <h3 class="card-title">{{'saved filter'| translatePipe}}</h3>
                    <button (click)="isSavedFilterMobileOpen=false" class="btn saved-filter-toggle-btn"><i
                            class="fa fa-fw"></i></button>
                </div>
                <div class="">
                    <div class="filter-container">
                        <div *ngFor="let filter of savedFilter" [class.choosen]="filter.name===choosenFilter?.name"
                            (click)="onChooseFilter(filter)" class="col-12 filters">
                            <p class="mb-2 mt-2">{{filter.name}}</p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="btns-container row">
                        <div class="col-4">
                            <button (click)="onLoadFilter()" class="mb-2 me-2 btn-icon btn btn-success col-12"><i
                                    class="fa fa-fw"></i>{{'load'|translatePipe}}</button>
                        </div>
                        <div class="col-4">
                            <button (click)="open(content2)"
                                class="mb-2 me-2 btn btn-primary col-12">{{'rename'|translatePipe}}</button>
                        </div>
                        <div class="col-4">
                            <button (click)="onDeleteFilter()" class="mb-2 me-2 btn-icon btn btn-danger col-12"><i
                                    class="fa fa-fw"></i>{{'delete'|translatePipe}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #content1 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Choose a filter name</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 150px;" [config]="config">
            <p>You must choose a none exist name</p>
            <input autocomplete="off" type="text" name="name" placeholder="name" class="form-control"
                [(ngModel)]="filterName">
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        <button [disabled]="disableSaveFilterButton()" (click)="addNewFilter(c)" type="button"
            class="btn btn-primary">Save filter</button>
    </div>
</ng-template>

<ng-template #content2 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Choose new name</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 150px;" [config]="config">
            <p>You must choose a none exist name</p>
            <input autocomplete="off" type="text" name="name" placeholder="name" class="form-control"
                [(ngModel)]="filterName">
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        <button [disabled]="disableSaveFilterButton()" (click)="onRenameFilter(c)" type="button"
            class="btn btn-primary">Save filter</button>
    </div>
</ng-template>

<!-- <ng-template #content1 let-c="close" let-d="dismiss">

    
    <div class="modal-header">
        <h4 class="modal-title">Choose a filter name</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 150px;" [config]="config">
            <p>You must choose a none exist name</p>
            <input autocomplete="off" type="text" name="name" placeholder="name" class="form-control"
                [(ngModel)]="filterName">
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        <button [disabled]="disableSaveFilterButton()" (click)="addNewFilter(c)" type="button"
            class="btn btn-primary">Save filter</button>
    </div>
</ng-template>

<ng-template #content2 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Choose new name</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <div class="modal-body">
        <perfect-scrollbar style="height: 150px;" [config]="config">
            <p>You must choose a none exist name</p>
            <input autocomplete="off" type="text" name="name" placeholder="name" class="form-control"
                [(ngModel)]="filterName">
        </perfect-scrollbar>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        <button [disabled]="disableSaveFilterButton()" (click)="onRenameFilter(c)" type="button"
            class="btn btn-primary">Save filter</button>
    </div>
</ng-template> -->