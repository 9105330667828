import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {

  @Input() label: string
  @Input() name: string
  @Input() type: string
  @Input() set time(val: any) {
    this._time = val;
    this.timeChange.emit(val);
  }
  @Output() timeChange = new EventEmitter<any>();
  get time() {
    return this._time;
  }
  private _time;


  constructor() { }

  ngOnInit(): void {
  }

}
