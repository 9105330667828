<code [class]="group?.type" (click)="onSelectChild.emit(group.id)">
    <div *ngIf="isShowButtons" [class.have-action]="!!action" class="hovered-control-btns">
        <button (click)="clickedHoveredButton('add',group.id)"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw" [title]="group?.type==='organization'?'add department':'add group'"></i>
        </button>
        <button  (click)="clickedHoveredButton('edit',group.id)"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw" [title]="group?.type==='organization'?'System settings':('Edit '+group?.type)" ></i>
        </button>
        <button *ngIf="group?.type!=='organization'" (click)="clickedHoveredButton('delete',group.id)"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw" [title]="'delete '+group?.type" ></i>
        </button>
        <button *ngIf="group?.type!=='department'" (click)="clickedHoveredButton('members',group.id)"
            class="mb-2 me-2 btn-icon btn-icon-only btn-pill btn btn-outline-primary">
            <i class="fa fa-fw" [title]="group?.type==='organization'?'Manage members':'Group members'" ></i>
        </button>
    </div>
    <p>{{group?.name}}</p>
    
</code>
<ul *ngIf="children?.length">
    <li [isShowButtons]="isShowButtons" (onSetCurrAction)="onSetCurrAction.emit($event)" [action]="action"
        *ngFor="let child of children" tree [group]="child" (onSelectChild)="onSelectChild.emit($event)"></li>
</ul>