import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PortalAccountsService } from 'src/app/services/portal-accounts.service';
import { PortalEventService } from 'src/app/services/portal-event.service';

@Component({
  selector: 'app-candidate-directory',
  templateUrl: './candidate-directory.component.html',
  styleUrls: ['./candidate-directory.component.scss']
})
export class CandidateDirectoryComponent implements OnInit {

  account: any = this.portalAccountService.getEmptyWithOutType();
  memberIdentidier = '';
  loggedInAccount = 'SPUaypD'
  accountEvents = []
  subscription: Subscription[] = [];



  constructor(
    private portalAccountService: PortalAccountsService,
    private portalEventService: PortalEventService,

  ) { }

  async ngOnInit(): Promise<void> {
    this.account = await this.portalAccountService.getById(this.loggedInAccount).toPromise();
    this.portalEventService.loadPortalEvents().toPromise();
    this.memberIdentidier = this.account.contactInfo.member.identifier

    this.subscription.push(
      this.portalEventService.portalEvents$.subscribe(events => {
        this.accountEvents = events.filter(event => (event.accountId === this.account.id) && !event.isVisivaleToAccount);
      })
    )
  }

  get directManagerShowData() {
    return this.account.typeData?.directManagers.map(directManager => directManager.identifier).join(', ')
  }

}
