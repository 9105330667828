import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Card } from '../interfaces/card';
import { Member } from '../interfaces/member';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  private BASE_URL = 'https://api.flexyc.com/members';
  private _members$ = new BehaviorSubject<Member[]>([])
  public members$ = this._members$.asObservable();
  public emptyMember = {
    identifier: '',
    id: '',
    memberSince: 0,
    isAdministrator: false,
    moderatorOf: [],
    email: '',
    profileImg: '',
    lastSeen: 0,
    status: '',
    cards: [],
    setMap: {}
  }


  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  public loadMembers() {
    this.http.get<Member[]>(this.BASE_URL)
      .subscribe(members => {
        this._members$.next(members);
      })
  }

  public getMember(id: string) {
    return this.http.get<Member>(`${this.BASE_URL}/${id}`)
  }

  public getMembersPromise() {
    return this.http.get<Member[]>(this.BASE_URL).toPromise()
  }

  public getMembers() {
    return this._members$.getValue()
  }

  public toggleMemberIsModerator(id: string, groupId: string) {
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === id);
    const newModeratorOf = beforeChanges.moderatorOf.some(id => id === groupId) ?
      beforeChanges.moderatorOf.filter(id => id !== groupId) :
      [...beforeChanges.moderatorOf, groupId]

    const afterChanges: Member = {
      ...beforeChanges,
      moderatorOf: newModeratorOf
    }
    return this.updateMember(afterChanges);
  }

  public toggleMemberIsAdministrator(id: string) {
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === id);
    const afterChanges: Member = {
      ...beforeChanges,
      isAdministrator: !beforeChanges.isAdministrator
    }
    return this.updateMember(afterChanges);
  }

  public changeMemberStatus(id: string, status: string) {
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === id);
    const afterChanges: Member = {
      ...beforeChanges,
      status: status
    }
    return this.updateMember(afterChanges);
  }

  public addCardToMember(memberId: string, card: Card) {
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === memberId);
    const newCard = {
      addAt: Date.now(),
      card: card,
      used: 0,
      id: this.utilService.generateId()
    }
    const afterChanges: Member = {
      ...beforeChanges,
      cards: [...beforeChanges.cards, newCard]
    }
    return this.updateMember(afterChanges).toPromise();
  }

  public useCardOfMember(memberId, cardId) {
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === memberId);
    let updatedCard = beforeChanges.cards.find(card => card.id === cardId);
    updatedCard.used++;
    const afterChanges: Member = {
      ...beforeChanges,
      cards: beforeChanges.cards.map(card => card.id === cardId ? updatedCard : card)
    }
    return this.updateMember(afterChanges).toPromise();
  }

  public updateCardOfMember(memberId, cardId, advanceOptions, used) {
    const { experationDate, expiredAfter, limitAmountOfTickets, startDate } = advanceOptions
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === memberId);
    let updatedCard = beforeChanges.cards.find(card => card.id === cardId);
    updatedCard = {
      ...updatedCard,
      used,
      card: {
        ...updatedCard.card,
        experationDate,
        expiredAfter,
        limitAmountOfTickets,
        startDate
      }
    }
    const afterChanges: Member = {
      ...beforeChanges,
      cards: beforeChanges.cards.map(card => card.id === cardId ? updatedCard : card)
    }
    return this.updateMember(afterChanges).toPromise();
  }

  public removeCardOfMember(memberId, cardId) {
    const beforeChanges: Member = this._members$.getValue().find(member => member.id === memberId);
    const afterChanges: Member = {
      ...beforeChanges,
      cards: beforeChanges.cards.filter(card => card.id !== cardId)
    }
    return this.updateMember(afterChanges).toPromise();
  }

  public findMemberByCardId(cardId) {
    return this.http.get<Member[]>(this.BASE_URL).pipe(
      map((members => members.find(member => member.cards.find(card => card.id === cardId))))
    ).toPromise();
  }

  public removeMember(id: string) {
    return this.http.delete<Member>(`${this.BASE_URL}/${id}`).pipe(
      map(result => {
        this.loadMembers();
        return true
      })
    )
  }

  public getFilterMembers(userName, email, fromTimestamp, toTimestamp) {
    return this.getMembers().filter(member => {
      if (!this._checkAllOptaion(userName, member.identifier)) return false;
      if (!this._checkAllOptaion(email, member.email)) return false;
      return (member.memberSince >= fromTimestamp && member.memberSince >= fromTimestamp)
    })
  }

  private _checkAllOptaion(filter, filtered) {
    if (filter.option === 'contain' && !filtered.includes(filter.text)) return false;
    if (filter.option === 'same' && filtered !== filter.text) return false;
    const filterCharts = filter.text.split('');
    const filteredCharts = filtered.split('');
    if (filter.option === 'start') {
      if (filterCharts.some((char, idx) => (char !== filteredCharts[idx]))) return false;
    }
    if (filter.option === 'end') {
      const reverseFilterCharts = filterCharts.reverse();
      const reverseFilteredCharts = filteredCharts.reverse();
      if (reverseFilterCharts.some((char, idx) => char !== reverseFilteredCharts[idx])) return false;
    }
    return true
  }

  public updateMember(updatedMember: Member) {
    return this.http.put<Member>(`${this.BASE_URL}/${updatedMember.id}`, updatedMember).pipe(
      map(result => {
        this.loadMembers();
        return updatedMember
      })
    )
  }

  public addMember(member) {
    delete member.id;
    member.lastSeen = Date.now();
    member.memberSince = Date.now();
    member.profileImg = "https://res.cloudinary.com/dif8yy3on/image/upload/v1633510434/nn4uhdwnuia5kii4dk6b.png";
    member.status = "active";
    this.http.post<Member>(this.BASE_URL, member).subscribe(_ => {
      this.loadMembers();
    })
  }

  public getAllCardsFromTamplate(templateId: string) {
    return this._members$.getValue().reduce((acc, member) => {
      member.cards.forEach(card => {
        if (card.card.id === templateId) acc = [...acc, { ...card, member: { identifier: member.identifier, id: member.id } }];
      })
      return acc
    }, [])
  }

  public async updateMemberSets(setMap,userId){
    const member = await this.getMember(userId).toPromise();
    member.setMap = setMap;
    this.updateMember(member).toPromise();
  }
}

