<!-- <div class="example-container mat-elevation-z8 mb-3">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="appliedBy">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Applied By</mat-header-cell>
            <mat-cell *matCellDef="let logSingle">{{logSingle.appliedBy.identifier}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="appliedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Applied On</mat-header-cell>
            <mat-cell *matCellDef="let logSingle">{{logSingle.appliedOn.title}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="logType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>logType</mat-header-cell>
            <mat-cell *matCellDef="let logSingle">{{logSingle.logType}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let logSingle">{{logSingle.description}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Time</mat-header-cell>
            <mat-cell *matCellDef="let logSingle">{{logSingle.logTime | formatDate}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="log?.length>pageSize" class="pagination-container">
        <app-paginator [collectionSize]="log.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
    </div>

</div> -->

<div dirControl class="mb-4">
    <app-flexyc-table #table [dataForPage]="logToShow" [dataSource]="log"
        [dataItemKeys]="displayedColumns">
        <ng-container options>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        Time
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of logToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.logTime | formatDate}}
                    </div>
                </div>
            </div>
            <!-- <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        Last seen
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of logToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell">
                        {{dataItem.lastSeen | formatDate}}
                    </div>
                </div>
            </div>
            <div class="col-container">
                <div class="col">
                    <div class="col-header cell">
                        Options
                    </div>
                    <div (mouseover)="table.hoveredIdx=rowIdx" (mouseleave)="table.hoveredIdx=-1"
                        *ngFor="let dataItem of logToShow;let rowIdx = index"
                        [class.hoverd]="rowIdx===table.hoveredIdx" class="cell cell-options">
                        <a [routerLink]="'/member-view/'+dataItem?.id"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"><i
                                class="pe-7s-user btn-icon-wrapper">
                            </i></a>
                        <a [href]="'mailTo:'+dataItem.email"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-secondary"><i
                                class="pe-7s-mail btn-icon-wrapper">
                            </i></a>

                        <button (click)="removeMember.emit(dataItem)"
                            class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger"><i
                                class="pe-7s-delete-user btn-icon-wrapper"> </i></button>

                    </div>
                </div>
            </div> -->

        </ng-container>

    </app-flexyc-table>
    <app-paginator [collectionSize]="log.length" [(pageSize)]="pageSize" [(page)]="page"></app-paginator>
</div>